<template>
  <div id="geoshape002" class="mt-2">
    <!-- <div>
      <b-btn @click="endPolygon" variant="primary">{{labels.map.endEdit}}</b-btn>
    </div> -->
    <!-- <b-btn v-if="properties.allowGpsButton" @click="getFromGPS">{{labels.map.addPointGPS}}</b-btn> -->
    <!-- <b-btn v-if="properties.allowGpsButton" @click="getFromGPS">{{labels.map.addPoint}}</b-btn> -->
    <div>
      <b-button @click="addPosition" variant="info" class="ml-1" v-if="!gpsInterval">
        <span class="glyphicon glyphicon-plus"></span>
      </b-button>
    </div>
    <b-button-group class="mt-2">
      <b-button v-b-modal.modalGeoshapeGPSInterval variant="secondary" v-if="!gpsInterval">
        <span class="glyphicon glyphicon-time"></span>
      </b-button>
      <b-button @click="startLogging" variant="success"  v-if="!gpsInterval">
        <span class="glyphicon glyphicon-forward"></span>
      </b-button>
      <!-- <b-button @click="getFromMap" variant="secondary" class="ml-1">
        <span class="glyphicon glyphicon-pause"></span>
      </b-button> -->
      <b-button @click="backToForm" variant="info" v-if="gpsInterval">
        <span class="glyphicon glyphicon-log-out"></span>
      </b-button>
      <b-button @click="endPolygon" variant="primary" >
        <span class="glyphicon glyphicon-stop"></span>
      </b-button>
    </b-button-group>
    <interval v-model="gpsIntervalSeconds"></interval>
  </div>
</template>

<script>
import base from './_baseComponent.js'
import map_utils from '../map_utils.js'
import interval from './geoshapeGPS_interval'

export default {
  name:'map-geoshape-gps',
  mixins:[base],
  components:{
    interval
  },
  data(){
    return{
      source:null,
      ObjToRemove:null,
      gpsIntervalSeconds:1,
      gpsInterval:null,
    }
  },
  computed:{
    position(){
      return this.$store.state.position.position
    },
    positionMap(){
      // position in map coordinates
      return map_utils.project_geom(this.$store.state.position.position,'EPSG:4326',this.map.getView().getProjection())
    },
    isLive(){
      if (this.properties.liveId && this.$store.state.formMap.formComponentLive[this.properties.liveId]){
        return true
      }
      return false
    },
  },
  watch:{
    isLive(){
      if(this.isLive==false){
        // has been destroyed, stop the interval.
        this.clear()
      }
    }
  },
  methods:{
    start(){
      // it's the watch of the start.
      // when created or updated
      console.log('start geoshape')
      var source = new ol.source.Vector();
      //add to source existing Geometry
      if(this.properties.currentValue){
        console.log(this.properties.currentValue)
        try{
          var geojson  = new ol.format.GeoJSON();
          let feat=geojson.readFeature(this.properties.currentValue,{
            featureProjection: this.map.getView().getProjection(),
            dataProjection: 'EPSG:4326'})
          source.addFeature(feat)
          if(this.$store.state.position.position_update_recenter){
            this.$store.commit('position/position_update_recenter')//toggle off
          }
          //center map on feature.
          let extent1=feat.getGeometry().getExtent()
          this.map.getView().setCenter([(extent1[0]+extent1[2])/2,(extent1[1]+extent1[3])/2]);
        }catch(e){
          console.error(e)
        }
      }else{
        // add an empty polygons
        // later or crash
      }

      //define layer
      var vector = new ol.layer.Vector({
       source: source,
       style: new ol.style.Style({
         fill: new ol.style.Fill({
           color: 'rgba(255, 255, 255, 0.2)'
         }),
         stroke: new ol.style.Stroke({
           color: '#ffcc33',
           width: 2
         }),
         image: new ol.style.Circle({
           radius: 7,
           fill: new ol.style.Fill({
             color: '#ffcc33'
           })
         })
       })
      });
      this.ObjToRemove={}
      this.ObjToRemove.layer=vector
      this.map.addLayer(vector)
      // var modify = new ol.interaction.Modify({source: source});
      // this.map.addInteraction(modify);
      // this.ObjToRemove.modify=modify
      // let draw = new ol.interaction.Draw({
      //     source: source,
      //     type: 'Polygon'
      //   });
      // this.map.addInteraction(draw);
      // this.ObjToRemove.draw=draw
      // let snap = new ol.interaction.Snap({source: source});
      // this.map.addInteraction(snap);
      // this.ObjToRemove.snap=snap
      this.source=source
      console.log('start geoshape******')
    },
    startLogging(){
      this.gpsInterval = window.setInterval(this.addPosition, this.gpsIntervalSeconds * 1000);
    },
    endPolygon(){
      if(this.gpsInterval){
        window.clearInterval(this.gpsInterval)
        this.gpsInterval=null
      }
      console.log('endPolygon');
      this.sendResponse()
      this.finished_me()
    },
    finished_me(){
      // TODO: **********************************************************************
      console.log('gps component finished')
      // console.log(this.response)
      this.$store.commit('formMap/removeFormComponentLive',this.properties.liveId)
      this.clear()
      this.$router.push(this.properties.returnRoute)
    },
    clear_sub(){
      if(this.gpsInterval){
        window.clearInterval(this.gpsInterval)
        this.gpsInterval=null
      }
      console.log('clear sub polygon')
      console.log(this.map.removeLayer(this.ObjToRemove.layer))
      // this.map.removeInteraction(this.ObjToRemove.draw);
      // this.map.removeInteraction(this.ObjToRemove.snap);
      // this.map.removeInteraction(this.ObjToRemove.modify);
    },
    backToForm(){
      this.$router.push(this.properties.returnRoute)
    },
    sendResponse(){
      // send response to listeners
      var geojson  = new ol.format.GeoJSON();
      var feat=this.source.getFeatures()[0]
      if(feat){
        let nbPoints = 0
        if(this.properties.isGeometryLine){
          // feat.setProperties({length:feat.getGeometry().getLength(), type: 'click'})
          feat.setProperties({length:map_utils.getLength(feat.getGeometry(),this.map.getView().getProjection()), input_type: 'GPS'})
          nbPoints=feat.getGeometry().getCoordinates().length
        }else{
          // feat.setProperties({area:feat.getGeometry().getArea(), type: 'click'})
          feat.setProperties({area:map_utils.getArea(feat.getGeometry(),this.map.getView().getProjection()), input_type: 'GPS'})
          feat.getGeometry().getCoordinates().map(x=>{
            nbPoints += x.length
          })
        }
        feat.setProperties({nbCoordinates: nbPoints})
        //send as geojson string...
        let as_geojson = geojson.writeFeatureObject(feat, {
          featureProjection: this.map.getView().getProjection(),
          dataProjection: 'EPSG:4326'
        });
        //send the object
        // console.log(as_geojson);
        this.response=as_geojson
        this.$store.commit('formMap/setFormComponentLiveResponse',{componentId:this.properties.liveId,response:as_geojson})
      }
    },
    addPosition(){
      console.log('add');
      console.log(this.position);
      // the interval had arrived and add new position to my polygon.
      var coords=[]
      
      var feat=this.source.getFeatures()[0]
      if(feat){
        coords = feat.getGeometry().getCoordinates()
      }
      if(this.properties.isGeometryLine==false){
        /// ****** polygon
        if(coords.length==0){
          coords[0]=[]
        }
        if (coords[0].length==0){
          coords[0].push([this.positionMap[0],this.positionMap[1]])
          coords[0].push([this.positionMap[0],this.positionMap[1]])
        }else{
          coords[0].pop()
          coords[0].push([this.positionMap[0],this.positionMap[1]])
          coords[0].push(coords[0][0])
        }
        if(feat){
          feat.getGeometry().setCoordinates(coords)
        }else{
          this.source.addFeature(new ol.Feature({geometry: new ol.geom.Polygon(coords)}))
        }
      }else{
        // it's a line
        coords.push([this.positionMap[0],this.positionMap[1]])
        if(feat){
          feat.getGeometry().setCoordinates(coords)
        }else{
          this.source.addFeature(new ol.Feature({geometry: new ol.geom.LineString(coords)}))
        }
      }
      this.sendResponse()

      
      // getCoordinates
      // setCoordinates
    }
  }
}
</script>


<style scoped>
#geoshape{
  position: fixed;
  /* bottom: 0; */
  right: 10px;
  /* left:300px; */
  /* left:0; */
  top:70px;
}
</style>

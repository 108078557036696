<template>
  <!-- <div class="d-flex justify-content-end"> -->
  <div>
    <div class="" v-if="$store.state.form.formDisplayOptions.showFlagCreate && !hasFlag && me.readonly===false">
      <b-btn size="sm" class="btn-flag btn-base-flag" @click="createFlag"><i class="mdi mdi-flag"></i></b-btn>
    </div>
    <div v-if="hasFlag">
      <b-btn size="sm" class="btn-flag-active btn-base-flag" :class="flag.status+'-btn'" @click="forceOpenFlagSection=!forceOpenFlagSection">
        <i class="mdi mdi-flag mr-1"></i>
        <i class="glyphicon" :class="{'glyphicon-chevron-right':!openFlagSection,'glyphicon-chevron-down':openFlagSection}"></i>
      </b-btn>
      <div class="card my-card-body mt-2" v-if="openFlagSection">
        <!-- <div class="">
          Flags
        </div> -->
        <div class="card-body">
          <b-list-group class="pl-4">
            <b-list-group-item v-for="message in messagesShow" class="p-2" :key="message.id">
              <div class="ml-2">
                <i class="mdi mdi-flag" :class="message.status"></i>
                {{message.message}}
              </div>
              <div class="text-muted small ml-4">
                {{message.flagged_by}} {{message.flagged_at.replace('T',' ').replace('Z','')}}
              </div>
            </b-list-group-item>
          </b-list-group>
          <div v-if="flag.status=='new'" class="mt-3">
            <b-form-textarea
              v-model="editText"
              rows="3"
            ></b-form-textarea>
            <div class="d-flex">
              <b-btn size="sm" class="mt-2" @click="saveNew" variant="primary" :disabled="!editText">
                <i class="mdi mdi-send"></i>
              </b-btn>
              <b-btn size="sm" class="mt-2 ml-3" @click="deleteNew" variant="warning">
                <i class="mdi mdi-trash-can"></i>
              </b-btn>

            </div>
          </div>
          <div v-if="flag.status!='new' && me.readonly===false" class="mt-3">
            <b-form-textarea
              v-model="editText"
              rows="3"
            ></b-form-textarea>
            <b-form-radio-group v-model="editStatus" stacked>
              <b-form-radio v-for="s1 in statusOptions" :value="s1.value" :key="s1.value">
                <i class="mdi mdi-flag" :class="s1.value"></i>
                {{s1.text}}
              </b-form-radio>
            </b-form-radio-group>
            <b-btn size="sm" class="mt-2" @click="saveAdd" variant="primary" :disabled="!canAdd">
              <i class="mdi mdi-send"></i>
            </b-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import utils_gen from '../../utils/utils_gen'
  import cuid from 'cuid'

  export default {
    name: 'input-row-footer-flag',
    props:['me'],
    data() {
      return {
        forceOpenFlagSection: false,
        editText: '',
        editStatus: null,
      }
    },
    computed: {
      labels(){
        return this.$store.state.labels.form.flags
      },
      statusOptions(){
        const status=[]
        // If flag already exists, then user can keep this status or even go back to raised status.
        status.push('flag-raised')
        status.push('flag-solved')
        if(this.$store.getters['form/userCanSolveFlag']){
          status.push('flag-validated')
        }
        return status.map(x=>({value: x, text: this.labels[x]}))
      },
      config() {
        return this.me.config
      },
      hasFlag(){
        return this.me.hasFlag
      },
      flag(){
        return this.me.flag
      },
      openFlagSection(){
        return this.forceOpenFlagSection || this.flag?.status=='new'
      },
      messagesShow(){
        return this.flag.messages.filter(x=>x.status!='new')
      },
      canAdd(){
        return !!this.editText && !!this.editStatus
      }
    },
    watch: {
      forceOpenFlagSection() {
        if (this.forceOpenFlagSection){
          this.reset()
        }
      },
      editStatus(){
        if (this.editStatus!='flag-raised' && this.editStatus!='new' && !this.editText){
          this.editText='Ok'
        }
      }
    },
    methods: {
      createFlag() {
        const newFlag={
          'status': 'new',
          messages: [
            {
              'id': cuid(),
              // 'status': 'flag-raised',
              'status': 'new',
              'flagged_by': this.$store.getters['userName'],
              'flagged_at': this.$store.getters['utcCurrentTime'](),
            }
          ],
        }
        utils_gen.setPropTree2(this.me.surveyDoc.form_data,this.me.flagNamePath,newFlag)
      },
      saveNew(){
        const newFlag= JSON.parse(JSON.stringify(this.flag))
        newFlag.messages[0].message=this.editText
        newFlag.messages[0].status='flag-raised'
        newFlag.status='flag-raised'
        utils_gen.setPropTree2(this.me.surveyDoc.form_data,this.me.flagNamePath,newFlag)
        this.forceOpenFlagSection=true
      },
      deleteNew(){
        utils_gen.setPropTree2(this.me.surveyDoc.form_data, this.me.flagNamePath, null)
      },
      saveAdd(){
        const newFlag= JSON.parse(JSON.stringify(this.flag))
        const message= {
          'id': cuid(),
          // 'status': 'flag-raised',
          'status': this.editStatus,
          'message':this.editText,
          'flagged_by': this.$store.getters['userName'],
          'flagged_at': this.$store.getters['utcCurrentTime'](),
        }
        newFlag.messages.push(message)
        newFlag.status=message.status
        utils_gen.setPropTree2(this.me.surveyDoc.form_data,this.me.flagNamePath,newFlag)
        this.reset()
      },
      reset(){
        // this.forceOpenFlagSection=false
        this.editText=''
        this.editStatus=this.flag.status
      },
    },
  }
</script>

<style scoped>
  .btn-base-flag{
    font-size: 1rem;
    padding: 0rem 0.3rem;
  }
  .btn-flag{
    background-color: rgb(255, 207, 159);
    border: 0;
    color: rgb(64, 64, 64);
  }
  .btn-flag:hover{
    background-color: rgb(252, 178, 103);
    outline: none;
    color: rgb(64, 64, 64);
  }
  .btn-flag:focus{
    background-color: rgb(252, 178, 103);
    outline: none;
    color: rgb(64, 64, 64);
  }
  .btn-flag-active{
    background-color: rgb(255, 164, 74);
    border: 0;
    color: rgb(64, 64, 64);
  }
  .btn-flag-active:hover{
    background-color: rgb(254, 130, 5);
    outline: none;
    color: rgb(64, 64, 64);
  }
  .btn-flag-active:focus{
    background-color: rgb(254, 130, 5);
    outline: none;
    color: rgb(64, 64, 64);
  }
  .my-card-body{
    padding: 0.5rem; 
    /* background-color: #e0ba7b !important; */
    background-color: #fceed8 !important;
  }
  .flag-raised-btn {
    background-color: #ff4f4f
  }
  .flag-solved-btn {
    background-color: #634c27;
    color: #fceed8;
  }
  .flag-validated-btn {
    background-color: #18932a
  }
  .flag-raised-btn:hover {
    background-color: #ff4f4f
  }
  .flag-solved-btn:hover {
    background-color: #634c27;
    color: #fceed8;
  }
  .flag-validated-btn:hover {
    background-color: #18932a
  }
  .flag-raised-btn:focus {
    background-color: #ff4f4f
  }
  .flag-solved-btn:focus {
    background-color: #634c27;
    color: #fceed8;
  }
  .flag-validated-btn:focus {
    background-color: #18932a
  }
</style>

<style>
  .flag-raised {
    color: #ff4f4f
  }
  .flag-solved {
    color: #634c27
  }
  .flag-validated {
    color: #18932a
  }
</style>
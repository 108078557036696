<template>
  <div class="my-3">
    <input-row 
      :showElement="showElement"
      :label="label" 
      :elId="elId" 
      :status="status" 
      :config="config"
      :me="me"
    >
      <!-- Autocomplete - could be possible not tested -->
          <!-- validationInputClass - don't work. it pu there, but the search input box is kind of not standard -->
      <!-- <v-select  v-if="appearance=='autocomplete'"
          v-model="myValue2"
          label="label"
          :options="options"
          taggable
          :maxShownChoices="50"
          :validationInputClass="{'is-valid':status_list[this.myStatus].input_state,'is-invalid':!status_list[this.myStatus].input_state}"
          ></v-select> -->
      <!-- label button inline -->
      <b-form-checkbox-group v-if="appearance=='compact'"
        v-model="myValue"
        stacked
        :disabled="readonly"
      >
        <div class="border border-top-secondary" :class="i==0?'rounded-top':i==(options.length-1)?'rounded-bottom border-top-0':'border-top-0'" v-for="(opt, i) in options" :key="elId+opt.name+opt.sequence">
          <div class="p-2" :class="myValue.indexOf(opt.name)!=-1?'my-compact-selected':''">
            <b-form-checkbox class="text-left text-reset my-custom-control"  :value="opt.name" ><select-option-text :opt="opt" :appearance_aecom="appearance_aecom" /></b-form-checkbox>
          </div>
        </div>
      </b-form-checkbox-group>
      <b-form-checkbox-group v-else-if="appearance=='quickcompact' || appearance=='horizontal-compact'"
        v-model="myValue"
        :disabled="readonly"
        stacked
        class="d-flex flex-row flex-wrap"
      >
        <div class="border border-top-secondary" :class="i==0?'rounded-top':i==(options.length-1)?'rounded-bottom':''" v-for="(opt, i) in options" :key="elId+opt.name+opt.sequence">
          <div class="p-2" :class="myValue.indexOf(opt.name)!=-1?'my-compact-selected':''">
            <b-form-checkbox class="text-left text-reset"  :value="opt.name" ><select-option-text :opt="opt" :appearance_aecom="appearance_aecom" /></b-form-checkbox>
          </div>
        </div>
      </b-form-checkbox-group>
      <!-- select view -->
      <!-- TODO support for minimal more options as the others... but is it used? -->
      <b-form-select v-else-if="appearance=='minimal'"
              v-model="myValue"
              :options="options"
              multiple
              text-field="label"
              value-field="name"
              :disabled="readonly"
              />
      <!-- default radio stacked -->
      <b-form-checkbox-group v-else
          v-model="myValue"
          :stacked="defaultStacked"
          :disabled="readonly"
        >
          <b-form-checkbox v-for="opt in options" :value="opt.name" :key="elId+opt.name+opt.sequence" :class="defaultStacked?'my-custom-control':''"><select-option-text :opt="opt" :appearance_aecom="appearance_aecom" /></b-form-checkbox>
        </b-form-checkbox-group>
      <!-- Display messages -->
      <b-alert show variant="danger" v-if="optionNotInList">
        <div>{{ labels.form.edit.valueNotInList }}</div>
        <div class="h5">{{value}} <b-btn @click="myValue=null" class="ml-4" size="sm" v-if="!readonly"><i class="mdi mdi-delete"></i></b-btn></div>
      </b-alert>
    </input-row>
  </div>
</template>

<script>
import base from './_baseInput.js'
import select_options from './subInput/select_options.js'
import selectOptionText from './subInput/selectOptionText.vue'

export default {
  name:'i-selectmultiple',
  mixins:[base,select_options],
  components:{
    selectOptionText,
  },
  data(){
    return {
    }
  },
  watch:{
  },
  computed:{
    defaultStacked(){
      return (this.appearance=='quick' || this.appearance=='horizontal')?false:true
    },
  },
  methods:{
    setValue2(){
      //do nothing just useful for seloct one.
    },
    formatInputValue(){
      // Mostly for components that want to format value before emit
      if(this.value===null || this.value===undefined){
        return []
      }
      if(this.value && Array.isArray(this.value)===false){
        return [this.value]
      }
      return this.value
    },
  }
}
</script>

<style scoped>
  .my-btn-group-block{
      display: block !important;
      /* It's by deflaut inline-flex, but too large  */
  }
  .my-compact-selected{
    background-color: rgb(187, 213, 222);
  }
  .my-custom-control{
    width: 100%;
    display: flex;
    flex-direction: column;
  }
</style>

<template>
  <input-row 
    :showElement="showElement"
    :label="label" 
    :elId="elId" 
    :status="status" 
    :config="config"
    :me="me"
  >
    <b-card bg-variant="light" class="mx-2">
      <div v-if="value && !input_type" class="text-danger">
        <div>
          {{labels.inputs.geoshapeNotNormalValue}}:
        </div>
        <div class="ml-3">
          <b>{{value}}</b>
        </div>
      </div>
      <div v-if="manualInputActive==false">
        <b-row class="my-1" v-if="area">
          <b-col sm="3" >
            <my-label :label="labels.inputs.area" :elId="elId+'a'" :status="status"></my-label>
          </b-col>
          <b-col sm="9">
            <b-form-input :id="elId+'a'" :placeholder="area" readonly>
            </b-form-input>
          </b-col>
        </b-row>
        <b-row class="my-1" v-if="geomLength">
          <b-col sm="3" >
            <!-- TODO: translation -->
            <my-label label="length" :elId="elId+'b'" :status="status"></my-label>
          </b-col>
          <b-col sm="9">
            <b-form-input :id="elId+'b'" :placeholder="geomLength" readonly>
            </b-form-input>
          </b-col>
        </b-row>
        <b-row class="my-1" v-if="nbCoordinates">
          <b-col sm="3" >
            <my-label :label="labels.inputs.nbCoordinates" :elId="elId+'c'" :status="status"></my-label>
          </b-col>
          <b-col sm="9">
            <b-form-input :id="elId+'c'" :placeholder="nbCoordinates" readonly>
            </b-form-input>
          </b-col>
        </b-row>
        <b-row class="my-1" v-if="input_type">
          <b-col sm="3" >
            {{labels.inputs.typePolygon}}:
          </b-col>
          <b-col sm="9">
            {{input_type}}
          </b-col>
        </b-row>
        <b-row class="my-1" >
          <b-col sm="3"></b-col>
          <b-col sm="9">
            <div v-if="!readonly">
              <b-btn @click="manualInputActive=true" size="sm" class="ml-2 myManual" v-if="showGpsEdit">
                <span class="glyphicon glyphicon-pencil"></span>
              </b-btn>
              <b-button @click="getFromMapGps" variant="primary" class="ml-5" v-if="$store.getters['form/isMapActive'] && appearance_aecom.indexOf('GpsTraceNotAllowed')==-1">
                <span class="glyphicon glyphicon-screenshot" v-if="!isLive"></span>
                <span class="glyphicon glyphicon-refresh" v-if="isLive"></span>
              </b-button>
              <b-button @click="getFromMapClick(1)" variant="primary" class="ml-3" v-if="$store.getters['form/isMapActive']" :disabled="isLive">
                <!-- {{labels.inputs.add}} -->
                <span class="glyphicon glyphicon-globe"></span>
              </b-button>
              <delete-button myclass="float-right" @delete="myValue=null" size="sm" v-if=myValue></delete-button>
            </div>
            <div v-else>
              <b-button @click="getFromMapClick(3)" variant="primary" class="ml-3" v-if="$store.getters['form/isMapActive'] && input_type">
                <span class="glyphicon glyphicon-globe"></span>
              </b-button>
            </div>
            <!-- disable for now, but interesting, really interesting would be able to also paste the json data on new geoShape... -->
            <b-btn @click="showJson=!showJson" size="sm" class="ml-2" v-if="false && $store.getters.isAdmin"><span class="glyphicon glyphicon-eye-open"></span></b-btn>
          </b-col>
        </b-row>
        <!-- TODO: could have buttons here, but for now, only in map -->
        <!-- <b-row class="my-1" >
          <b-col sm="3" >
            GPS
          </b-col>
          <b-col sm="9">
            <b-button @click="getFromMap" variant="info" class="ml-1" v-if="properties.allowGpsButton && $store.getters['form/isMapActive']">
              <span class="glyphicon glyphicon-screenshot"></span>
            </b-button>
            <b-button @click="getFromMap" variant="success" class="ml-1">
              <span class="glyphicon glyphicon-forward"></span>
            </b-button>
            <b-button @click="getFromMap" variant="secondary" class="ml-1">
              <span class="glyphicon glyphicon-pause"></span>
            </b-button>
            <b-button @click="getFromMap" variant="primary" class="ml-1">
              <span class="glyphicon glyphicon-stop"></span>
            </b-button>
          </b-col>
        </b-row> -->
      </div>
      <div v-else>
        <!-- manual input -->
        <div>{{labels.inputs.geoshapeManualFormat}}</div>
        <b-input v-model="manualInputValue"></b-input>
        <div v-if="manualInputError" class="text-warning">
          {{manualInputError}}
        </div>
        <div class="mt-2">
          <b-btn variant="primary"><i class="mdi mdi-check" @click="processWKT()"></i></b-btn>
          <b-btn variant="secondary" class="ml-2" @click="manualInputActive=false;manualInputValue=null"><i class="mdi mdi-cancel"></i></b-btn>
        </div>
      </div>
      <div v-if="showJson">
        <b-card>
          <pre>{{myValue}}</pre>
        </b-card>
      </div>


    </b-card>
  </input-row>
</template>

<script>
// it show X Y, but it save the result as geojson. easier to show and store some attributes (accuracy).
import base from './_baseInput.js'
import Geolocation from 'ol/geolocation'
import cuid from 'cuid'
import deleteButton from '../utils/deleteButton.vue'
import map_utils from '../../pages/form/map/map_utils.js'

export default {
  name:'i-geoshape',
  mixins:[base],
  components:{
    deleteButton,
  },
  data(){
    return{
      waitForResponse:null,
      componentType:null,
      liveId: null,
      manualInputActive: false,
      manualInputValue: null,
      manualInputError: null,
      showJson: false,
    }
  },
  created () {
    // get an existing liveId if exists.
    const path2=this.currentNamePath.join('.')
    this.$store.getters['formMap/surveyComponentLiveIds'].map(id=>{
      const path1=this.$store.state.formMap.formComponentLive[id].component.currentNamePath.join('.')
      if(path1==path2){
        this.liveId=id
      }
    })
  },
  computed:{
    isGeometryLine(){
      if(this.config.type=='geotrace'){
        return true
      }
      return false
    },
    area(){
      if (this.value && this.value.properties && this.value.properties.area){
        return this.value.properties.area.toFixed(2)
      }
    },
    nbCoordinates(){
      if (this.value && this.value.properties && this.value.properties.nbCoordinates){
        return this.value.properties.nbCoordinates.toFixed(0)
      }
    },
    geomLength(){
      if (this.value && this.value.properties && this.value.properties.length){
        return this.value.properties.length.toFixed(2)
      }
    },
    isLive(){
      if (this.liveId && this.$store.state.formMap.formComponentLive[this.liveId]){
        return true
      }
      return false
    },
    liveResponse(){
      if (this.isLive){
        return this.$store.state.formMap.formComponentLive[this.liveId].response
      }
    },
    input_type(){
      return this.value? this.value?.properties?.input_type:null
    },
    showGpsEdit(){
      // its a definition or parameter setting
      return this.$store.getters['form/actualFormDefitionSetting']('showGpsEdit',true,true) == true && this.parameters.showGpsEdit!==false
    },
  },
  watch:{
    liveResponse(){
      if(this.liveResponse){
        console.log('getFromMapGPS_response');
        // No more necessary as it's done as watcher in the edit.vue, as this component can be unmounted when page changes.
        // this.myValue=this.liveResponse
        console.log(this.liveResponse);
        //this.area=newVal.properties.area
        this.type='GPS'
      }
    }
  },
  methods:{
    getFromMap(formInput){
      // let gol= new Geolocation({projection:'EPSG:4326'})
      // gol.on('change', evt=> {
      //   console.log('test')
      //   this.accuracy=gol.getAccuracy()
      //   let xy=gol.getPosition()
      //   this.x=xy[0]
      //   this.y=xy[1]
      // })
      
    },
    getFromMapClick(mode){
      // # as geopoint, mode =1 normal, 3= read only
      this.componentType='map-geoshape-gps2'
      let formInput={
        component_name:'map-geoshape-gps2',
        properties:{
          survey_id: this.surveyDoc._id,
          isGeometryLine:this.isGeometryLine,
          returnRoute:this.$route.path,
          allowGpsButton: mode==3?false:true,
          allowClickMap: mode==3?false:true,
          currentValue:this.myValue, // if a value, we want to draw it on the map.
          readonly: this.readonly,
        }
      }
      // ********* OPEN the map and let the user click or select to use the GPS
      this.$store.commit('formMap/formComponent',formInput)
      this.waitForResponse=this.$watch(()=>{
        return this.$store.state.formMap.formComponent_response
      },(newVal,oldVal)=>{
        if (mode!=3){
          this.getFromMap_response(newVal)
        }
      })
      this.$router.push('../map')
    },
    getFromMapGps(){
      if (!this.isLive){
        this.componentType='map-geoshape-gps'
        let formInput={
          component_name:'map-geoshape-gps',
          isLive:true,
          type:'survey', // type survey says that when we quit the survey, the components close
          currentNamePath:this.currentNamePath,
          properties:{
            isGeometryLine:this.isGeometryLine,
            liveId:cuid(),
            returnRoute:this.$route.path,
            allowGpsButton:true,
            allowClickMap:false,
            currentValue:this.myValue // if a value, we want to draw it on the map.
          }
        }
        this.$store.commit('formMap/addFormComponentLive',formInput)
        this.liveId=formInput.properties.liveId
      }
      this.$router.push('../map')
    },
    getFromMap_response(newVal){
      console.log('getFromMap_response - enter');
      if(newVal){
        console.log('getFromMap_response');
        this.myValue=newVal
        console.log(newVal);
        //this.area=newVal.properties.area
        this.type='Map'
      }
      this.waitForResponse() //unwatch - https://vuejs.org/v2/api/#vm-watch
    },
    processWKT(){
      try {
        let wkt = new ol.format.WKT;
        let features = wkt.readFeatures(this.manualInputValue);
        let feat = features[0] 
        // calculate props - save as src\pages\form\map\form_components\geoshape.vue
        if(this.isGeometryLine){
          feat.setProperties({length:map_utils.getLength(feat.getGeometry(),'EPSG:4326'), input_type: 'manual'})
        }else{
          feat.setProperties({area:map_utils.getArea(feat.getGeometry(),'EPSG:4326'), input_type: 'manual'})
        }
        let nbPoints = 0
        feat.getGeometry().getCoordinates().map(x=>{
          nbPoints += x.length
        })
        feat.setProperties({nbCoordinates: nbPoints})
        // Convert to geoJSON
        var writer = new ol.format.GeoJSON();
        var geoJsonVal = writer.writeFeatureObject(feat);
        this.myValue=geoJsonVal
        this.manualInputValue=null
        this.manualInputActive=false
      } catch (error) {
        console.error(error);
        this.manualInputError = error
      }
    }
  }
}
</script>

<style>

</style>

<template>
  <div ref="syncpull">
    <h2>{{ labels.title }}</h2>
    <h5 class="ml-3 mb-3">{{ labels.title2 }}</h5>
    <div v-if="!canDownloadForms">
      <b-alert show variant="warning">{{ labels.notInSettings }}</b-alert>
    </div>
    <div v-else>
      <b-alert v-if="!hasLocal" show variant="secondary">{{
        labels.hasLocalNot
      }}</b-alert>

      <download :settings="{ showSyncOptions: true, displayBySearch: true }">
        <template v-slot:buttonContent>
          <i class="glyphicon glyphicon-download mr-2"></i>
          {{ labels.buttonText }}
        </template>
      </download>

      <!-- ******* JSON -->
      <h2 class="mt-5">{{ labels.jsonTitle }}</h2>
      <h5 class="ml-3 mb-3">{{ labels.title2 }}</h5>
      <h5 class="ml-3 mb-3 text-warning">{{ labels.jsonTitle2 }}</h5>   
      
      <b-form-file
        v-model="files"
        :state="files && files.length>0"
        accept=".json"
        :placeholder="labels.jsonFile"
        class="my-3"
        multiple
      >
      </b-form-file>
      <!-- <div v-if="file && !nbSurveys">
        {{ this.labels.jsonFileError }}
      </div> -->
      <div v-if="files && files.length>0">
        <div>
          {{ this.labels.jsonCount}}: {{ files.length }}
        </div>
        <div class="my-3" v-if="!fileMess">
          <b-btn @click="newSurveys" variant="primary"><i class="glyphicon glyphicon-download mr-2"></i>{{ this.labels.buttonText }}</b-btn>
        </div>
      </div>
      <div>
        <b-alert show variant="secondary" v-if="fileMess">{{fileMess}}</b-alert>
      </div>
      <div v-if="fileResults">
        <b-list-group>
          <b-list-group-item v-for="item in fileResults" :key="item.name">{{ item.name}} ({{item.sizeh}}): {{item.nb}}</b-list-group-item>
        </b-list-group>
      </div>
    </div>
  </div>
</template>

<script>
import download from "../listDownload.vue";
import filesize from 'filesize'

export default {
  name: "form-sync-pull",
  components: {
    download,
  },
  data() {
    return {
      files: null,
      fileContent: null,
      fileMess: null,
      fileResults: null,
    };
  },
  computed: {
    labels() {
      return this.$store.state.labels.form.sync.pull;
    },
    hasLocal() {
      return this.$store.getters["form/hasLocal"];
    },
    canDownloadForms() {
      return (
        this.$store.state.settings.allowDownloadSurveys ||
        this.$store.getters.isAdmin
      );
    },
    docs(){
      if (this.fileContent) {
        if (Array.isArray(this.fileContent) && this.fileContent[0].rows) {
          return this.fileContent[0].rows.map(x=>x.doc);
        }
        if (Array.isArray(this.fileContent)) {
          return this.fileContent
        }
      }
    },
    nbSurveys() {
      if (this.fileContent) {
        return this.docs.length
      }
      return null
    },
  },
  watch: {
    files() {
      this.fileMess = null
      
    },
  },
  methods: {
    readFile(file) {
      return new Promise((resolve, reject) => {
        var fr = new FileReader();
        //fr.onload =  resolve;  // CHANGE to whatever function you want which would eventually call resolve
        fr.onload = (e) => {
          let fileContent = JSON.parse(fr.result);
          if (Array.isArray(fileContent) && fileContent[0].rows) {
            fileContent = fileContent[0].rows.map(x=>x.doc);
          }else if (!Array.isArray(fileContent)) {
            fileContent = []
          }
          resolve(fileContent);
        };
        fr.readAsText(file);
      });
    },
    newSurveys() {
      this.fileMess = this.labels.jsonInProgress
      const formId=this.$store.state.form.form_id
      let form= this.$store.getters['localDB/form_object_for_local_add'](formId)
      // We always fire the addFormLocal before, to ensure local is created and that all form_definition are present.
      return this.$store.dispatch('localDB/addFormLocal',form).then(()=>{
        //add a form to the local store
        const localForm=new PouchDB(form.id,this.$store.dispatch('localDB/getPouchDbOptions'))
        
        // https://decembersoft.com/posts/promises-in-serial-with-array-reduce/
        return this.files.reduce((promiseChain, currentTask) => {
            var nbDone = 0
            return promiseChain.then(chainResults => {
              return this.readFile(currentTask).then(fileContent=>{
                nbDone = fileContent.length
                return localForm.bulkDocs(fileContent,{new_edits:false})
                //  when new_edits is false, it does return nothing... so get the number before.
              }).then(currentResult => [ ...chainResults, nbDone ]).catch(err=>{
                return [ ...chainResults, err ]
              })
            })
          }, Promise.resolve([])
        )             
      }).then(arrayOfResults=>{
        this.fileResults = this.files.map((file, i)=>{
          const rep =  {
            name: file.name,
            size: file.size,
            sizeh: filesize(file.size,{round:1}),
          }
          if(Array.isArray(arrayOfResults[i])){
            rep['nb'] = arrayOfResults[i].length
          }else{
            rep['nb'] = arrayOfResults[i]
          }
          return rep
        })
        return Promise.resolve()
      }).then(rep=>{
        this.fileMess = this.labels.jsonEnd
      }).catch(err=>{
        this.$store.dispatch('app_message_error',err)
        this.fileMess = this.labels.jsonError + ' - ' + err.toString()
      })
    },
  },
};
</script>

<style lang="scss" scoped></style>
// Implements layer.js...

export default {
  namespaced: true,
  state: {
    className:'layer_imagery_couchdb',
    db: null
  },
  getters: {

  },
  mutations: {

  },
  actions: {
    defineByUrlNull(context, config) {
      if (config.layer_type == 'Tile' && config.source_type == 'XYZ') {
        const url_online_split=context.state.config.url_online.split("/")
        const attachmentName=url_online_split[2]
        const docName=url_online_split[1]
        // get the attachment
        //adapted from: https://gis.stackexchange.com/a/119220/2043
        context.state.map_layer = new ol.layer.Tile({
        //   extent:  [
        //     -8234680, 5687114,
        //     -8169759, 5709867
        // ],
          source: new ol.source.XYZ({
            //adapted from: http://jsfiddle.net/gussy/LCNWC/
            tileLoadFunction: function (imageTile, src) {
              context.state.db.getAttachment(src, attachmentName, function (err, res) {
                if (err && err.name == 'not_found') {
                  imageTile.getImage().src ='' 
                  // important: in case we just return(as before), after some errors, new asked tiles, 
                  // even if present are not loaded... 
                  // they pass tileUrlFunction but never reach tileLoadFunction
                  // with this, it's solved.
                  //https://github.com/openlayers/openlayers/issues/5532
                  //https://stackoverflow.com/questions/5278304/how-to-cancel-an-image-from-loading/5278475#5278475
                }else if (!res) {
                  imageTile.getImage().src =''  // see upper comment
                }else{
                  // console.log('tile load couchdb imagery url: '+src + ' - ' + attachmentName);
                  imageTile.getImage().src = window.URL.createObjectURL(res);
                }
              });
            },
            tileUrlFunction: function (coordinate, projection) {
              if (coordinate === null) return undefined;
              // OSM NW origin style URL
              let imgURL=replaceAll(docName,'{x}',coordinate[1])
              //y is negative and minus 1 see: https://stackoverflow.com/a/38881347/140384
              // why ??? but this patch works
              imgURL=replaceAll(imgURL,'{y}',Math.abs(coordinate[2]+1))
              imgURL=replaceAll(imgURL,'{z}',coordinate[0])
              // console.log('tile url couchdb imagery url: '+imgURL);
              return imgURL;
            }
          }),
          visible: config.visible
        });
      } else {
        console.log(config)
        console.error('Not a layer type defined-basemap ' + config.layer_type + '-' + config.source_type)
        throw 'Not a layer type defined-basemap'
      }
      return Promise.resolve(config.layer_id) // true, it's defined.
    },
    getUrl(context, config) {
      return context.dispatch('localDB/getDatabase', { dbName: config.couchdb_name }, { root: true }).then(rep => {
        context.state.db = rep.db
        // console.log('get URL imagery couchdb online: ' + rep.online);
        // *** online is not a parameter, as we always need to send a token to the remote database, so using the tileLoadFunction
        // *** only pulic dataset would not need it, but not the case for any at the moment.
        // if (rep.online) {
        //   return Promise.resolve(rep.connection + config.url_online) //return the url connection
        // } else {
          return Promise.resolve(null) // will then be redirected to defineByUrlNull
        // }
      })
    }
  },
}

//same as in expressionEvaluation.js
let replaceAll=function (str,find, replace) {
  //var str = this;
  return str.replace(new RegExp(find.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), 'g'), replace);
}

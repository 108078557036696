import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

Vue.config.productionTip = false

// Polyfills
// https://gomakethings.com/the-new-array.prototype.tospliced-method-in-vanilla-js/
if (!Array.prototype.toSpliced) {
	Array.prototype.toSpliced = function (...args) {
		return Array.from(this).splice(...args);
	};
}

// Request Persistent Storage
if (navigator.storage && navigator.storage.persist) {
  navigator.storage.persist().then(function (isPersistent) {
      if (isPersistent) {
          console.log("Storage is now persistent.");
      } else {
          console.log("Storage is not persistent.");
      }
  }).catch(function (error) {
      console.log("Failed to request persistent storage:", error);
  });
}

// ************ Bootstrap vue
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue);

// ************ Pouch DB
import PouchDB from 'pouchdb'
Vue.prototype.$PouchDB = PouchDB
// https://www.npmjs.com/package/pouchdb-security-helper
const securityPlugin = require("pouchdb-security-helper");
PouchDB.plugin(securityPlugin);
window.PouchDB=PouchDB // Allow console access when debugging... useful for custom sync or debug quota

// ************* moment in vue
import moment from 'moment'
Vue.prototype.$moment = moment

// ************* moment in vue
import cuid from 'cuid'
Vue.prototype.$_cuid = cuid

// ************* store
import store from './store'
// lauch project init task if there are any:
if(store.state.settings.initTasks){
  store.state.settings.initTasks.map(task=>{
    if(task.type=='dispatch'){
      store.dispatch(task.action,task.params)
    }
  })
}
// ** position
store.dispatch('position/start') // start the position watch, so always accessible
// update the gpsage each seconds.
window.setInterval(() => {
  store.dispatch('position/positionAgeSeconds')
},1000);
// ** page title
document.title = store.state.settings.documentTitle
// ** language - default
if(store.state.settings.default_language || store.state.localConfig.jsonConfig.language){
  store.dispatch('switchLanguage', store.state.localConfig.jsonConfig.language ?? store.state.settings.default_language) 
}

// ************* async computed
import AsyncComputed from 'vue-async-computed'
Vue.use(AsyncComputed);


// ************** scroll to
//https://github.com/rigor789/vue-scrollto
var VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo,{offset:-70}) // 60px is the navbar. 10 px for better look then 70.

// ************** touch-events
//https://github.com/jerrybendy/vue-touch-events
// be careful, can only pass a method name, not the executing code, so no parameter to the method or direct store or other events
// see: https://stackoverflow.com/a/48137032/140384
// import Vue2TouchEvents from 'vue2-touch-events'
// Vue.use(Vue2TouchEvents)
// ********** deactivated, caused the form to change page when not needed... TODO: should debug.

// ******** Loading 
// https://github.com/ankurk91/vue-loading-overlay
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
Vue.use(Loading);



new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')

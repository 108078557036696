<template>
  <div ref="sync">
    <b-container class="">
      <div>
        <div v-if="(isSyncReady && $store.state.form.remoteDb || syncingState==3)">
          <sync-state :sync-state="syncingState" :change-counter="ChangeCounter" :error-message="mess">
            <div slot="state0">
              <!-- The primary screen -->
              <div v-if="ready && hasLocalNullDB!==undefined">
                <b-button @click="replicateTo" variant="primary">
                  {{syncLabels.sendData}}
                </b-button>
                <b-form-checkbox
                  class="ml-3"
                  v-model="syncIncludePhotos"
                >
                  {{ syncLabels.syncIncludePhotos }}
                </b-form-checkbox>
                <div v-if="hasLocalNullDB" class="alert alert-warning small mt-2">Synchronisation des formulaires en problème incluse</div>
                <sync-options v-model="selectedOption" class="ml-3"></sync-options>
                <div class="pb-3 d-flex justify-content-end">
                  <div>
                    <b-btn :to="'/projectSync/'+project.id" variant="secondary" size = "sm">
                      <span class="glyphicon glyphicon-cloud-upload mr-2"></span>{{syncLabels.projectSync}}
                    </b-btn>
                  </div>
                </div>
              </div>
            </div>
            <div slot="state2">
              <!-- Success sync -->
              <div class="my-2 ml-3">
                <b-btn to="."  variant="primary">{{syncLabels.return}}</b-btn> 
              </div>
              <b-card class="mt-5 bg-light" v-if="syncIncludePhotos!==false">
                <b-btn @click="deleteLocals" variant="warning">{{syncLabels.deletelocals}}</b-btn>
                <div class="small alert alert-warning mt-2 mx-4">{{syncLabels.deletelocalsMess}}</div>
              </b-card>
              <b-card class="mt-5 bg-light" v-if="syncIncludePhotos!==false">
                <b-btn @click="deleteLocals2" variant="outline-danger">{{syncLabels.deletelocals2}}</b-btn>
                <div class="small alert alert-secondary mt-2 mx-4">{{syncLabels.deletelocals2Mess}}</div>
              </b-card>
            </div>
            <div slot="state4">
              <div class="my-2">
                <b-btn to="."  variant="secondary">{{syncLabels.return}}</b-btn> 
              </div>
            </div>
          </sync-state>
          <div>
            <!-- sync tab informations -->
            <b-card no-body v-if="syncingState==0" class="mt-5">
              <b-tabs card v-model="tabInfo">
                <b-tab :title="syncLabels.syncTabInfo">
                  <h5>
                    {{syncLabels.nbNotSync}}: {{nbNotSync}}
                  </h5>
                  <div class="font-italic ml-2">
                    <!-- Warning about deleted elements -->
                    {{syncLabels.deletedWarming}}
                  </div>
                  <div class="mt-3 h6">{{syncLabels.syncTabInfoText}}</div>
                </b-tab>
                <b-tab :title="syncLabels.syncTabListSurveys">
                  <div v-if="listOnlyLocalDocs && listOnlyLocalDocs.length>0" >
                    <!-- <b-table :items="listOnlyLocalDocs" :fields="['id','value.rev']" responsive ></b-table> -->
                    <b-table :items="listOnlyLocalDocs" :fields="['id']" responsive ></b-table>
                  </div>
                </b-tab>
                <b-tab :title="syncLabels.syncTabCompare">
                  <div v-if="compareServer" >
                    <b-table :items="compareServer" responsive ></b-table>
                  </div>
                </b-tab>
              </b-tabs>
            </b-card>
          </div>
          <!-- list of ids to transfert -->
        
        </div>
        <div v-else-if="syncingState==5">
          <sync-state :sync-state="syncingState" :error-message="mess"></sync-state>
          <b-btn to="."  variant="secondary">{{syncLabels.return}}</b-btn> 
        </div>
        <div v-else>
          <!-- we are not in a local form -->
          <h5>
            {{syncLabels.notLocal}}
          </h5>
          <div v-if="hasLocal && isLocal===false" class="mt-4">
            <b-btn variant="secondary" @click="makeLocal">
              <span class="glyphicon glyphicon-download-alt"></span>
              {{labels.form.viewOfflineVerison}}
            </b-btn>
          </div>
          <b-btn to="." class="mt-4" variant="secondary"><i class="mdi mdi-arrow-left-bold mr-2"></i> {{syncLabels.return}}</b-btn> 
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import syncOptions from '../../components/sync/syncOptions.vue'
import syncState from '../../components/sync/syncState.vue'
import cuid from 'cuid'
import { Promise } from 'q';
import couchDbUtils from '../../utils/couchdb.js'
import base from '../../pages/form/_base.js'


export default {
  name:'form-sync-push',
  mixins:[base],
  components:{
    syncOptions,
    syncState
  },
  data(){
    return{
      syncingState:0,//0= not sync, 1=in sync, 2=sync finiched,99=sync errors
      mess:null,
      selectedOption:null,
      tabInfo:0,
      syncIncludePhotos:true,
    }
  },
  computed: {
    labels(){return this.$store.state.labels},
    isSyncReady(){
      if(!this.ready){return null}
      return this.isLocal
    },
    ChangeCounter(){return this.$store.state.form.ChangeCounter},
    isOnline(){return navigator.onLine && this.$store.state.isLogged},
    syncLabels(){return this.labels.form.sync},
    project(){
      if(!this.ready){return null}
      return this.$store.getters['project/project']
    },
    nbNotSync(){
      if(this.listOnlyLocalDocs){
        return this.listOnlyLocalDocs.length
      }
    },
  },
  asyncComputed:{
    hasLocalNullDB(){
      if(!this.ready){return null}
      //check if we have the problem that the current form what locally sync - was a bug
      // name with null
      return couchDbUtils.resolveConflictWithLatest(this.$store.state.form.db, '_design/editList').then(()=>{
        if(indexedDB.databases){
          return indexedDB.databases()
        }else{
          return Promise.reject('indexedDB.databases not supported')
        }
      }).then(r => {
        let dbName='_pouch_null'+this.form_id
        let hasNullLocal=r.filter(x=>x.name==dbName)
        if(hasNullLocal.length>0){
          let db1=new this.$PouchDB('null'+this.form_id, this.$store.getters.pouchDbOptions)
          return db1.allDocs({startkey:'survey_',endkey:'survey_\ufff0'}).then(rep=>{
            if(rep.rows.length>0){
              // we have surveys, so we want to create deisgh docs so we can query the number of documents per date
              // our first iteration cause conflicts - on server... that propagated....
              return couchDbUtils.resolveConflictWithLatest(db1, '_design/editList').then(rep=>{
                return couchDbUtils.resolveConflictWithLatest(this.$store.state.form.db, '_design/editList')
              }).then(()=>{
                return this.$store.dispatch('form/createDesignDocs',{useThisDb:db1})
              })
              .then(()=>true)
              .catch(err=>{
                this.$store.dispatch('app_message_error',err)
                return false
              })
              // could be.... but too risky of not ok version....
              // return db1.get('_design/editList').catch(e=>{
              //   return this.$store.dispatch('form/createDesignDocs',{useThisDb:db1})
              // }).then(()=>true)
              // .catch(err=>{
              //   console.error(err)
              //   return false
              // })
            }
            return false
          })
        }
        return false
      }).catch(err=>{
        this.$store.dispatch('app_message_error',err)
        return false
      })
    },
    listOnlyLocalDocs(){
      if(!this.ready){return null} // avoid fetch twice... not sure why it happen?
      return this.$store.dispatch('form/getOnlyLocalDocs').then(rep=>{
        return rep
      }).catch(err=>{
        this.$store.dispatch('app_message_error',err)
        return []
      })
    },
    compareServer(){
      if(this.ready && this.tabInfo==2){
        const valNames=['server','local']
        const pro1=[
          this.$store.dispatch('form/fetchSurveysDates',{remote:true,only_own_device:true}),
          this.$store.dispatch('form/fetchSurveysDates',{only_own_device:true}) // local as we check that the current db is local
        ]
        if(this.hasLocalNullDB){
          valNames.push('local_lost')
          pro1.push(this.$store.dispatch('form/fetchSurveysDates',{useThisDb:new this.$PouchDB('null'+this.form_id, this.$store.getters.pouchDbOptions),only_own_device:true}))
        }
        return Promise.all(pro1).then(rep=>{
          let rep2=[]
          rep.map((x,i)=>{
            const valName=valNames[i]
            x.map(y=>{
              const f2=rep2.filter(f1=>f1.key==y.key)
              if(f2.length>0){
                f2[0][valName]=y.value
              }else{
                const rep3={key:y.key}
                valNames.map(v1=>{rep3[v1]=0})// ensure we have empty values
                rep3[valName]=y.value
                rep2.push(rep3)
              }
            })
          })
          return rep2.sort((a,b)=>a.key.localeCompare(b.key))
        })
      }
    }
  },
  watch: {
    syncIncludePhotos: {
      handler(){
        this.$store.state.form.syncIncludePhotos = this.syncIncludePhotos
      },
      immediate: true
    }
  },
  methods:{
    makeLocal(){
      this.$store.dispatch('localDB/switchDatabasesAccesMode',{accessMode: 2}).catch(err=>{
        this.$store.dispatch('app_message_error',err)
      })
    },
    replicateTo(){
      this.syncingState=1
      new Promise((resolve2, reject2) =>{
        if(this.hasLocalNullDB){
          // null sync first
          this.$store.dispatch('form/replicateToMaster',Object.assign({name:'null',useThisDb:new this.$PouchDB('null'+this.form_id, this.$store.getters.pouchDbOptions)}, this.selectedOption))
          .then(()=>{
            resolve2()
          }).catch(err=>{
            reject2(err)
          })
        }else{
          resolve2()
        }
      }).then(()=>{
        // normal sync
        return this.$store.dispatch('form/replicateToMaster',this.selectedOption)
      }).then(()=>{
        // normal sync
        return this.$store.dispatch('app_messsages_sync')
      }).then(()=>{
          this.syncingState=2
      }).catch(err=>{
        this.syncingState=99
        this.mess=err
        this.$store.dispatch('app_message_error',err)
      })
    },
    refresh(){
      window.location.reload()
    },
    deleteLocals(){
      let loader = this.$loading.show({
        // Optional parameters
        container: this.$refs.sync,
      });
      this.ready=false // avoid updates on computed properties
      this.syncingState=3
      const formId=this.$store.state.form.form_id
      let form2= this.$store.getters['localDB/form_object_for_local_add'](formId)
      //erase local data (the database) and after sync back the definition.
      this.$store.dispatch('localDB/deleteFormLocal',{id:formId}).then(()=>{
        // add local
        return this.$store.dispatch('localDB/addFormLocal',form2)
      }).then(()=>{
        this.$store.commit('form/form_id',null)
        return this.fetchForm() //as our form database is not the same now.
      }).then(()=>{
        //all done, message the user
        loader.hide()
        this.syncingState=4
        this.mess=null
      }).catch(err=>{
        this.syncingState=99
        this.mess=err
        this.$store.dispatch('app_message_error',err)
      })
    },
    deleteLocals2(){
      let loader = this.$loading.show({
        // Optional parameters
        container: this.$refs.sync,
      });
      this.ready=false // avoid updates on computed properties
      this.syncingState=3
      const formId=this.$store.state.form.form_id
      let form2= this.$store.getters['localDB/form_object_for_local_add'](formId)
      //erase local data (the database) and after sync back the definition.
      this.$store.dispatch('localDB/deleteFormLocal',{id:formId}).then(()=>{
        this.$store.commit('form/form_id',null)
        return this.fetchForm() //as our form database is not the same now.
      }).then(()=>{
        //all done, message the user
        loader.hide()
        this.syncingState=5
        this.mess=null
      }).catch(err=>{
        this.syncingState=99
        this.mess=err
        this.$store.dispatch('app_message_error',err)
      })
    }
  }
}
</script>

<style>

</style>

<template>
  <div class="mt-1" > 
    <b-btn class="" :variant="position_update_recenter?'success':'dark'" @click="recenter">
      <!-- {{labels.map.position_update_recenter}} -->
      <span class="glyphicon glyphicon-screenshot"></span>
    </b-btn>
  </div>
</template>

<script>
import map_utils from '../map_utils.js'
import base from './_base.js'

export default{
  name:'map-position',
  mixins:[base],
  data(){
    return{
      vectorLayer:null,
      vectorSource:null
    }
  },
  created(){

  },
  computed:{
    position(){
      return this.$store.state.position.position
    },
    position_map(){
      return map_utils.project_geom(this.position,'EPSG:4326',this.map.getView().getProjection())
    },
    position_update_recenter:{
      get(){
        return this.$store.state.position.position_update_recenter
      },
      set(newVal){
        this.$store.commit('position/position_update_recenter',newVal)
      }
    },
  },
  watch:{
    position:{
      immediate:true,
      handler(){
        // console.log('watch position');
        this.positionUpdate()
      }
    }
  },
  methods:{
    init(){
      this.addLayer() // first add the layer if necessary
      this.positionUpdate()
    },
    positionUpdate(){
      console.log('position.vue - map/position - positionUpdate')
      if(this.map && this.position){
        var pos = this.position
        var map_pos=this.position_map
        if(this.$store.state.position.position_update_recenter){
          this.map.getView().setCenter(map_pos);
        }
        var posFeature = new ol.Feature({
          geometry: new ol.geom.Point(map_pos)
        })
        this.vectorSource.clear()
        this.vectorSource.addFeature(posFeature)
        // console.log(this.$store.getters['position/getAccuracyGeometry']);
        var accFeature = new ol.Feature({
          geometry: this.$store.getters['position/getAccuracyGeometry']
        })
        this.vectorSource.addFeature(accFeature)
        this.$store.commit('formMap/geolocation_position',pos)
      }
    },
    addLayer(){
      if(this.map && this.vectorSource===null){
        this.vectorSource = new ol.source.Vector({});
        var vectorLayer = new ol.layer.Vector({
          source: this.vectorSource,
          style: new ol.style.Style({
            image: new ol.style.Circle({
              radius: 6,
              fill: new ol.style.Fill({
                color: '#3399CC'
              })
            }),
            fill: new ol.style.Fill({
              color: 'rgba(0, 153, 255, 0.4)'
            })
            /*,
            stroke: new ol.style.Stroke({
                color: '#ffffff',
                width: 2
            })*/
          }),
          map: this.map,
          useSpatialIndex:false, //When features are removed and added frequently, and the total number of features is low, setting this to false may improve performance. https://openlayers.org/en/latest/apidoc/module-ol_source_Vector-VectorSource.html
          updateWhileAnimating: true, // optional, for instant visual feedback
          updateWhileInteracting: true // optional, for instant visual feedback
        })
        this.vectorLayer=vectorLayer
      }
    },
    recenter(){
      this.position_update_recenter=!this.position_update_recenter
      if(this.position_update_recenter){
        this.map.getView().setCenter(this.position_map)
      }
    }
  }
}
</script>

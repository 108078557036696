<template>
  <div>
    
  </div>
</template>

<script>
import base from './_base.js'


export default{
  name:'map-hover',
  mixins:[base],
  components:{},
  data(){
    return{
      features_by_layers:null,
      featureOverlay:null,
    }
  },
  computed:{
    layers(){
      if(this.features_by_layers){
        return Object.keys(this.features_by_layers).filter(x=>{
          return this.features_by_layers[x].length>0
        })
      }
    },
    formComponent(){
      return this.$store.state.formMap.formComponent
    }
  },
  methods:{
    init(){
      if(this.map){
        // https://openlayers.org/en/latest/examples/hitdetect-vector.html
        console.log('pointermove registered')
        this.map.on('pointermove',this.pointerMove)
        this.featureOverlay = new ol.layer.Vector({
          source: new ol.source.Vector(),
          map: this.map,
          style: function (feature) {
            // highlightStyle.getText().setText(feature.get('name'));
            return highlightStyle;
          },
        });
      }
    },
    pointerMove(evt){
      if (evt.dragging) {
        return;
      }
      var pixel = this.map.getEventPixel(evt.originalEvent);

      this.features_by_layers=null
      if(!this.formComponent){
        //TODO: support diffrent tool options
        //we impelment identify, but could be anything else?? what else...
        this.$store.dispatch('map/getFeaturesAtPixel',{pixel:pixel,onlyVisible:true, hover: true}).then(features=>{
          //features is an object with the layer_id as key
          console.log('hover features:')
          console.log(features)
          const features2 = []
          Object.values(features).forEach(x => features2.push(...x) )
          // only add the first
          this.featureOverlay.getSource().clear()
          this.featureOverlay.getSource().addFeatures(features2)
        })
      }
    }
  }
}

const highlightStyle = new ol.style.Style({
  stroke: new ol.style.Stroke({
    color: '#f00',
    width: 1,
  }),
  fill: new ol.style.Fill({
    color: 'rgba(255,0,0,0.2)',
  }),
  // text: new Text({
  //   font: '12px Calibri,sans-serif',
  //   fill: new Fill({
  //     color: '#000',
  //   }),
  //   stroke: new Stroke({
  //     color: '#f00',
  //     width: 3,
  //   }),
  // }),
});
</script>

export default{
  props:['properties','parent'],
  data(){
    return {
      response:null,
      started:false
    }
  },
  computed:{
    labels(){return this.$store.state.labels},
    position_update_recenter:{
      get(){
        return this.$store.state.position.position_update_recenter
      },
      set(newVal){
        this.$store.state.commit('position/position_update_recenter',newVal)
      }
    },
    map(){return this.parent.map},
    formComponent(){
      return this.$store.state.formMap.formComponent
    }
  },
  watch:{
    properties:{
      handler:'startComponent',
      immediate:true
    },
    map:{
      handler:'startComponent',
      immediate:true
    }
  },
  methods:{
    startComponent(){
      if(this.properties && this.map && this.started===false){
        this.started=true
        this.start()
      }
    },
    clear(){
      this.clear_sub() //call the sub clear.
    },
    finished(){
      console.log('base component finished')
      // console.log(this.response)
      this.$store.commit('formMap/formComponent_response',this.response)
      this.$store.commit('formMap/formComponent',null)
      this.$router.push(this.properties.returnRoute)
    },

  },
  beforeDestroy(){
    console.log('map component destroy');
    this.started='ended'
    this.clear()
  }
}


// ******** Haïti - Resepag

export default {
  name:'resepag',
  documentTitle:'RESEPAG AECOM Formulaires',
  navbarTitle:'Formulaires - RESEPAG',//not used for now
  //TODO: correct this problem: does not work on https... 
  couchdbUrls: ['https://db.resepag.aecom.space/','https://db.resepag2.aecom.space/'],//,'http://192.168.50.2:5984/'], // we pass many as we want to support mobile field server
  databaseSystem:'forms_system_resepag',
  pouhdb_replicate_batch_size:5, //default to 100, but crash in browser with photos.
  serial_position_active:false,
  inputGeopointDirectGpsClickAllowed:true,//this by pass the map.
  new_db_couchdb_security_users:"resepag_users",
}

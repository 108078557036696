<template>
  <div v-if="data">
    <div class="h3">Table {{tableName}}</div>
    <div class="my-3">
      <div>
        <b-tabs content-class="mt-3">
          <b-tab title="Sélection" active>
            <div class="h4 ml-2">
              Choix des champs 
              <b-btn size="sm" @click="selected=[]" class="ml-3"><i class="fas fa-trash"></i></b-btn>
            </div>
            <div class="row">
              <div class="col">
                <div class="m-2">
                  <b-form-input v-model="searchFields" placeholder="Rechercher" debounce="200"></b-form-input>
                </div>
                <b-table
                  :items="fieldsList"
                  :fields="fields"
                  responsive="sm"
                  sticky-header="600px"
                  :filter="searchFields"
                  @row-clicked="onRowClicked"
                >
                  <template #cell(label)="cellData">
                    <field-name :fieldObj="cellData.item"></field-name>
                  </template>
                  <template #cell(selected)="cellData">
                    <template v-if="selectedIds.indexOf(cellData.item.id) > -1">
                      <span aria-hidden="true">&check;</span>
                      <span class="sr-only">Selected</span>
                    </template>
                    <template v-else>
                      <span aria-hidden="true">&nbsp;</span>
                      <span class="sr-only">Not selected</span>
                    </template>
                  </template>
                </b-table>
              </div>
              <div class="col">
                <!-- {{selected}} -->
                <order-list v-model="selected" labelAttribute="label">
                  <template v-slot:label="slotProps">
                    <div class="d-flex">
                      <field-name :fieldObj="slotProps.sel"></field-name>
                      <opt-toggle v-if="slotProps.sel.type == 'select all that apply'" :fieldObj="slotProps.sel" ico='sitemap' name='split'></opt-toggle>
                    </div>
                  </template>
                </order-list>
                <div v-if="selected.length != 0" class="mt-4">
                  <b-btn @click="show" variant="primary">Obtenir les données</b-btn>
                </div>
              </div>
            </div>
          </b-tab>
          <!-- ************** DATA tab -->
          <b-tab title="Données" v-if="tableData">
            <div v-show="!showPhoto">
              <b-table 
                :items="tableData" 
                responsive
                sticky-header="600px"
                :fields="fieldsTableData"
              >
                <template #cell(docId)="cellData">
                  {{ cellData.value }}
                </template>
                <template #cell()="cellData">
                  <!-- take into account photos -->
                  <template v-if="cellData.field.oriFi.type == 'photo' && cellData.value">
                    <a @click="showPhoto = cellData.value">
                      <b-img-lazy :src="cellData.value" blank-height="400"></b-img-lazy>
                    </a>
                    <!-- <a @click="showPhoto = cellData.value.split('@')[0]">
                      <b-img-lazy :src="cellData.value.split('@')[1]" offset="1200" blank-height="96"></b-img-lazy>
                    </a> -->
                  </template>
                  <template v-else>
                    {{ cellData.value }}
                  </template>
                </template>
              </b-table>
            </div>
            <div @click="showPhoto=null" v-show="showPhoto">
              <b-img :src="showPhoto"></b-img>
            </div>
          </b-tab>
          <!-- ************** Stats tab -->
          <b-tab title="Statistiques" v-if="tableData">
            <!-- {{dataStats}} -->
            <b-table-simple hover small responsive bordered sticky-header="600px">
              <!-- <colgroup v-for="(col,i) in dataStats.colGroups" :key="'g'+i"><col v-for="i2 in [...Array(col.nbCol).keys()]" :key="'c'+i2"></colgroup> -->
              <b-thead >
                <b-tr>
                  <b-th v-for="(col,i) in dataStats.colGroups" :colspan="col.nbCol" :key="col.label" :variant="i/2==(i/2).toFixed(0)?'dark':'secondary'">{{col.label}}</b-th>
                </b-tr>
                <b-tr>
                  <b-th v-for="(col,i) in dataStats.cols" :key="col+i" :variant="col.nGroup/2==(col.nGroup/2).toFixed(0)?'secondary':'dark'">{{col.label}}</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-for="(row,i) in dataStats.rep2" :key="'r'+i">
                  <b-td v-for="(cell,y) in row" :key="'c'+i+'_'+y" :rowspan="cell.span>1?cell.span:1">
                    {{cell.value}}
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-tab>
        </b-tabs>
        <!-- {{tableData}} -->
        
      </div>
    </div>
  </div>
</template>

<script>
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

import {axiosGraphql} from '../utils.js'
import orderList from '../components/orderList.vue'
import fieldName from '../components/tableFieldName.vue'
import optToggle from '../components/optToggle.vue'

  export default {
    name:'form-table',
    components:{
      orderList,
      fieldName,
      optToggle,
    },
    props:['tableName','project_id'],
    data() {
      return {
        selected: [],
        tableDataQuery: null,
        searchFields: null,
        showPhoto: null,
      }
    },
    computed: {
      fieldsList(){
        return this.data.parTableColumnsByIdTable.nodes.filter(x=> x.label).sort((a,b)=> {
          const oa = a.columnName.match(/^([a-z])([0-9]+)/)
          const ob = b.columnName.match(/^([a-z])([0-9]+)/)
          if(oa && ob){
            if(oa[1] == ob[1] && oa[2] != ob[2]){
              return Number(oa[2]) - Number(ob[2])
            }
            return a.columnName.localeCompare(b.columnName)
          }else if(oa){
            return -1
          }else if(ob){
            return 1
          }
          return a.columnName.localeCompare(b.columnName)
        })
      },
      fields(){
        // return ['selected', 'columnName', 'label']
        return ['selected', 'label']
      },
      dataStats() {
        const fis = this.selected.map(fi=>{
          fi.name = camelCase(fi.columnName)
          fi.group = ["select one", 'select all that apply'].indexOf(fi.type) != -1
          fi.isNumeric = ['integer', 'decimal'].indexOf(fi.type) != -1
          return fi
        })

        const putValInSubs = (subs, fi, x)=>{
          const rep = []
          subs.map(sub=>{
            if(x[fi.name] && x[fi.name] != 'null'){ // sometimes the null is a string....
              // has a value
              sub[fi.name].count += 1
            }else{
              sub[fi.name].countEmpty += 1
            }
            if(fi.isNumeric){
              sub[fi.name].values.push(x[fi.name])
            }
            rep.push(sub[fi.name].sub)
          })
          return rep
        }
        const putValInSubsGroup = (subs, fi, x)=>{
          const rep = []
          subs.map(sub=>{
            sub[x].count += 1
            rep.push(sub[x].sub)
          })
          return rep
        }
        const subsHasVal = (subs, fi, val, isGroup)=>{
          subs.map(sub=>{
            if(!sub[val]){
              if(isGroup){
                sub[val] = {count:0, fi: fi, sub:{}, group: true}
              }else{
                sub[val] = {count:0, countEmpty: 0, fi: fi, sub:{}, group: false, values:[]}
              }
            }
          })
        }

        const rep = {}
        this.tableData.map(x=>{
          // process each lines of the results data
          let subs = [rep]
          for( let ifi=0;ifi<fis.length;ifi++){
            // for each filed, create hierarchical record each has a sub (with stats)
            const fi = fis[ifi]
            if(fi.group){
              const splitVals=[]
              if (fi.split){
                x[fi.name].split(',').map(x3=>{
                  splitVals.push(x3)
                })
              }else{
                splitVals.push(x[fi.name])
              }
              let nextSubs = []
              splitVals.map((x2, x2i)=>{
                subsHasVal(subs, fi, x2, true)
                nextSubs = nextSubs.concat(putValInSubsGroup(subs, fi, x2))
              })
              subs = nextSubs
            }else{
              subsHasVal(subs, fi, fi.name, false)
              subs = putValInSubs(subs, fi, x)
            }
          }
        })
        const cols = []
        const colGroups = []
        fis.map((fi, i)=>{
          if(fi.group){
            cols.push({label: 'Valeur', nGroup: i})
            cols.push({label: 'Nb', nGroup: i})
            cols.push({label: '%', nGroup: i})
            colGroups.push({label: fi.label, nbCol: 3})
          }else{
            cols.push({label: 'Nb pas vide', nGroup: i})
            cols.push({label: 'Nb vide', nGroup: i})
            cols.push({label: '% pas vide', nGroup: i})
            cols.push({label: '% vide', nGroup: i})
            if(fi.isNumeric){
              cols.push({label: 'Moyenne', nGroup: i})
              cols.push({label: 'Minimum', nGroup: i})
              cols.push({label: 'Maximum', nGroup: i})
              colGroups.push({label: fi.label, nbCol: 7})
            }else{
              colGroups.push({label: fi.label, nbCol: 4})
            }
          }
        })
        const nbTot = countDepthProps(rep,'sub')
        const choices = this.fieldsChoices
        statsPc(rep)
        const rep2 = statsPrepare(rep, choices)
        // return {rep, rep2, fis, cols, colGroups, nbTot}
        return {rep2, fis, cols, colGroups, nbTot}
      },
      fieldsChoices(){
        return this.tableDataQuery['allParTableColumnChoices'].nodes
      },
      queryName(){
        let tab1 = upperFirst(camelCase(this.tableName))
        if(!tab1.endsWith('s')){

          tab1 += 's'
        }
        if(!isNaN(Number(tab1.slice(-2,-1)))){
          // Bug or will, but graphiql put an uppercase end S when the previous letter is a number.
          tab1 = tab1.slice(0,-1) + 'S'
        }
        return 'all' + tab1
      },
      queryColumns(){
        return this.selected.map(x=>camelCase(x.columnName)).join(' ')
      },
      tableData(){
        if(this.tableDataQuery){
          return this.tableDataQuery[this.queryName].nodes.filter(x=> x)
        }
      },
      fieldsTableData(){
        if(this.tableDataQuery){
          const choices = this.fieldsChoices
          const idCols = [...new Set(choices.map(x=>x.idColumn))];
          const rep = this.selected.map(x=>{
            const myFi = {
              key: camelCase(x.columnName),
              label: x.label,
              sortable: true,
              sortByFormatted: true,
              oriFi: x,
            }
            if (idCols.indexOf(x.id) != -1){
              const myChoices={}
              choices.filter(y=> y.idColumn == x.id).map(y=>{
                myChoices[y.name] = y.label
              })
              myFi.formatter = (value, key, item)=>{
                if(value.includes(',')){
                  return value.split(',').map(x=>myChoices[x]).join(', ')
                }else{
                  return myChoices[value]
                }
              } 
            }
            return myFi
          })
          return [{
              key: 'docId',
              label: 'ID',
              sortable: true,
              sortByFormatted: true,
            }].concat(rep)
        }
      },
      selectedIds(){
        return this.selected.map(x=> x.id)
      }
    },
    asyncComputed:{
      data(){
        return axiosGraphql(this, query(this)).then(rep=>{
          return rep.allParTables.nodes[0]
        })
      },
    },
    watch: {
      selected(newValue, oldValue) {
        this.tableDataQuery = null
      }
    },
    methods: {
      onRowClicked(item,index, event ) {
        let iSel = -1
        this.selected.map( (x, xi)=>{
          if(x.id == item.id){
            iSel = xi
          }
        })
        if (iSel==-1){
          this.selected.push(item)
        }else{
          this.selected.splice(iSel, 1)
        }
      },
      show() {
        axiosGraphql(this, query2(this)).then(rep=>{
          this.tableDataQuery = rep
        })
        
      },
      showStats(){

      },
      getDataValue(cellData){
        // find the field with attributes

      },
    },
  }
const query = (that)=>`
query{
  allParTables(condition: { tableName: "${that.tableName}" }){
    nodes{
      id
      label
      parTableColumnsByIdTable{
        nodes{
          id
          columnName
          private
          label
          type
        }
      }
    }
  }
}
`
const query2 = (that)=>`
query{
  allParTableColumnChoices(filter: { idColumn: { in: [ ${ that.selected.map(x=> x.id).join(',') } ]} }){
    nodes{
      idColumn
      name
      label
    }
  }
  ${that.queryName}{
    nodes{
      
      ${that.queryColumns}
    }
  }
}
`

const countDepthProps = (ob, prop)=>{
    let totalCount=Object.keys(ob).length
    Object.keys(ob).map(k => {
      if (ob[k][prop]) {
        var nb1 = countDepthProps(ob[k][prop], prop)
        ob[k][prop + 'Count'] = nb1
        if(nb1>0){
          nb1=nb1-1 // we already have one for myself
        }
        totalCount += nb1
      }
    })
    return totalCount
}
const statsPrepare = (rep, choices, row)=>{
    const average = arr => arr.reduce( ( p, c ) => p + c, 0 ) / arr.length;
    let lines = []
    Object.keys(rep).map((k, i) => {
      let rep3 = [] // is one line....
      if (i == 0 && row){
        rep3 = row
      }
      if(rep[k].fi.group){
        const fiId =rep[k].fi.id
        // group name and stats
        let val = null
        if(k && k !=='null'){
          try {
            if(k.includes(',')){
              val = k.split(',').map(x7=>choices.filter(y=> y.idColumn == fiId && y.name == x7)[0].label).join(', ')
            }else{
              val = choices.filter(y=> y.idColumn == fiId && y.name == k)[0].label
            }
          } catch (error) {
            // console.log(k);
            // console.log(error);
          }
        }
        rep3.push( {value: val, span: rep[k].subCount }) 
        rep3.push( {value: rep[k].count, span: rep[k].subCount }) 
        rep3.push( {value: formatPc(rep[k].count / rep[k].countAll), span: rep[k].subCount }) 
      }else{
        // only count stats
        rep3.push( {value: rep[k].count, span: rep[k].subCount }) 
        rep3.push( {value: rep[k].countEmpty, span: rep[k].subCount }) 
        rep3.push( {value: formatPc (rep[k].count / (rep[k].count + rep[k].countEmpty)), span: rep[k].subCount }) 
        rep3.push( {value: formatPc (rep[k].countEmpty / (rep[k].count + rep[k].countEmpty)), span: rep[k].subCount }) 
        if(rep[k].fi.isNumeric){
          rep3.push( {value: average(rep[k].values), span: rep[k].subCount }) 
          rep3.push( {value: Math.min(...rep[k].values), span: rep[k].subCount }) 
          rep3.push( {value: Math.max(...rep[k].values), span: rep[k].subCount }) 
        }
      }
      // add sub information
      const subLines = statsPrepare(rep[k].sub, choices, rep3)
      
      lines.push(rep3)
      // sub lines, don't push the first one.
      for(let i2=1;i2<subLines.length;i2++){
        lines.push(subLines[i2])
      }
    })
    return lines
}
const statsPc = (rep)=>{
  let count1 = 0
  if(Object.keys(rep).length == 0){return}
  
  Object.keys(rep).map((k, i) => {
    count1 += rep[k].count
    statsPc(rep[k].sub)
  })
  if(rep[Object.keys(rep)[0]].fi.group){
    Object.keys(rep).map((k, i) => {
      rep[k].countAll = count1
      rep[k].pc = count1
    })
  }
}
const formatPc = (val)=>{
  return (val * 100).toFixed(1) + ' %'
}
</script>

<style scoped>

</style>
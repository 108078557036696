 
// ******** OMVG

export default{
  name:'omvg',
  documentTitle:'OMVG',
  navbarTitle:'Formulaires - OMVG',//not used for now
  couchdbUrls: ['https://db.formulaires.omvg.net/'],//,'http://192.168.50.2:5984/'], // we pass many as we want to support mobile field server
  databaseSystem:'forms_system',
  pouhdb_replicate_batch_size:5, //default to 100, but crash in browser with photos.
  serial_position_active:false,
  inputGeopointDirectGpsClickAllowed:true,//this by pass the map.
  new_db_couchdb_security_users:"omvg_users",
  sql_params:{
    api_url: 'https://api.formulaires.omvg.net/',
    login_suffix: '@couchdb.sys',
    auto_sync_changes: true, // if we automatically create the record so nodesync will begin sync
    auto_sql_creation: true, // 
    auto_sql_creation_options:{ // same options possible as the sql.vue
      namebyform: true,
      selectManyTable: true,
      attachements: true,
      attachmentsView: true,
    }
  },
  allowDownloadSurveys: true,
  disableEditingOnOtherDevice: true,
  // TODO: Think about a better way to integrate those, but works for now.
  tableStatsViews:{
    // Id of the project
    'project_ckcw29dba0000365qz1i2cxul':{
      apiUrl: 'https://api2.formulaires.omvg.net',
      tokenType: 'sql_params',
    }
  }
}

<template>
  <i class="mr-2" :class="classIco" @click="updateVal"></i>
</template>

<script>
  // value type
  // 0 or null = not selected
  // 1 = selected
  // 2 = parent selected
  // 3 = child selected
  const valTypes={
    0:['fal','fa-square'],
    1:['fas','fa-check-square', 'color1'],
    2:['fas','fa-check-square', 'color1', 'color102'],
    3:['fas','fa-minus-square', 'color101']
  }

  export default {
    name:'sg-tree-checkbox',
    props:['value'],
    computed:{
      val2(){
        // we don't want null here
        if(this.value===null){
          return 0
        }
        if(this.value > 3){
          return 0
        }
        return this.value
      },
      classIco(){
        return valTypes[this.val2]
      }
    },
    methods:{
      updateVal(){
        if(this.value==1){
          this.$emit('input',0) 
        }else{
          this.$emit('input',1) 
        }
      }
    },
  }
</script>

<style scoped>
  .color101 {
    color: #808080;
  }
  .color102 {
    opacity: 0.5;
  }

</style>

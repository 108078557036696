var styleMap = {
  fill: function(obj) {
    if (typeof obj == 'string' || Array.isArray(obj)) {
      obj = {
        color: obj
      };
    }
    return new ol.style.Fill(obj);
  },
  stroke: function(obj) {
    if (typeof obj == 'string' || Array.isArray(obj)) {
      obj = {
        color: obj
      };
    } else if (typeof obj == 'number') {
      obj = {
        width: obj
      };
    }
    return new ol.style.Stroke(obj);
  },
  text: function(obj) {
    if (typeof obj == 'string') {
      obj = {
        text: obj
      };
    }
    if (obj.fill) {
      obj.fill = styleMap.fill(obj.fill);
    }
    if (obj.stroke) {
      obj.stroke = styleMap.stroke(obj.stroke);
    }
    //  TODO: implement someday, but complicated as nee to send a function.
    // see: https://openlayers.org/en/latest/examples/vector-labels.html
    // if (obj.text) {
    //   obj.text = getText(feature, resolution, dom);
    // }
    return new ol.style.Text(obj);
  },
  circle: function(obj) {
    if (obj.fill) {
      obj.fill = styleMap.fill(obj.fill);
    }
    if (obj.stroke) {
      obj.stroke = styleMap.stroke(obj.stroke);
    }
    return new ol.style.Circle(obj);
  },
  icon: function(obj) {
    return new ol.style.Icon(obj);
  },
  image: function(obj) {
    if (typeof obj.radius !== 'undefined') {
      return styleMap.circle(obj);
    }
    if (obj.regularshape) {
      return styleMap.regularshape(obj.regularshape)
    }
    return styleMap.icon(obj);
  },
  regularshape: function(obj) {
    if (obj.fill) {
      obj.fill = styleMap.fill(obj.fill);
    }
    if (obj.stroke) {
      obj.stroke = styleMap.stroke(obj.stroke);
    }
    return new ol.style.RegularShape(obj);
  },
};


export default{
  makeStyle(styleSpec0) {
    // Ensure we don't modify the original styleSpec - which is our config
    const styleSpec = JSON.parse(JSON.stringify(styleSpec0))
    if (Array.isArray(styleSpec)) {
      return styleSpec.map(oneSpec=>{
        return this.makeStyle(oneSpec);
      });
    }

    var obj = {};
    Object.keys(styleSpec).forEach(function(key) {
      var val = styleSpec[key];
      if (styleMap[key]) {
        obj[key] = new styleMap[key](val);
      } else {
        obj[key] = val;
      }
    });
    return new ol.style.Style(obj);
  },
  makStyleFeatureRule(styleSpec){
    let styleSpecRules = {rules: {}, attribute_name: styleSpec.attribute_name}
    styleSpec.rules.map(x=>{
      styleSpecRules.rules[x.value] = this.makeStyle(x.style)
    })
    styleSpecRules.default_style = this.makeStyle(styleSpec.default_style)
    let styleSpecRulesFunction = (feature,resolution) =>{
      let val = feature.get(styleSpecRules.attribute_name)
      if (styleSpecRules.rules[val]){
        return styleSpecRules.rules[val]
      }else{
        return styleSpecRules.default_style
      }
    }
    return styleSpecRulesFunction
  },
}


// Samples
/*
** Triangles:
 "ol_style": {
    "image": {
      "fill": {
        "color": "#9C27B0"
      },
      "radius": 6,
      "zIndex": 3
    },
    "stroke": {
      "color": "#9C27B0",
      "width": 2,
      "zIndex": 2
    }
  },

  *** lines, polygons or points:
  "ol_style": {
    "image": {
      "fill": {},
      "radius": 6,
      "zIndex": 3
    },
    "stroke": {
      "width": 2,
      "zIndex": 2
    }
  }

*/
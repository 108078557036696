<template>
  <div>
    <div class="alert alert-primary">
      <div class="my-2">
        <b-form-input v-model="wmsAddress" type="text" placeholder="Enter wms URL"></b-form-input>
        <b-form-text class="ml-3">
          Example: http://10.228.16.66:8080/geoserver/rem/wms?LAYERS=rem%3Aortho&STYLES=&FORMAT=image%2Fjpeg&SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&SRS=EPSG%3A3857&BBOX={BBOX}&WIDTH=256&HEIGHT=256
        </b-form-text>
        <b-form-text class="ml-3">
          Ensure you put {BBOX} in place of the bounding box value.
        </b-form-text>
      </div>
      <div class="my-2">
        <b-form-input v-model="cache" type="text" placeholder="CouchDB cache URL"></b-form-input>
        <b-form-text class="ml-3">
          Example: https://dbforms.aecom.space/imagery_rem/ortho-{TileMatrix}-{TileCol}-{TileRow}/tile
        </b-form-text>
      </div>
      <div class="my-2">
        <label>levels (min max):</label>
        <b-form inline>
          <b-form-input v-model="minLevel" type="text" placeholder="Min level"></b-form-input>
          <b-form-input v-model="maxLevel" type="text" placeholder="Max level" class="ml-3"></b-form-input>
        </b-form>
      </div>
      <div class="my-2">
        <b-form-input v-model="bounds" type="text" placeholder="Bounds comma separated"></b-form-input>
        <b-form-text class="ml-3">
          Comma separated in EPSG:3857 or EPSG:4326 (see below) format: minx,miny,maxx,maxy
        </b-form-text>
        <b-form-checkbox class="ml-3" v-model="bounds4326">Geographic (EPSG:4326) coordinates?</b-form-checkbox>
      </div>
      <b-button @click="process" variant="primary" :disabled="!complete" v-if="!seeder">
        Process
      </b-button>
      <b-button @click="cancel" variant="danger" v-if="seeder" class="ml-2">
        Cancel
      </b-button>
      <b-button @click="createLayer" variant="primary" :disabled="!complete" class="ml-2">
        Create project base layer using those cached imagery
      </b-button>
    </div>
    <div>
      {{message}}
    </div>
    <div class="alert alert-danger" v-if="err">
      {{err}}
    </div>
  </div>
</template>

<script>
//import Seed0 from "./seed/seed.js"
//https://github.com/omniscale/seedjs
require('./seed/src/seed.js')
require('./seed/src/util.js')
require('./seed/src/grid.js')
require('./seed/src/source.js')
require('./seed/src/cache.js')
import utils from '../../utils/utils_gen.js'

export default {
  data() {
    return {
      wmsAddress: null,
      bounds: null,
      bounds4326: false,
      boundsNumeric:null,
      cache: null,
      minLevel: 1,
      maxLevel: null,
      message: null,
      seeder: null,
      err:null
    }
  },
  computed: {
    complete() {
      if (
        this.wmsAddress &&
        this.bounds &&
        this.cache &&
        this.minLevel &&
        this.maxLevel
      ) {
        return true
      }
      return false
    }
  },
  methods: {
    process() {

      var bounds = this.bounds.split(",").map(x => Number(x))
      if(this.bounds4326){
        bounds=ol.proj.transformExtent(bounds,'EPSG:4326', 'EPSG:3857')
      }
      this.boundsNumeric=bounds
      //var bounds = bounds boxLayer.features[0].geometry.getBounds();
      var minLevel = parseInt(this.minLevel)
      var maxLevel = parseInt(this.maxLevel)

      console.log(Seed)
      var source = new Seed.Source.WMSSource(this.wmsAddress)
      var cache = new Seed.Cache.CouchDB(this.cache)

      let grid = new Seed.Grid()
      let bbox = new Seed.BBox(bounds[0], bounds[1], bounds[2], bounds[3])
      console.log(grid.affectedTiles(11, bbox))

      this.seeder = new Seed.Seeder({
          grid: grid,
          bbox: bbox,
          levels: [minLevel, maxLevel]
        },
        source,
        cache
      )
      //ensure the DB is created
      let db = new this.$PouchDB(this.cache.split("/").slice(0, 4).join("/"), this.$store.getters.pouchDbOptions)
      let obj1 = {
        _id: 'seed_' + this.$_cuid(),
        creator: this.$store.getters['userName'],
        creationTime: this.$store.getters['utcCurrentTime'](),
        source: this.wmsAddress,
        cache:this.cache,
        bbox: bounds,
        bounds:this.boundsNumeric,
        levels: [minLevel, maxLevel],
        //project_id:this.$store.project.project_id
      }
      db.put(obj1).then(rep => {
        //start the seeding
        this.seeder.start(this.seedProgress)
      })

    },
    seedProgress(progress) {
      if (!progress.running) {
        this.message = "not running"
      }
      this.message = progress.tiles + ' / ' + progress.totalTiles
    },
    cancel() {
      this.seeder.stop()
      this.seeder = null
    },
    createLayer() {
      let label1=this.cache.split("/").slice(3, 4)[0]
      let label2=this.cache.split("/").slice(4, 5)[0].split("-")[0]
      let label=label1+"_"+label2
      //url
      let imgURL=this.cache.split("/").slice(4, 6).join("/")
      imgURL=utils.replaceAll(imgURL,'{TileMatrix}','{z}')
      imgURL=utils.replaceAll(imgURL,'{TileCol}','{x}')
      imgURL=utils.replaceAll(imgURL,'{TileRow}','{y}')
      // create the layer doc in the project DB.
      let obj1 = {
        "_id": "map_layer_base_" + label2,
        "type_store": "layer_imagery_couchdb",
        "visible": true,
        "is_basemap": true,
        "label": label2,
        "layer_type": "Tile",
        "source_type": "XYZ",
        "couchdb_name": label1,
        "url_online": '/'+imgURL,
        "name": label,
        "extent": this.boundsNumeric
      }
      this.$store.dispatch('project/addLayerDoc',obj1).then(rep=>{
        this.message='Doc added'
      }).catch(err=>{
        this.$store.dispatch('app_message_error',err)
        this.err=err
      })
    }
  }
}
</script>

<style>
</style>

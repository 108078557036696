// as messages in index.js

// TODO: Continu this implementation...

// represent the messages that can be show to the user.
//    - For now only app messages that come form the code.
//    - TODO: Later we can add server messages. from the database, specific to the user.

// Each message has a type, expiration date, applicable env array, and a message.

export default {
  namespaced: true,
  state: {
    
  },
  getters: {
    messages(){
      return [
        {
          type:'info',
          expiration: '2024-06-01',
          env:['all'],
          messageKey: 'Welcome to the app. This is an info message.'
        },
      ]
    }

  },
  mutations: {
    
  },
  actions: {
    
  },
}

<template>
  <input-row 
    :showElement="showElement"
    :label="label" 
    :elId="elId" 
    :status="status" 
    :config="config"
    :me="me"
  >
    <VueSignaturePad width="100%" height="14em" ref="signaturePad" class="signature-border" v-if="!myValue"/>
    <img :src="myValue" class="signature-border" v-if="!!myValue"/>
    <div class="mt-2">
      <b-btn variant="primary" @click="save" :disabled="!!myValue"><span class="glyphicon glyphicon-plus"></span></b-btn>
      <b-btn variant="danger" class="ml-3" @click="reset" :disabled="!myValue"><span class="glyphicon glyphicon-remove"></span></b-btn>
    </div>
  </input-row>
</template>

<script>
import base from './_baseInput.js'
import VueSignaturePad from 'vue-signature-pad';

export default {
  name:'i-signature',
  mixins:[base],
  components:{
    VueSignaturePad
  },
  data(){
    return {
      
    }
  },
  watch:{
    // myValue:{
    //   handler(){
    //     if(this.myValue){
    //       this.$refs.signaturePad.clearSignature()
    //       this.$refs.signaturePad.fromDataURL(this.myValue) //**** not working */
    //     }else{
    //       this.$refs.signaturePad.clearSignature()
    //     }
    //   },
    //   immediate:true,
    // }
  },
  methods:{
    save(){
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if(!isEmpty){
        this.myValue=data
      }
    },
    reset(){
      this.myValue=null
      this.$refs.signaturePad.clearSignature()
    },
  }
}
</script>

<style>
.signature-border {
  border: 3px solid;
  border-radius: 5px;
  border-color: darkslategrey;
  /* background-color: darkslategrey; */
  /* background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #4bc5e8, #9f6274); */
  /* background-origin: border-box; */
  /* background-clip: content-box, border-box; */
}
</style>

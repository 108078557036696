<template>
  <div>
    <b-list-group>
      <b-list-group-item 
        v-for="(sel, i) in list1" 
        :key="'ss'+sel.id"
        class="d-flex justify-content-between align-items-center"
      >
        <div>
          <slot name="label" v-bind:sel="sel">
            {{sel[labelAttribute]}}
          </slot>
        </div>
        <div>
          <b-btn @click="orderAssembly(sel,-1)" variant="info" :disabled="i==0" pill><i class="fas fa-level-up-alt"></i></b-btn>
          <b-btn @click="orderAssembly(sel,1)" variant="info" :disabled="i==(list1.length-1)" pill class="ml-2"><i class="fas fa-level-down-alt"></i></b-btn>
        </div>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import Vue from 'vue'

  export default {
    name:'sg-order-list',
    props:['value', 'labelAttribute'],
    computed:{
      list1(){
        return this.value
      },
    },
    methods: {
      orderAssembly(sel,upDown){
        // https://stackoverflow.com/a/12358579/140384
        let i2 = this.list1.indexOf(sel)
        swap(this.list1,i2,i2+upDown)
        this.$emit('input',this.list1)
      },
    },
  }

const swap = function(theArray, indexA, indexB) {
  var temp = theArray[indexA];
  Vue.set(theArray,indexA,theArray[indexB])
  Vue.set(theArray,indexB,temp)
};
</script>

<style scoped>

</style>
<template>
    <div>
        <div v-if="form_definition && surveyData">
            <div class="list-group list-group-root">
                <i-group
            :config="form_definition"
            :formDefinition="form_definition"
            v-model="surveyData"
            :status="form_status"
            :showSystemValues="false"
            :surveyDoc="surveyDoc"
            :namePath="[]"
            :pagesObj="pagesObj"
            ></i-group>
            </div>
        </div>
        
    </div>
</template>

<script>
import base from './_base.js'
import group from '../../components/summaryGroup.vue'
import formUtils from '../../utils/form_utils.js'

export default {
  name:'survey-summary',
  mixins:[base],
  // components:inputs,
  components:{IGroup:group},
  props:['survey_id','otherData'],
  data(){
    return{
      surveyDoc:null,
      form_definition:null,
    }
  },
  computed:{
    form_status(){
      // the json form status.
      if(this.surveyDoc){
        return this.surveyDoc.form_status
      }
    },
    formStatus(){
      //the global status of the form, one value for all. the higher one.
      if(this.surveyDoc && this.surveyDoc.form_status){
        return formUtils.getStatusOfTree(this.surveyDoc.form_status)
      }else{
        return 999
      }
    },
    surveyData(){
      return this.surveyDoc.form_data
    },
    pagesObj(){
      if(this.surveyDoc && this.form_definition){
        return formUtils.computePages(this.surveyDoc,this.form_definition,this.otherData)
      }
    },
  },
  watch:{
    form_id:'clear',
    survey_id:{
      handler:'fetchSurvey',
      immediate:true
    },
  },
  methods:{
    clear(){
      this.form_definition=null
      this.surveyDoc=null
    },
    end(){
      this.clear()
      this.$router.push('/forms/'+this.$store.state.form.form_id)
    },
    fetchSurvey(){
      this.fetchFormPromise.then(()=>{
        this.fetchSurvey2()
      }).catch(err=>this.$store.dispatch('app_message_error',err))
    },
    fetchSurvey2(){
      console.log('fecth survey 2 ' + this.survey_id)
      this.clear()
      //fetch the survey_id
      this.$store.dispatch('form/fetchSurvey',{ survey_id: this.survey_id}).then(doc=>{
        this.surveyDoc=doc
        return this.$store.dispatch('form/fetchDefinition',doc.form_definition)
      }).then(doc=>{
        this.form_definition=doc.form_definition
      }).catch(err=>this.$store.dispatch('app_message_error',err))
    },
  }
}
</script>

<style>

</style>

<!-- this is used for syncing a form, used in form sync and in project sync 
  syncState comtrol the flow of events, received only, the owrk is done by the caller
  only display here
-->
<template>
  <div>
    <div v-if="syncState==0">
      <slot name="state0"></slot>
    </div>
    <!-- inprogress -->
    <div v-if="syncState==1" class="alert alert-warning">
      <h4>{{labels.inProgress}}</h4>
      {{labels.inProgressFeatures}}: {{changeCounter}}
    </div>
    <!-- complete -->
    <div v-if="syncState==2">
      <div class="alert alert-success h4">{{labels.complete}}</div>
      <slot name="state2"></slot>
    </div>
    <!-- delete local in progress -->
    <div v-if="syncState==3" class="alert alert-warning">
      <h4>{{labels.deletelocalsProgress}}</h4>
      <div>{{mess}}</div>
    </div>
    <!-- delete local done -->
    <div v-if="syncState==4" class="alert alert-success">
      <h4>{{labels.deletelocalsDone}}</h4>
      <div>{{mess}}</div>
      <slot name="state4"></slot>
    </div>
    <!-- delete local and form done -->
    <div v-if="syncState==5" class="alert alert-success">
      <h4>{{labels.deletelocalsDone}}</h4>
      <div>{{mess}}</div>
      <slot name="state5"></slot>
    </div>
    <!-- Error -->
    <div v-if="syncState==99" class="alert alert-danger">
      <h4>{{labels.error}}</h4>
      <div>{{errorMessage}}</div>
    </div>
  </div>
</template>

<script>
// syncingState:0,//0= not sync, 1=in sync, 2=sync finiched,99=sync errors
  const syncStates=[
    {id:0,description:'not syncing'},
    {id:1,description:'sync in process'},
    {id:2,description:'sync finished'},
    {id:3,description:'Deleting local in progress'},
    {id:4,description:'Deleting local finished'},
    {id:99,description:'not syncing'},
  ]


  export default {
    name:'syncState',
    props:['syncState','changeCounter','errorMessage'],
    data() {
      return {
        mess:null, // not used for now, could be a props!?!?
      }
    },
    computed: {
      labels(){return this.$store.state.labels.form.sync},
    },
  }
</script>

<style scoped>
</style>
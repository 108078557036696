// as sqlApi in index.js

// represent the server only sqlApi, postgrest in the background.
// useful for auto sync, exports, dashboards, and sql view creation.
import axios from 'axios';

export default {
  namespaced: true,
  state: {
    me:null,
    axiosInstance:null,
  },
  getters: {
    isAuthenticated: state => !!state.me,
    name: state => state.me?state.me.name:'',
    email: state => state.me?state.me.email:'',
    role: state => state.me?state.me.role:'',
    //  determine if it's defined?
    params: (state, getters, rootState, rootGetters) => rootState.settings.sql_params,
    isDefined: (state, getters, rootState, rootGetters) => getters.params?true:false,
    apiUrl (state, getters, rootState, rootGetters){
      if(getters.isDefined){
        return getters.params.api_url
      }
    },

  },
  mutations: {
    login(state,info){
      state.me=info.me
      if(localStorage.getItem('user-token')){
        state.axiosInstance=axios.create({
          baseURL: info.apiUrl,
          headers: {'Authorization': "Bearer " + localStorage.getItem('user-token')}
        })
      }
      if(state.axiosInstance && !info.me){
        state.axiosInstance=null
      }
    },
  },
  actions: {
    // see this used as inspiration: https://blog.sqreen.com/authentication-best-practices-vue/?utm_source=external&utm_campaign=Vuejsdeveloppers&utm_medium=social
    login(context,options){
      if(context.getters.isDefined===false){
        return Promise.resolve()
      }
      if(options.user){
        // user must be an email
        options.email=options.user + context.getters.params.login_suffix
      }
      let reqOpts={}
      let req1 = ['mutation {','}']
      if(options.email){
        //normal login
        req1.splice(1,0,`login(input: {email: "${options.email}", pass: "${options.pass}"}){json}`)
        context.commit('login',{token: null,email:null})
      }else{
        //use the token to authenticate
        req1.splice(1,0,`refreshToken(input: {}){json}`)
        const token1 = localStorage.getItem('user-token')
        if(token1){
          reqOpts.headers= {'Authorization': "Bearer " + token1}
        }else{
          return Promise.reject()
        }
      }
      
      // do the operation and get me and a token (new)
      return axios.post(context.getters.apiUrl, { query: req1.join(' ') },reqOpts).then(rep=>{
        if (rep.data.errors){
          return Promise.reject(rep.data.errors[0].message)
        }
        const rep2 = rep.data.data[Object.keys(rep.data.data)[0]].json
        const token=rep2.token
        context.commit('login',{me: rep2.me, apiUrl: context.getters.apiUrl})
        localStorage.setItem('user-token', token)
        localStorage.setItem('user-token-couchdb', rep2.token_couchdb)
        // axios.defaults.headers.common['Authorization'] = "Bearer " + token
        // console.log('sqlApi login ok')
        // if it's sucessful we refresh the token every 0.5 hours
        return context.dispatch('fetchInitialData').then(x=>{
          return Promise.resolve( rep2.me )
        })
      }).catch(err=>{
        // localStorage.removeItem('user-token')
        // localStorage.removeItem('user-token-couchdb')
        context.commit('login',{token: null,email:null})
        return Promise.reject(err)
      })
    },
    logout(context){
      localStorage.removeItem('user-token')
      localStorage.removeItem('user-token-couchdb')
      // delete axios.defaults.headers.common['Authorization']
      context.commit('login',{token: null,email:null})
    },
    fetchInitialData(context){
      //non blocking queries
      // axios.get(context.state.apiUrl + 'specification_group_types').then(rep=>context.commit('set_specification_group_types',rep.data))
      // axios.get(context.state.apiUrl + 'specification_groups').then(rep=>context.commit('set_specification_groups',rep.data))
      return Promise.resolve()
    },
  },
}

<template>

<div id="app">

<!-- </div> class="container-fluid"> -->
    <nav-bar v-show="(!$route.meta) || (!$route.meta.hideDefaultNavBar)"></nav-bar>
    <!-- https://github.com/vuejs/vue-router/issues/403 -->
    <keep-alive>
      <router-view v-if="$route.meta && $route.meta.keepAlive"/>
    </keep-alive>
    <router-view v-if="(!$route.meta) || (!$route.meta.keepAlive)" />
    <!-- Modals -->
    <!-- Here or we have visibility problems see documentation -->
    <b-modal v-if="$store.state.modals.photo" v-model="$store.state.modals.photoShow" id="modal-photo" :title="$store.state.modals.photo.name" size="lg">
      <img :src="$store.state.modals.photo.url" class="img-fluid"/>
    </b-modal>
    <!-- TODO: make a route with this, would be probably better as UI. easier to push the back button, the component don't stay in memory, etc. -->
    <!-- See if we remove the nav-bar or not then -->
    <b-modal v-if="$store.state.modals.orderSelect" v-model="$store.state.modals.orderSelectShow" id="modal-oder-select" :title="$store.state.labels.form_select.configureOrder" size="lg">
      <order-select></order-select>
    </b-modal>
</div>

</template>

<script>
import navBar from './components/navbar.vue'
import orderSelect from './components/orderSelect.vue'
// import cuid from 'cuid'

export default {
  name: 'App',
  components:{navBar,orderSelect},
  data() {
    return {
    //   key: cuid.cuid()
    }
  },
  computed:{
    isLogged(){return this.$store.state.isLogged},
    systemDoc(){return this.$store.state.systemDoc},
    isLocal(){return this.$store.getters['localDB/isOnlineMode']===false},
  },
  watch:{
    // TODO: better handle when local change, now in each component... But could be here. But also, track when local data added or deleted, as is might change the current or alive view. Can we put a key in router-view, to be tested.
    // isLocal(){
    //   // database to use changed... reset myself.
    //   this.key = cuid()
    // },
  },
  methods:{

  }
}
</script>

<style>
#app {
  /* font-family: 'Avenir', Helvetica, Arial, sans-serif; */
  /* -webkit-font-smoothing: antialiased; */
  /* -moz-osx-font-smoothing: grayscale; */
  /* text-align: center; */
  /* color: #2c3e50; */

  /* for navbar.*/
  margin-top: 60px;
}
</style>

import { render, staticRenderFns } from "./tableFieldName.vue?vue&type=template&id=19d05aa6&scoped=true"
import script from "./tableFieldName.vue?vue&type=script&lang=js"
export * from "./tableFieldName.vue?vue&type=script&lang=js"
import style0 from "./tableFieldName.vue?vue&type=style&index=0&id=19d05aa6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19d05aa6",
  null
  
)

export default component.exports
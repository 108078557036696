<template>
    <div v-if="relevant">
      <div class="list-group-item" v-if="!isRoot" @click="showCollapse=!showCollapse"
        :style="{'padding-left': padding + 'px'}"
        :class="groupStatusClass"
      >
        <div class="d-flex justify-content-between">
          <div>
            <i class="glyphicon mx-2" :class="{'glyphicon-chevron-right':!showCollapse,'glyphicon-chevron-down':showCollapse}"></i>
            <span v-html="label_group"></span>
          </div>
          <div>
            <span v-for="(flagCount, flag) in flagsClass" class="mr-3" :class="flag" :key="flag">{{flagCount}} <i class="mdi mdi-flag"></i></span>
          </div>
        </div>
      </div>
      <div v-if="isRoot && !hideButtons" class="my-3 ml-5">
        <b-btn variant="primary" @click="$emit('hide')"><span class="glyphicon glyphicon-edit"></span> {{labels.form.summary.backToEdit}}</b-btn>
        <b-btn variant="secondary" class="ml-4" 
          @click="$store.commit('form/inputGroupCurrentPage',0);$emit('hide')"
          ><span class="glyphicon glyphicon-fast-backward"></span> {{labels.form.summary.gotoFormStart}}</b-btn>
        <b-btn variant="secondary" class="ml-2" 
          @click="lastPage"
        >{{labels.form.summary.gotoFormEnd}} <span class="glyphicon glyphicon-fast-forward"></span></b-btn>
      </div>
      <div :id="'group'+config.name" v-if="showCollapse" class="list-group">
        <component v-for="(el) in formElements"
            :is="getComponentName(el)"
            :config="el"
            :key="el.name"
            :formDefinition="formDefinition"
            :value="myValue?.[el.name]"
            :status="status?.[el.name]"
            :surveyDoc="surveyDoc"
            :showSystemValues="showSystemValues"
            :namePath="newNamePath()"
            :pagesObj="pagesObj"
            >
        </component>
      </div>
      <div v-if="isRoot && !hideButtons" class="my-3 ml-5">
        <b-btn variant="primary" @click="$emit('hide')"><span class="glyphicon glyphicon-edit"></span> {{labels.form.summary.backToEdit}}</b-btn>
        <b-btn variant="secondary" class="ml-4" 
          @click="$store.commit('form/inputGroupCurrentPage',0);$emit('hide')"
          ><span class="glyphicon glyphicon-fast-backward"></span> {{labels.form.summary.gotoFormStart}}</b-btn>
        <b-btn variant="secondary" class="ml-2" 
          @click="lastPage"
        >{{labels.form.summary.gotoFormEnd}} <span class="glyphicon glyphicon-fast-forward"></span></b-btn>
        <b-btn class="ml-5 text-right" :href="printUrl"><span class="glyphicon glyphicon-print"></span></b-btn>
      </div>
      
    </div>
</template>

<script>
import base from './input/_baseInput.js'
import summaryItem from './summaryItem.vue'
import summaryRepeat from './summaryRepeat.vue'
import formUtils from '../utils/form_utils.js'

export default {
  name:'summary-group',
  mixins:[base],
  components:{'summary-item':summaryItem,'summary-repeat':summaryRepeat},
  props:{
    pagesObj:{},
    isRootRepeat:{
      type:Boolean,
      default:false
    },
    hideButtons:{
      type:Boolean,
      default:false
    },
  },
  data(){
    return {
      showCollapse:false
    }
  },
  created(){
    if(this.isRoot){
      this.showCollapse=true
    }
  },
  computed:{
    padding(){
      if(this.isRootRepeat){
        return ((this.namePath.length-1) * 40) - 30
      }
      return (this.namePath.length * 40)
    },
    groupStatusClass(){
      if(this.groupStatus){
        return this.status_list?.[this.groupStatus]?.list_group_class
      }
    },
    label_group(){
      if(this.isRootRepeat){
        return this.label + ' (' + (parseInt(this.namePath.slice(-1))+1) + ')'
      }
      return this.label
    },
    printUrl(){
      return window.location.href.replace('/edit/','/print2/')
    },
    flagsClass(){
      let flags =  this.$store.getters['form/survey/flagsStatusByPath'](this.currentNamePath)
      const rep = {}
      flags.forEach((f1)=>{
        if(!rep[f1]){
          rep[f1]=0
        }
        rep[f1]++
      })
      return rep
    },
  },
  watch:{
    
  },
  methods:{
    getComponentName(el){
      if(el.type=='group'){
        return 'summary-group'
      }else if(el.type=='repeat'){
        return 'summary-repeat'
      }
      return 'summary-item'
    },
    newNamePath(){
      let new1=this.namePath.slice()
      if(!this.isRoot && this.isRootRepeat===false){
        new1.push(this.config.name)
      }
      return new1
    },
    gotoElementPage(){
      if(this.pages){
        this.$store.commit('form/inputGroupCurrentPage',this.pageElementPageIndex)
      }else{
        this.$router.push(this.$router.currentRoute.path.replace('summary','edit')+'/page/'+(this.pageElementPageIndexSummaryActive+1))
      }
    },
    lastPage(){
      this.$store.commit('form/inputGroupCurrentPage',this.pages.length-1)
      this.$emit('hide')
    }
  }
}
</script>

<style>

</style>

<template>
  <b-modal id="modalAddProject" :title="labels.addProject" @ok="saveNewProject">
    <b-row class="my-1" v-for="type in fields" :key="type.name">
      <b-col sm="3">
        <label :for="`type-${type.name}`">
          {{ type.label }}:
        </label>
      </b-col>
      <b-col sm="9">
        <b-form-input :id="`type-${type.name}`"
          :type="type.type" v-model="type.value">
        </b-form-input>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import cuid from 'cuid'

export default {
  name:'addProject',
  data(){
    let labels=this.$store.state.labels
    let fields=[
      {name:"project_no",label:labels.project_no,type:'text'},
      {name:"project_label",label:labels.project_label,type:'text'},
      {name:"manager",label:labels.manager,type:'text'}
    ]
    let obj1={}
    obj1.fields=fields
    fields.map(x=>x.value='')
    return obj1
  },
  computed: {
    systemDoc(){return this.$store.state.systemDoc},
    labels(){return this.$store.state.labels}
  },
  methods:{
    saveNewProject(){
      let db=this.$store.state.dbDatabaseSystem
      db.get('config').then(doc=>{
        let obj1={
          id:"project_"+cuid(),
          creator:this.$store.getters['userName'],
          creationTime:this.$store.getters['utcCurrentTime'](),
          forms:[],
        }
        this.fields.map(x=>obj1[x.name]=x.value)
        obj1.label=obj1.project_no + ' - ' + obj1.project_label
        doc.projects.push(obj1)
        console.log(obj1)
        return db.put(doc)
      }).then(res=>{
        this.$store.dispatch('getSystemDoc')
      }).catch(err=>this.$store.dispatch('app_message_error',err))
    }
  }
}
</script>

<style>

</style>

<template>
  <div class="container-fluid" v-if="systemDoc">
    <div class="h2  text-center">
      {{labels.projectsListTitle}}
    </div>
    <div class="row justify-content-md-center">
      <div class="col-lg-6">
        <div>
          <b-button v-b-modal.modalAddProject variant="primary" class="m-3" v-if="$store.getters['isAdmin']">
            <span class="glyphicon glyphicon-plus"></span>
              {{labels.addProject}}
          </b-button>
          <b-form-checkbox
            v-if="$store.getters.isAdmin"
            v-model="showDeleted"
            class="float-right"
          >
            {{labels.deletedShow}}
          </b-form-checkbox>
        </div>
        <add-project></add-project>
        <ul class="list-group">
          <li class="list-group-item bg-dark text-white text-center my-pad2">
            {{labels.projectsListTitle2}}
          </li>
          <project
            :key="item.id"
            :project="item"
            v-for="item in systemDoc.projects.filter(x=>x.deleted!==true?true:showDeleted)"
            :opened="systemDoc.projects.length==1"
            :showDeleted="showDeleted"
            >
          </project>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import addProject from '../components/addProject.vue'
import project from '../components/project.vue'

export default {
  name:'listProjects',
  components:{
    addProject,
    project
  },
  data() {
    return {
      showDeleted:false, 
    }
  },
  created(){
    this.$store.dispatch('localDB/switchDatabasesAccesMode',{accessMode: 1})
  },
  computed: {
    systemDoc(){return this.$store.state.systemDoc},
    labels(){return this.$store.state.labels}
  },
}
</script>

<style>

</style>

//http://stackoverflow.com/a/42202802/140384
//and
//https://github.com/chrisvfritz/7-secret-patterns
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

const files = require.context('.', false, /\.vue$/)
const modules = {}
files.keys().forEach((fileName) => {
  // Get PascalCase name of component
  const componentObj=files(fileName).default || files(fileName)
  // const componentName = upperFirst(
  //   camelCase(fileName.replace(/^\.\//, '').replace(/\.\w+$/, ''))
  // )
  const componentName=upperFirst(camelCase(componentObj.name))
  //if(componentObj.name!='IGroup'){
    modules[ componentName ] = componentObj
  //}
})
export default modules

<template>
<div class="panel panel-default">
  <ul class="list-group">
    <li class="list-group-item list-group-item-dark d-flex justify-content-between">
      <div class="h3">
        {{labels.tableOfContent.baseLayers}}
      </div>
      <b-btn variant="primary" @click="baseLayersNone">{{labels.tableOfContent.baseLayersNone}}</b-btn>
    </li>
    <basemaps-item :key="'baselayer'+index" v-for="(lay,index) in layers_id" :layer_id="lay"></basemaps-item>
  </ul>
</div>
</template>

<script>
import basemapsItem from './basemaps_item.vue'

export default {
  components:{basemapsItem:basemapsItem},
  data() {
    return {
    }
  },
  computed: {
    labels(){
      return this.$store.state.map.labels
    },
    layers_id() {
      return this.$store.getters['map/layers_basemap']
    },
  },
  methods: {
    baseLayersNone(){
      this.$store.dispatch('map/setBaseLayer',-1)
    }
  }
}
</script>

<style>
</style>

<template>
  <div>
    <div v-if="isLocal" class="mt-2">
      <b-btn variant="secondary" @click="exportForms(false)" class="ml-5">
        <span class="glyphicon glyphicon-save"></span>
      </b-btn>
      <b-btn variant="secondary" @click="exportForms(true)" class="ml-2">
        <span class="glyphicon glyphicon-save"></span>
        <span class="glyphicon glyphicon-picture ml-2"></span>
      </b-btn>
      <div v-if="error" class="text-danger">{{ error }}</div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'exports-json',
    props: ['doc_id'],
    data() {
      return {
        error: null,
      }
    },
    computed: {
      isLocal(){return this.$store.getters['localDB/isOnlineMode']===false},
    },
    methods: {
      exportForms(includePhotos){
        const objOption = {}
        if(this.doc_id){
          objOption.keys = [this.doc_id]
          if ( includePhotos ){
            objOption.includePhotos = true
          }
        }
        let download = [ this.$store.dispatch('form/fetchSurveys', objOption) ]
        if ( includePhotos ){
          const objOption2 = {}
          if(this.doc_id){
            // find the photos of the survey
            if(this.$store.getters['form/survey/doc_id'] == this.doc_id){
              objOption2.keys = this.$store.getters['form/survey/getPhotoIds']
              console.log('objOption2.keys', objOption2.keys);
            } else{
              // This should never happen
              this.error = 'The survey is not loaded in the $store'
              return false
            }
          }
          download.push(this.$store.dispatch('form/fetchPhotos', objOption2))
        }
        Promise.all(download).then(reps => {
          downloadObjectAsJson(reps, this.$store.state.localDB.config.device_id + '_' + this.$store.state.form.form_id + '_surveys')
        }).catch(err => {
          this.error = err
        })
      },
    },
  }

  // https://stackoverflow.com/a/30800715/140384
  function downloadObjectAsJson(exportObj, exportName){
    var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportObj));
    var downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href",     dataStr);
    downloadAnchorNode.setAttribute("download", exportName + ".json");
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  }
</script>

<style lang="scss" scoped>

</style>
<template>
  <div class="container">
    <div class="h2 text-center">
        {{labels.title}}
      </div>
      <div>To be implemented</div>
      <!-- <div v-if="users">
        <b-table striped hover :items="users" :fields="fields" responsive>
        <!-- <b-table striped hover :items="users" responsive> -->
<!--           
        </b-table>
      </div> -->
  </div>
</template>

<script>
import serverAPI from '../../services/couchdb/server.js'

export default {
  computed: {
    labels(){return this.$store.state.labels.admin.users},
    fields(){
      let obj=[
        // {
        //   key: '_id',
        //   sortable: true
        // },
        {
          key: 'name',
          sortable: true
        },
        {
          key: 'roles2',
          sortable: true
        }
      ]
      const fieldsNames=this.labels.fields
      return obj.map(x=>{
        x.label=fieldsNames[x.key]
        return x
      })
    }
  },
  asyncComputed:{
    users(){
      return serverAPI.getUserList(this.$store.getters.pouchDbOptions).then(rep=>{
        return rep.map(user=>{
          user.roles2=user.roles.join(', ')
          return user
        })
      })
    }
  }
}
</script>

<style>

</style>

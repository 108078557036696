
// ******** REM - forestry

export default{
  name:'rem',
  documentTitle:'REM AECOM Formulaires',
  navbarTitle:'Formulaires - REM',//not used for now
  //TODO: correct this problem: does not work on https... 
  couchdbUrls: ['https://db.remfor.aecom.space/'],//,'http://192.168.50.2:5984/'], // we pass many as we want to support mobile field server
  databaseSystem:'forms_system_rem',
  pouhdb_replicate_batch_size:5, //default to 100, but crash in browser with photos.
  serial_position_active:false,
  inputGeopointDirectGpsClickAllowed:false,//this by pass the map.
  new_db_couchdb_security_users:"rem_users",
  sql_params:{
    api_url: 'https://api.remfor.aecom.space/graphql',
    login_suffix: '@couchdb.sys',
    auto_sync_changes: true, // if we automatically create the record so nodesync will begin sync
    // todo: implement those options
    auto_sql_creation: true, // 
    auto_sql_creation_options:{ // same options possible as the sql.vue
      namebyform: true,
      selectManyTable: true,
      attachements: true,
      attachmentsView: true,
    }
  },
}

<template>
  <input-row 
    :showElement="showElement"
    :label="label" 
    :elId="elId" 
    :status="status" 
    :config="config"
    :me="me"
  >
    <!-- Default appearance -->
    <template v-if="appearance!='multiline'">
      <template v-if="inputMask">
        <!-- TODO have a paramter for the masked prop. -->
        <b-form-input-mask :id="elId" type="text" v-model="myValue" :readonly="readonly" :mask="inputMask" :masked="masked"></b-form-input-mask>
      </template>
      <template v-else>
        <b-form-input :id="elId" type="text" v-model="myValue" :readonly="readonly"></b-form-input>
      </template>
    </template>
    
    <!-- multiline appearance -->
    <b-form-textarea :id="elId"
                  v-model="myValue"
                  v-if="appearance=='multiline'"
                  :readonly="readonly"
                  :rows="rows"
                  >
    </b-form-textarea>

  </input-row>
</template>

<script>
import base from './_baseInput.js'
import utils from '../../utils/utils_gen.js'
import BFormInputMask from './subInput/BFormInputMask.vue'

export default {
  name:'i-text',
  mixins:[base],
  components:{
    BFormInputMask,
  },
  computed: {
    rows() {
      return this.parameters.rows || 3
    },
    inputMask(){
      // https://github.com/vuejs-tips/vue-the-mask
      if(this.config.input_mask){
        return utils.replaceAll( this.config.input_mask,'9','#')
      }
      return null
    },
    masked(){
      if(this.parameters.masked){
        return this.parameters.masked
      }
      return false
    },
  },
  methods: {
    
  },
}
</script>

<style>

</style>

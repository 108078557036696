<template>
  <div class="container">
    <!-- {{surveyDoc}} -->
    <!-- deactivated, not sure if it's what causing un wanted page change... -->
    <!-- v-touch:swipe.left="nextPage"
      v-touch:swipe.right="previousPage" -->
    <div v-if="form_definition && surveyData">
      <!-- We use group element to be sure we don't duplicate code.  -->
      <i-group
          :config="form_definition"
          :formReadOnly="formReadOnly"
          :formDefinition="form_definition"
          v-model="surveyData"
          :status="form_status"
          :showSystemValues="false"
          :surveyDoc="surveyDoc"
          :namePath="[]"
          :editingIndexGiven=0
          >
      </i-group>
      <!-- Pages -->
      <div v-if="$store.state.form.inputGroupPages" class="my-2 row justify-content-center">
        <b-btn @click="previousPage" v-if="showPreviousPage" variant="dark">{{labels.form.previousPage}}</b-btn>
        <div class="text-italic mx-3"
        >{{currentPageIndex+1}} / {{pages.length}}</div>
        <b-btn @click="nextPage" v-if="showNextPage" variant="dark">{{labels.form.nextPage}}</b-btn>
        <!-- summary toggle TODO: put in store -->
        <b-btn @click="showaAllPages()" variant="primary" class="ml-5 float-right">{{labels.form.emptyForm.allPages}}</b-btn>
      </div>      
    </div>

  </div>
</template>

<script>

import base from './_base.js'
// import inputs from '../../components/input'
// console.log('ici3')
// console.log(inputs)
import group from '../../components/inputGroup.vue'
import debounce from 'lodash/debounce'
import utils_gen from '../../utils/utils_gen.js'
import formUtils from '../../utils/form_utils.js'
import summaryGroup from '../../components/summaryGroup.vue'

export default {
  name:'empty_form',
  mixins:[base],
  // components:inputs,
  components:{IGroup:group,summaryGroup:summaryGroup},
  data(){
    return{
      surveyDoc:null,
      surveyData:null, // as we pass the property of doc object at the instantiation, the doc is changed righ away.
      form_definition:null,
      formReadOnly:false,
      my_page_id:0,
    }
  },
  created(){
    console.log('empty created ' + this.survey_id);
    this.fetchSurvey() // because if we go back to the same survey, survey_id is not changed, so no event.
    this.showUpperNavigation()
  },
  computed:{
    form_status(){
      // the json form status.
      if(this.surveyDoc){
        return this.surveyDoc.form_status
      }
    },
    formStatus(){
      //the global status of the form, one value for all. the higher one.
      if(this.surveyDoc && this.surveyDoc.form_status){
        return formUtils.getStatusOfTree(this.surveyDoc.form_status)
      }else{
        return 999
      }
    },
    pages(){
      if(this.surveyDoc && this.form_definition){
        return formUtils.computePages(this.surveyDoc,this.form_definition,{})
      }
    },
    hasPages(){
      return this.pages && this.pages.length>0
    },
    currentPageIndex(){
      return this.$store.state.form.inputGroupCurrentPage
    },
    currentPage(){
      return this.$store.getters['form/currentPage']
    },
    showPreviousPage(){
      return this.hasPages && this.currentPageIndex!=0
    },
    showNextPage(){
      return this.hasPages && this.currentPageIndex!=(this.pages.length-1)
    },
  },
  watch:{
    pages:{
      handler(){
        console.log('empty - pages - watch');
        this.showUpperNavigation()
      },
      immediate:true
    },
  },
  methods:{
    fetchSurvey(){
      this.fetchFormPromise.then(rep=>{
        this.fetchSurvey2()
      })
    },
    fetchSurvey2(){
      console.log('fecth survey empty ' + this.survey_id)
      this.$store.commit('form/inputGroupCurrentPage', 0)
      //create new survey in DB
      let defs=this.$store.state.form.actualFormConfig.form_definitions
      let obj1={
        _id:'survey_empty',
        form_id:this.$store.state.form.form_id,
        form_definition:defs[defs.length-1],
        // form_data:null,
        // form_status:null,
        creator:this.$store.getters['userName'],
        device_id:this.$store.state.localDB.config.device_id,
        creationTime:'',
      }
      this.$store.dispatch('form/fetchDefinition',obj1.form_definition).then(doc=>{
        this.form_definition=doc.form_definition
        obj1=formUtils.buildEmptySurveyData(obj1,doc.form_definition)
        // for each repeat:
        populateEmptyRepeat(obj1,this.form_definition)
        statusEmptyRepeat(obj1,this.form_definition)
        this.surveyDoc=obj1
        this.surveyData=obj1.form_data
      })
    },
    showUpperNavigation(){
      // here because has to change with the activate event.
      if(this.hasPages){
        this.$store.commit('form/inputGroupPages', this.pages)
      }else{
        this.$store.commit('form/inputGroupPages', null)
      }
    },
    nextPage(){
      this.$store.commit('form/inputGroupCurrentPage',this.currentPageIndex+1)
    },
    previousPage(){
      this.$store.commit('form/inputGroupCurrentPage',this.currentPageIndex-1)
    },
    showaAllPages(){
      // remove the pages definition and show all pages on the same screen.
      this.$store.commit('form/inputGroupPages',null)
    }
  },
  beforeRouteLeave (to, from, next) {
    this.$store.commit('form/inputGroupPages',null)
    next()
  },
}

const populateEmptyRepeat=function(obj1,form_definition){
  //used both for creating complete new form and to create empty repeat element.
  populateEmptyRepeat_sub(form_definition.children, obj1.form_data,null,obj1.form_data,form_definition.children,[])
  return obj1
}
const populateEmptyRepeat_sub=function(children,data,status,formData,formDefinition,namePath){
  children.map(x=>{
    if(x.hasOwnProperty('children')){
      //should always have a name if we have children.
      if(x.type=='repeat'){
        // this.log('** build empty repeat ' + x.name)
        let newEl=formUtils.buildEmptySurveyData({},x)
        populateEmptyRepeat(newEl,x)
        data[x.name]=[newEl.form_data]
      }else{
        data[x.name]={}
        populateEmptyRepeat_sub(x.children,data[x.name],null,formData,formDefinition,namePath.splice(0,0,x.name)) // call ourself on sub group
      }
    }else{
      data[x.name]=''
    }
  })
}

const statusEmptyRepeat=function(obj1,form_definition){
  //used both for creating complete new form and to create empty repeat element.
  statusEmptyRepeat_sub(form_definition.children, obj1.form_data,obj1.form_status,obj1.form_data,form_definition.children,[])
  return obj1
}
const statusEmptyRepeat_sub=function(children,data,status,formData,formDefinition,namePath){
  children.map(x=>{
    if(x.hasOwnProperty('children')){
      //should always have a name if we have children.
      if(x.type=='repeat'){
        // this.log('** build empty repeat ' + x.name)
        status[x.name]=data[x.name].map((y,y2)=>{
          const newNamePath=namePath.slice()
          newNamePath.push(x.name)
          newNamePath.push(y2)
          const repeat_status={}
          statusEmptyRepeat_sub(x.children,y,repeat_status,formData,formDefinition,newNamePath) // call ourself on sub group
          return repeat_status
        })
      }else{
        status[x.name]={}
        statusEmptyRepeat_sub(x.children,data[x.name],status[x.name],formData,formDefinition,namePath.splice(0,0,x.name)) // call ourself on sub group
      }
    }else if(x.hasOwnProperty('name')){
      status[x.name]=0
    }
  })
} 

</script>

<style>
.container{
  padding-right:3px;
  padding-left:3px;
}
.list-group-item{
  padding-right:3px;
  padding-left:3px;
}
</style>



export default{
  isHiddenInViewMode(viewMode, that, overrideStatus){
    if(that.$store.state.form.viewMode == viewMode){
      // Check if error status, them show
      if(overrideStatus){
        if(overrideStatus>10){
          return false
        }
      }else{
        if(that.status>10){
          return false
        }
      }
      // check if has a value in the config column. If yes, them visible
      // TODO: Implement a function as relevant.
      if(that.config['view-' + that.$store.state.form.viewValue]){
        return false
      }
      // element is make distinct to see that it's not part of the view.
      return true
    }
    return false
  }
}
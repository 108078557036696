<template>
  <label :for="elId" :class="myclasses">
    <div v-html="label" class="font-weight-bold"></div>
    <!-- {{status}} -->
    <span v-if="status_list[status].input_message" class="text-warning font-italic small">
      &emsp;{{labels.status_message?.[status_list[status]?.input_message]}}
    </span>
    <b-btn pill variant="info" size="sm" :href="popupUrl" v-if="popup_aecom && popupUrl" target="_blank"><span class="glyphicon glyphicon-question-sign"></span></b-btn>
  </label>
</template>

<script>
  import formUtils from '../../../utils/form_utils.js'
  import popup from './mixin_popup.js'
  export default{
    name: 'input-label',
    mixins:[popup],
    props:['label','elId','status','config', 'classes'],
    data(){
      return {
      }
    },
    computed: {
      labels(){return this.$store.state.labels},
      status_list(){return formUtils.status},
      myclasses(){
        let rep = []
        if(this.status){
          rep=[ this.status_list?.[this.status]?.label_class ]
        }
        // if we have to add some classes
        if(this.classes){
          rep = rep.concat(this.classes)
        }
        return rep
      }
    }
  }
</script>

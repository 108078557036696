<template>
  <div :class="{'my-3': (appearance==null)}">

    <input-row 
      :showElement="showElement"
      :label="label" 
      :elId="elId" 
      :status="status" 
      :config="config"
      :me="me"
    >
      <!-- <div class="mr-5"> -->
      <b-input-group>
        <!-- Autocomplete -->
            <!-- validationInputClass - don't work. it pu there, but the search input box is kind of not standard -->
        <v-select  v-if="appearance=='autocomplete'"
          :id="'autocomplete-' + elId"
          @dropdown="t1"
          v-model="myValue2"
          label="label"
          :options="options"
          :taggable="!disallowNotInList"
          :maxShownChoices="50"
          :disabled="readonly"
          :searchOptions="{'searchFirstLettersOnly':searchFirstLettersOnly}"
        >
          <template v-slot:option="option">
            <select-option-text :opt="option" :appearance_aecom="appearance_aecom" />
          </template>
        </v-select>
        
        <!-- label button inline -->
        <!-- <b-form-radio-group v-else-if="appearance=='compact' || appearance=='quickcompact'"
          v-model="myValue"
          buttons
          button-variant="outline-primary"
          class="my-btn-group-block"
          :stacked="(appearance=='quickcompact')?false:true"
          :disabled="readonly"
        > -->
          <!-- text-left, unless it's center. Text reset for black and not blue. The borders are blue -->
          <!-- <b-form-radio class="text-left text-reset" v-for="opt in options" :value="opt.name" :key="elId+opt.name"><select-option-text :opt="opt" :appearance_aecom="appearance_aecom" /></b-form-radio>
        </b-form-radio-group> -->
        <b-form-radio-group v-else-if="appearance=='compact'"
          v-model="myValue"
          stacked
          :disabled="readonly"
          class="d-flex flex-column my-custom-control"
        >
          <!-- text-left, unless it's center. Text reset for black and not blue. The borders are blue -->
          <!-- <div class="my-compact py-2" v-for="opt in options" :key="elId+opt.name"> -->
          <div class="border border-top-secondary" :class="i==0?'rounded-top':i==(options.length-1)?'rounded-bottom border-top-0':'border-top-0'" v-for="(opt, i) in options" :key="elId+opt.name+opt.sequence">
            <div class=" p-2" :class="opt.name==myValue?'my-compact-selected':''">
              <b-form-radio class="text-left text-reset my-custom-control"  :value="opt.name" ><select-option-text :opt="opt" :appearance_aecom="appearance_aecom" /></b-form-radio>
            </div>
          </div>
        </b-form-radio-group>
        <b-form-radio-group v-else-if="appearance=='quickcompact' || appearance=='horizontal-compact'"
          v-model="myValue"
          stacked
          :disabled="readonly"
          class="d-flex flex-row flex-wrap"
        >
          <!-- text-left, unless it's center. Text reset for black and not blue. The borders are blue -->
          <!-- <div class="my-compact py-2" v-for="opt in options" :key="elId+opt.name"> -->
          <div class="border border-top-secondary" :class="i==0?'rounded-top':i==(options.length-1)?'rounded-bottom':''" v-for="(opt, i) in options" :key="elId+opt.name+opt.sequence">
            <div class=" p-2" :class="opt.name==myValue?'my-compact-selected':''">
              <b-form-radio class="text-left text-reset my-custom-control"  :value="opt.name" ><select-option-text :opt="opt" :appearance_aecom="appearance_aecom" /></b-form-radio>
            </div>
          </div>
        </b-form-radio-group>
        <!-- select view -->
          <!-- <b-form-select v-else-if="appearance=='minimal'"
            v-model="myValue"
            :disabled="readonly"
            >
            <b-form-select-option v-for="opt in options" :value="opt.name" :key="elId+opt.name"><select-option-text :opt="opt" :oneline="true" /></b-form-select-option>
          </b-form-select> -->
        <v-select  v-else-if="appearance=='minimal'"
          :id="'select-' + elId"
          v-model="myValue2"
          label="label"
          :options="options"
          :taggable="!disallowNotInList"
          :maxShownChoices="50"
          :disabled="readonly"
          :searchOptions="{'searchFirstLettersOnly':searchFirstLettersOnly}"
        >
          <template v-slot:option="option">
            <select-option-text :opt="option" :appearance_aecom="appearance_aecom"/>
          </template>
        </v-select>


        <!-- default radio stacked -->
        <!-- radio inline => stacked=false -->
        <b-form-radio-group v-else
          v-model="myValue"
          :stacked="defaultStacked"
          :disabled="readonly"
        >
          <b-form-radio v-for="opt in options" :value="opt.name" :key="elId+opt.name+opt.sequence" :class="defaultStacked?'my-custom-control':''"><select-option-text :opt="opt" :appearance_aecom="appearance_aecom" /></b-form-radio>
        </b-form-radio-group>
        <b-input-group-append>
          <b-btn v-if="appearance_aecom.indexOf('device_order')>-1"
              @click="configOrder"
              variant="secondary"
              v-b-popover.hover="labels.form_select.configureOrder"
              class="ml-2 float-right"
              >
            <span class="glyphicon glyphicon-sort"></span>
          </b-btn>
        </b-input-group-append>
      </b-input-group>
      <!-- Display messages -->
      <b-alert show variant="danger" v-if="myValue && optionNotInList">
        <div>{{ labels.form.edit.valueNotInList }}</div>
        <div class="h5">{{value}} <b-btn @click="myValue=null" class="ml-4" size="sm" v-if="!readonly"><i class="mdi mdi-delete"></i></b-btn></div>
      </b-alert>
    </input-row>
  </div>
</template>

<script>
import base from './_baseInput.js'
import vSelect from './subInput/vue-select/Select.vue'
import select_options from './subInput/select_options.js'
import selectOptionText from './subInput/selectOptionText.vue'

export default {
  name:'i-selectone',
  mixins:[base,select_options],
  components:{
    vSelect:vSelect,
    selectOptionText,
  },
  data(){
    return {
      myValue2:null, // hold a js object with label and other properties. necessary for the autocomplete
    }
  },
  created(){
    // console.log('select one created')
  },
  computed:{
    disallowNotInList(){
      //aecom prop - to avoid autocomplete to allow other values
      if(this.appearance_aecom.indexOf('disallowNotInList')>-1){
        return true
      }
      return false
    },
    searchFirstLettersOnly(){
      if(this.appearance_aecom.indexOf('searchFirstLettersOnly')){
        return true
      }
      return false
    },
    defaultStacked(){
      return (this.appearance=='quick' || this.appearance=='horizontal')?false:true
    },
  },
  watch:{
    myValue2(){
      //object and have to get the name.
      if(this.myValue2){
        this.myValue=this.myValue2.name
      }else{
        this.myValue=null
      }
    },
    myValue(){
      this.setValue2()
    },
  },

  methods:{
    setValue2(){
      // called from the mixin select_options.js
      if(this.myValue && this.options){
        let filter1=this.options.filter(x=>x.name==this.myValue)
        if(filter1.length>0){
          this.myValue2=filter1[0]
        }else{
          this.myValue2={name:this.myValue} //for autocomplete when not in list.
        }
      }else{
        this.myValue2=null
      }
    },
    t1(){
      // debugger
      // this.$scrollTo('#'+'autocomplete-' + this.elId,500,{force:false})
      // console.log('scrool - auto complete');
    }
  }
}
</script>

<style scoped>
  .my-btn-group-block{
    display: block !important;
    /* It's by deflaut inline-flex, but too large  */
  }
  .my-compact{
    border-bottom: solid;
    border-bottom-color: blueviolet;
  }
  .my-compact-selected{
    background-color: rgb(187, 213, 222);
  }
  .input-group{
    flex-direction: column !important;
    /* border: solid; */
    /* border-color: blue; */
  }
  .my-custom-control{
    width: 100%;
    display: flex;
    flex-direction: column;
  }
</style>

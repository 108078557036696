//import inputs from '../input'
import expr from '../../utils/expressionEvaluation.js'
import utilGen from '../../utils/utils_gen.js'
import debounce from 'lodash/debounce'
import formUtils from '../../utils/form_utils.js'
import myLabel from './subInput/label.vue'
import inputRow from './_inputRow.vue'
import utils_gen from '../../utils/utils_gen'
import { isNumber } from 'lodash'


export default{
  //components:inputs,
  props:[
      'value',
      'status',
      'config',
      'formDefinition', // root definition - to get the choices that are global
      'surveyDoc',
      'formReadOnly',
      'showSystemValues',
      'namePath', // useful for pages, holds the parent names
      'readOnlyInput', // if the value is read only - no edition.
  ],
  components:{MyLabel:myLabel, inputRow},
  data: function () {
      return {
          myValue: this.formatInputValue(),
          elId: this.$_cuid(),
      }
  },
  computed: {
    me(){ return this},
    labels(){return this.$store.state.labels},
    formConfig(){return this.$store.state.form.actualFormConfig},
    status_list(){return formUtils.status},
    readonly(){
      if(this.readOnlyInput == true){
        return true
      }
      if(this.config.hasOwnProperty('bind') && this.config.bind.hasOwnProperty('readonly')){
        if(['yes','true','True','Yes','TRUE'].indexOf(this.config.bind.readonly)>-1)
          return true
      }else if (this.calculation){
        return true // if it's a calculation, we don't allow user to change the value.
      }
      if(this.config.roles_can_edit){
        // only certain roles can edit this element.
        if(this.config.roles_can_edit.split(',').filter(value=>this.$store.getters['roles'].indexOf(value) != -1).length == 0){
          return true
        }
      }
      return false
    },
    isRoot(){
      if(this.formDefinition==this.config){
        return true
      }
      return false
    },
    hasChildren(){
      return this.config.hasOwnProperty('children')
    },
    relevant(){
      if(this.status==4){
        return false
      }
      return true
    },
    groupStatus(){
      if (this.status){
        return formUtils.getStatusOfTree(this.status)
      }
    },
    label(){
      let prefix=''
      if(this.config.label_no){
        prefix='<b>' + this.config.label_no + '</b>' + ' - '
      }
      if(this.config.label){
        if(typeof this.config.label === 'object'){
          // TODO: optimize this to support key cache, better for performances.... to analyse.
          // select language
          let label2 = null
          const curLang = this.$store.state.language
          Object.keys(this.config.label).map(x=>{
            if (x.indexOf('('+curLang+')')>-1){
              label2 = this.config.label[x]  
            }
          })
          if(!label2){
            label2 = this.config.label.default
          }
          return prefix + label2
        }
        return prefix + this.config.label
      }else{
        // We connot return empty as it completely crash the app.
        return prefix + this.config.name
      }
    },
    formElements(){
      if (!this.config.hasOwnProperty('children')){
        return null
      }
      // useful only for inputgroup and repeat.
      //filter the elements we show.
      if(this.showSystemValues===true){
        return this.config.children
      }
      //filter for only the ones that have a label.
      let elements=this.config.children.filter(x=>{
        if(x.label){
          return true
        }
        return false
      })
      //todo: put this kind of remove in a config or app or the xlsform.
      elements=elements.filter(x=>x.name!='copyrights') // remove the copyrights from the form.
      return elements
    },
    constraint_message(){
      if(this.config.hasOwnProperty('bind')){
        if(this.config.bind.hasOwnProperty('jr:constraintMsg')){
          return this.config.bind['jr:constraintMsg']
        }
      }
      return null
    },
    currentNamePath(){
      if(this.isRootRepeat){
        // if it's a repeat, we don't have to add the name of the repeat.
        return this.namePath
      }
      return this.namePath.concat(this.config.name)
    },
    appearance(){
      if(this.config.hasOwnProperty('control')){
        if(this.config.control.hasOwnProperty('appearance')){
          return this.config.control.appearance
        }
      }
      return null
    },
    appearance_aecom(){
      //return an array of the options - separated by ";" in xlsx file
      if(this.config.hasOwnProperty('appearance_aecom')){
        return this.config.appearance_aecom.split(';')
      }
      return []
    },
    parameters(){
      //return an array of the options - separated by ";" in xlsx file
      const rep= {}
      if(this.config.hasOwnProperty('parameters')){
        this.config.parameters.split(' ').map(x=>{
          const rep2 = x.split('=')
          let val = rep2[1]
          if (val.toLowerCase() == 'true'){
            val = true
          } else if (val.toLowerCase() == 'false'){
            val = false
          }
          rep[rep2[0]] = val

        })
      }
      return rep
    },
    calculation(){
      // This is only usefuyl to know if a component is calculated if it's not a calculate element (select one for example.)
      // so we can determine if readonly.
      if(this.config.bind && this.relevant){ // return the calculation column of the excel file.
        return this.config.bind.calculate
      }
    },
    // for pages...
    pages(){
      return this.$store.state.form.inputGroupPages
    },
    hasPages(){
      return this.pages && this.pages.length>0
    },
    currentPage(){
      return this.$store.getters['form/currentPage']
    },
    pageIsVisible(){
      //indicate if the component is visible or not.
      if(this.hasPages){
        if (this.isRoot){
          return true
        }
        //current page
        let currentPagePaths=[]
        if(this.currentPage.type=='frontPage'){
          currentPagePaths=this.currentPage.controls
        }else{
          currentPagePaths=[this.currentPage.pathComplete]
        }
        
        //my path - control
        let controlPath=this.currentNamePath
        // console.log('pageIsVisible' + this.config.name);
        // console.log(controlPath);
        // console.log(cur1);
        for(let i=0;i<currentPagePaths.length;i++){ // check all paths... normal case, only one. front page= possible many
          const cur1=currentPagePaths[i]
          if (cur1.indexOf(this.config.name)>-1){
            return true
          }
          if(controlPath.length>=cur1.length){
            if(JSON.stringify(cur1) === JSON.stringify(controlPath.slice(0,cur1.length))){
              return true
            }
          }
        }
        return false
      }
      return true
    },
    pageElementPageIndex(){
      if(this.pages){
        //return the page index = page number -1
        const pathComlete=this.namePath.concat(this.config.name)
        for(let i=0;i<this.pages.length;i++){
          if(this.pages[i].type!='frontPage'){
            let cur1=this.pages[i].pathComplete
            if(pathComlete.length>=cur1.length){
              if (JSON.stringify(cur1) === JSON.stringify(pathComlete.slice(0,cur1.length))){
                return i
              }
            }
          }
        }
      }
      return 0 // we haven't found it...
    },
    pageIsElementRootPage(){
      //if it's the root element of the page
      if(this.hasPages){
        let pathComlete=this.namePath.concat(this.config.name)
        const cur1=this.currentPage.pathComplete
        return JSON.stringify(cur1) === JSON.stringify(pathComlete) 
      }
      return false
    },
    showElement() {
      // concatenate the two and could handle more conditions.
      return this.pageIsVisible && this.relevant
      // hidden is take into account in _inputrow && this.appearance!='hidden'
      // TODO: support for empty label we don't display... see xmlform. But don't display anything at the moments...
      //  && this.config.label && this.config.label!=''
    },
    isDataArray(){
      if(['select all that apply'].indexOf(this.config.type)!=-1){
        return true
      }
      return false
    },
    printVisible(){
      if(this.isRoot){
        return true
      }
      if (this.config[this.printName] && this.config[this.printName].toLowerCase() == 'true'){
        return true
      }else if(this.config[this.printName]){
        //  it's a formula, so check it
        return expr.checkRelevant(this.surveyDoc.form_data,this.config[this.printName],{},this.namePath)
      }
      return false
    },
    printVisibleRepeat(){
      if (this.isRoot || this.config[this.printName]){ // if print, the reapeat is visible - the printVisible will check each repeat if formula
        return true
      }
      return false
    },
    printName(){
      return this.$store.state.form.currentPrintName
    },
    printLabel(){
      if (this.config[this.printName + '_label'] && this.config[this.printName + '_label'] == 'none'){
        return null
      }
      if (this.config[this.printName + '_label']){
        return this.config[this.printName + '_label']
      }
      return this.label
    },
    printStyle(){
      return this.formDefinition.print_style || 'normal'
    },
    info(){
      // Additional info to show, take into account the form language.
      return this.config?.info?.[this.$store.state.form.formLanguage]
    },
    flagNamePath(){
      const namePath = this.currentNamePath
      return namePath.toSpliced(namePath.length-1, 1, namePath[namePath.length-1]+'__flag')
    },
    flag(){
      if(this.hasChildren){
        // we use the stora that keep getters that are cached to search the flatten surveyDoc
        // TODO
        return []
      }else{
        return utils_gen.getPropTree2(this.surveyDoc.form_data,this.flagNamePath)
      }
    },
    hasFlag(){
      // if(flagVal?.status == 'flag-raised'){
      if(Array.isArray(this.flag)){
        return this.flag.length>0
      }else if(this.flag ){
        return true
      }
      return false
    },
  },
  watch:{
    //these watch are called first before any component watch - same name
    myValue(val){
      // console.log('watch myValue' + ' ' + this.config.label)
      if(this.myValue && this.appearance_aecom.indexOf('UpperCase')>-1){
        this.myValue=this.myValue.toUpperCase()
      }
      if(this.myValue && this.isDataArray){
        if(Array.isArray(this.myValue)==false){
          this.myValue=[this.myValue]
          return
        }
      }
      if(this.surveyDoc){
        this.emit()
      }
    },
    value(){
      // console.log("Watch value");
      // console.log(this.value);
      
      // console.log('value' + ' ' + this.config.label)
      //the parent has change the value, change mine, useful for calculated value
      this.myValue=this.formatInputValue()
    },
  },
  methods:{
    emit(){
      if(this.myValue!=this.value){
        this.$emit('input', this.formatEmitValue())
      }
    },
    formatEmitValue(){
      // Mostly for components that want to format value before emit
      return this.myValue
    },
    formatInputValue(){
      // Mostly for components that want to format value before emit
      return this.value
    },
    copyValToClipboard(){
      return navigator.clipboard.writeText(JSON.stringify(this.myValue)).then(()=> {
        return true
      }).catch(err => {
        this.$store.dispatch('app_message_error',err)
        return false
      });
    },
    getValFromClipboard(repeatAppend=false){
      return navigator.clipboard.readText().then(x=> {
        if(repeatAppend){
          // if we want to append to the repeat
          if(Array.isArray(this.myValue)){
            this.myValue = this.myValue.concat(JSON.parse(x))
            // EXIT
            return true
          }
        }
        this.myValue=JSON.parse(x)
        return true
      }).catch(err => {
        this.$store.dispatch('app_message_error',err)
        return false
      });
    },
  }
}

<template>
  <div class="container" v-if="formDefinition">
    <div class="h2">Download photos</div>
    <div show class="alert alert-secondary">
      <div class="h4">Instructions</div>
      <ul>
        <li>
          Select the file field you want to download
        </li>
        <li>
          In the config section select the fields and static characters that are gone a name the file/folders. The File name pattern represent the final name. You can use - or any charater and / for folder structure. Survey field name are like: ${field_name}
        </li>
        <li>
          The config file will be valid for a limited time (authentification expiration). You can download it again if you need to run it again.
        </li>
        <li>
          Place the executable and the config file in the folder where you want photos/files to be downloaded
        </li>
        <li>
          Execute (double click) the executable. Any already downloaded photos is not downloaded again.
        </li>
        <li>
          Note: To be safe, you should always include the photo field(left column) as it's ensure unique photo name. 
        </li>
        <li>
          Note: be careful with photos inside repeat, ensure you include the repeat itself (sequential number) or the photo name or other value to ensure you have all your photos, because if two resulting name are the same, only the first photo will be downloaded. Easier to do when looking at the form xlsx. And don't use fields that are inside other repeat than the ones of your photo.
        </li>
        <li>
          Warning: You must not be connected to the AECOM VPN (global protect). Does not work if you are connected to the AECOM VPN (global protect). At least at 2023-10-23.
        </li>
      </ul>
    </div>
    
    <div class="card  my-4">
      <div class="card-content p-3">
        <div class="h4">
          File naming configuration
        </div>
        <div>
          Existing configuration (select to load)
          <b-form-select v-model="configId" :options="existingConfigurations" value-field="_id" text-field="_id"></b-form-select>
        </div>
        <div class="row my-3">
          <div class="col-3">
            <!-- List photos fields -->
            <b-list-group>
              <b-list-group-item v-for="x in listPhotosFields" :key="x.name" :variant="activeField==x?'dark':null" @click="activeField=x">{{getLabels(x.namePath,x)}}</b-list-group-item>
            </b-list-group>
          </div>
          <div class="col-9">
            <div class="row" v-if="activeField">
              <span class="h5">File name pattern:</span>
              <b-input v-model="configDownload[activeField.name].file_name"></b-input>
            </div>
            <div class="row mt-3">
              <div class="col-5">
                <b-list-group>
                  <b-list-group-item v-for="x in activeFieldParentFields" :key="x.name" @click="addField(x.name)">{{x.name}}</b-list-group-item>
                </b-list-group>
              </div>
              <div class="col-5">
                <b-list-group class="my-list-group">
                  <b-list-group-item v-for="x in activeFieldParents" :key="x.name" @click="addField(x.name)">{{x.name}}</b-list-group-item>
                </b-list-group>
              </div>
            </div>
          </div>
        </div>
        <div>
          <b-btn variant="primary" @click="saveConfig">Save config</b-btn>
          <b-btn variant="primary" class="ml-3" v-if="configId" @click="downloadConfig">Download config file</b-btn>
          <span class="float-right">
            <b-btn variant="danger" class="ml-3" v-if="configId" @click="deleteConfig">Delete config</b-btn>
          </span>
        </div>
        <div v-if="err" class="my-2">
          <b-alert show variant="danger">{{err}}</b-alert>
        </div>
        <div v-if="mess" class="my-2">
          <b-alert show variant="success">{{mess}}</b-alert>
        </div>
      </div>
    </div>
    <b-btn variant="primary" class="mt-4" href="/static/DownloadPhotos.exe">Download executable</b-btn>
    <!-- <b-btn variant="secondary" size="sm" class="mt-4" href="/static/DownloadPhotos.zip">Executable (zipped for antivirus) password=aecom1234</b-btn> -->
  </div>
</template>

<script>
  import base from './_base.js'
  import formUtils from '../../utils/form_utils.js'
  import Vue from 'vue'
  import cuid from 'cuid'
  import moment from 'moment'
  import downloadjs from 'downloadjs'  

  export default {
    mixins:[base],
    data() {
      return {
        activeField:null,
        configId: null,
        configRev: null,
        configDownload:{},
        err: null,
        mess: null,
      }
    },
    computed:{
      listPhotosFields(){
        if(this.formDefinition){
          const findAtt = (children,rep,namePath)=>{
            children.map(x=>{
              const newNamePath=namePath.concat(x.name)
              if(x.hasOwnProperty('children')){
                findAtt(x.children,rep,newNamePath)
              }else if(x.hasOwnProperty('name') && x.type == 'photo'){
                rep.push(Object.assign({namePath:newNamePath, downloadType:'photo'},x))
                // also offer the option to download the annotation
                if (x.appearance_aecom && x.appearance_aecom.indexOf('image_annotation')!=-1){
                  rep.push(Object.assign({namePath:newNamePath,downloadType:'annotation'},x))
                }
                if (x.control && x.control.appearance=='signature'){ // TODO: plan for singature, which is save directly in the survey form...
                  rep.pop()
                  // rep.push(Object.assign({namePath:newNamePath,downloadType:'signature'},x))
                }
              }
            })
          }
          let rep=[]
          findAtt(this.formDefinition.children,rep,[])
          return rep
        }
      },
      activeFieldParentFields(){
        if(this.activeField && this.activeField.namePath.length > 1){
          const parent=formUtils.getFieldFromDefinition(this.formDefinition,this.activeField.namePath[this.activeField.namePath.length-2])
          return parent.children
        }else if (this.activeField){
          return this.formDefinition.children
        }
        return []
      },
      activeFieldParents(){
        if(this.activeField){
          const findAtt = (children,rep,namePath)=>{
            children.map(x=>{
              if(x.hasOwnProperty('children') && (x.type != 'repeat')){ //} || namePath.indexOf(x.name)!=-1)){
                findAtt(x.children,rep,namePath)
              }else if(x.hasOwnProperty('name')){
                rep.push(x)
              }
            })
          }
          let rep=[]
          findAtt(this.formDefinition.children,rep,this.activeField.namePath)
          return rep
        }
      },
      currentExistingConfig(){
        if(this.existingConfigurations && this.configId){
          return this.existingConfigurations.filter(x=>x._id==this.configId)[0]
        }
      },
    },
    asyncComputed: {
      existingConfigurations(){
        if(this.$store.state.form.db){
          return this.$store.state.form.db.allDocs({startkey: 'downloadConfig_', endkey:'downloadConfig_\ufff0', include_docs: true }).then(rep=>{
            return rep.rows.map(x=>x.doc)
          })
        }
      }
    },
    watch:{
      listPhotosFields:{
        immediate:true,
        handler(){
          if(this.formDefinition){
            this.configDownload={}
            this.listPhotosFields.map(x=>{
              const fi = formUtils.getFieldFromDefinition(this.formDefinition, x.name)
              Vue.set(this.configDownload,x.name,{field: fi})
              Vue.set(this.configDownload[x.name],'downloadType',x.downloadType)
              Vue.set(this.configDownload[x.name],'file_name','${' + x.name + '}')
            })
          }
        }
      },
      currentExistingConfig(){
        if(this.currentExistingConfig){
          // update my config from the selected config
          this.configRev=this.currentExistingConfig._rev
          this.listPhotosFields.map(x=>{
            if(this.currentExistingConfig.configDownload[x.name]){
              this.configDownload[x.name].file_name = this.currentExistingConfig.configDownload[x.name].file_name
            }
          })
        }
      }
    },
    methods: {
      getLabels(namesPath, x) {
        const label1=[]
        namesPath.map(x=>{
          const fi = formUtils.getFieldFromDefinition(this.formDefinition, x)
          if(fi){
            label1.push(fi.label)
          }else{
            label1.push(x) // annotation case
          }
        })
        if(x.downloadType=='annotation'){
          return label1.join(' / ') + ' / Annotation'
        }
        return label1.join(' / ')
      },
      addField(name){
        this.configDownload[this.activeField.name].file_name += '${' + name + '}'
      },
      saveConfig(){
        this.err=null
        this.mess=null
        let obj1 = {
          creator: this.$store.getters['userName'],
          device_id: this.$store.state.localDB.config.device_id,
          creationTime: this.$store.getters['utcCurrentTime'](),
          configDownload:this.configDownload
        }
        if(this.configId){
          // already exists so
          obj1._id=this.configId
          obj1._rev=this.configRev
        }else{
          obj1._id = "downloadConfig_" + cuid()
        }
        return this.$store.state.form.db.put(obj1).then(rep => {
          this.configId=rep.id
          this.configRev=rep.rev
          // create view with all the photo fields
          return this.createViewPhotos() 
        }).then(()=>{
          this.mess= 'Saved'
        }).catch(err => {
          this.err= err
          this.$store.dispatch('app_message_error',err)
        })
      },
      deleteConfig(){
        return this.$store.state.form.db.get("_design/" + this.configId).then(rep => {
          return this.$store.state.form.db.bulkDocs([
            {_id: this.configId, _rev: this.configRev, _deleted:true},
            {_id: rep._id, _rev: rep._rev, _deleted:true}
          ])
        }).then(() => {
          this.mess = "Deleted"
          this.configId= null
        }).catch(err => {
          this.err = true
          this.$store.dispatch('app_message_error',err)
        })
        
      },
      downloadConfig(){
        // TODO: If admin, get a token with less privileges
        const obj = {downloads:[this.configId], token: localStorage.getItem('user-token-couchdb'), couchdbUrls: this.$store.state.settings.couchdbUrls, form_id: this.$store.state.form.form_id}
        downloadjs( JSON.stringify(obj), this.configId + '.json', 'text/plain' )
      },
      createViewPhotos(){
        let views = {}
        this.listPhotosFields.map(x=>{
          // get the names we meed
          let varFielNameString=''
          const file_name = this.configDownload[x.name].file_name.trim()
          if (file_name && file_name!=''){
            const paramsFileName = file_name.match(/\${(.*?)}/g)
            if(paramsFileName){
              paramsFileName.map((x, index) => {
                const varName = x.match(/[^${}]+/g)[0] // the match function return array, so get the first one.
                const varFi = formUtils.getFieldFromDefinition(this.formDefinition, varName)
                let varPath='doc.form_data'
                let nbRepeat=1
                varFi.namePath.map((y,i)=>{
                  if (varFi.type == 'repeat' && (i+1) == varFi.namePath.length){
                    // repeat is the last element, so the var_Path is gone a be sequence number only
                    varPath = `i${nbRepeat} + 1`
                  }else if (y == 'repeat'){
                    varPath += `[i${nbRepeat}]`
                    nbRepeat += 1
                  }else{
                    varPath += '.' + y
                  }
                })
                varFielNameString +=  `, "${varName}": ${varPath}` //',{"var_name":"' + varName + '", "var_path": ' + varPath + '}'
              })
              varFielNameString=varFielNameString.slice(1)
            }

            // get the field, as the one we have don't have the full name path with repeat
            const fi = formUtils.getFieldFromDefinition(this.formDefinition, x.name)
            // we have one or more repeat
            const liBefore=[]
            const liAfter=[]
            let path = 'doc.form_data'
            let nbRepeat=1
            for (let i = 0; i < fi.namePath.length; i++) {
              const cur = fi.namePath[i];
              if (cur == 'repeat'){
                liBefore.push(`try { for(var i${nbRepeat} =0;i${nbRepeat} < ${path}.length;i${nbRepeat}++){ `)
                path += `[i${nbRepeat}]`
                liAfter.push(`}} catch (e) {}`)
                nbRepeat += 1
              }else{
                path += '.' + cur
              }
            }
            views[x.name]={
              map: `function(doc) {
                if(doc._id.indexOf('survey_')==0){
                  try{
                    ${liBefore.join(' ')}
                    try {
                      if(${path}){
                        emit(${path}, {variables: {${varFielNameString}}})
                      }
                    }catch (e) {}
                    ${liAfter.join(' ')}
                  } catch (e) {}
                }
              }`
            }
          }
        })
        // now save it to the DB
        return this.$store.state.form.db.get("_design/" + this.configId)
          .then(rep => {
            //replace views
            rep.views = views;
            return this.$store.state.form.db.put(rep);
          })
          .catch(err => {
            //create
            return this.$store.state.form.db.put({
              _id: "_design/" + this.configId,
              views: views
            });
          })
      }
    },
  }

</script>

<style scoped>
  .my-list-group{
    max-height: 30rem;
    overflow:scroll;
    -webkit-overflow-scrolling: touch;
}
</style>
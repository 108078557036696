<template>
  <div class="container-fluid">
    <b-navbar fixed="top" type="dark" variant="primary">
      <b-navbar-nav>
        <b-button variant="secondary" @click="$router.go(-1)"><span class="glyphicon glyphicon-arrow-left"></span></b-button>
     </b-navbar-nav>
    </b-navbar>
    <div class="row justify-content-md-center">
      <div class="col-lg-6">
        <layers></layers>
      </div>
    </div>
    <div class="row justify-content-md-center mt-4">
      <div class="col-lg-6">
        <base-layers></base-layers>
      </div>
    </div>
    <div class="row justify-content-md-center mt-4">
      <div class="col-lg-6">
        <b-button variant="secondary" @click="$router.go(-1)"><span class="glyphicon glyphicon-arrow-left"></span></b-button>
      </div>
    </div>
    
  </div>
</template>

<script>
import baseLayers from './tableOfContent/basemaps.vue'
import layers from './tableOfContent/layers.vue'

export default {
  components:{baseLayers,layers},
  data(){
    return {

    }
  },
}
</script>

<style>

</style>

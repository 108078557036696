<template>
    <div v-if="relevant && printVisible && printLabel" 
      :class="itemClasses"
      :style="{'padding-left': padding + 'px'}"
    >
      <!-- {{label}} <small>{{showValue}}</small> -->
      <div v-if="printLabel.indexOf('html@')==0">
        <span v-html="printLabel.replace('html@','')"></span>
      </div>
      <div v-else-if="config.type=='note'">
        {{prefixDash}}
        <span v-html="printLabel"></span>
      </div>
      <div v-else>
        <!-- normal case -->
        {{prefixDash}}
        <small v-html="printLabel"></small>: <b v-html="showValue"></b> 
      </div>
      
    </div>
</template>

<script>
import base from '../input/_baseInput.js'
import select_options from '../input/subInput/select_options.js'

export default {
  name:'print-item',
  mixins:[base,select_options],
  props:['pagesObj'],
  computed:{
    padding(){
      if(this.printStyle == 'raw'){
        return 0
      }
      return (this.namePath.length * 40)
    },
    prefixDash(){
      if(this.printStyle == 'raw'){
        return '-'.repeat(this.namePath.length+1)
      }
    },
    itemClasses(){
      if(this.printStyle != 'raw'){
        return ['list-group-item']
      }
      return ''
    },
    showValue(){
      if(!this.value){
        return null
      }
      if (['select one', 'select all that apply'].indexOf(this.config.type) != -1 && this.options0){
        if(Array.isArray(this.value)){
          let rep = []
          this.value.map(x=>{
            rep.push(this.optionsByName[x].label)
          })
          return rep.join(', ')
        }else{
          return this.optionsByName[this.value].label
        }
      }else if(Array.isArray(this.value)){
        return this.value.join(', ')
      }else{
        return this.value
      }
    },
  },
  methods:{
    
  },
}
</script>

<style>

</style>

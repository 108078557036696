<script>
  import VueAutonumeric from 'vue-autonumeric/src/components/VueAutonumeric.vue';
  
  export default {
    name:'BFormInputMaskAutonumeric',
    mixins:[VueAutonumeric],
    methods: {
      updateVModel(event) {
        if (this.anElement !== null) {
          // We override to add this condition, as we don'T want to emit if our parent value has change to null... unless it send 0
          if(this.anElement.rawValue!=null && this.anElement.rawValue!=''){
            this.$emit('input', this.anElement.getNumber(), event);
          }
        }
      },
    },
  }
</script>

<style lang="scss" scoped>

</style>
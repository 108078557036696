<template>
  <input-row 
    :showElement="showElement"
    :label="label" 
    :elId="elId" 
    :status="status" 
    :config="config"
    :me="me"
  >
    <datetime v-model="myValue" auto 
      type="datetime "
      :min-datetime="minDate" 
      :max-datetime="maxDate"
      :input-id="elId"
      :disabled="readonly"
      ></datetime>

  </input-row>
</template>

<script>
import base from './_baseInput.js'
import { Datetime } from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css';
import { DateTime as LuxonDateTime } from 'luxon'


export default {
  name:'IDateTime',
  mixins:[base],
  components:{
    datetime: Datetime
  },
  computed:{
  // TODO: add config for min and max 
  minDate(){
    return LuxonDateTime.local().minus({ year: 130 }).toISO()
  },
  maxDate(){
    //today
    return LuxonDateTime.local().toISO()
  }
}
}
</script>

<style>

</style>

<!-- list the current project forms add allow to add new -->

<template>
  <div>
    <div class="h2 text-center">
      {{labels.form.newSurvey}}
    </div>
    <div class="row justify-content-md-center mt-3">
      <div class="col-lg-3">
        <b-nav vertical class="w-100 text-center" pills>
          <b-nav-item v-for="form in projectForms" :key="form.id" active class="my-2" :to="'/forms/'+form.id+'/edit'" append>
            {{form.label}}
          </b-nav-item>
        </b-nav>      
        <b-button class="mt-4" variant="secondary" @click="$router.go(-1)"><span class="glyphicon glyphicon-arrow-left"></span></b-button>
        <div class="pt-5"><quota></quota></div>
      </div>
    </div>
  </div>
</template>

<script>
  import quota from '../components/utils/quota.vue'

  export default {
    props: ['project_id'],
    components:{quota},
    computed: {
      labels(){return this.$store.state.labels},
      projectForms() {
        return this.$store.getters['project/projectForms']
      }
    },
    watch:{
      project_id:{
        handler:'fetchProject',
        immediate:true
      },
    },
    methods: {
      fetchProject() {
        return this.$store.dispatch('project/setProjectId',this.project_id)
      }
    },
  }
</script>

<style scoped>

</style>
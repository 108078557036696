<template>
  <div v-if="formConfig" ref="form_menu">
    <div class="h2 text-center">
      {{formConfig.label}}
    </div>
    <div class="h4 text-center text-secondary">
      {{labels.form.versionNumber}}: {{$store.getters['form/version']}}
        <b-btn @click="addFormLocal()" variant="success" class="ml-3" size="sm" v-b-popover.hover="labels.projectForm.makeOffLine">
          <span class="glyphicon glyphicon-download-alt float-right"></span>
        </b-btn>
    </div>
    <div class="row justify-content-md-center">
      <div class="col-lg-3">
        <b-nav vertical class="w-100 text-center" pills>
          <b-nav-item v-for="opt in optionsComputed" :key="opt.label" active class="my-2" :to="opt.to" append>
            {{labels.form[opt.label]}}
          </b-nav-item>
        </b-nav>
        <!-- Is Local or online management -->
        <div class="mt-4">
          <b-alert :variant="isLocal===false?'success':'warning'" show>
            <span v-if="!isLocal" class="mdi mdi-signal"></span>
            <span v-if="isLocal" class="mdi mdi-signal-off"></span>
            {{isLocal?labels.form.isLocal:labels.form.isLocalFalse}}
          </b-alert>
        </div>
        <div v-if="isLocal && online && isLogged" class="mt-2">
          <b-btn variant="secondary" @click="makeOnline">
            <span class="glyphicon glyphicon-cloud-upload"></span>
            {{labels.form.viewOnlineVerison}}
          </b-btn>
        </div>
        <exports-json></exports-json>
        <div v-if="hasLocal && isLocal===false" class="mt-2">
          <b-btn variant="secondary" @click="makeLocal">
            <span class="glyphicon glyphicon-download-alt"></span>
            {{labels.form.viewOfflineVerison}}
          </b-btn>
        </div>
        <div class="pt-5"><quota></quota></div>
      </div>
    </div>
  </div>
</template>

<script>
import PouchDB from 'pouchdb'
import base from './_base.js'
import quota from '../../components/utils/quota.vue'
import exportsJson from '@/components/sync/exportsJson.vue'


export default {
  name:'myform',
  mixins:[base],
  components:{
    quota:quota,
    exportsJson,
  },
  data(){
    return{
      options:[
        {label:"newSurvey",to:"edit"},
        {label:"editSurvey",to:"list"},
        {label:"map",to:"map", ifGetter:"form/isMapActive"},
        {label:"syncMenu",to:"sync"},
        {label:"summaryMenu",to:"summary", thisHasArray:"summaries"},
        {label:"viewsMenu",to:"views", thisHasArray:"views"},
        {label:"configDownload",to:"downloadPhotos", ifGetter:"isAdmin"},
        {label:"emptyFormLabel",to:"emptyForm", ifGetter:"isAdmin"},
        {label:"configMenu",to:"config", ifGetter:"isAdmin"},
      ]
    }
  },
  computed:{
    optionsComputed(){
      // to filter based on different criteria
      let opts=this.options.filter(x=>{
        if(x.ifGetter){
          return this.$store.getters[x.ifGetter]
        }
        if(x.thisHasArray){
          if(this[x.thisHasArray] && this[x.thisHasArray].length > 0){
            return true
          }else{
            return false
          }
        }
        return true
      })
      return opts
    },
    views(){
      if(this.formDefinition && this.formDefinition.views_programmed){
        return this.formDefinition.views_programmed.split(',')
      }
      return null
    }
  },
  asyncComputed: {
    summaries(){
      // TODO: this should be cached in the store... as also used in summary.vue
      if(this.ready){
        return this.$store.dispatch('form/getActualFormSummaries')
      }
      return []
    },
  },
  methods:{
    makeLocal(){
      this.$store.dispatch('localDB/switchDatabasesAccesMode',{accessMode: 2}).catch(err=>{
        this.$store.dispatch('app_message_error',err)
      })
    },
    makeOnline(){
      this.$store.dispatch('localDB/switchDatabasesAccesMode',{accessMode: 1}).catch(err=>{
        this.$store.dispatch('app_message_error',err)
      })
    },
    addFormLocal(){
      let loader = this.$loading.show({
        // Optional parameters
        container: this.$refs.form_menu,
      });
      const formId=this.$store.state.form.form_id
      let form2= this.$store.getters['localDB/form_object_for_local_add'](formId)
      this.$store.dispatch('localDB/addFormLocal',form2).then(rep=>{
        this.$store.commit('form/form_id',null)
        return this.fetchForm()
      }).then(()=>{
        loader.hide()
      }).catch(err=>{
        this.$store.dispatch('app_message_error',err)
      })
    },
    
  }
}


</script>

<style >

</style>

import map_utils from '../map_utils.js'

export default{
  props:['mapActivatedId'],
  computed:{
    labels(){return this.$store.state.labels},
    map(){
      return this.$store.state.map.map
    },
    map_config(){
      return this.$store.state.map.map_config
    }
  },
  watch:{
    map:{
      immediate:true,
      handler(){
        this.init()
      }
    },
  },
  methods:{
    init(){
      // to be override if necessary
      //https://vuejs.org/v2/guide/mixins.html
      //Options that expect object values, for example methods, components and directives, will be merged into the same object. The component’s options will take priority when there are conflicting keys in these objects:
    }
  }
}

<template>
  <div>
    <b-container fluid>
      <div class="row justify-content-md-center ">
        <div class="col-lg-6 mt-3">
          <div class="h4 mt-2">{{ labels.titleConfigLocal }}</div>
          <b-row class="my-1">
            <b-col sm="3"><label>{{ labels.mapIdentityPixel }}:</label></b-col>
            <b-col sm="9"><b-form-input :value="$store.state.localConfig.jsonConfig.mapIdentityPixel" @input="$store.dispatch('localConfig/setConfig',{key:'mapIdentityPixel',val:$event})"></b-form-input></b-col>
          </b-row>
          <b-row class="my-1">
            <b-col sm="3"><label>{{ labels.photoMaxPixelSize }}:</label></b-col>
            <b-col sm="9"><b-form-input :value="$store.state.localConfig.jsonConfig.photoMaxPixelSize" @input="$store.dispatch('localConfig/setConfig',{key:'photoMaxPixelSize',val:$event})"></b-form-input></b-col>
          </b-row>
          <b-row class="my-1" v-if="$store.getters.isAdmin">
            <b-col sm="3"><label>note position:</label></b-col>
            <b-col sm="9"><b-form-select 
              :value="$store.state.form.formDisplayOptions.notePosition" 
              @input="$store.commit('form/setFormDisplayOptions',{key:'notePosition',val:$event})"
              :options="[ { text: 'below question', value: 'below-question' }, { text: 'below answer', value: 'below-answer' }, { text: 'above answer', value: 'above-answer' } ]"
              ></b-form-select></b-col>
          </b-row>
          <b-row class="my-1" v-if="$store.getters.isAdmin">
            <b-col sm="3"><label>hintLanguageMode:</label></b-col>
            <b-col sm="9"><b-form-select 
              :value="$store.state.form.formDisplayOptions.hintLanguageMode" 
              @input="$store.commit('form/setFormDisplayOptions',{key:'hintLanguageMode',val:$event})"
              :options="[ { text: 'Default and selected language', value: 'default+language' }, { text: 'Only selected language', value: 'language' } ]"
              ></b-form-select></b-col>
          </b-row>
          <b-row class="my-1" v-if="$store.getters.isAdmin">
            <b-col sm="3"><label>selectInfoMode:</label></b-col>
            <b-col sm="9"><b-form-select 
              :value="$store.state.form.formDisplayOptions.selectInfoMode" 
              @input="$store.commit('form/setFormDisplayOptions',{key:'selectInfoMode',val:$event})"
              :options="[ { text: 'Label + Info', value: 'label+info' }, { text: 'Only info', value: 'info' }, { text: 'Only label', value: 'label' } ]"
              ></b-form-select></b-col>
          </b-row>
          <div class="text-muted">* {{labels.noteConfigLocal}}</div>

          <div class="h4 mt-5">{{ labels.titleConfigServer }}</div>
          <b-row class="my-1">
            <b-col sm="3"><label>{{ labels.deviceNumber }}:</label></b-col>
            <b-col sm="9"><b-form-input v-model="deviceNumber"></b-form-input></b-col>
          </b-row>
          <b-row class="my-1">
            <b-col sm="3"><label>{{ labels.deviceName }}:</label></b-col>
            <b-col sm="9"><b-form-input v-model="deviceName"></b-form-input></b-col>
          </b-row>
          <div>
            <b-btn @click="save" :disabled="saveDisabled">{{ labels.save }}</b-btn>
          </div>
          <div class="alert alert-warning mt-4" v-if="mess">
            {{mess}}
          </div>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  data(){
    return{
      // config with default values
      deviceNumber:null,
      deviceName:null,
      saveDisabled:false,
      mess:null,
      localConfigs:localStorage.getItem('user-token-couchdb')
    }
  },
  created(){
    this.getDoc().then(doc=>{
      if(doc.deviceNumber && this.deviceNumber===null){
        this.deviceNumber=doc.deviceNumber
      }
      if(doc.deviceName && this.deviceName===null){
        this.deviceName=doc.deviceName
      }
    })
  },
  computed:{
    device_id(){
      return this.$store.state.localDB.config.device_id
    },
    labels(){
      return this.$store.state.labels.deviceConfig
    }
  },
  methods:{
    save(){
      //save locally
      this.getDoc().then(doc=>{
        // update the doc
        doc.deviceNumber=this.deviceNumber
        doc.deviceName=this.deviceName
        return this.$store.state.localDB.db.put(doc)
      }).then(()=>{
        //sync the doc to the master config DB
        return this.$store.state.localDB.db.replicate.to(this.$store.state.dbDatabaseSystem, Object.assign({doc_ids:[this.device_id]}, this.$store.getters.pouchDbOptions))
      }).then(()=>{
        //woks, so done. we don't disable... 
        this.saveDisabled=false
        this.$router.push('/')
      }).catch(err=>{
        this.mess = err
        this.$store.dispatch('app_message_error',err)
      })
    },
    getDoc(){
      return this.$store.state.localDB.db.get(this.device_id).then(doc=>{
        // console.log(doc);
        // doc already exists
        return Promise.resolve(doc)
      }).catch(err=>{
        //doc don't exists
        let obj1={_id:this.device_id}
        return Promise.resolve(obj1)
      })
    }
  }
}
</script>

<style>

</style>

<!--
************************************************************
  Usage
************************************************************
  Properties
    -v-model / value is the selection array - array even if mode single
    -Level is the level depth - always 1 at the begining. optional, not always useful
    -nodes is the node array with each object properties:
        -key - the value that is selected
        -title - the label shown
        -type - used for styles
        -children - array of the children (as node format)
        -expanded - determine if sub open or not. (optional)
    -styles object hold info on icon et text class
    -options is an object with those props to true if needed
        - multiselect - allow multi select - false by default
        - showCheckbox - show a checkbox for selection - false by default
        - showBackgroundLabelSelected - if we show checkbox, show also the background color. - false by default
        - lazyLoadUrl - example: '/tree/sub/$key$', 
            where $key$ will be replaced by the element key
            for this to be fetched, the children attribute must not be defined prior.
            ensure that the returned node will be an array and structured as node is. - if not, we could think about having a custom function to handle the differences
        - lazyLoadOnVisible - the children are loaded when the component is visible, if false(default) loaded only when caret click
        - keyAttributeName - specify the key specific attribute name
        - titleAttributeName - specify the title specific attribute name
        - childrenAttributeName - specify the title specific attribute name
        - caretHiddenNoChildren - hide the caret if no childre - not implemented
        - nbLevelExpanded - When expended not present, nb of level to expand by default
  Notes:
    - type=hidden - behave like it's not shown, but show children. Usually at the root of the tree.
************************************************************        
-->
<template>
  <div>
    <!-- <b-form-checkbox :value="data.key" disabled>{{data.title}}</b-form-checkbox> -->
    <!-- <input class="form-check-input" type="checkbox" id="gridCheck"> -->
    <div class="" :class="textClass" v-if="node.type!='hidden' && node.hidden!=true">
      <table>
        <tr>
          <td class="td-first">
            <!-- icon for folder else space -->
            <span v-if="hasChildren || childrenDefined==false" @click="toggleExpanded">
              <i class="fas fa-caret-down my-min-width" v-if="expanded"></i>
              <i class="fas fa-caret-right my-min-width" v-else></i>
            </span>
            <span v-else class="my-min-width">
              <i class="glyphicon glyphicon-minus"></i>
            </span>
            <!-- input box -->
            <!-- <b-form-checkbox :value="data.key" ></b-form-checkbox> -->
            <sg-tree-checkbox :value="state" @input="newVal($event)" v-if="showCheckbox"></sg-tree-checkbox>
            <!-- label and icon -->
            <i class="mr-2" :class="classIcon"></i>
          </td>
          <td class="td-second">
            <span @click="labelClicked" :class="{'label-selected':showLabelSelected}">{{title}}</span>
          </td>
        </tr>
      </table>
    </div>
    <!-- Subs -->
    <div class="ml-4 vld-parent loading" v-if="loading">
      <loading :active="loading" :is-full-page="false" loader="dots"></loading>
    </div>
    <div :class="{'ml-4':node.type!='hidden'}" v-if="hasChildren && node.expanded">
      <sg-tree
        :value="value"
        :options="options"
        :styles="styles"
        
        v-for="el in children"
        :node0="el"
        :key="el[ keyAttributeName ]"
        :level="level+1"
        :parent-checked="isSelected || parentChecked"
        @input="childEvent($event)"
        @childSelected="hasChildSelected=$event+hasChildSelected"
      ></sg-tree>
    </div>
  </div>
</template>

<script>
  import checkbox from './treeCheckbox.vue'
  

  // vue object
  export default {
    name:'sg-tree',
    components:{'sg-tree-checkbox':checkbox},
    props:{
      'value': {
        type: Array,
        required: false,
      },
      'level': {
        type: Number,
        required: false,
        default: 1
      },
      'nodes': { // used for external input
        type: Array,
        required: false,
      },
      'node0': { // used for internal input - children.
        type: Object,
        required: false,
      },
      'parentChecked': {
        type: Boolean,
        required: false,
      },
      'styles': {
        type: Object,
        required: false,
        default: () => ({})
      },
      'options': {
        type: Object,
        required: false,
        default: () => ({})
      },
    },
    data() {
      let nodes2 = this.node0
      if(!nodes2){
        nodes2 = {type:'hidden', expanded:true}
        nodes2[this.options.keyAttributeName || 'key'] ='hidden'
        nodes2[this.options.childrenAttributeName || 'children'] = calculateExpanded(JSON.parse(JSON.stringify(this.nodes)),1,this.options.nbLevelExpanded)
      }
      return {
        hasChildSelected:0,
        loading:false,
        node: nodes2,
        childrenDefined: nodes2[this.options.childrenAttributeName || 'children']?true: (this.options.lazyLoadUrl?false:true), // TODO: should be somewhere else and resuse the computed props...
        lazyChildren:null,
      }
    },
    mounted(){
      // so it's triggerred at the creation, useful if we receive a value.
      this.childSelectedWatch(this.isSelected?1:0,0)
    },
    computed:{
      expanded(){
        return this.node.expanded
      },
      isSelected(){
        return this.value.indexOf(this.key)!=-1
      },
      state(){
        if(this.isSelected){return 1}
        if(this.parentChecked){return 2}
        if(this.hasChildSelected){return 3}
        return 0
      },
      title(){
        let rep = null
        if(this.options.titleAttributeName){
          rep = this.node[this.options.titleAttributeName]
        }else{
          rep = this.node.title
        }
        if (!rep){
          // them show the  key
          rep = this.key
        }
        return rep
      },
      keyAttributeName(){
        return this.options.keyAttributeName || 'key'
      },
      key(){
        return this.node[this.keyAttributeName]
      },
      type(){
        return this.node.type
      },
      classIcon(){
        if(this.styles.hasOwnProperty(this.type)){
          return this.styles[this.type].icon
        }
      },
      textClass(){
        if(this.styles.hasOwnProperty(this.type)){
          return this.styles[this.type].text
        }
      },
      //options:
      showCheckbox(){
        return this.options.showCheckbox || false
      },
      multiselect(){
        return this.options.multiselect || false
      },
      childrenAttributeName(){
        return this.options.childrenAttributeName || 'children'
      },
      children(){
        return this.node[this.childrenAttributeName] || this.lazyChildren
      },
      hasChildren(){
        if(this.children && this.children.length>0){
          return true
        }else{
          return false
        }
      },
      showLabelSelected(){
        if(this.isSelected==false){return false}
        // if we show a background color for selected label
        if( ! this.showCheckbox){ // if no check box, yes
          return true
        }
        if( this.options.showBackgroundLabelSelected){
          return true
        }
        return false
      }
    },
    asyncComputed:{
      // **** laxy load children
      lazyChildrenGet(){ 
        if(this.options.lazyLoadUrl){
          // we have a provided url, try to get children
          // if not already defined and (if lazy load on visible or if expanded clicked)
          if( this.childrenDefined==false && (this.options.lazyLoadOnVisible || this.node.expanded)){
            this.loading=true
            // si children n'est pas définit
            return this.$axios.get(this.options.lazyLoadUrl.replace('$key$',this.key))
            .then(response => {
              this.loading=false
              this.childrenDefined=true
              this.lazyChildren=response.data // define another variable, because unless, the function (because computed) is called again and value returnned is undefined...
              return true
            })
            .catch(error => {
              this.$store.dispatch('app_message_error',err)
            });
          }
        }
      }
    },
    watch:{
      isSelected(){
        this.childSelectedWatch(this.isSelected?1:-1,0)
      },
      hasChildSelected(newVal,oldVal){
        this.childSelectedWatch(newVal,oldVal)
      }
    },
    methods:{
      childSelectedWatch(newVal,oldVal){
        if(newVal!=oldVal){ // can happen at the beginning mounted
          this.$emit('childSelected',newVal-oldVal)
        }
      },
      newVal(val){
        // The user clieckd the checkbox, act accordingly
        if(val==0){
          this.$emit('input',
            // remove-  we return a new array without our value
            this.value.filter(x => x!=this.key)
          )
        }
        if(val==1){
          this.$emit('input',
            //add - we return a new array with our value
            this.newValArray()
          )
        }
      },
      newValArray(){
        if(this.multiselect){
          return this.value.concat(this.key)
        }else{
          return [this.key]
        }
      },
      childEvent(val){
        // the child emited a new array, pass it to our parent.
        this.$emit('input',val)
      },
      labelClicked(){
        // our label has been cliked, 
        if(this.isSelected){
          this.newVal(0)
        }else{
          this.newVal(1)
        }
      },
      toggleExpanded(){
        this.$set(this.node,'expanded',!this.node.expanded)
      }
    }
  }


const calculateExpanded=(data, level, nbLevelExpanded)=>{ // data is an array
  data.map(x=>{
    if(data.hasOwnProperty('expanded')==false){
      if(level<nbLevelExpanded){
        x.expanded=true // not provided and needed by the tree component.

      }else{
        x.expanded=false // not provided and needed by the tree component.
      }
    }
    if(x.childs){
      calculateExpanded(x.childs, level + 1, nbLevelExpanded) // do children
    }
  })
  return data
}  
</script>

<style scoped>
  .my-min-width{
    min-width: 1em;
    display: inline-block;
  }
  .label-selected{
    /* background-color: $treeSelectedColor; */
    font-weight: 900;
    /* background-color: rgb(204, 204, 204); */
  }
  .loading{
    min-height:2em;
    max-width: 5rem;
  }
  .td-first{
    white-space: nowrap;
    vertical-align: top;
  }
  .td-second{
    text-align: left;
  }
</style>
<template>
    <div v-if="relevant && appearance!='hidden'" 
      class="list-group-item" 
      :class="t1"
      :style="{'padding-left': padding + 'px'}"
      @click="gotoElementPage"
    >
      <div class="d-flex justify-content-between">
        <!-- {{label}} <small>{{showValue}}</small> -->
        <div>
          <small v-html="label"></small> 
          <b class="ml-3">{{showValue}}</b> 
        </div>
        <div>
          <span v-if="hasFlag" class="mr-3" :class="flag.status"><i class="mdi mdi-flag"></i></span>
          <span>{{pageElementPageIndex + 1}}</span>          
        </div>
      </div>
    </div>
</template>

<script>
import base from './input/_baseInput.js'

export default {
  name:'summary-item',
  mixins:[base],
  props:['pagesObj'],
  computed:{
    padding(){
      return (this.namePath.length * 40)
    },
    t1(){
      if(this.status){
        return this.status_list[this.status].list_group_class
      }
      return ''
    },
    showValue(){
      if(Array.isArray(this.value)){
        return this.value.join(', ')
      }else if(['geopoint','geoshape', 'geotrace'].indexOf(this.config.type)>-1){
        if (this.value){
          return this.config.type
        }
        return ''
      }else{
        return this.value
      }
    },
    pageElementPageIndexSummary(){
      //return the page index = page number -1
      const pathComlete=this.namePath.concat(this.config.name)
      for(let i=0;i<this.pagesObj.length;i++){
        let cur1=this.pagesObj[i].pathComplete
        if (cur1.indexOf(this.config.name)>-1){
          return i
        }
        if(pathComlete.length>=cur1.length){
          if (JSON.stringify(cur1) === JSON.stringify(pathComlete.slice(0,cur1.length))){
            return i
          }
        }
      }
      return 0 // we haven't found it...
    },
    pageElementPageIndexSummaryActive(){
      // TODO: avoid this, we should have the survey in a store... but big change.
      if(this.pages){
        //we are inside the edit element
        return this.pageElementPageIndex
      }else{
        //we are in the summary route
        return this.pageElementPageIndexSummary
      }
    }
  },
  methods:{
    gotoElementPage(){
      if(this.pages){
        this.$store.commit('form/inputGroupCurrentPage',this.pageElementPageIndex)
      }else{
        this.$router.push(this.$router.currentRoute.path.replace('summary','edit')+'/page/'+(this.pageElementPageIndexSummaryActive+1))
      }
    }
  }
}
</script>

<style>

</style>

<template>
  <b-container :fluid="surveyDoc2?true:'md'">
    <!-- {{surveyDoc}} -->
    <!-- deactivated, not sure if it's what causing un wanted page change... -->
    <!-- v-touch:swipe.left="nextPage" v-touch:swipe.right="previousPage" -->
    <b-alert show v-if="isDeleted" class="pt-2" variant="danger">
      <i class="mdi mdi-alert text-danger h1"></i>
      {{ labels.form.isDeleted }}
    </b-alert>
    <div v-if="saveError" class="pt-2">
      <b-btn @click="saveSurvey" variant="danger" :disabled="isSaving">{{labels.form.finishNoSave}}</b-btn>
    </div>
    <div v-if="otherData && otherData.errors">
      <div show class="alert alert-danger" v-for="err in otherData.errors" :key="err">{{err}}</div>
    </div>
    <div v-if="isReady && editShowItem==1"
      id="editTop"
      >
      <div v-if="showRoleSuggestOfflineMode" class="pt-2">
        <b-alert show variant="warning">
          <i class="mdi mdi-alert text-danger h1"></i>
          {{ labels.form.suggestOfflineMode }}
        </b-alert>
      </div>
      
      <!-- We use group element to be sure we don't duplicate code.  -->
      <div :class="surveyDoc2?'row':''">
        <div v-if="surveyDoc2" class="col-6 bg-warning p-2">
          <div class="h2 text-danger mx-3 mb-0 d-flex justify-content-between">
            {{surveyDoc2['_rev']}}
            <b-btn-group size="sm">
              <b-btn @click="nextRev(5)"><i class="glyphicon glyphicon-backward"></i></b-btn>
              <b-btn @click="nextRev(1)"><i class="glyphicon glyphicon-arrow-left"></i></b-btn>
              <b-btn @click="nextRev(-1)"><i class="glyphicon glyphicon-arrow-right"></i></b-btn>
              <b-btn @click="nextRev(-5)"><i class="glyphicon glyphicon-forward"></i></b-btn>
            </b-btn-group>
          </div>
          <div class="ml-3">
            <span class="font-weight-bold ml-2">{{surveyDoc2.editor}}</span> {{surveyDoc2.editor_time}}
          </div>
          <div class="mx-4 mt-0 mb-4">
            <i-group
              :config="form_definition"
              :formReadOnly="true"
              :formDefinition="form_definition"
              :value="surveyDoc2['form_data']"
              :status="surveyDoc2['form_status']"
              :showSystemValues="false"
              :surveyDoc="surveyDoc"
              :namePath="[]"
              :readOnlyInput="true"
              >
            </i-group>
          </div>
        </div>
        <div :class="surveyDoc2?'col-6':''">
          <div v-if="surveyDoc2" class="h1">{{surveyDoc['_rev']}}</div>
          <i-group
              :config="form_definition"
              :formReadOnly="formReadOnly"
              :formDefinition="form_definition"
              :value="surveyData"
              @input="inputSurveyData($event)"
              :status="form_status"
              :showSystemValues="false"
              :surveyDoc="surveyDoc"
              :namePath="[]"
              :readOnlyInput="!canEdit"
              >
          </i-group>
        </div>
      </div>
      
      <!-- Pages -->
      <div class="my-2 row justify-content-center">
        <template v-if="hasPages">
          <b-btn @click="previousPage" v-if="showPreviousPage" variant="dark">{{labels.form.previousPage}}</b-btn>
          <div class="text-italic mx-3">{{currentPageIndex+1}} / {{pages.length}}</div>
          <b-btn @click="nextPage" v-if="showNextPage" variant="dark">{{labels.form.nextPage}}</b-btn>
        </template>
        <!-- summary toggle TODO: put in store -->
        <b-btn @click="setEditShowItem(2)" variant="primary" class="ml-5 float-right">{{labels.form.summaryButton}}
          <i v-if="surveyDoc._conflicts" class="glyphicon glyphicon-alert text-danger ml-2 my-conflicts"></i>
        </b-btn>      
        <b-btn @click="forceCanEdit=true" v-if="!canEdit" variant="warning" class="ml-5 float-right">
          <i class="glyphicon glyphicon-edit"></i>
        </b-btn>      
      </div>
      <!-- end new delete controls -->
      <div class="card mt-3" v-if="form_definition && canEdit && ((hasPages==false) || (hasPages && showNextPage==false) || (form_definition.survey_end_only_on_last_page!='true'))">
        <div class="card-header">
          <div v-if="formStatus > 10 && form_definition.form_status_global_error_message" show class="alert alert-danger" v-html="form_definition.form_status_global_error_message"></div>
          <div v-if="formStatus <= 10 && form_definition.form_status_global_no_error_message" show class="alert alert-success" v-html="form_definition.form_status_global_no_error_message"></div>
          <b-btn v-if="saveError" @click="saveSurvey" variant="danger" :disabled="isSaving">{{labels.form.finishNoSave}}</b-btn>
          <b-btn v-else-if="formStatus<=10" @click="end()" variant="primary" :disabled="isSaving">{{labels.form.finish}}</b-btn>
          <b-btn v-else @click="end()" variant="warning" :disabled="isSaving">{{labels.form.finishWithError}}</b-btn>
          <div class="mt-4">
            <b-button variant="dark" to="../edit" class="mr-5 btn-sm" v-if="form_definition.survey_show_new!='false'">{{labels.form.newSurvey}}</b-button>
            <div class="text-right">
              <delete-button @delete="deleteSurvey" myclass="btn-sm" v-if="form_definition.survey_show_delete!='false'"
                >{{labels.form.deleteSurvey}}<template slot="confirm">{{labels.form.deleteSurveyConfirm}}</template>
              </delete-button>
            </div>
          </div>
          <div class="my-2 d-flex justify-content-center">
            <export-json :doc_id="surveyDoc._id"></export-json>
          </div>
        </div>
      </div>   
    </div>
    <!-- Summary  -->
    <!-- // TODO: avoid this, we should have the survey in a store... but big change. -->
    <div v-if="isReady && editShowItem==2">
      <div class="list-group list-group-root">
        <div :class="surveyDoc2?'row':''">
          <div v-if="surveyDoc2" class="col-6 bg-warning p-2">
            <div class="h1 text-danger mx-3 d-flex justify-content-between">
              {{surveyDoc2['_rev']}}
              <b-btn-group size="sm">
                <b-btn @click="nextRev(5)"><i class="glyphicon glyphicon-backward"></i></b-btn>
                <b-btn @click="nextRev(1)"><i class="glyphicon glyphicon-arrow-left"></i></b-btn>
                <b-btn @click="nextRev(-1)"><i class="glyphicon glyphicon-arrow-right"></i></b-btn>
                <b-btn @click="nextRev(-5)"><i class="glyphicon glyphicon-forward"></i></b-btn>
              </b-btn-group>
            </div>
            <div class="ml-3">
              <b-btn @click="surveyDoc2=null" variant="primary" class="ml-3"><i class="fa fa-stop-circle"></i></b-btn>
              <b-btn @click="setEditShowItem(4)" variant="primary" class="ml-3"><i class="mdi mdi-file-compare"></i></b-btn>
              <span class="font-weight-bold ml-2">{{surveyDoc2.editor}}</span> {{surveyDoc2.editor_time}}
            </div>
            <div class="mx-4 mb-4 mt-3">
              <summary-group
                :config="form_definition"
                :formDefinition="form_definition"
                :value="surveyDoc2['form_data']"
                :status="surveyDoc2['form_status']"
                :showSystemValues="false"
                :surveyDoc="surveyDoc2"
                :namePath="[]"
                :otherData="otherData"
                :hideButtons="true"
              ></summary-group>
            </div>
          </div>
          <div :class="surveyDoc2?'col-6':''">
            <div v-if="surveyDoc2" class="h1">{{surveyDoc['_rev']}}</div>
              <summary-group
                :config="form_definition"
                :formDefinition="form_definition"
                :value="surveyData"
                @input="inputSurveyData($event)"
                :status="form_status"
                :showSystemValues="false"
                :surveyDoc="surveyDoc"
                :namePath="[]"
                @hide="setEditShowItem(1)"
                :otherData="otherData"
              ></summary-group>
          </div>
        </div>
      </div>
      <div class="text-right" v-if="surveyDoc.form_definition != formDefinition._id">
        <b-btn :disabled="!canEdit" @click="migrateSurveyFormDefinition" variant="warning">{{labels.form.migrateSurveyFormDefinition}}</b-btn>
      </div>
      <div class="mt-4">
        <b-card bg-variant="info">
          <b-card-text>
            <div>
              ID: {{surveyDoc._id}}
            </div>
            <div>
              Rev: {{surveyDoc._rev}}
              <b-btn @click="listEditRev()">List available versions(rev)</b-btn>
              <!-- <b-btn @click="listEditRev(true)" class="ml-3">List old conflicts versions(rev)</b-btn> -->
              <div class="ml-4 text-small">
                <div class="mt-1" v-for="rev in listRev" :key="rev">
                  <b-btn @click="seeRev(rev)" variant="secondary" size ="sm"> <i class="glyphicon glyphicon-eye-open"></i></b-btn> 
                  {{rev}} 
                  <span v-if="surveyDoc2 && surveyDoc2['_rev']==rev">
                    <b-btn :disabled="!canEdit" variant="warning" @click="replaceWithDoc2Rev()">Replace current rev by this one</b-btn>
                    <span class="font-weight-bold ml-2">{{surveyDoc2.editor}}</span> <span class="font-italic">({{surveyDoc2.editor_device_id}})</span> {{surveyDoc2.editor_time}}
                  </span>
                </div>
              </div>
            </div>
            <div class="mt-2">
              {{labels.form.summary.creatorId}}: <span class="font-weight-bold">{{surveyDoc.creator}}</span> <span class="font-italic">({{surveyDoc.device_id}})</span> {{surveyDoc.creationTime}}
            </div>
            <div>
              {{labels.form.summary.editorId}}: <span class="font-weight-bold">{{surveyDoc.editor}}</span> <span class="font-italic">({{surveyDoc.editor_device_id}})</span> {{surveyDoc.editor_time}}
            </div>
            <div v-if="surveyDoc._conflicts">
              <div class="h4 mt-3">
                <i class="glyphicon glyphicon-alert text-danger mr-2"></i>
                {{labels.form.conflicts}}:
              </div>
              <div class="ml-4">
                <div v-for="rev in surveyDoc._conflicts" :key="rev" class="my-2">
                  <b-btn @click="seeRev(rev)" variant="secondary" size ="sm"> <i class="glyphicon glyphicon-eye-open"></i></b-btn> 
                  {{rev}} 
                  <delete-button @delete="deleteRev(rev)" myclass="btn-sm" class="ml-4"></delete-button>
                </div>
                <div>
                  Current rev: {{surveyDoc._rev}} 
                  <delete-button @delete="deleteRev(surveyDoc._rev)" myclass="btn-sm" class="ml-4"></delete-button>
                </div>
              </div>
              <div class="ml-5 pl-5 mt-3">
                <b-btn @click="solveConflicts" variant="danger" size="sm">{{labels.form.conflictsSolve}}</b-btn>
              </div>
              <div class="ml-3 mt-3">
                <b-btn class="ml-1" size="sm" variant="dark" :href="$store.state.couchdbUrl+'_utils/#database/'+ form_id +'/'+ survey_id +'/conflicts'" target="_blank"><i class="mdi mdi-database"></i></b-btn>
              </div>
              <b-alert show class="ml-3 mt-3" v-if="conflictsMessage">
                <b-btn @click="reloadPage" variant="success" v-if="conflictsMessage.indexOf('Ok')!=-1"><i class="glyphicon glyphicon-refresh"></i></b-btn>
                {{conflictsMessage}}
              </b-alert>
            </div>
          </b-card-text>
        </b-card>
        <div :class="surveyDoc2?'row':''">
          <div v-if="surveyDoc2" class="col-6 bg-warning p-2">
            <div class="h1 text-danger mx-3">{{surveyDoc2['_rev']}}</div>
            <div class="m-4">
              <b-card class="mt-4" bg-variant="light">
                <b-card-text>
                  <h2>{{labels.form.summary.beginEdit}}</h2>
                  <b-table-lite :items="surveyDoc2.begin_edit" small class="ml-3"></b-table-lite>
                  <h2>{{labels.form.summary.endClicked}}</h2>
                  <b-table-lite :items="surveyDoc2.end_click" small class="ml-3"></b-table-lite>
                </b-card-text>
              </b-card>
            </div>
          </div>
          <div :class="surveyDoc2?'col-6':''">
            <div v-if="surveyDoc2" class="h1">{{surveyDoc['_rev']}}</div>
            <b-card class="mt-4" bg-variant="light">
              <b-card-text>
                <h2>{{labels.form.summary.beginEdit}}</h2>
                <b-table-lite :items="surveyDoc.begin_edit" small class="ml-3"></b-table-lite>
                <h2>{{labels.form.summary.endClicked}}</h2>
                <b-table-lite :items="surveyDoc.end_click" small class="ml-3"></b-table-lite>
              </b-card-text>
            </b-card>
          </div>
        </div>
      </div>
    </div>
    <!-- Printing -->
    <div v-if="isReady && editShowItem==3">
      <div class="list-group list-group-root">
        <print-group
          :config="form_definition"
          :formDefinition="form_definition"
          :value="surveyData"
          @input="inputSurveyData($event)"
          :status="form_status"
          :showSystemValues="false"
          :surveyDoc="surveyDoc"
          :namePath="[]"
          @hide="setEditShowItem(1)"
          :otherData="otherData"
        ></print-group>
      </div>
    </div>
    <div v-if="surveyDoc2 && editShowItem==4">
      <compare-survey :obj1="surveyDoc" :obj2="surveyDoc2" :form_definition="form_definition" @hide="setEditShowItem(2)"></compare-survey>
    </div>
    <!-- not found, but present remotly -->
    <edit-not-local-but-remote v-if="notPresentLocalButRemotly" :survey_id="this.survey_id" @goOnline="goOnline"></edit-not-local-but-remote>
  </b-container>
</template>

<script>

import base from './_base.js'
// import inputs from '../../components/input'
// console.log('ici3')
// console.log(inputs)
import group from '../../components/inputGroup.vue'
import deleteButton from '../../components/utils/deleteButton.vue'
import debounce from 'lodash/debounce'
import utils_gen from '../../utils/utils_gen.js'
import formUtils from '../../utils/form_utils.js'
import summaryGroup from '../../components/summaryGroup.vue'
import printGroup from '../../components/print/printGroup.vue'
import editNotLocalButRemote from '../../components/form/editNotLocalButRemote.vue'
import compareSurvey from '../../components/form/compareSurvey.vue'
import exportJson from '@/components/sync/exportsJson.vue'


export default {
  name:'form_edit',
  mixins:[base],
  // components:inputs,
  components:{
    IGroup:group,
    summaryGroup:summaryGroup,
    deleteButton,
    printGroup,
    editNotLocalButRemote,
    compareSurvey,
    exportJson,
  },
  props:['survey_id','page_id'],
  data(){
    return{
      surveyDoc:null,
      surveyDoc2:null, // when we compare to another survey revision(version)...
      oldSurveyDoc:null,
      surveyData:null, // as we pass the property of doc object at the instantiation, the doc is changed righ away.
      surveyDataWatch:()=>{},
      form_definition:null,
      lastEditName:null,
      formReadOnly:false,
      my_page_id:0,
      currentState:null,
      saveDebounce:null,
      inputSurveyDataDebounce: null,
      saveError:false, // if we have a save error, prevent quitting and message user.
      previousPageJson:null,
      hasToScrool:false,
      otherData:{},
      surveyComponentLiveWatch:{},
      forceCanEdit: false,
      conflictsMessage: null,
      firstSave: true,
      notPresentLocalButRemotly:false,
      listRev: null,
      isSaving: false,
    }
  },
  created(){

  },
  activated(){
    console.log('edit activated ' + this.survey_id);
    if(!this.surveyDoc && this.survey_id){
      this.fetchSurvey() // because if we go back to the same survey, survey_id is not changed, so no event.
    }
    this.showUpperNavigation()
  },
  updated(){
    if(this.hasToScrool && this.editShowItem==1){
      // console.log('scroll');
      // scroot to top if page change      
      var options = {
        container: 'body',
        easing: 'ease-in',
        offset: -60,
        cancelable: true,
        y: true
      }
      this.$scrollTo('#editTop',500,options)
      this.hasToScrool=false
    }
  },
  computed:{
    isReady(){
      if(this.surveyDoc && this.form_definition){
        return true
      }
      return false
    },
    isDeleted(){
      if(this.surveyDoc && this.surveyDoc.survey_deleted){
        return true
      }
      return false
    },
    form_status(){
      // the json form status.
      if(this.surveyDoc){
        return this.surveyDoc.form_status
      }
    },
    formStatus(){
      //the global status of the form, one value for all. the higher one.
      return this.surveyDoc.form_status_global
    },
    pages(){
      if(this.isReady){
        return formUtils.computePages(this.surveyDoc,this.form_definition,this.otherData)
      }
    },
    hasPages(){
      return this.pages && this.pages.length>0
    },
    currentPageIndex(){
      return this.$store.state.form.inputGroupCurrentPage
    },
    currentPage(){
      return this.$store.getters['form/currentPage']
    },
    editShowItem(){
      return this.$store.state.form.editShowItem
    },
    showPreviousPage(){
      return this.hasPages && this.currentPageIndex!=0
    },
    showNextPage(){
      return this.hasPages && this.currentPageIndex!=(this.pages.length-1)
    },
    surveyComponentLiveIds(){
      return this.$store.getters['formMap/surveyComponentLiveIds']
    },
    disableEditingOnOtherDevice(){
      if(this.$store.getters['form/actualFormDefitionSetting']('disableEditingOnOtherDevice',null,true) ?? this.$store.state.settings.disableEditingOnOtherDevice){
        // config in the form definition or in the settings
        if(
          this.$store.state.localDB.config.device_id == this.surveyDoc.device_id 
          && (
            !this.surveyDoc.editor_device_id ||
            this.$store.state.localDB.config.device_id==this.surveyDoc.editor_device_id
          )){
          return true
        }
        return false
      }
      return null
    },
    disableEditingNotCreationDate(){
      if(this.$store.getters['form/actualFormDefitionSetting']('disableEditingNotCreationDate',false,true)){
        // config in the form definition or in the settings
        if( this.$store.getters['utcCurrentTime']().slice(0,10) == this.surveyDoc.creationTime.slice(0,10)){
          return true
        }
        return false
      }
      return null
    },
    canEdit(){
      if(this.forceCanEdit){
        return true
      }else if(this.disableEditingOnOtherDevice === false || this.disableEditingNotCreationDate === false){
        return false
      }
      return true
    },
    showRoleSuggestOfflineMode(){
      if(this.$store.state.settings.editRoleSuggestOfflineMode){
        if(this.$store.state.settings.editRoleSuggestOfflineMode.filter(value=>this.$store.getters['roles'].indexOf(value) != -1).length > 0){
          // Check if online or offline
          if(this.$store.getters['localDB/isOnlineMode']==false){
            return true
          }
        }
      }
      return false
    }
  },
  watch:{
    form_id:'clear',
    survey_id:{
      handler:'fetchSurvey',
      immediate:true
    },
    pages:{
      handler(){
        // console.log('edit - pages - watch');
        this.showUpperNavigation()
      },
      immediate:true
    },
    page_id:{
      immediate:true,
      handler(){
        this.$store.commit('form/inputGroupCurrentPage', this.page_id?(parseInt(this.page_id)-1):0)
      }
    },
    currentPage(){
      this.setEditShowItem(1)
      //ensure if we change page, the delete status is reset
      this.isDeleting=false
      this.hasToScrool=true
    },
    surveyComponentLiveIds(){
      // create triggers
      Object.keys(this.surveyComponentLiveWatch).map(id=>{
        if(this.surveyComponentLiveIds.indexOf(id)==-1){
          //unwatch https://vuejs.org/v2/api/#vm-watch
          this.surveyComponentLiveWatch[id]()
          delete this.surveyComponentLiveWatch[id]
        }
      })
      this.surveyComponentLiveIds.map(id=>{
        if(!this.surveyComponentLiveWatch[id]){
          // not present, create it
          this.surveyComponentLiveWatch[id]=this.$watch(
            // https://stackoverflow.com/a/56461539/140384
            '$store.state.formMap.formComponentLive.' + id + '.response',
            (newVal,oldVal) => {
              //execute your code here
              const valuePath=this.$store.state.formMap.formComponentLive[id].component.currentNamePath
              utils_gen.setPropTree2(this.surveyData,valuePath,newVal)
            }
          )
        }
      })
    },
    forceCanEdit(){
      // this always change to true, unless we clear/quit the form.
      if(this.forceCanEdit){
        // check if there are any question with parameters set to on_survey_edit_set_to_default and them set them to default.
        this.$store.getters['form/actualFormDefitionFieldsFlatten'].filter(x=>{
          if((x.field?.parameters?.indexOf('on_survey_edit_set_to_default') ?? -1) != -1){
            return true
          }
          return false
        }).forEach(x=>{
          // set those values to the default value
          // console.log(x.namePath,x.field.default);
          utils_gen.setPropTree2(this.surveyDoc.form_data,x.path,x.field.default)
        })
      }
    },
  },
  methods:{
    clear(){
      // remove pending formMap live components
      this.$store.dispatch('formMap/clearSurveyLiveComponents')
      this.setEditShowItem(1)
      if(this.surveyData && this.saveDebounce){
        this.saveDebounce.flush()
        this.saveDebounce.cancel()
      }
      this.form_definition=null
      this.otherData={}
      this.surveyDoc=null
      this.surveyDoc2=null
      this.surveyData=null
      this.isDeleting=false
      this.firstSave=true
      this.listRev=null
      this.forceCanEdit=false
      this.saveError=false
      this.isSaving=false
    },
    end(){
      if(!this.surveyDoc.end_click){
        this.surveyDoc.end_click = []
      }
      this.surveyDoc.end_click.push({ 
        utcTime: this.$store.getters['utcCurrentTime'](), 
        user: this.$store.getters['userName'], 
        device: this.$store.getters['localDB/device_id'] , 
        saveError:this.saveError, 
        formStatus:this.formStatus,
        rev: this.surveyDoc._rev,
      })
      this.saveDebounce.cancel() // unless we might trigger it twice...
      // ensure saved - only if success we wuit the page, unless show the save error, already set in the saveSurvey()
      this.saveSurvey().then(()=>{
        this.clear()
        if(this.$store.state.form.editOnEndReturnTo == 'map'){
          this.$router.push('/forms/'+this.$store.state.form.form_id+'/map')
        }else if(this.$store.state.form.editOnEndReturnTo == 'form_list'){
          this.$router.push('/forms/'+this.$store.state.form.form_id+'/list')
        }else{
          this.$router.push('/forms/'+this.$store.state.form.form_id)
        }
      })
    },
    fetchSurvey(){
      // console.log('fecth survey 1 ' + this.survey_id)
      this.$store.commit('form/inputGroupCurrentPage', this.page_id?(parseInt(this.page_id)-1):0)
      if(!this.currentState){
        this.currentState='fetching'//avoid save - because fetch 2 times when refresh a form.
        this.fetchFormPromise.then(rep=>{
          return this.fetchSurvey2()
        }).then(()=>{
          // fetch ended - emit event
          this.$emit('fetchSurvey', true)
        }).catch(err=>{
          this.$store.dispatch('app_message_error',err)
          this.$store.commit('error_message',err.message)
          this.$router.push('/error')
        })
      }
    },
    fetchSurvey2(){
      // console.log('fecth survey 2 ' + this.survey_id)
      this.clear()

      if(!this.survey_id){
        //create new survey in DB
        this.$store.dispatch('form/newSurvey').then(rep=>{
          console.log('new survey '+ rep.id)
          this.$router.replace(this.$router.currentRoute.path + '/' + rep.id)
        }).catch(err=>{
          this.$store.dispatch('app_message_error',err)
          this.$store.commit('error_message',err.message)
          this.$router.push('/error')
        })
        //will refresh the route and them we're gone a fetch this survey, no no need to do it here.
        this.currentState=null
        return Promise.resolve()
      }
      //fetch the survey_id
      return this.$store.dispatch('form/fetchSurvey',{survey_id: this.survey_id}).then(doc=>{
        this.saveDebounce=debounce(this.saveSurvey,3000,{ 'maxWait': 10000 })
        this.inputSurveyDataDebounce = debounce(this.inputSurveyData,900,{leading:true})
        this.surveyData=doc.form_data
        this.surveyDoc=doc
        this.oldSurveyDoc=JSON.parse(JSON.stringify(this.surveyDoc))
        // as we pass the property of doc object at the instantiation, the doc is changed righ away.
        this.defineWatch()
        // console.log(this.surveyData)
        // console.log(this.surveyStatus)
        // now get the form definition
        return this.$store.dispatch('form/fetchDefinition',doc.form_definition)
      }).then(doc=>{
        this.form_definition=doc.form_definition
        this.currentState=null
        //  when importing third party form, the status is not calculate, calculate it
        if (!this.surveyDoc.form_status){
          this.surveyDoc.form_status={}
          this.setStatus()
        }
        this.$store.commit('form/setEditingFormDefinitionId', doc._id)
        this.$store.commit('form/setViewMode', 1) // reset the view mode.
        this.$store.commit('form/survey/setSurveyDoc', this.surveyDoc)
        this.form_definition=doc.form_definition
        this.currentState=null
        //  when importing third party form, the status is not calculate, calculate it
        if (!this.surveyDoc.form_status){
          this.surveyDoc.form_status={}
          this.setStatus()
        }
        if (this.$store.getters['form/hasViews']){
          this.$watch(
            'surveyData.' + this.form_definition.viewsFieldName,
            (newVal,oldVal) => {
              this.$store.commit('form/setViewValue',newVal)
            },
            {immediate: true, deep: true}
          )
        }
      }).catch(err=>{
        if (err=='survey present in remotedb'){
          this.notPresentLocalButRemotly=true
          this.currentState=null
        }else{
          this.$store.dispatch('app_message_error',err)
          if (err.message){
            this.$store.commit('error_message',err.message)
          }else{
            this.$store.commit('error_message',err)
          }
          this.$router.push('/error')
        }
      })
    },
    defineWatch(){
      //ensure the previous one is stopped
      this.endWatch()
      // to avoid infinite loop in the setStatus and calculations, we dynamicly create the watch...
      //TODO: Think of a better solution, but for now works with what has been done before...     
      this.surveyDataWatch=this.$watch(
        'surveyData',
        (newSurvey,oldSurvey) => {
          //execute your code here
          if(oldSurvey && newSurvey){
            this.inputSurveyDataDebounce()
          }
        },
        { deep: true }
      )
    },
    endWatch(){
      this.surveyDataWatch()
      this.surveyDataWatch=()=>{}
    },
    inputSurveyData(){
      // trigered by inputSurveyDataDebounce on the defineWatch when surveyData changes.
      if(this.form_definition){
        // data has change, sub input components.
        this.setStatus()
        // Commit to the store the document:
        this.$store.commit('form/survey/setSurveyDoc', this.surveyDoc)
        this.saveSurveyDebounce()
      }
    },
    ///************ Status and relevant */
    setStatus(){
      console.log('edit.vue - setStatus');
      this.endWatch() // unwatch we're gone a change the values of status and calculation - multiple times, so avoid loop.
      // deal with calculated default
      // if value has change and status=2 put status=0
      this.otherData={}
      this.otherData.oldSurvey=this.oldSurveyDoc.form_data
      this.otherData.oldSurveyStatus=this.oldSurveyDoc.form_status
      this.otherData.formDefinition=this.formDefinition
      this.otherData.surveyDoc=this.surveyDoc // some could need it.
      this.otherData.flagsEnabled=this.$store.getters['form/actualFormDefitionSetting']('flagsEnabled',false,true)

      formUtils.setSurveyDocStatus(this.surveyDoc, this.form_definition, this.otherData)
      
      this.surveyData=this.surveyDoc.form_data
      this.oldSurveyDoc=JSON.parse(JSON.stringify(this.surveyDoc))
      this.defineWatch()
    },
    saveSurveyDebounce(){this.saveDebounce()},
    saveSurvey(){
      if(this.surveyDoc && this.canEdit){
        console.log('edit.vue - save')
        if(this.firstSave){
          this.firstSave=false
          if(!this.surveyDoc.begin_edit){
            this.surveyDoc.begin_edit = []
          }
          this.surveyDoc.begin_edit.push({ 
            utcTime: this.$store.getters['utcCurrentTime'](), 
            user: this.$store.getters['userName'], 
            device: this.$store.getters['localDB/device_id'], 
            formStatus:this.formStatus,
            rev: this.surveyDoc._rev,
          })
        }
        // console.log(this.surveyDoc)
        global.lastEditSurveyDoc=this.surveyDoc
        this.isSaving=true
        return this.$store.dispatch('form/saveSurvey',this.surveyDoc).then(rep=>{
          this.saveError=false
          if(this.surveyDoc && rep.id==this.surveyDoc._id){ //verify in case it's an old survey that got saved(debounce) and we got a new one at the same time.
            this.surveyDoc._rev=rep.rev
          }
          this.isSaving=false
          return Promise.resolve(rep.rev)
        }).catch(err=>{
          this.isSaving=false          
          this.saveError=true
          this.$store.dispatch('app_message_error',err)
          return Promise.resolve(null)
        })
      }
      return Promise.reject('cannot edit survey or survey not loaded')
    },
    deleteSurvey(){
      // We don't actually delete the survey, we just mark it as deleted, so we can keep the history.
      // this.surveyDoc._deleted=true
      this.surveyDoc.survey_deleted=true
      if(!this.surveyDoc.begin_edit){
        this.surveyDoc.begin_edit = []
      }
      this.surveyDoc.begin_edit.push({ 
        utcTime: this.$store.getters['utcCurrentTime'](), 
        user: this.$store.getters['userName'], 
        device: this.$store.getters['localDB/device_id'], 
        formStatus:this.formStatus,
        delete:true,
        rev: this.surveyDoc._rev,
      })
      this.saveSurvey()
      this.$router.go(-1)
    },
    showUpperNavigation(){
      // here because has to change with the activate event.
      if(this.hasPages){
        this.$store.commit('form/inputGroupPages', this.pages)
      }else{
        this.$store.commit('form/inputGroupPages', 0) // no pages, will still evalate to false, but we them know we are in a form , as it's null when we leave edit.
      }
    },
    nextPage(){
      this.$store.commit('form/inputGroupCurrentPage',this.currentPageIndex+1)
    },
    previousPage(){
      this.$store.commit('form/inputGroupCurrentPage',this.currentPageIndex-1)
    },
    setEditShowItem(newItem){
      this.$store.commit('form/editShowItem',{value: newItem, name: null})
    },
    migrateSurveyFormDefinition(){
      // This will update the form definition and do a save so our computed properties are updated
      this.surveyDoc.form_definition = this.formDefinition._id
      // fetch our
      return this.$store.dispatch('form/fetchDefinition',this.surveyDoc.form_definition).then(doc=>{
        this.form_definition=doc.form_definition
        // calculate status and claculation
        this.setStatus()
        this.surveyDoc.from_def_migration_time = this.$store.getters['utcCurrentTime']()
        // now do the save
        return this.saveSurvey()
      })
    },
    solveConflicts(){
      // conflictsSolve:'Resolve conflicts keeping only creator(device) editions',
      // first get all the docs
      this.$store.dispatch('form/fetchSurvey',{survey_id: this.survey_id, options:{open_revs:'all'}}).then(rep=>{
        console.log(rep)
        //  get the rev that is the editor and craetor device ==
        let m2 = [];
        let docs = []
        rep.filter(x=> x.ok._deleted !== true).map(x=>{
          console.log(x.ok.device_id + ' ' + x.ok._rev);
          if(x.ok.device_id == x.ok.editor_device_id){
            m2.push(x.ok._rev)
          }else{
            docs.push({
              _deleted: true,
              _id: x.ok._id,
              _rev: x.ok._rev,
            })
          }
        })
        if (m2.length == 1){
          console.log(m2);
          console.log(docs);
          this.$store.state.form.db.bulkDocs(docs).then(rep2=>{
            console.log(rep2);
            this.conflictsMessage = this.labels.form.conflictsMess1 + m2[0]
          })
        }else if (m2.length > 1){
          this.conflictsMessage = this.labels.form.conflictsMess2
        }else if (m2.length == 0){
          this.conflictsMessage = this.labels.form.conflictsMess3
        }
      })
    },
    reloadPage(){
      window.location.reload();
    },
    goOnline(){
      this.notPresentLocalButRemotly=false
      this.fetchSurvey()
    },
    seeRev(rev){
      return this.$store.dispatch('form/fetchSurvey',{survey_id: this.survey_id, options:{ rev: rev}}).then(doc=>{
        this.surveyDoc2 = doc
        this.setEditShowItem(1)
      })
    },
    deleteRev(rev){
      return this.$store.state.form.db.remove(this.survey_id, rev).then(()=>{
        // update our document
        this.surveyDoc2 = null
        this.fetchSurvey()
      })
    },
    nextRev(factor){
      try {
        let cur = this.listRev.indexOf(this.surveyDoc2._rev)
        cur = cur + factor
        this.seeRev(this.listRev[cur])
      } catch (error) {
        console.error(error);
        console.log('no more revs');
      }
    },
    listEditRev(seeOldConflicts=false){
      if(this.listRev){
        this.listRev=null
        return 
      }
      const opts = { revs_info: true}
      if(seeOldConflicts){
        // // *** not working.... cannot retreive the rev info of the _deleted docs, revs_info is not working
        // // should work, but would have to verify with certainty we have the conflict revision https://gist.github.com/eiri/a40bcb2eb9a8b1752d604948e052be04
        // this.listRev = []
        // const opts2 = { open_revs: 'all', revs: true}
        // return this.$store.dispatch('form/fetchSurvey',{survey_id: this.survey_id, options:opts2}).then(revs55=>{
        //   //  this list the revs, but not if available or not
        //   console.log(revs55);
        //   let fetch1 = revs55.map(x=>{
        //     console.log(x);
        //     if(x.ok._deleted){
        //       console.log('deleted');
        //       opts['rev'] = x.ok._rev
        //       return this.$store.dispatch('form/fetchSurvey',{survey_id: this.survey_id, options:opts})
        //     }
        //     return Promise.resolve()
        //   })
        //   console.log(fetch1);
        //   return Promise.all(fetch1).then(reps=>{
        //     console.log(reps);
        //     reps.filter(x=>x).map(doc=>{
        //       // console.log(revsOpen);
        //       this.listRev = this.listRev.concat( doc['_revs_info'].filter(x=>x.status=='available').map(x=>x.rev).filter(x=>x!=this.surveyDoc._rev))
        //     })
        //   })
        // })
      }else{
        return this.$store.dispatch('form/fetchSurvey',{survey_id: this.survey_id, options:opts}).then(doc=>{
          this.listRev = doc['_revs_info'].filter(x=>x.status=='available').map(x=>x.rev).filter(x=>x!=this.surveyDoc._rev)
        })
      }
    },
    replaceWithDoc2Rev(){
      this.surveyDoc2['_rev'] = this.surveyDoc['_rev']
      if(!this.surveyDoc2['restored']){
        this.surveyDoc2['restored'] = []
      }
      this.surveyDoc2['restored'].push({ utcTime: this.$store.getters['utcCurrentTime'](), user: this.$store.getters['userName'], device: this.$store.getters['localDB/device_id'] , formStatus:this.formStatus})
      this.surveyDoc = this.surveyDoc2
      return this.$store.dispatch('form/saveSurvey',this.surveyDoc).then(rep=>{
          this.fetchSurvey()
      }).catch(err=>{
        this.saveError=true
        this.$store.dispatch('app_message_error',err)
      })
    },
  },
  beforeRouteLeave (to, from, next) {
    // called when the route that renders this component is about to
    // be navigated away from.
    // has access to `this` component instance.

    //remove the inputGroupPages from the store, will be added if necessary.
    this.$store.commit('form/inputGroupPages',null)

    //todo: make this work... no big deal, but should be
    //have check the source and doc and should work
    //tested with https://www.npmjs.com/package/lodash.debounce -- not working
    //tested debounce in data - not working
    // https://lodash.com/docs#debounce
    //this.saveSurveyDebounce.flush()
    next()
  },
  beforeRouteEnter (to, from, next) {
    //TODO: better handle the page_id, should be handle on the edit.vue component. as it's the one receiving (option) the page_id
    //  error when edit a survey and back and them forward.
    //  the component is not recreated, so no page navigation in the title...

    // if(to.params.hasOwnProperty('page_id')){
    //
    // }
    next()
  }
}
</script>

<style>
.container{
  padding-right:3px;
  padding-left:3px;
}
.list-group-item{
  padding-right:3px;
  padding-left:3px;
}
</style>

<template>
    <div v-if="relevant">
      <div class="list-group-item" v-if="!isRoot" @click="showCollapse=!showCollapse"
        :style="{'padding-left': padding + 'px'}"
        :class="groupStatusClass"
      >
        <a class="glyphicon" :class="{'glyphicon-chevron-right':!showCollapse,'glyphicon-chevron-down':showCollapse}"></a>
        <span v-html="label_group"></span>
      </div>
      <div v-if="isRoot" class="my-3 ml-5">
        
      </div>
      <div :id="'group'+config.name" v-if="showCollapse" class="list-group">
        <component v-for="(el) in formElements"
            :is="getComponentName(el)"
            :config="el"
            :key="el.name"
            :formDefinition="formDefinition"
            :value="value[el.name]"
            :status="status && status[el.name]"
            :surveyDoc="surveyDoc"
            :showSystemValues="showSystemValues"
            :namePath="newNamePath()"
            :pagesObj="pagesObj"
            >
        </component>
      </div>     
    </div>
</template>

<script>
import base from '../input/_baseInput.js'
import print2Item from './print2Item.vue'
import print2Repeat from './print2Repeat.vue'

export default {
  name:'print2-group',
  mixins:[base],
  components:{'print2-item':print2Item,'print2-repeat':print2Repeat},
  props:{
    pagesObj:{},
    isRootRepeat:{
      type:Boolean,
      default:false
    }
  },
  data(){
    return {
      showCollapse:true
    }
  },
  created(){
    if(this.isRoot){
      this.showCollapse=true
    }
  },
  computed:{
    padding(){
      if(this.isRootRepeat){
        return (this.namePath.length * 40) - 30
      }
      return (this.namePath.length * 40)
    },
    groupStatusClass(){
      if(this.groupStatus){
        return this.status_list[this.groupStatus].list_group_class
      }
    },
    label_group(){
      if(this.isRootRepeat){
        return this.label + ' (' + (parseInt(this.namePath.slice(-1))+1) + ')'
      }
      return this.label
    }
  },
  watch:{
    
  },
  methods:{
    getComponentName(el){
      if(el.type=='group'){
        return 'print2-group'
      }else if(el.type=='repeat'){
        return 'print2-repeat'
      }
      return 'print2-item'
    },
    newNamePath(){
      let new1=this.namePath.slice()
      if(!this.isRoot && this.isRootRepeat===false){
        new1.push(this.config.name)
      }
      return new1
    },
  }
}
</script>

<style>

</style>

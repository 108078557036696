<template>
  <div v-if="formConfig && formDefinition">
    <b-container>
      <div class="h2 text-center my-2">
        {{formConfig.label}}
        <b-btn v-if="!curDate" to="list/all" variant="primary" size="sm"><i class="fas fa-eye mr-1"></i><i class="fas fa-globe"></i></b-btn>
      </div>
      <div v-if="hasSearchFields && !curDate" class="my-2">
        <b-input-group>
          <b-form-input v-model="searchText" :placeholder="labels.form.list.searchPlaceholder" debounce="600"></b-form-input>
          <template #append>
            <b-btn @click="searchText=null"><i class="fas fa-times"></i></b-btn>
          </template>
        </b-input-group>
      </div>
      <!-- Show date list -->
      <div v-if="!surveys">
        <b-table striped hover :items="dates" :fields="dateFields" responsive>
          <template #cell(action)="data">
            <!-- <b-checkbox v-if="canDownloadForms" value="selected.indexOf(data.item.key)!=-1" @input="val => select(data.item.key, val)"></b-checkbox> -->
            <!-- <b-button @click="curDate=data.item.key;fetchSurveys()">View</b-button> -->
            <b-btn :to="'list/'+data.item.key"><span class="glyphicon glyphicon-eye-open"></span></b-btn>
            <delete-button
              myclass="ml-2" 
              v-if="$store.getters.isAdmin"
              @delete="deleteSurveys(data.item.key)" 
            ></delete-button>
          </template>
        </b-table>
      </div>
      <!-- show info for a data -->
      <div v-else-if="surveys">
        <!-- <div class="h4 text-center">
          {{curDate}}
          <b-btn @click="curDate=null" variant="primary">reset</b-btn>
        </div> -->
        <b-alert show variant='info' size='lg' v-if="!searchText" class="d-flex justify-content-between">
          <router-link class="h4 no-link" :to="'.'">
            <span class="glyphicon glyphicon-arrow-left"></span>
            <span class="ml-3">{{curDate}}</span>
          </router-link>
          <span class="ml-3 text-secondary"><i class="mdi mdi-tally-mark-5 mr-2 mt-1"></i>{{surveys_filtered.length}}</span>
          <div>
            <b-btn @click="filterConflict=!filterConflict" :variant="filterConflict?'dark':''" size="sm" class="ml-4"><i class="glyphicon glyphicon-filter"></i><i class="glyphicon glyphicon-alert text-danger"></i></b-btn>
            <b-btn @click="filterInvalid=!filterInvalid" :variant="filterInvalid?'dark':''" size="sm" class="ml-4"><i class="glyphicon glyphicon-filter"></i><i class="glyphicon glyphicon-pencil text-warning"></i></b-btn>
          </div>
        </b-alert>
        <div class="row">
          <!-- <div class="col-md-8">
            <b-form-select v-model="filter_device" :options="device_list" class="mb-3" />
          </div>
          <div class="col-md-4 h5">
            {{surveys_filtered.length}}
          </div> -->
        </div>
        <b-table striped hover :items="surveys_filtered" :fields="fields" responsive>
          <template slot="cell(creationTime)" slot-scope="data">
            <span class="date-one-line">{{data.value.replace('T',' ').replace('Z','')}}</span>
          </template>
          <template slot="cell(action)" slot-scope="data">
            <!-- <b-checkbox v-if="canDownloadForms" value="selected.indexOf(data.item._id)!=-1" @input="val => select(data.item._id, val)"></b-checkbox> -->
            <div class="d-flex">
              <b-button 
                :to="(curDate?'..':'.') + '/edit/'+data.item._id" 
                size="sm"
                :variant="data.item.form_status_global > 9 ? 'warning' : 'secondary' "
              ><span class="glyphicon glyphicon-pencil"></span></b-button>
              <delete-button
                myclass="ml-2" 
                v-if="$store.getters.isAdmin"
                @delete="deleteSurvey(data.item._id)" 
                size="sm"
              ></delete-button>
              <i v-if="data.item.hasConflicts === true" class="glyphicon glyphicon-alert text-danger ml-2 my-conflicts"></i>
            </div>
          </template>
        </b-table>
        <!-- <b-table-simple striped hover responsive>
          <b-tr v-for="survey in surveys_filtered" :key="survey._id">
            <b-td>
              <b-button 
                :to="'../edit/'+survey._id" 
                size="sm"
                :variant="survey.form_status_global > 9 ? 'warning' : 'secondary' "
              ><span class="glyphicon glyphicon-pencil"></span></b-button>
              <delete-button
                myclass="ml-2" 
                v-if="$store.getters.isAdmin"
                @delete="deleteSurvey(survey._id)" 
                size="sm"
              ></delete-button>
              <i v-if="survey.hasConflicts === true" class="glyphicon glyphicon-alert text-danger ml-2 my-conflicts"></i>
            </b-td>
            <b-td v-for="fi in fields" :key="survey._id+fi.key">
              {{ survey[fi.key] }}
            </b-td>
          </b-tr>
        </b-table-simple> -->
      </div>
      <div class="my-2" v-if="canDownloadForms">
        <download :curDate="curDate" :selected="selected"></download>
      </div>
    </b-container>
  </div>
</template>

<script>
import base from './_base.js'
import deleteButton from '../../components/utils/deleteButton.vue'
import { Promise } from 'q';
import couchDbUtils from '../../utils/couchdb.js'
import download from '../../components/listDownload.vue'

export default {
  name:'form_list',
  mixins:[base],
  props:['curDate'],
  components: {
    deleteButton,
    download,
  },
  data(){
    return{
      surveys:null,
      dates:null,
      filter_device:null,
      tryDates:0,
      selected: [],
      searchText:null,
      allSurveys: null,
      filterInvalid: false,
      filterConflict: false,
    }
  },
  activated(){
    let dates=this.dates
    this.getDates()
    if(this.dates!=dates){
      this.fetchSurveys()
    }
    this.allSurveys = null
  },
  computed:{
    labels2(){return this.$store.state.labels.form.edit},
    canDownloadForms(){
      if(this.hasLocal && this.isLocal===false && this.online){
        if (this.$store.state.settings.allowDownloadSurveys || this.$store.getters.isAdmin){
          return true
        }
      }
      return false
    },
    surveys_filtered(){
      let rep = this.surveys
      if(this.filter_device){
        rep = rep.filter(x=>x.device==this.filter_device)
      }
      if(this.filterInvalid){
        rep = rep.filter(x=>x.form_status_global > 9)
      }
      if(this.filterConflict){
        rep = rep.filter(x=>x.hasConflicts === true)
      }
      return rep
    },
    form_id_settings(){
      //useful for the watch to know if it has change.
      if(this.ready){
        return this.$store.state.form.form_id + this.isLocal.toString()
      }
    },
    device_list(){
      if(this.surveys){
        let listAll=this.surveys.map(x=>x.device)
        let list2=listAll.filter((v, i, a) => a.indexOf(v) === i)
        if(this.devices){
          list2=list2.map(x=>{
            if(this.devices.hasOwnProperty(x)){
              return {value:x,text:this.devices[x].label}
            }else{
              return {value:x,text:x}
            }
          })
          list2.sort((a,b)=>a.text>b.text?1:-1) // order them so easier to find
        }
        list2.push(null)
        return list2
      }
    },
    dateFields(){
      return [
        { key: 'action', label: 'Actions' },
        { key: 'key',label:this.labels2.fields[this.$store.getters['form/editGroupType']],sortable: true},
        { key: 'value',label:'Nb',sortable: true},
        // A virtual column made up from two fields
      ]
    },
    editFields(){
      if(this.$store.getters['form/editListFields']){
        let fields=this.$store.getters['form/editListFields'].map(x=>{
          if(Array.isArray(x)){
            return x[x.length-1]
          }else{
            return x
          }
        })
        return fields
      }
      return []
    },
    fields(){
      if(this.formDefinition){
        let fields=this.editFields.map(x=>{
          if(this.labels2.fields.hasOwnProperty(x)){
            //system field
            return {key:x, sortable:true, label:this.labels2.fields[x],sys:true}
          }else{
            //form fields
            return {
              key:x,
              sortable:true,
              label: this.$store.getters['form/editListFieldsForm'].filter(y=>y.name==x)[0].label ,
              sys:false
            }
          }
        })
        if(this.formDefinition.edit_sys_fields){
          const fi2=this.formDefinition.edit_sys_fields.split(',').map(x=>x.trim())
          fields=fields.filter(fi=>fi2.indexOf(fi.key)>-1 || fi.sys==false)
        }else{
          fields=fields.filter((fi,i)=>['creationTime','creator','device_id'].indexOf(fi.key)>-1 || fi.sys==false)
        }
        return [{ key: 'action', label: this.labels2.fields.action }].concat(fields)
      }
    },
    hasSearchFields(){
      if (this.$store.getters['form/editListFieldsForm'] && this.$store.getters['form/editListFieldsForm'].length > 0){
        return true
      }
      return false
    },
    hasToGetAllSurveys(){
      return !this.curDate && this.searchText && this.searchText.length > 1
    }
  },
  asyncComputed:{
    ready(){
      // ensure every thing is loaded before render.
      return this.fetchFormPromise.then(rep=>true)
    },
    devices(){
      if(!this.ready || !this.$store.state.dbDatabaseSystem){return}
      return this.$store.state.dbDatabaseSystem.allDocs({
        include_docs:true,
        startkey:'device_',
        endkey:'device_\ufff0'
      }).then(docs=>{
        let rep={}
        docs.rows.map(row=>{
          let doc=row.doc
          if(doc.deviceNumber && doc.deviceName){
            doc.label=doc.deviceNumber + ' - ' + doc.deviceName
          }else if(doc.deviceNumber){
            doc.label=doc.deviceNumber
          }else if(doc.deviceName){
            doc.label=doc.deviceName
          }
          rep[doc._id]=doc
        })
        return rep
      })
    },
  },
  watch:{
    form_id_settings(){
      // this.curDate=null
      this.searchText=null
      this.allSurveys=null
      this.getDates()
    },
    curDate:{
      handler(){
        this.surveys=null
        this.dates=null
        this.searchText=null
        this.filterInvalid= false
        this.filterConflict= false
        
        this.fetchFormPromise.then(()=>{
          if(this.curDate){
            this.fetchSurveys()
          }else{
            this.getDates()
          }
        })
      },
      immediate:true
    },
    searchText(){
      if(!this.curDate){
        this.surveys = null
      }
      if(this.hasToGetAllSurveys){
        this.doSearch()
      }
    },
  },
  methods:{
    fetchSurveys(){
      if(this.curDate){
        let prom1 = Promise.resolve()
        // TODO: not working yet
        // if(this.curDate=='all'){
        //   prom1 = this.getAllSurveys()
        // }else{
          prom1 = this.$store.dispatch('form/fetchSurveysDate', this.curDate)
        // }
        prom1.then(alldocs=>{
          //console.log(alldocs)
          this.surveys=alldocs.map(x=>{
            // let x1={_id:x.id,"editor_time":x.value[0],device:x.value[1]}
            let x1={_id:x.id}
            this.editFields.map((y,i)=>{
              x1[y]=x.value[i]
            })
            x1.hasConflicts = x.value[x.value.length - 1]
            return x1
          })
          this.filter_device=null //reset
        }).catch(err=>this.$store.dispatch('app_message_error',err))
      }
    },
    getDates(){
      //this.fetchForm(this.form_id) // we override the base, we will have to call fetchForm

      return this.$store.dispatch('form/fetchSurveysDates').then(allDates=>{
        this.dates=allDates.sort((a,b)=>b.key.localeCompare(a.key))
        return Promise.resolve()
      }).then(()=>{
        // TODO: remove this, as it's a temporary solution for an index problem...
        // console.warn('ici1');
        if (this.tryDates==0 && this.dates.length==0){
          console.warn('va re créer index doc');
          this.tryDates=1
          // return this.$store.dispatch('form/createDesignDocs').then(()=>this.getDates())
          return couchDbUtils.resolveConflictWithLatest(this.$store.state.form.db, '_design/editList').then(()=>this.getDates())
        }
        return Promise.resolve()
      }).catch(err=>this.$store.dispatch('app_message_error',err))
    },
    deleteSurveys(key){
      this.$store.dispatch('form/fetchSurveysDateWithDocs', key).then(alldocs=>{
        console.log(alldocs)
        let docs=alldocs.map(x=>{
          x.doc.survey_deleted=true
          return x.doc
        })
        return this.$store.dispatch('form/saveSurveys', docs)
      }).then(rep=>{
        this.getDates()
      }).catch(err=>this.$store.dispatch('app_message_error',err))
    },
    deleteSurvey(id){
      this.$store.dispatch('form/fetchSurvey', {survey_id: id}).then(doc=>{
        doc.survey_deleted=true
        return this.$store.dispatch('form/saveSurvey', doc)
      }).then(rep=>{
        this.fetchSurveys()
        this.getDates() // as dates may have disapeared or just number changed.
      }).catch(err=>this.$store.dispatch('app_message_error',err))
    },
    // select(key, val){
    //   if (val){
    //     this.selected.push(key)
    //   }else{
    //     this.selected.splice(this.selected.indexOf(key))
    //   }
      
    // },
    // selectAll(){
    //   if(this.curDate){
    //     this.selected = this.surveys_filtered.map(x => x._id)
    //   }else{
    //     this.selected = this.dates.map(x => x.key)
    //   }
    // },
    getAllSurveys(){
      if (this.allSurveys){
        return Promise.resolve( this.allSurveys )
      }
      return this.$store.dispatch('form/fetchSurveysView').then(alldocs=>{
        //console.log(alldocs)
        this.allSurveys = alldocs.map(x=>{
          // let x1={_id:x.id,"editor_time":x.value[0],device:x.value[1]}
          let x1={_id:x.id}
          this.editFields.map((y,i)=>{
            x1[y]=x.value[i]
          })
          x1.hasConflicts = x.value[x.value.length - 1]
          return x1
        })
        return this.allSurveys
      }).catch(err=>this.$store.dispatch('app_message_error',err))
    },
    doSearch(){
      this.getAllSurveys().then(()=>{
          const fis = this.fields.filter(y=>!y.sys && y.key!='action')
          this.surveys = this.allSurveys.filter(x=>{
            for (let x2 = 0; x2 < fis.length; x2++) {
              if (x[fis[x2].key] && x[fis[x2].key].toString().indexOf(this.searchText)!=-1){
                return true
              }
            }
            return false
          })
        })
    },
  },
}
</script>

<style scoped>
/* https://stackoverflow.com/a/2204687/140384 */
.no-link{
  text-decoration: none;
  color:inherit;
  
}
.no-link:hover {
  color: inherit;
}
.my-conflicts {
  font-size: 1.6rem;
  vertical-align: middle;
}
.date-one-line{
  white-space: nowrap;
}
</style>

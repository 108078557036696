<template>
  <div v-if="false">
    {{value}}
  </div>
</template>

<script>
// XLSform definition: A field with no associated UI element which can be used to store a constant
// we can use it also to store a calcutation we don't want to show to the user.
import base from './_baseInput.js'

export default {
  name:'i-hidden',
  mixins:[base],
}
</script>

<template>
  <!-- https://codepen.io/ace-subido/pen/qELGdd -->
  <!-- see also if want more options: https://www.w3schools.com/howto/howto_css_circles.asp -->
  <span :class="myClass" :style="style"></span>
</template>

<script>


export default {
  props:['variant','size','color'],
  computed:{
    myClass(){
      let obj={dot:true}
      if(this.variant){
        obj['dot-'+this.variant]=true
        // success danger warning
      }
      if(this.size){
        obj['dot-'+this.size]=true
        // lg sm
      }
      return obj
    },
    style(){
      if(this.color){
        return {
          'background-color':this.color
        }
      }
      return {}
    }
  }
}
</script>

<style scoped>
body {
	padding: 40px;
}

.dots {
	margin: 40px auto;
}

.dot {
	display: inline-block;
	border-radius: 50%;
	width: 50%;
	background-color: #EEE;
	height: 15px;
	width: 15px;
	margin: 0 2px;
}

.dot.dot-success {
	background-color: #449D44;
}

.dot.dot-warning {
	background-color: #F0AD4E;
}

.dot.dot-danger {
	background-color: #D9534F;
}

.dot.dot-sm {
	height: 10px;
	width: 10px;
}

.dot.dot-lg {
	margin: 0 4px;
	height: 20px;
	width: 20px;
}
</style>

<template>
  <input-row 
    :showElement="showElement"
    :label="config.label_no" 
    :elId="elId" 
    :status="status" 
    :config="config"
    :me="me"
  >
    <div>
      <div v-html="config.label"></div>
      <div>
        <b-form-checkbox
          v-model="myValue"
          :disabled="readonly"
          name="checkbox-1"
          value="OK"
          :unchecked-value="null"
        >
          Ok
        </b-form-checkbox>
      </div>
    </div>
  </input-row>
</template>

<script>
import base from './_baseInput.js'

export default {
  name:'i-acknowledge',
  mixins:[base],
  computed:{
    showText(){
      if(this.calculation){
        return this.label.replace('$calculated',this.calculated) 
      }
      return this.label
    }
  },
  watch:{
    showText(){
      //we want to write to the form data, the message the user saw.
      // TODO: do this or not.... if long message or static, not... if short ok. Should be a param.
      // this.myValue=this.showText
    }
  }
}
</script>

<style>

</style>

<!-- Button that allow to click twice to delete 

import deleteButton from '../../components/utils/deleteButton.vue'
components: {
    deleteButton
  },

<delete-button
  myclass="ml-2" 
  v-if="$store.getters.isAdmin"
  @delete="deleteSurveys(data.item.key)" 
></delete-button>

-->
<template>
  <b-button 
    variant="danger" 
    :class="myclass"
    @click="deleteClick"
    :size="size"
  ><slot v-if="isDeleting===false"><span class="glyphicon glyphicon-remove" :class="btnclass"></span></slot>
  <slot name="confirm" v-if="isDeleting===true"><span class="glyphicon glyphicon-warning-sign" :class="btnclass"></span></slot></b-button>
</template>

<script>
  import cuid from 'cuid'

  export default {
    name: 'delete-button',
    props:['myclass','size','btnclass'],
    data() {
      return {
        id:cuid()
      }
    },
    computed:{
      cuidIsDeleting(){
        return this.$store.state.cuidIsDeleting
      },
      isDeleting(){ // the button has been pressed once if true
        return this.id==this.cuidIsDeleting
      }
    },
    methods:{
      deleteClick(){
        if (this.isDeleting===false){
          this.$store.commit('cuidIsDeleting', this.id)
        }else{
          this.$store.commit('cuidIsDeleting', null)
          this.$emit('delete')
        }
      }
    }
  }
</script>

<style scoped>

</style>
<!-- Components that display a button to open page to display add button to all project forms -->

<template>
  <div v-if="ok" class="mt-1" >
    <b-btn class="" variant="primary" @click="displayAddForm">
      <span class="glyphicon glyphicon-plus"></span>
      <!-- <span class="ml-4 glyphicon glyphicon-file"></span> -->
    </b-btn>
  </div>
</template>

<script>
import base from './_base.js'

  export default {
  name:'project-add-survey',
  mixins:[base],
  computed:{
    ok(){
      if (this.map_config && this.map_config.my_controls && this.map_config.my_controls.projectAddSurvey===true){
        return true
      }
      return false
    }
  },
  methods: {
    displayAddForm() {
      this.$router.push('/projectAddSurvey/'+this.$store.state.project.project_id)
    }
  }, 
  }
</script>

<style scoped>

</style>
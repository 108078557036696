//http://stackoverflow.com/a/42202802/140384
//and
//https://github.com/chrisvfritz/7-secret-patterns
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

const files = require.context('.', false, /\.js$/)
const modules = {}
files.keys().forEach((fileName) => {
  const componentObj=files(fileName).default || files(fileName)
  modules[ componentObj.name ] = componentObj
})
export default modules

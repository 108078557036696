<template>
  <div v-if="showElement">
    <b-row class="my-1">
      <b-col sm="12">
        <div>
          <b-alert show :variant="alertVariant" >
            <div class="row" :class="{'py-3': status>=10}">
              <i v-if="status>=10" class="glyphicon glyphicon-warning-sign text-danger my-icon mx-3"></i>
              <span v-html="showText"></span>
              <b-btn pill variant="info" size="sm" :href="popupUrl" v-if="popup_aecom && popupUrl" target="_blank" class="ml-2"><span class="glyphicon glyphicon-question-sign"></span></b-btn>
            </div>
          </b-alert>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import base from './_baseInput.js'
  import popup from './subInput/mixin_popup.js'


export default {
  name:'i-note',
  mixins:[base, popup],
  computed:{
    showText(){
      if(this.calculation){
        return this.label.replace('$calculated',this.calculated) 
      }
      return this.label
    },
    alertVariant(){
      if(this.appearance){
        return this.appearance
      }else{
        return 'dark'
      }
    },
  },
  watch:{
    showText(){
      //we want to write to the form data, the message the user saw.
      // TODO: do this or not.... if long message or static, not... if short ok. Should be a param.
      // this.myValue=this.showText
    }
  }
}
</script>

<style scoped>
  .my-icon{
    font-size: 41px;
  }
</style>


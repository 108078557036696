
// ******** Bénin SGESSS

export default{
  name:'benin',
  documentTitle:'SGESSS Formulaires - Bénin',
  navbarTitle:'SGESSS - Bénin',//not used for now
  couchdbUrls: 'https://couchdb.sgesss.org/',
  databaseSystem:'forms_system',
  pouhdb_replicate_batch_size:5, //default to 100, but crash in browser with photos.
  serial_position_active:false,
  inputGeopointDirectGpsClickAllowed:false,
}

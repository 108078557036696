<template>
  <div>
    <b-form-group :label="labels.syncOptionsTitle" class="mt-3">
      <b-form-radio-group v-model="selected"
                  :options="syncOptions"
                  stacked
                  name="radiosStacked">
      </b-form-radio-group>
    </b-form-group>
  </div>
</template>

<script>
  const syncOptions0=[
    {id:'1',value:{batches_limit:1,batch_size:1,timeout:1000*60*10}},
    {id:'2',value:{batches_limit:4,batch_size:20,timeout:1000*60*3}},
    {id:'3',value:{batches_limit:10,batch_size:50,timeout:1000*60*3}},
    {id:'4',value:{batches_limit:10,batch_size:150,timeout:1000*60*3}},
    // {id:'5',value:{batches_limit:10,batch_size:50,timeout:1000*60*3}},
  ]
  export default {
    name:'syncOptions',
    props:['value'],
    data() {
      return {
        selected:syncOptions0[2].value, // with default.
      }
    },
    computed: {
      labels(){return this.$store.state.labels.form.sync},
      syncOptions(){
        // to get the translation text for each option.
        return syncOptions0.map(x=>{
          x.text=this.labels.syncOptions[x.id]
          return x
        })
      }
    },
    watch: {
      selected(newValue, oldValue) {
        this.$emit('input', newValue)
      },
      value:{
        handler(){
          if(this.value){
            this.selected=this.value
          }else{
            //support for default value
            this.$emit('input', this.selected)
          }
        },
        immediate:true
      },
    },
  }
</script>

<style scoped>

</style>
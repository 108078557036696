<template>
  <li @click="clickOn" class="list-group-item">
      <div class="radio">
          <input type="radio" name="baselayersradio" :checked="visible">
          <label class="text-left" for="radio1">{{label}}</label>
      </div>
  </li>
</template>

<script>

export default{
  props:['layer_id'],
  computed:{
    label(){return this.$store.getters['map/'+this.layer_id+'/label']},
    visible(){return this.$store.getters['map/'+this.layer_id+'/visible']}
  },
  methods:{
    clickOn(){
      //set this base layer as visible
      this.$store.dispatch('map/setBaseLayer',this.layer_id)
    }
  }
}

</script>

<style scoped>
/*https://bootsnipp.com/snippets/ZkMKE*/

.radio {
    padding-left: 20px;
}

.radio label {
    display: inline-block;
    position: relative;
    padding-left: 5px;
}

.radio label::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 17px;
    height: 17px;
    left: 0;
    margin-left: -20px;
    border: 1px solid #cccccc;
    border-radius: 50%;
    background-color: #fff;
    -webkit-transition: border 0.15s ease-in-out;
    -o-transition: border 0.15s ease-in-out;
    transition: border 0.15s ease-in-out;
}

.radio label::after {
    display: inline-block;
    position: absolute;
    content: " ";
    width: 11px;
    height: 11px;
    left: 3px;
    top: 3px;
    margin-left: -20px;
    border-radius: 50%;
    background-color: #555555;
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
    -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
}

.radio input[type="radio"] {
    opacity: 0;
}

.radio input[type="radio"]:focus+label::before {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

.radio input[type="radio"]:checked+label::after {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
}

.radio input[type="radio"]:disabled+label {
    opacity: 0.65;
}

.radio input[type="radio"]:disabled+label::before {
    cursor: not-allowed;
}

.radio.radio-inline {
    margin-top: 0;
}


</style>

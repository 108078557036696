import axios from "axios";

const axiosForms = axios.create({
    // baseURL: "https://api.formulaires.esoc.ci"
    baseURL: ""
});
axiosForms.defaults.headers.common = {};

const axiosGraphql = (that, query, queryIsComplete = false) => {
    that.loading = true;
    that.graphql_error = null;
    let sendQuery = null;
    if (queryIsComplete) {
        // when we use variables, better that way.
        sendQuery = query;
    } else {
        sendQuery = { query: query }; // when just a string, easier that way.
    }
    return axiosForms.post( that.$store.state.settings.tableStatsViews[that.project_id].apiUrl + "/graphql", sendQuery, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem('user-token')
            }
        })
        .then(response => {
            if (response.data.errors) {
                // we have graphql errors. throw exeption
                throw response.data.errors;
            }
            that.loading = false;
            return Promise.resolve(response.data.data);
        })
        .catch(error => {
            this.$store.dispatch('app_message_error',err)
            that.graphql_error = error;
            throw error;
        });
};

export { axiosGraphql };

<template>
  <div v-if="data" class="m-4">
    <div class="h2 mb-4">Liste des tables du PAR</div>
    <!-- <b-list-group>
      <b-list-group-item v-for="t in data2" :key="t.tableName">{{t.label}}</b-list-group-item>
    </b-list-group> -->
    <div class="ml-3">
      <tree v-model="selected" :nodes="data2" :options="treeOpts"></tree>
    </div>

    <div v-if="selected.length==1" class="mt-3">
      <b-btn :to="'/project/'+ this.project_id + '/table/' + selected[0]" variant="primary">Voir table</b-btn>
    </div>
    
    <!-- <div class="mt-5">
      {{data}}
    </div> -->


  </div>
</template>

<script>
import {axiosGraphql} from '../utils.js'
import tree from '../components/tree.vue'

  export default {
    name:'form-tables',
    components:{
      tree,
    },
    props: ['project_id'],
    data() {
      return {
        selected: [],
      }
    },
    computed: {
      data2() {
        const data = this.data.allParTables.nodes
        const rep = data.filter(x=>x.tableName == x.parent)
        const popSubTables = (tables, allTables)=>{
          tables.map(y=>{
            y.children = allTables.filter(x=> (x.parent == y.tableName || !x.parent) && y.tableName != x.tableName)
            console.log(y.children);
            popSubTables(y.children,allTables)
          })
        }
        popSubTables(rep,data)
        return rep
      },
      treeOpts(){
        return {
          keyAttributeName: 'tableName',
          titleAttributeName: 'label',
          nbLevelExpanded: 1,
          caretHiddenNoChildren: true,
        }
      },
    },
    asyncComputed:{
      data(){
        return axiosGraphql(this, query)
      },
    },
  }
const query=`
query{
  allParTables{
    nodes{
      tableName
      parent
      label
    }
  }
}
`

</script>

<style scoped>

</style>
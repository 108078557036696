<template>
  <!-- This represent the base for all(almost) input visual. In a component to avoid reapeat code and to have easier option to add functionalities  -->
  <div v-if="showElement" v-show="appearance!='hidden'" :class="{'bg-secondary': viewMode3Active, 'small': viewMode3Active}" class="my-3">
    <b-row class="" v-if="force_label_own_line==false && viewMode2Active==false">
      <!-- Primary option for display -->
      <b-col sm="3" v-b-hover="handleHoverLabelCol">
        <my-label :label="label" :elId="elId" :status="status" :config="config" :classes="labelClasses"></my-label>
        <b-btn-group v-if="$store.state.allowCopyPaste" class="ml-2">
          <b-btn @click="me.copyValToClipboard()" size="sm"><i class="glyphicon glyphicon-copy"></i></b-btn>
          <b-btn @click="me.getValFromClipboard()" size="sm" v-if="!me.readonly"><i class="glyphicon glyphicon-paste"></i></b-btn>
        </b-btn-group>
        <!-- Sub label slot -->
        <slot name="sublabel"></slot>
        <div v-if="me.info && $store.state.form.formDisplayOptions.notePosition=='below-question'" class="ml-2 text-secondary info-text">
          <i class="mdi mdi-information-outline mr-1"></i>
          <span v-html="me.info"></span>
        </div>
        <div v-if="hoverLabelCol">
          <inputRowFooterFlag2 :me="me"></inputRowFooterFlag2>
        </div>
      </b-col>
      <b-col sm="9">
        <input-row-header :me="me" class="mb-2"></input-row-header>
        <!--  insert the default slot -->
        <slot></slot>
        <input-row-footer :me="me"></input-row-footer>
      </b-col>
    </b-row>
    <!-- Second option for display -->
    <b-row class="" v-if="force_label_own_line && viewMode2Active==false">
      <b-col sm="12" >
        <div v-b-hover="handleHoverLabelCol">
          <my-label :label="label" :elId="elId" :status="status" :config="config" :classes="labelClasses"></my-label>
          <slot name="sublabel"></slot>
          <div v-if="me.info && $store.state.form.formDisplayOptions.notePosition=='below-question'" class="ml-2 text-secondary info-text">
            <i class="mdi mdi-information-outline mr-1"></i>
            <span v-html="me.info"></span>
          </div>
          <div v-if="hoverLabelCol">
            <inputRowFooterFlag2 :me="me"></inputRowFooterFlag2>
          </div>
        </div>
        <div class="ml-5">
          <input-row-header :me="me" class="mb-2"></input-row-header>
          <slot></slot>
          <input-row-footer :me="me"></input-row-footer>
        </div>
      </b-col>
    </b-row>
    <div v-if="viewMode2Active"><i class="fas fa-eye-slash small"></i><span class="ml-2 text-secondary small">{{label}}</span></div>
    <!-- slot after the row -->
    <slot name="afterRow"></slot>
  </div>
</template>

<script>
  import myLabel from './subInput/label.vue'
  import inputRowFooter from './_inputRowFooter.vue'
  import inputRowHeader from './_inputRowHeader.vue'
  import fieldViewUtils from '../../utils/fieldViewUtils'
  import inputRowFooterFlag2 from './_inputRowFooterFlag2.vue'

  export default {
    name:'InputRow',
    props:['showElement', 'label', 'elId', 'status', 'config', 'me'],
    components:{
      myLabel, 
      inputRowHeader,
      inputRowFooter,
      inputRowFooterFlag2,
    },
    data() {
      return {
        hoverLabelCol: false,
      }
    },
    computed: {
      appearance(){
        if(this.config.hasOwnProperty('control')){
          if(this.config.control.hasOwnProperty('appearance')){
            return this.config.control.appearance
          }
        }
        return null
      },
      // "appearance_aecom": "force_label_own_line",
      force_label_own_line(){
        //return an array of the options - separated by ";" in xlsx file
        if(this.config.hasOwnProperty('appearance_aecom')){
          return this.config.appearance_aecom.split(';').indexOf('force_label_own_line') != -1
        }
        return false
      },
      labelClasses(){
        const rep = []
        if(this.config.hasOwnProperty('appearance_aecom')){
          if(this.config.appearance_aecom.split(';').indexOf('label_bold') != -1){
            rep.push('font-weight-bold')
          }
        }
        if (this.force_label_own_line){
          rep.push('mt-2')
        }
        return rep
      },
      viewMode3Active(){
        return fieldViewUtils.isHiddenInViewMode(3, this)
      },
      viewMode2Active(){
        return fieldViewUtils.isHiddenInViewMode(2, this)
      },
    },
    methods: {
      handleHoverLabelCol(hovered) {
        this.hoverLabelCol = hovered
      }
    },
  }
</script>

<style scoped>
  .info-text{
    /* font-size: 0.8em; */
    color: #959575;
  }
</style>
<template>
  <input-row 
    :showElement="showElement"
    :label="label" 
    :elId="elId" 
    :status="status" 
    :config="config"
    :me="me"
  >
    <b-input-group>
      <tempalte v-if="inputMask">
        <b-form-input-mask :id="elId" :mask="inputMask" v-model="myValue2" :readonly="readonly"></b-form-input-mask>
      </tempalte>
      <template v-else-if="inputMaskMoney">
        <!-- the v-money don't support well the null values -->
        <!-- TODO: remove from here, b-form-input-mask and package.json -->
        <!-- <b-form-input-mask :id="elId" :money="inputMaskMoney" v-model="myValue2" :readonly="readonly"></b-form-input-mask>  -->
        <b-form-input-mask :id="elId" :autonumeric="autonumeric" v-model="myValue2" :readonly="readonly"></b-form-input-mask> 
      </template>
      <template v-else>
        <b-form-input inputmode="numeric" :id="elId" type="number" v-model="myValue2" :readonly="readonly"></b-form-input>
      </template>
      <b-input-group-append>
        <b-btn class="mdi mdi-close" variant="" size="sm" @click="elId=elId+'1';myValue2=null" v-if="!readonly"></b-btn>  
      </b-input-group-append>
    </b-input-group>
  </input-row>
</template>

<script>
import base from './_baseInput.js'
import utils from '../../utils/utils_gen.js'
import BFormInputMask from './subInput/BFormInputMask.vue'

export default {
  name:'IDecimal',
  mixins:[base],
  components:{
    BFormInputMask,
  },
  data() {
    return {
      myValue2: null,
    }
  },
  computed:{
    myValue2_asNumber(){
      if(this.myValue2===null || this.myValue2==''){ // support for null value, unless when a value and no more a value, it's 0... we want nulll.
        return null
      }
      let val3=this.myValue2 //.replace(',','.')
      // console.log(val3);
      // const regex = /(-?[0-9\\.]+)/g
      // let n1 = ''
      // let m =null
      // while ((m = regex.exec(val3)) !== null) {
      //   // This is necessary to avoid infinite loops with zero-width matches
      //   if (m.index === regex.lastIndex) {
      //       regex.lastIndex++;
      //   }
      //   n1 += m[0]
      //   // The result can be accessed through the `m`-variable.
      // }
      // val3 = n1
      // console.log(val3);
      // https://stackoverflow.com/a/44568739/140384
      // let t=0;   
      // val3 = val3.replace(/\./g, function (match) {
      //   t++
      //   return (t >1) ? "" : match
      // })
      // console.log(val3);
      
      if(this.config.type=='integer'){
        return Math.round(+val3)
      }else{
        return +val3
      }
    },
    inputMask(){
      // https://github.com/vuejs-tips/vue-the-mask
      if(this.config.input_mask && this.config.input_mask.indexOf('money')==-1){
        return utils.replaceAll( this.config.input_mask,'9','#')
      }
      return null
    },
    inputMaskMoney(){
      // https://github.com/vuejs-tips/v-money/tree/master
      // We could also check the  http://autonumeric.org/ library
      if(this.config.input_mask && this.config.input_mask.indexOf('money')!=-1){
        const maksText = this.config.input_mask.split('@')
        return {
          decimal: maksText[1],
          thousands: maksText[2],
          prefix: maksText[3],
          suffix: maksText[4],
          precision: +maksText[5],
          masked: false
        }
      }
      return null
    },
    autonumeric(){
      if(this.config.input_mask && this.config.input_mask.indexOf('money')!=-1){
        return {
          decimalPlaces: this.inputMaskMoney.precision,
          decimalCharacter: this.inputMaskMoney.decimal,
          digitGroupSeparator: this.inputMaskMoney.thousands,
          currencySymbol: this.inputMaskMoney.prefix || this.inputMaskMoney.suffix,
          currencySymbolPlacement: this.inputMaskMoney.prefix?'p':'s',
          unformatOnSubmit: true,
          // decimalCharacterAlternative: '.',
          // roundingMethod: 'U',
          // minimumValue: '0',
          //
        }
      }
    }
  },
  watch:{
    //We do this to save the value as a number and not as a string as the input return it.
    myValue2(){
      this.myValue=this.myValue2_asNumber
    },
    value:{
      handler(){
        if(this.value===0 || this.value){
          if(this.myValue2_asNumber != this.value){ // if equal don't update, as we are probably in the process of typeyng 50.0003 and don't want to loose the .00
            this.myValue2=this.value.toString()
          }
        }else{
          this.myValue2=null
        }
      },
      immediate:true
    },
  },
  methods: {
    
  },
}
</script>

<style>

</style>

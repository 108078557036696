<template>
  <div >
    <template v-if="!form">
      <b-row class="my-1" v-for="type in fields" :key="type.name">
        <b-col sm="3">
          <label :for="`type-${type.name}`">
            {{ type.label }}:
          </label>
        </b-col>
        <b-col sm="9 py-1">
          <b-form-input v-if="type.type!='textarea'" :id="`type-${type.name}`"
            :type="type.type" v-model="type.value">
          </b-form-input>
          <b-form-textarea  v-if="type.type=='textarea'" :id="`type-${type.name}`"
            v-model="type.value" :rows="4">
          </b-form-textarea >
        </b-col>
      </b-row>  
    </template>
    <b-row>
      <b-col>
        <b-form-file v-model="file" :state="Boolean(file)"
          accept=".json"
          :placeholder="labels.forms.uploadXmlFile"></b-form-file>
      </b-col>
    </b-row>
    <b-row>
      <b-button :disabled="!isComplete"
        @click="saveNewForm" size="sm" variant="primary" class="my-3 ml-5" v-if="!form">
          {{labels.save}}
      </b-button>
      <b-button :disabled="!isComplete"
        @click="newDefinition" size="sm" variant="primary" class="my-3 ml-5" v-if="form">
          {{labels.save}}
      </b-button>
      <b-button @click="$emit('end')" size="sm" variant="" class="my-3 mx-2">
          {{labels.cancel}}
      </b-button>
    </b-row>
  </div>
</template>

<script>
import cuid from 'cuid'
import form_utils from '@/utils/form_utils'

export default {
  name:'addForm',
  props:['project','form'],
  data(){
    let labels=this.$store.state.labels
    let fields=[
      {name:"form_no",label:labels.forms.no,type:'text'},
      {name:"form_label",label:labels.forms.label,type:'text'},
      {name:"form_comments",label:labels.forms.comments,type:'textarea'}
    ]
    let obj1={}
    obj1.fields=fields
    fields.map(x=>x.value='')
    obj1.file=null
    obj1.fileContent=null
    return obj1
  },
  computed: {
    systemDoc(){return this.$store.state.systemDoc},
    labels(){return this.$store.state.labels},
    isComplete(){
      if ((!this.form) && this.fields[0].value &&
        this.fields[1].value &&
        this.file && this.fileContent){
        return true
      }else if(this.form && this.file && this.fileContent){
        return true
      }
      return false
    }
  },
  watch:{
    file(){
      this.readFile()
    },
    fileContent(){
      //we want to get the title from the xls if not already populated
      if(this.fields[1].value==='' && this.fileContent.title){
        this.fields[1].value=this.fileContent.title
      }
    }
  },
  methods:{
    saveNewForm(){
      let db=this.$store.state.dbDatabaseSystem
      const newDbId='form_'+ cuid()
      const newDbName=this.$store.state.couchdbUrl+newDbId
      let newFormDb = new this.$PouchDB(newDbName, this.$store.getters.pouchDbOptions)
      let obj1={
        id:newDbId,
        creator:this.$store.getters['userName'],
        creationTime:this.$store.getters['utcCurrentTime'](),
        project_id:this.project.id
      }
      this.fields.map(x=>obj1[x.name]=x.value)
      obj1.label=obj1.form_no + ' - ' + obj1.form_label
      let obj2=Object.assign({},obj1)
      //create a definition object
      // form_definition, is another object as it could be updated,
      //so we want to keep each version in separate doc.
      let obj3={}
      obj3._id='form_definition_'+ cuid()
      //config must have the form definition
      obj2.form_definitions=[obj3._id]
      obj3.form_definition=this.fileContent
      return newFormDb.put(obj3).then(()=>{
        console.log(newFormDb);
        // newFormDb.fetch=newFormDb._ajax
        return newFormDb.security().fetch()
      }).then(()=>{
        const security = newFormDb.security()  
        security.admins.roles.add(this.$store.state.settings.new_db_couchdb_security_admins)
        security.members.roles.add(this.$store.state.settings.new_db_couchdb_security_users)
        return security.save()
      }).then(()=>{
        //create a new form config object
        obj2._id='form_config'
        return newFormDb.put(obj2)
      }).then(rep=>{
        //if ok, we add our form to the current project in global config
        return db.get('config')
      }).then(doc=>{
        // witht the current doc push new form
        doc.projects.filter(x=>x.id==this.project.id)[0].forms.push(obj1)
        return db.put(doc)
      }).then(res=>{
        // if all ok, then end and reread the config doc to update the UI
        return this.$store.dispatch('getSystemDoc')
      }).then(rep=>{
        return this.processNewDefinition(newDbId)
      }).then(rep=>{
        // if sqlApi available, create the record in since_checkpoint
        if(this.$store.getters['sqlApi/isAuthenticated']){
          return this.$store.state.sqlApi.axiosInstance.post('',{query:`mutation {createSinceCheckpoint(input: {sinceCheckpoint: {pgtable: "${newDbId}", enabled: ${true}}}){sinceCheckpoint{pgtable}}}`})
        }else{
          return Promise.resolve()
        }
      }).then(rep=>{
        // if all ok, then end and reread the config doc to update the UI
        return this.$store.dispatch('replicateDB',newDbId)
      }).then(rep=>{        
        this.$emit('end')
      }).catch(err=>this.$store.dispatch('app_message_error',err))

    },
    readFile(){
      return new Promise((resolve, reject) => {
        var fr = new FileReader();
        //fr.onload =  resolve;  // CHANGE to whatever function you want which would eventually call resolve
        fr.onload = e => {
            this.fileContent = JSON.parse(fr.result);
            //console.log(this.fileContent)
            resolve(true)
        }
        fr.readAsText(this.file);
      })
    },
    newDefinition(){
      const dbForm = new this.$PouchDB(this.$store.state.couchdbUrl+this.form.id, this.$store.getters.pouchDbOptions)
      let obj3={}
      obj3._id='form_definition_'+ cuid()
      obj3.form_definition=this.fileContent
      return dbForm.put(obj3).then(rep=>{
        //create a new form config object
        return dbForm.get('form_config')
      }).then(doc=>{
        // insert the form definition to the form config
        doc.form_definitions.push(obj3._id)
        return dbForm.put(doc)
      }).then(rep=>{
        return this.processNewDefinition(this.form.id)
      }).then(rep=>{        
        this.$emit('end')
      }).catch(err=>this.$store.dispatch('app_message_error',err))
    },
    processNewDefinition(form_id){
      return this.$store.dispatch('form/setActualForm',form_id).then(rep=>{//for next step
        return this.$store.dispatch('form/getActualConfig',form_id)//for next step
      }).then(rep=>{
        return this.$store.dispatch('form/getActualFormDefition')//for next step
      }).then(rep=>{
        // we also want to create the default design docs - update if necessary
        return this.$store.dispatch('form/createDesignDocs') 
      }).then(rep=>{
        // verify if there are popup documents and sync accordingly
        const popList = []
        const getPopupsDocs = (field,rep2)=>{
          if(field.popup_aecom){
            const pop = field.popup_aecom.split(':')
            if(rep2.indexOf(pop[0])==-1){
              rep2.push(pop[0])
            }
          }
        }
        form_utils.genericLoopFunction(
          this.$store.getters['form/actualFormDefition'],
          getPopupsDocs,
          popList
        )
        if (popList.length > 0){
          // add to the formconfig
          return this.$store.state.form.remoteDb.get('form_config').then(doc=>{
            // insert the form definition to the form config
            if (!doc.always_sync_docs){
              doc.always_sync_docs = []
            }
            const addPopPromises = []
            popList.map(x=>{
              if(doc.always_sync_docs.indexOf(x)==-1){
                doc.always_sync_docs.push(x)
                // if a new one, we need to check if exists in the main form system DB and them copy if necessary
                //  for now, we don't take into acccount that the doc could change... to the user to give a new doc_id. And at some point it's consistent as the doc don't change and can still be attached to an old form_definition.
                addPopPromises.push(
                  this.$store.state.dbDatabaseSystem.get(x,{attachments:true}).then(docPop=>{
                    delete docPop._rev
                    return this.$store.state.form.remoteDb.put(docPop)
                  }).catch(()=>Promise.resolve()) // if document don't exists, just continue
                )
              }
            })
            return Promise.all( addPopPromises.concat(this.$store.state.form.remoteDb.put(doc)))
          })
        }else{
          return Promise.resolve()
        }
      })
    }
  }
}
</script>

<style >

</style>

<!-- component to show the distance and inside/outside a polygon -- GPS position -->

<template>
  <div v-if="ok && in_polygon!==undefined">
    <b-btn class="mt-1" :variant="in_polygon?'dark':'warning'"><svg-polygon width="1.5em" height="1.5em"></svg-polygon> {{polygon_perimeter_distance}}</b-btn>
  </div>
</template>

<script>

// ****** For this to work easily, we use the jsts library
// https://github.com/bjornharrtell/jsts
// http://bjornharrtell.github.io/jsts/1.6.1/doc/module-org_locationtech_jts_io_GeoJSONReader.html
// import jsts from 'jsts'
// #var reader = new jsts.io.WKTReader()
// import WKTReader from 'jsts/org/locationtech/jts/io/WKTReader'
// var reader = new WKTReader()
// // var a=reader.read('POINT (-20 0)')
// var a=reader.read('LINESTRING(-20 20,20 20))')
// var b=reader.read('POINT (0 0)')
// // console.log(a);
// import DistanceOp from 'jsts/org/locationtech/jts/operation/distance/DistanceOp'
var DistanceOp = jsts.operation.distance.DistanceOp
// console.log(DistanceOp.distance(a, b))
// console.log(a.distance(b));
// import Coordinate from 'jsts/org/locationtech/jts/geom/Coordinate'
// Coordinates.name
// **** http://bjornharrtell.github.io/jsts/1.6.1/doc/module-org_locationtech_jts_io_GeoJSONReader.html
// import geojsonReader from 'jsts/org/locationtech/jts/io/GeoJSONReader'
// var reader = new geojsonReader()
var reader = new jsts.io.GeoJSONReader()
import svgPolygon from '../../../../components/svg/polygon.vue'

import base from './_base.js'
  export default {
    name:'constraint-polygon',
    mixins:[base],
    components:{svgPolygon},
    computed: {
      ok(){
        if (this.map_config && this.map_config.my_controls && this.map_config.my_controls.constraintPolygon){
          return true
        }
        return false
      },
      position(){
        return this.$store.state.position.position
      },
      positionGeojson(){
        if(this.position){
          return reader.read(this.$store.getters['position/getGeojsonProj'](this.map.getView().getProjection())).geometry
        }
      },
      position_map(){
        return map_utils.project_geom(this.position,'EPSG:4326',this.map.getView().getProjection())
      },
      polygon_distance(){
        if(this.geojson_polygon && this.positionGeojson){
          return this.round(DistanceOp.distance(this.geojson_polygon,this.positionGeojson))
          // return this.geojson_polygon.distance(this.positionGeojson)
        }
      },
      in_polygon(){
        // bool that indicate if we are in the polygon
        if(this.polygon_distance){
          if(this.polygon_distance>0){
            return false
          }
          return true
        }
        return undefined
      },
      polygon_perimeter_distance(){
        if(this.in_polygon===true){
          // get the polygon perimeter
          let b1=this.geojson_polygon.getBoundary()
          return this.round(DistanceOp.distance(b1,this.positionGeojson))
        }else if(this.in_polygon===false){
          return this.polygon_distance
        }
      }
    },
    asyncComputed:{
      geojson_polygon(){
        if(this.ok){
          return this.$store.state.project.db_project.get( this.map_config.my_controls.constraintPolygon).then(doc=>{
            // normally in map projection, => 3857.
            //TODO: plan from more?? should have only one feature - assumption...
            return reader.read(doc).features[0].geometry
          })
        }
      }
    },methods: {
      round(val) {
        return Math.round(val*100)/100
      }
    },
  }
</script>

<style scoped>

</style>
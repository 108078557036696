export default {
  getQuota(){
    try{
      // https://developer.mozilla.org/en-US/docs/Web/API/StorageManager/estimate
      return navigator.storage.estimate().then(estimate=> {
        estimate.percent=estimate.usage/estimate.quota*100
        // console.log(estimate);
        return Promise.resolve(estimate)
        // if( (!estimate) || Object.keys(estimate).length==0){
        //   navigator.webkitTemporaryStorage.queryUsageAndQuot00a((usage, quota)=> {
        //     this.doc={usage: this.size(usage), quota:this.size(quota), Percent: parseInt((usage/quota*100)*10)/10.0 + ' %'}
        //   })
        // }
      }).catch(err=>{
        return Promise.reject(err)
      })
    } catch (e) {
      return Promise.reject(e)
    }
  }
}

<!-- List the formas that are available offline on the computer. -->
<template>
  <div class="container-fluid">
    <div class="row justify-content-md-center">
      <div class="col-lg-6">
        <div class="my-3 h5 ml-4">
          <span class="glyphicon glyphicon-user mr-3"></span>
          <span><i class="text-muted">{{labels.navbar.user}}:</i> <b>{{ $store.getters['userName'] }}</b></span>
        </div>
        <div class="h3 text-dark my-3">
          {{labels.page_forms.title}}
        </div>
        <table class="table table-striped">
          <thead  class="thead-dark">
            <tr>
              <th scope="col">{{labels.page_forms.project}}</th>
              <th scope="col">{{labels.page_forms.form}}</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="form in config.forms" :key="form.id" >
              <td>
                <delete-button
                  myclass="mr-2" 
                  size="sm"
                  @delete="deleteForm(form)" 
                  v-if="canDelete"
                ></delete-button>
                {{form.project.label}}
                <!-- <b-btn :to="'/projectSync/'+form.project.id" variant="secondary" size = "sm" class="float-right">
                  <span class="glyphicon glyphicon-cloud-upload"></span>
                </b-btn> -->
              </td>
              <td @click="$router.push('forms/'+form.id)"
                ><b>{{form.label}}</b> <span v-if="versionNumbers" class="small ml-2"
                  >{{labels.form.versionNumber}}: {{versionNumbers[form.id]}}</span>
              </td>
              <td>
                <b-btn  :to="{path:'/forms/'+form.id}" variant="primary" class="float-right" v-b-popover.hover="labels.projectForm.view">
                  <span class="glyphicon glyphicon-eye-open float-right"></span>
                </b-btn>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="mt-5">
          <div class="h3 text-dark my-3">
            {{labels.page_forms.titleProject}}
          </div>
          <table class="table table-striped">
            <thead  class="thead-dark">
              <tr>
                <th scope="col">{{labels.page_forms.project}}</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="form in $store.getters['localDB/listLocalProjects']" :key="form.id" >
                <td>
                  <delete-button
                    myclass="mr-2" 
                    size="sm"
                    @delete="deleteProject(form)" 
                    v-if="canDelete"
                  ></delete-button>
                  {{form.label || getProjectName(form.id) || form.id}}
                </td>
                <td>
                  <b-btn  :to="{path:'/projectMapManagement/'+form.id}" variant="primary" class="float-right">
                    <span class="glyphicon glyphicon-eye-open float-right"></span>
                  </b-btn>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="mt-5">
          <div class="h3 text-dark my-3">
            {{labels.page_forms.titleOther}}
          </div>
          <table class="table table-striped">
            <thead  class="thead-dark">
              <tr>
                <th scope="col">{{labels.page_forms.otherData}}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="form in $store.getters['localDB/listLocalOtherDatabase']" :key="form.id" >
                <td>
                  <delete-button
                    myclass="mr-2" 
                    size="sm"
                    @delete="deleteOther(form)" 
                    v-if="canDelete"
                  ></delete-button>
                  {{form.label || form.id}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="pt-4"><quota></quota></div>
      </div>
    </div>
  </div>
</template>

<script>
import quota from '../components/utils/quota.vue'
import deleteButton from '../components/utils/deleteButton.vue'

export default {
  name:'forms',
  components:{
    quota:quota,
    deleteButton
  },
  data(){
    return{

    }
  },
  created(){
    this.$store.dispatch('localDB/switchDatabasesAccesMode',{accessMode: 2})
  },
  computed: {
    config(){return this.$store.state.localDB.config},
    labels(){return this.$store.state.labels},
    canDelete(){
      if(this.$store.getters.isAdmin || this.$store.state.allowLocalDataDeletion){
        return true
      }
      return false
    }
  },
  asyncComputed: {
    versionNumbers(){
      // get the different forms the version number
      if(this.config){
        let arrPromises=this.config.forms.map(x=>{
          return this.$store.dispatch('localDB/getDatabase', {dbName: x.id, alwaysGetLocalDb: true}).then(rep=>{
            return rep.db.get('form_config')
          })
        })
        return Promise.all(arrPromises).then(rep=>{
          let versionNumbers={}
          this.config.forms.map((x,i)=>{
            versionNumbers[x.id]=rep[i]._rev.split('-')[0]
          })
          return versionNumbers
        })
      }
    }
  },
  methods:{
    deleteForm(form){
      this.$store.dispatch('localDB/deleteFormLocal',form)
    },
    deleteProject(form){
      this.$store.dispatch('localDB/deleteProjectLocal',form.id)
    },
    deleteOther(form){
      this.$store.dispatch('localDB/deleteOtherDatabaseLocal',form.id)
    },
    getProjectName(project_id){
      let rep = null
      this.config.forms.forEach(x => {
        if (x.project.id == project_id){
          rep = x.project.label
        }
      })
      return rep
    },
  }
}
</script>

<style>

</style>

// Implements layer.js...


export default {
  namespaced: true,
  state: {
    className:'layer_imagery',
  },
  getters: {

  },
  mutations: {

  },
  actions: {
    define_from_config_sub(context,config){
      if (config.layer_type=='Tile' && config.source_type=='XYZ') {
        return context.dispatch('define_XYZ',config)
      }else if (config.layer_type=='Tile' && config.source_type=='BingMaps') {
        context.state.map_layer=new ol.layer.Tile({
          source: new ol.source.BingMaps({
            imagerySet: config.imagerySet,
            //account: jeb.aecom@gmail.com
            key: config.key
          }),
          visible:config.visible
        })
      }else {
        console.error('Not a layer imagery type defined ' + config.layer_type + '-' +config.source_type)
        return Promise.reject( 'Not a layer imagery type defined')
      }
      return Promise.resolve(config.layer_id)
    },
    define_XYZ(context,config){
      return context.dispatch('getUrl',config).then(url=>{
        if(url){
          return context.dispatch('defineByUrl',url)
        }else{
          return context.dispatch('defineByUrlNull',config)
        }
      }).then(rep=>{
        return Promise.resolve(config.layer_id)
      })
    },
    getUrl(context,config){
      return Promise.resolve(config.url) //simple, but useful for other layer that Implements this one
    },
    defineByUrl(context,url){
      let config=context.state.config
      // get the attachment
      context.state.map_layer=new ol.layer.Tile({
        source: new ol.source.XYZ({
          url: url,
          attributions: [new ol.Attribution({
            html: [config.attributions_html]
          })]
        }),
        visible:config.visible
      })
    },
    defineByUrlNull(){
      //others should implement that... should not happen
      return Promise.reject( 'Url not defined')
    }
  },

}


// ******** ESOC - Côte d'ivoire

export default {
  name:'esoc',
  documentTitle:'ESOC Formulaires',
  navbarTitle:'Formulaires - ESOC',//not used for now
  couchdbUrls: ['https://db.formulaires.esoc.ci/'],
  databaseSystem:'a_forms_system',
  pouhdb_replicate_batch_size:5, //default to 100, but crash in browser with photos.
  serial_position_active:false,
  inputGeopointDirectGpsClickAllowed:true,//this by pass the map.
  new_db_couchdb_security_users:"esoc_users",
  disableEditingOnOtherDevice: true,
  sql_params:{
    api_url: 'https://api.formulaires.esoc.ci/graphql',
    login_suffix: '@couchdb.sys',
    auto_sync_changes: true, // if we automatically create the record so nodesync will begin sync
    // todo: implement those options
    auto_sql_creation: true, // 
    auto_sql_creation_options:{ // same options possible as the sql.vue
      namebyform: true,
      selectManyTable: true,
      attachements: true,
      attachmentsView: true,
    }
  },
  allowDownloadSurveys: true,
}

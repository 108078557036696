<template>
  <input-row 
    :showElement="showElement"
    :label="label" 
    :elId="elId" 
    :status="status" 
    :config="config"
    :me="me"
  >
    <b-button v-if="value" @click="showPhoto" variant="secondary" size="sm">
      {{value}}
      <span class="glyphicon glyphicon-eye-open ml-3"></span>
    </b-button>
    <b-button v-if="value && !readonly" @click="removePhoto" variant="warning" size="sm"
    ><span class="glyphicon glyphicon-remove"></span>
    </b-button>
    <b-button v-if="value && appearance_aecom.indexOf('image_annotation')!=-1" @click="showAnnotationClick" variant="primary" size="sm" class="ml-4"
    ><span class="glyphicon glyphicon-edit"></span>
    </b-button>
    <b-form-file v-if="!value" v-model="file" accept="image/*" capture="environment"></b-form-file>

    <template v-slot:afterRow>
      <div v-if="showAnnotation" ref="mapcontainer" :key="annotationKey">
        <div class="h4 ml-3">
          Annotation
          <b-btn variant="primary" @click="saveAnnotation" :disabled="false"><span class="glyphicon glyphicon-save-file"></span></b-btn>
          <b-btn variant="warning" class="ml-3" @click="clearAnnotation" :disabled="!myValue" size="sm"><span class="glyphicon glyphicon-remove"></span></b-btn>
        </div>
        <div class="mt-2" v-if="message">
          <b-alert show>{{message}}</b-alert>
        </div>
        <div class="photo-map" :id="'map' + elId" ref="map" :style="{height:annotationHeight+'px', width:annotationWidth+'px'}">
        </div>
      </div>
    </template>
  </input-row>
</template>

<script>
import base from './_baseInput.js'
import ImageTools from '../../utils/imageTool'

export default {
  name:'i-photo',
  mixins:[base],
  data(){
    return {
      file:null,
      imageSize:null,
      annotationExtent:null,
      annotationMap:null,
      annotationSource:null,
      annotationDraw: null,
      showAnnotation:false,
      annotationHeight: '200',
      annotationWidth: '200',
      message:null,
      annotationKey:null,
      quickFirstTime:false,
    }
  },
  computed: {
    olTypeString(){
      if(this.appearance_aecom.indexOf('image_annotation_point')!=-1){
        return 'Point'
      }
      return 'LineString'
    },
    maxPixels(){
      return this.$store.state.localConfig.jsonConfig.photoMaxPixelSize ?? this.parameters['max-pixels']
    }
  },
  watch:{
    file:'uploadPhoto',
    value(newval,oldval){
      this.file=null
      this.showAnnotation=false
      if(this.value && oldval==null && this.appearance_aecom.indexOf('image_annotation_quick')!=-1){
        this.quickFirstTime=true
        this.showAnnotationClick()
      }
    }
  },
  methods:{
    uploadPhoto(){
      if(! this.file) {return}
      this.$store.dispatch('app_message',`uploadPhoto - begin - ${this.file.name}, ${this.file.type}, ${this.file.size} bytes`)
      // if config, resize:
      let prom1 = Promise.resolve(this.file)
      if (this.maxPixels){
        prom1 = ImageTools.resize(this.file, {width: this.maxPixels, height: this.maxPixels})
        this.$store.dispatch('app_message','Resizing image to '+this.maxPixels+' pixels.')
      }
      prom1.then( file2=>{
        return this.$store.dispatch('form/uploadPhoto',{file: file2, fileName: this.file.name, fileType: this.file.type, survey_id: this.$route.params.survey_id})
      }).then(rep=>{
        this.$store.dispatch('app_message',`uploadPhoto - end - ${rep.id}`)
        this.myValue=rep.id
      }).catch(function (err) {
        this.$store.dispatch('app_message_error','uploadPhoto - error')
        this.$store.dispatch('app_message_error',err)
        this.$store.commit('error_message',err.message)
      })
    },
    getImageSize(photo){
      // https://stackoverflow.com/a/52060802/140384
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.addEventListener("load", () => {
          photo.width =img.width
          photo.height =img.height
          resolve(photo)
        });
        img.addEventListener("error", err => reject(err));
        img.src = photo.url;
      })
    },
    getPhotoUrl(forceOri=false){
      if(this.value){
        let photo={}
        return this.$store.state.form.db.get(this.value).then(doc=>{
          photo.name=doc.name
          photo.attachmentGeoJson=doc.attachmentGeoJson // if we have already an attachment
          if(doc._attachments.annotation && forceOri==false){
            return this.$store.state.form.db.getAttachment(doc._id,'annotation' )
          }else{
            return this.$store.state.form.db.getAttachment(doc._id,'photo' )
          }
        }).then(blob=>{
          //could also use the URL of couchDB if online - https://dbforms.aecom.space/form_cjh7vrjy5000uiatendvjvkn8/photo_cjh82ywlr000giateg0v5xlf4/photo
          //but has to support offline mode (pouchDB).
          var url = URL.createObjectURL(blob);
          photo.url=url
          return Promise.resolve(photo)
        }).catch(err=>this.$store.dispatch('app_message_error',err))
      }
      return Promise.reject('no image')
    },
    showPhoto(){
      if(this.value){
        this.getPhotoUrl().then(photo=>{
          this.$store.commit('modals/photo',photo)
        }).catch(err=>this.$store.dispatch('app_message_error',err))
      }
    },
    removePhoto(){
      if(this.$store.state.settings.deletePhotoOnDelete){
        this.$store.state.form.db.get(this.value).then(doc=>{
          this.$store.state.form.db.remove(doc)
        })
      }
      this.myValue=null
    },
    saveAnnotation(){
      this.annotationMap.removeInteraction(this.annotationDraw) // remove it or we cna have the blue point in the saved image.
      this.annotationFit()
      this.annotationMap.once('postcompose', (event) => {
      // this.annotationMap.once('rendercomplete', (event) => {
        var canvas = event.context.canvas;
        // if (navigator.msSaveBlob) {
        //   navigator.msSaveBlob(canvas.msToBlob(), 'map.png');
        // } else {
        canvas.toBlob((blob) => {
          // navigator.saveAs(blob, 'map.png');
          this.storeAnnotation(blob)
        });
        // }
      });
      // window.setTimeout(this.annotationMap.renderSync,1000)
      this.annotationMap.renderSync();
    },
    storeAnnotation(blob ){
      // build geojson of the vectors:
      // https://gis.stackexchange.com/a/180249/2043
      var writer = new ol.format.GeoJSON();
      var geojsonStr = writer.writeFeatures(this.annotationSource.getFeatures());

      // store the new attachment blob:
      return this.$store.state.form.db.get(this.value).then(doc=>{
        if(this.annotationSource.getFeatures().length==0){
          delete doc.attachmentGeoJson
          delete doc._attachments.annotation
          return this.$store.state.form.db.put(doc)
        }else{
          doc.attachmentGeoJson=geojsonStr
          return this.$store.state.form.db.put(doc)
        }
      }).then(response=>{
        if(this.annotationSource.getFeatures().length==0){
          return Promise.resolve()
        }else{
          return this.$store.state.form.db.putAttachment(this.value,'annotation',response.rev,blob,'image/png')
        }
      }).then(()=>{
        // this.message='Ok'
        this.showAnnotationClick()
      }).catch(err=> {
        this.$store.dispatch('app_message_error',err)
        this.message=err
      })
    },
    clearAnnotation(){
      this.annotationSource.clear()
    },
    showAnnotationClick(){
      if(this.showAnnotation){
        this.showAnnotation=false
        this.annotationMap=null
        this.annotationExtent=null
        this.annotationSource=null
        this.annotationKey=this.$_cuid()
      }else{
        this.annotationKey=this.$_cuid()
        this.showAnnotation=true
        this.$nextTick(() => {
          // have to wait for the mpa div to be in the DOM before building the map.
          this.configAnnotationMap()
        });
      }
    },
    configAnnotationMap(){
       if(this.value && this.appearance_aecom.indexOf('image_annotation')!=-1){
        this.getPhotoUrl(true).then(photo=>{
          return this.getImageSize(photo)
        }).then(photo=>{
          const maxMapWidth= Math.round(this.$refs.mapcontainer.clientWidth * 0.9)
          if(photo.width>maxMapWidth){
            // normal case
            this.annotationWidth=maxMapWidth
          }else{
            // if don't limit the with, we ended with a larger image than orignal... we don't want that.
            this.annotationWidth=photo.width
          }
          this.annotationHeight= this.annotationWidth * photo.height / photo.width
          // wait for change heigh and width to be done, unless problem with image size.
          this.$nextTick(() => {
            // have to wait for the mpa div to be in the DOM before building the map.
            this.configAnnotationMap2(photo)
          });
        })
      }
    },
    configAnnotationMap2(photo){
      // display as map
      // https://openlayers.org/en/latest/examples/static-image.html
      var extent = [0, 0, photo.width, photo.height];
      var projection = new ol.proj.Projection({
        code: 'xkcd-image',
        units: 'pixels',
        extent: extent
      });
      
      // the vector layer we draw
      // https://openlayers.org/en/v4.6.5/examples/draw-and-modify-features.html
      var source = new ol.source.Vector();
      if(photo.attachmentGeoJson){
        // already attachment info
        var features = (new ol.format.GeoJSON()).readFeatures(photo.attachmentGeoJson);
        source.addFeatures(features)
      }
      var vector = new ol.layer.Vector({
        source: source,
        style: new ol.style.Style({
          // fill: new ol.style.Fill({
          //   color: 'rgba(255, 255, 255, 0.2)'
          // }),
          stroke: new ol.style.Stroke({
            color: '#ffcc33',
            width: 3
          }),
          image: new ol.style.Circle({
            radius: 7,
            fill: new ol.style.Fill({
              color: '#ffcc33'
            })
          })
        })
      });

      // https://stackoverflow.com/a/25682186/140384
      var interactions = ol.interaction.defaults({altShiftDragRotate:false, pinchRotate:false});
      var map = new ol.Map({
        interactions: interactions,
        layers: [
          new ol.layer.Image({
            source: new ol.source.ImageStatic({
              // attributions: '© <a href="http://xkcd.com/license.html">xkcd</a>',
              url: photo.url,
              projection: projection,
              imageExtent: extent
            })
          }),
          vector
        ],
        target: 'map' + this.elId,
        view: new ol.View({
          projection: projection,
          center: ol.extent.getCenter(extent),
          extent: extent, // TODO: Include a buffer calculated from the div size, as this resterict the center of view.
          zoom: 2,
          zoomFactor:1.5,
          maxZoom: 8
        }),
        controls: [
          new ol.control.Zoom(),
          // new OpenLayers.Control.ArgParser(),
          // new OpenLayers.Control.Attribution()
        ]
      });

      var modify = new ol.interaction.Modify({source: source});
      map.addInteraction(modify);
      var draw = new ol.interaction.Draw({
        source: source,
        type: this.olTypeString
      });
      map.addInteraction(draw);
      // draw.on('drawend', (e)=>{
      vector.on('change', (e)=>{
        // console.log('test draw end');
        // console.log(this.value);
        if(this.quickFirstTime){
          this.quickFirstTime=false
          // *** quick annotation and it's the first time, so auto save after.
          // wait to ensure mouse is awai... but does not work... on desktop
          // but we need it so the vector is in the canvas... or it's save as json, but not in the iamge.
          window.setTimeout(this.saveAnnotation,600)
          // this.saveAnnotation()
        }
      })
      var snap = new ol.interaction.Snap({source: source});
      map.addInteraction(snap);

      this.annotationMap=map
      this.annotationExtent=extent
      this.annotationSource=source
      this.annotationDraw=draw
      // full zoom
      this.annotationFit()
    },
    annotationFit(){
      this.annotationMap.getView().fit(this.annotationExtent,{constrainResolution:false});
    }
  }
}
</script>

<style>
.photo-map{
  border: 0.1rem;
  border-style: solid;
  border-color: black;
  /* width: 90%; */
  margin-left: 5%;

}
</style>

<template>
  <div id="geoshape00" class="mt-1">
    <div>
      <b-btn @click="endPolygon" variant="primary">{{labels.map.endEdit}}</b-btn>
    </div>
    <!-- <b-btn v-if="properties.allowGpsButton" @click="getFromGPS">{{labels.map.addPointGPS}}</b-btn> -->
    <!-- <b-btn v-if="properties.allowGpsButton" @click="getFromGPS">{{labels.map.addPoint}}</b-btn> -->
  </div>
</template>

<script>
import base from './_baseComponent.js'
import map_utils from '../map_utils.js'

export default {
  name:'map-geoshape',
  mixins:[base],
  data(){
    return{
      source:null,
      ObjToRemove:null
    }
  },
  methods:{
    start(){
      // it's the watch of the start.
      //when created or updated
      console.log('start geoshape')
      var source = new ol.source.Vector();
      //add to source existing Geometry
      if(this.properties.currentValue){
        console.log(this.properties.currentValue)
        try{
          var geojson  = new ol.format.GeoJSON();
          let feat=geojson.readFeature(this.properties.currentValue,{
            featureProjection: this.map.getView().getProjection(),
            dataProjection: 'EPSG:4326'})
          source.addFeature(feat)
          if(this.$store.state.position.position_update_recenter){
            this.$store.commit('position/position_update_recenter')//toggle off
          }
          //center map on feature.
          let extent1=feat.getGeometry().getExtent()
          this.map.getView().setCenter([(extent1[0]+extent1[2])/2,(extent1[1]+extent1[3])/2]);
        }catch(e){
          console.error(e)
        }
      }

      //define layer
      var vector = new ol.layer.Vector({
        source: source,
        style: new ol.style.Style({
          fill: new ol.style.Fill({
            color: 'rgba(255, 255, 255, 0.2)'
          }),
          stroke: new ol.style.Stroke({
            color: '#ffcc33',
            width: 2
          }),
          image: new ol.style.Circle({
            radius: 7,
            fill: new ol.style.Fill({
              color: '#ffcc33'
            })
          })
        })
      });
      this.ObjToRemove={}
      this.ObjToRemove.layer=vector
      this.map.addLayer(vector)
      var modify = new ol.interaction.Modify({source: source});
      this.map.addInteraction(modify);
      this.ObjToRemove.modify=modify
      let draw = new ol.interaction.Draw({
          source: source,
          type: this.properties.isGeometryLine?'LineString':'Polygon'
        });
      this.map.addInteraction(draw);
      this.ObjToRemove.draw=draw
      let snap = new ol.interaction.Snap({source: source});
      this.map.addInteraction(snap);
      this.ObjToRemove.snap=snap
      this.source=source
    },
    endPolygon(){
      console.log('endPolygon');
      var geojson  = new ol.format.GeoJSON();
      var feat=this.source.getFeatures()[0]
      if(feat){
        var wgs84Sphere = new ol.Sphere(6378137);
        if(this.properties.isGeometryLine){
          // feat.setProperties({length:feat.getGeometry().getLength(), type: 'click'})
          feat.setProperties({length:map_utils.getLength(feat.getGeometry(),this.map.getView().getProjection()), input_type: 'click'})
        }else{
          // feat.setProperties({area:feat.getGeometry().getArea(), type: 'click'})
          feat.setProperties({area:map_utils.getArea(feat.getGeometry(),this.map.getView().getProjection()), input_type: 'click'})
        }
        let nbPoints = 0
        feat.getGeometry().getCoordinates().map(x=>{
          nbPoints += x.length
        })
        feat.setProperties({nbCoordinates: nbPoints})
        //send as geojson string...
        let as_geojson = geojson.writeFeatureObject(feat, {
          featureProjection: this.map.getView().getProjection(),
          dataProjection: 'EPSG:4326'
        });
        //send the object
        console.log(as_geojson);
        this.response=as_geojson
      }
      //calculate the area
      this.finished()
    },
    clear_sub(){
      console.log('clear sub polygon')
      console.log(this.map.removeLayer(this.ObjToRemove.layer))
      this.map.removeInteraction(this.ObjToRemove.draw);
      this.map.removeInteraction(this.ObjToRemove.snap);
      this.map.removeInteraction(this.ObjToRemove.modify);
    }
  }
}
</script>


<style scoped>
#geoshape{
  position: fixed;
  /* bottom: 0; */
  right: 10px;
  /* left:300px; */
  /* left:0; */
  top:70px;
}
</style>

export default{
  data(){
    return {
      popupUrl: null,
    }
  },
  computed: {
    popup_aecom(){
      // if we have a popup that can be opened
      if (this.config && this.config.popup_aecom){
        const pop = this.config.popup_aecom.split(':')
        return this.$store.state.form.db.get(pop[0]).then(doc=>{
          return this.$store.state.form.db.getAttachment(doc._id,pop[1])
        }).then(blob=>{
          //could also use the URL of couchDB if online - https://dbforms.aecom.space/form_cjh7vrjy5000uiatendvjvkn8/photo_cjh82ywlr000giateg0v5xlf4/photo
          //but has to support offline mode (pouchDB).
          this.popupUrl = URL.createObjectURL(blob);
        }).catch(err=>this.$store.dispatch('app_message_error',err))
        return true
      }
      return false
    },
  }
}

<template>
  <div class="container-fluid" v-if="systemDoc">
    <div class="h2 text-center">{{labels.projectsListTitle}}</div>
    <div class="row justify-content-md-center">
      <div class="col-lg-6">
        <b-form-checkbox-group v-model="selected_projects">
          <ul class="list-group">
            <li
              class="list-group-item bg-dark text-white text-center my-pad2"
            >{{labels.projectsListTitle}}</li>
            <li class="list-group-item" v-for="project in systemDoc.projects" :key="project.id">
              <b-form-checkbox :value="project">{{project.label}}</b-form-checkbox>
            </li>
          </ul>
        </b-form-checkbox-group>
      </div>
    </div>
    <div class="row justify-content-md-center mt-3">
      <div class="col-lg-6">
        <b-form-checkbox-group v-model="selected_options">
          <ul class="list-group">
            <li class="list-group-item bg-dark text-white text-center my-pad2">Options</li>
            <li class="list-group-item" v-for="opt in options" :key="opt.name">
              <b-form-checkbox :value="opt.name">{{opt.label}}</b-form-checkbox>
            </li>
          </ul>
        </b-form-checkbox-group>
        <!-- Options for selected -->
        <div class="m-3">
          <!-- replicate -->
          <div v-if="selected_options.indexOf('replicate') > -1">
            <b-form-input v-model="params.localURL" type="text" placeholder="Local URL - leave blank to use the default https(not always working)"></b-form-input>
            <b-form-input v-model="params.remoteURL" type="text" placeholder="Remote URL"></b-form-input>
            <b-form-checkbox v-model="params.remoteUsers">Do _users</b-form-checkbox>
            <b-form-checkbox v-model="params.databaseSystem">Do database system</b-form-checkbox>
          </div>
          <div v-if="selected_options.indexOf('replicate') > -1 || selected_options.indexOf('clone') > -1">
            <b-form-input
              v-model="params.remoteAndLocalAuthorisation1"
              type="text"
              placeholder="UserName"
            ></b-form-input>
            <b-form-input
              v-model="params.remoteAndLocalAuthorisation2"
              type="text"
              placeholder="Password"
            ></b-form-input>
          </div>
          <div v-if="selected_options.indexOf('defineSecurity') > -1">
            <b-form-checkbox v-model="params.databaseSystem">Do database system</b-form-checkbox>
          </div>
          <div v-if="selected_options.indexOf('clone') > -1">
            <b-form-input v-model="params.localURL" type="text" placeholder="Local URL - leave blank to use the default https(not always working, them type: http://couchdb:5984/)"></b-form-input>
            <b-form-input
              v-model="params.clone_project_no"
              type="text"
              placeholder="Project number"
            ></b-form-input>
            <b-form-input
              v-model="params.clone_project_label"
              type="text"
              placeholder="Project Name"
            ></b-form-input>
          </div>
        </div>
        <!-- Process -->
        <b-btn variant="primary my-3" @click="process">Process</b-btn>
        <div v-if="err" class="alert alert-warning">
          {{err}}
        </div>
        <div v-if="mess.length>0" class="alert alert-secondary">
          {{mess.join('\n')}}
        </div>
      </div>
    </div>
    <div class="row">
      <div class="card" v-if="sql">{{sql}}</div>
    </div>
    <div class="row card my-5">{{selected_projects}}</div>
  </div>
</template>

<script>
import axios from 'axios'
import { Promise } from 'q';
import basictoken from 'basic-auth-token'

export default {
  name: "listProjects",
  components: {},
  data() {
    return {
      mess:[],
      err:null,
      sql: null,
      selected_projects: [],
      selected_options: [],
      options: [
        { name: "clone", label: "Clone project - without surveys" },
        {
          name: "create_project_doc",
          label: "Create project document if don't exists"
        },
        { name: "del_ortho", label: "Delete ortho documents" },
        { name: "replicate", label: "Replicate project(s)" },
        { name: "defineSecurity", label: "Define security (current URL, can be changed in help)"},
        { name: "defineSecurityUsers", label: "Define security users (only to _admin unless anonymous can create users)"},
        { name: "restore_forms_today", label: "Restore deleted forms 2019-03-" },
        { name: "recreateDesignDocs", label: "Re-create design documents" },
      ],
      params: {}
    };
  },
  computed: {
    systemDoc() {
      return this.$store.state.systemDoc
    },
    labels() {
      return this.$store.state.labels
    },
    currentUrl(){
      return this.$store.state.couchdbUrl
    },
    selected_projects_ids(){
      return this.selected_projects.map(x=>x.id)
    },
    selected_projects_froms_ids(){
      let rep=[]
      this.systemDoc.projects.filter(x=>
        this.selected_projects_ids.indexOf(x.id)>-1
      ).map(pro=>{
        pro.forms.filter(x=>x.deleted!==true).map(form=>{
          rep.push(form.id)
        })
      })
      return rep
    }
  },
  methods: {
    process() {
      this.mess=[]
      this.err=null
      
      if (this.selected_options.indexOf("create_project_doc") > -1) {
        this.selected_projects.map(pro => {
          this.$store.dispatch('project/createNewProjectConfig',pro.id)
        })
        this.mess.push('Done projects creation')
      }
      if (this.selected_options.indexOf("recreateDesignDocs") > -1) {
        this.selected_projects_froms_ids.reduce(
          (promiseChain,CurrentTask)=>{
            return promiseChain.then(chainResults =>{
              //TODO: Make this work when the form is present locally... for the moment has to delete locally.
              return this.fetchFormPromise=this.$store.dispatch('form/setActualForm',CurrentTask).then(rep=>{
                //fetch the most recent definition
                // console.log('ici1');
                return this.$store.dispatch('form/getActualFormDefition')
              }).then(rep=>{
                // console.log('ici2');
                return this.$store.dispatch('form/createDesignDocs')
              }).then(rep=>
                [ ...chainResults, rep ]
              )
            })
          },
          Promise.resolve([])
        ).then(arrayOfResults => {
          // Do something with all results
          this.mess.push('Done design doc creation')
        }).catch(err=>this.$store.dispatch('app_message_error',err))
      }
      if (this.selected_options.indexOf("del_ortho") > -1) {
        this.selected_projects.map(pro => {
          var db = null
          this.$store
            .dispatch("localDB/getDatabase", { dbName: pro.id, alwaysUseRemoteDb: true })
            .then(rep => {
              db = rep.db;
              return db.allDocs({
                startkey: "ortho",
                endkey: "ortho\ufff0"
              })
            })
            .then(docs => {
              let docs_deleted = docs.rows.map(doc => {
                return { _id: doc.id, _deleted: true, _rev: doc.value.rev };
              });
              db.bulkDocs(docs_deleted).catch(err=>this.$store.dispatch('app_message_error',err));
            })
        })
      }
      if(this.selected_options.indexOf("replicate") > -1){
        // Create the replication to another database, do all the databases selected or affected.
        this.mess.push('Processing - replicate')
        // create the list of dabases to do:
        const dbs=[]
        if(this.params.databaseSystem){dbs.push(this.$store.state.settings.databaseSystem)}
        if(this.params.remoteUsers){dbs.push('_users')}
        this.selected_projects_ids.map(pro=>{dbs.push(pro)})
        this.selected_projects_froms_ids.map(form=>{dbs.push(form)})
        //create the replicator documents
        let replicateDocs=[]
        dbs.map(x=>{
          replicateDocs.push(this.MakeReplicateDoc(x,false))
          replicateDocs.push(this.MakeReplicateDoc(x,true))
        })
        const db=new this.$PouchDB(this.currentUrl+'_replicator', this.$store.getters.pouchDbOptions)
        db.bulkDocs(replicateDocs).then(rep=>{
          this.mess.push('Success')
        }).catch(err=>{
          this.err=err
          this.mess.push('Error')
        })
      }
      if(this.selected_options.indexOf('defineSecurity')>-1){
        this.mess.push('Processing - security')
        // Create the security doc for all the selected databases
        const role=this.$store.state.settings.new_db_couchdb_security_users
        const securityDoc={
          "admins":{
            "roles":[
              this.$store.state.settings.new_db_couchdb_security_admins
            ]
          },
          "members":{
            "roles":[
              role
            ]
          }
        }
        let dbs=[]
        if(this.params.databaseSystem){dbs.push(this.$store.state.settings.databaseSystem)}
        dbs=dbs.concat(this.selected_projects_ids)
        dbs=dbs.concat(this.selected_projects_froms_ids)
        const arrRep=dbs.map(x=>{
          console.log(x);
          
          return axios.put(this.currentUrl+x+'/_security',securityDoc, this.$store.getters.pouchDbOptions)
        })
        Promise.all(arrRep).then(rep=>{
          this.mess.push('Success')
        }).catch(err=>{
          this.err=err
          this.mess.push('Error')
          this.$store.dispatch('app_message_error',err)
        })
      }
      if(this.selected_options.indexOf('defineSecurityUsers')>-1){
        this.mess.push('Processing - define security')
        // Create the security doc for all the selected databases
        const securityDoc={
          "members":{
            "roles":[
              '_admin'
            ]
          }
        }
        axios.put(this.currentUrl+'_users/_security',securityDoc, this.$store.getters.pouchDbOptions).then(rep=>{
          this.mess.push('Success')
        }).catch(err=>{
          this.err=err
          this.mess.push('Error')
          this.$store.dispatch('app_message_error',err)
        })
      }
      if(this.selected_options.indexOf('restore_forms_today')>-1){
        this.mess.push('Processing - restore')
        let db=this.$store.state.dbDatabaseSystem
        return db.get('config').then(doc=>{
          doc.projects.map(pro=>{
            pro.forms.map(form=>{
              // TODO: have the date as a parameter.
              if(form.deleted && form.deletedTime.indexOf('2019-03-')==0){
                delete form.deleted
                delete form.deletedTime
              }
            })
          })
          db.put(doc)
        }).catch(err=>{
          this.$store.dispatch('app_message_error',err)
        })
      }
      // *********** CLONE
      if(this.selected_options.indexOf('clone')>-1){
        this.selected_projects.map(pro => {
          this.mess.push('Processing - Clone - ' + pro.id)
          let cloned_project_id = "project_" + this.$_cuid()
          // create the list of dabases to do:
          const dbs=[]
          dbs.push([pro.id,cloned_project_id])
          this.getProjectFormsIds(pro.id).map(form_id=>{
            dbs.push([form_id,"form_" + this.$_cuid()])
          })
          //create the replicator documents
          let replicateDocs=[]
          dbs.map(x=>{
            replicateDocs.push(this.MakeReplicateDoc_clone(x[0],x[1]))
          })
          let db=this.$store.state.dbDatabaseSystem
          const dbreplicator=new this.$PouchDB(this.currentUrl+'_replicator', this.$store.getters.pouchDbOptions)
          dbreplicator.bulkDocs(replicateDocs).then(rep=>{
            // TODO: once finished we have to change the form_id in the map_layer_form in project
            this.mess.push('Success')
            return db.get('config')
          }).then(doc=>{
            this.mess.push('   Project cloned')
            // ** add in the config
            let proEntry = doc.projects.filter(x=> x.id == pro.id)[0]
            proEntry = JSON.parse(JSON.stringify(proEntry))
            proEntry.project_label = this.params.clone_project_label
            proEntry.project_no = this.params.clone_project_no
            proEntry.label=proEntry.project_no + ' - ' + proEntry.project_label
            proEntry.id = cloned_project_id
            dbs.map(x=>{
              if(x[0].indexOf('form_')==0){
                let formEntry = proEntry.forms.filter(y=>y.id==x[0])[0]
                formEntry.id = x[1]
                formEntry.project_id = cloned_project_id
              }
            })

            doc.projects.push(proEntry)
            return db.put(doc)
          }).then(res=>{
            this.mess.push('   Added to config')
            this.$store.dispatch('getSystemDoc')
            this.mess.push('Success')
          }).catch(err=>{
            this.$store.dispatch('app_message_error',err)
            this.err=err
            this.mess.push('Error')
          })
        })
      }
    },
    MakeReplicateDoc(dbName,reverse){
      const db1={
        // implemented the localURL, because the local login with the https address does not always work... probably because of the reverse proxy. specifying the local name couchdb or 127.0.0.1 with the port number works... so http:127.0.0.1:5984/
        url:(this.params.localURL?this.params.localURL:this.currentUrl)+dbName,
        headers:{
          Authorization:'Basic ' + basictoken(this.params.remoteAndLocalAuthorisation1, this.params.remoteAndLocalAuthorisation2) 
        }
      }
      const db2={
        url:this.params.remoteURL+dbName,
        headers:{
          Authorization:'Basic ' + basictoken(this.params.remoteAndLocalAuthorisation1, this.params.remoteAndLocalAuthorisation2) 
        }
      }
      return {
        _id:'replicate_' + dbName + (reverse?'_r':''),
        user_ctx:{
          name:this.$store.state.session.userCtx.name,
          "roles": [
            "_admin",
            "_reader",
            "_writer"
          ]
        },
        "create_target": true,
        "continuous": true,
        // "owner": this.$store.state.session.userCtx.name,
        source:reverse?db2:db1,
        target:reverse?db1:db2
      }
    },
    MakeReplicateDoc_clone(dbName, destName){
      const db1={
        url:(this.params.localURL?this.params.localURL:this.currentUrl) + dbName,
        headers:{
          Authorization:'Basic ' + basictoken(this.params.remoteAndLocalAuthorisation1, this.params.remoteAndLocalAuthorisation2) 
        }
      }
      const db2={
        url:(this.params.localURL?this.params.localURL:this.currentUrl) + destName,
        headers:{
          Authorization:'Basic ' + basictoken(this.params.remoteAndLocalAuthorisation1, this.params.remoteAndLocalAuthorisation2) 
        }
      }
      return {
        _id:'replicate_' + dbName + '_' + destName,
        user_ctx:{
          name:this.$store.state.session.userCtx.name,
          "roles": [
            "_admin",
            "_reader",
            "_writer"
          ]
        },
        "create_target": true,
        "continuous": false,
        // "owner": this.$store.state.session.userCtx.name,
        source: db1,
        target: db2,
        selector :{
          "_id":{"$regex": "^(?!survey_|sync_|photo_)"},
        }
      }
    },
    getProjectFormsIds(project_id){
      let rep=[]
      this.systemDoc.projects.filter(x=> project_id == x.id).map(pro=>{
        pro.forms.filter(x=>x.deleted!==true).map(form=>{
          rep.push(form.id)
        })
      })
      return rep
    }
  }
};
</script>

<style>
</style>

<template>
  <div v-if="summaries">
    <div class="row justify-content-md-center">
      <div class="col-lg-8">
        <!-- {{summaries}} -->
        <div class="h5">{{labels.form.summarySelect}}</div>
        <b-form-select v-model="selected" :options="summariesOptions" text-field="label" value-field="query" size="lg"></b-form-select>
        <!-- {{selectedObj}} -->
        <div class="mt-3 d-flex" v-if="selected">
          <b-input-group>
            <b-form-input v-model="filter" placeholder="Search"></b-form-input>
            <b-input-group-append>
              <b-button @click="filter=null" size="sm"><i class="glyphicon glyphicon-remove"></i></b-button>
            </b-input-group-append>
          </b-input-group>
          <b-btn v-if="data" @click="exportData" class="ml-4"> 
            <i class="glyphicon glyphicon-download"></i>
          </b-btn>
        </div>
        <div class="d-flex mt-3" v-if="selected">
          <div v-if="keyIsDate !== null" class="d-flex">
            <datetime v-model="dateFilter" auto 
              format="yyyy-LL-dd"

            ></datetime>  
            <b-btn v-if="dateFilter" @click="dateFilter = null" size="sm" class="ml-1" variant='warning'>
              <i class="glyphicon glyphicon-remove"></i>
            </b-btn>
          </div>
          <div v-if="allowGroupLevel" class="ml-3 d-flex">
            <span class="mr-2" >Group: </span>
            <b-form-select
              v-model="group"
              :options="this.selectedObj.cols[0].map((x,i)=> { return {text: x, value: i}})"
            ></b-form-select>
          </div>
        </div>
        <!-- {{data}} -->
        <b-table v-if="data" :items="data2" :fields="fields" class="mt-5" :filter="filter">
          <!-- <template slot:cell(id)="data1" > -->
          <template slot="cell(key)" slot-scope="data1">
            <router-link :to="'edit/' + data1.item.id" v-if="data1.item.id">{{ formatKey(data1.value) }}</router-link>
            <div v-else>{{ formatKey(data1.value) }}</div>
          </template>
        </b-table>
        <div v-if="valTotal" class="h4 mt-4">
          <div v-if="valNbVal != 1">
            <div v-for="(col, i) in selectedObj.cols[1]" :key="col" class="mt-2">
              Total {{col}}: {{ valTotal[i] }}
            </div>
          </div>
          <div v-else>Total: {{ valTotal }}</div>
        </div> 
      </div>
    </div>
  </div>
</template>

<script>
import base from './_base.js'
import { Datetime } from 'vue-datetime';

//TODO: to refactor to has less condition if not many cols in key... But keep in mind that we have to be backward compatible. So change the input

export default {
  name: 'form-summary',
  mixins:[base],
  components:{
    datetime: Datetime,
  },
  data() {
    return {
      selected: this.$store.state.form.formDisplayOptions.currentSummarySelected || null,
      filter: null,
      dateFilter: null,
      group: null,
    }
  },
  computed: {
    summariesOptions(){
      // form select should not have child options, or problems
      return this.summaries.map(x=>{
        const y = Object.assign({},x)
        delete y.options
        return y
      }).sort((a,b)=>{
        if(a.label < b.label) { return -1; }
        if(a.label > b.label) { return 1; }
        return 0;
      })
    },
    selectedObj() {
      if(this.selected){
        return this.summaries?.filter(x=>x.query==this.selected)?.[0]
      }
    },
    keyNbVal(){
      if (Array.isArray(this.selectedObj.cols[0])){
        return this.selectedObj.cols[0].length
      }
      return 1
    },
    valNbVal(){
      if (Array.isArray(this.selectedObj.cols[1])){
        return this.selectedObj.cols[1].length
      }
      return 1
    },
    fields(){
      if(this.selectedObj && this.selectedObj.cols){
        if(this.selectedObj.hasReduce){
          let cols = []
          if(this.keyNbVal != 1){
            this.selectedObj.cols[0].map((x,i)=>{
              cols.push({key:'key' + i, label: x})
            })
          }else{
            cols.push({key: 'key', label:this.selectedObj.cols[0]})
          }
          if(this.valNbVal != 1){
            this.selectedObj.cols[1].map((x,i)=>{
              cols.push({key:'value' + i, label: x})
            })
          }else{
            cols.push({key:'value', label: this.selectedObj.cols[1]})
          }
          return cols
        }else{
          return [
            {key: 'key', label:this.selectedObj.cols[0]},
            // {key:'id'}
          ]
        }
      }
    },
    valTotal(){
      if(this.data && this.selectedObj.reduce == '_sum' && this.selectedObj.options && this.selectedObj.options.showTotal == true){
        if (this.valNbVal != 1){
          // many vals
          let rep = []
          for (let y = 0; y < this.valNbVal; y++){
            rep.push(0)
          }
          this.data2.map(x=>{
            for (let y = 0; y < this.valNbVal; y++){
              rep[y] = rep[y] + x['value' + y]
            }
          })
          return rep
        }else{
          // normal case
          let rep = 0
          this.data2.map(x=>{
            rep += x.value
          })
          return rep
        }
      }
      return null
    },
    data2(){
      // if cols or key are array, separete the data
      const data = JSON.parse(JSON.stringify(this.data))
      const rep = data.map(x=>{
        if(this.keyNbVal > 1){ // > cause we can have 0...
          for (let y = 0; y < this.keyNbVal; y++){
            x['key' + y] = x.key[y]
          }
          delete x.key
        }
        if(this.valNbVal != 1){
          for (let y = 0; y < this.valNbVal; y++){
            x['value' + y] = x.value[y]
          }
          delete x.value
        }
        return x
      })
      if (this.dateFilter){
        return rep.filter(x=>{
          if (x['key' + this.keyIsDate].indexOf(this.dateFilter) == 0){
            return true
          }
          return false
        })
      }else{
        return rep
      }
    },
    keyIsDate(){
      if(this.group != this.keyNbVal - 1){
        return null
      }
      if (this.selectedObj && this.selectedObj.options && this.selectedObj.options.hasOwnProperty('keyIsDate')){
        let kIndex = ''
        if (this.keyNbVal != 1){
          kIndex = this.selectedObj.options.keyIsDate
        }
        return kIndex
      }
      return null
    },
    allowGroupLevel(){
      if(this.dateFilter){
        // TODO: do better, consider the date could be index 1.
        return false
      }
      if (this.selectedObj && this.selectedObj.options && this.selectedObj.options.hasOwnProperty('allowGroupLevel')){
        return this.selectedObj.options.allowGroupLevel
      }
      return false
    },
  },
  asyncComputed:{
    summaries(){
      if(this.ready){
        return this.$store.dispatch('form/getActualFormSummaries')
      }
    },
    data(){
      if(this.selectedObj){
        const opts ={
          group: this.selectedObj.hasReduce,
        }
        if(this.allowGroupLevel){
          opts['group_level'] = this.group + 1
        }
        return this.$store.state.form.db
          .query(this.selectedObj.query, opts)
          .then(rep => {
            return rep.rows
        });
      }
    },
  },
  watch: {
    selectedObj() {
      this.dateFilter = null
      this.group = this.keyNbVal - 1
    },
    dateFilter(){
      if (this.dateFilter.indexOf('T')!=-1){
        this.dateFilter = this.dateFilter.split('T')[0]
      }
    },
    selected(){
      this.$store.commit('form/setFormDisplayOptions',{key:'currentSummarySelected',val:this.selected})
    },
  },
  methods: {
    formatKey(val){
      if(Array.isArray(val)){
        return val.join(' / ')
      }
      return val
    },
    exportData(){
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += this.fields.map(x=>x.label).join(',')
      csvContent += '\n'
      this.data2.map(row=>{
        console.log(row);
        csvContent += this.fields.map(x=>row[x.key]).join(',')
        csvContent += '\n'
      })
      // var encodedUri = encodeURI(csvContent);
      // window.open(encodedUri);
      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "my_data.csv");
      document.body.appendChild(link); // Required for FF

      link.click(); // This will download the data file named "my_data.csv".

    },
    // nbDays(){
    //   // https://stackoverflow.com/a/543152/140384
    //   function parseDate(str) {
    //     var mdy = str.split('-');
    //     return new Date(mdy[0], mdy[1]-1, mdy[2]);
    //   }
      
    //   function datediff(first, second) {
    //       // Take the difference between the dates and divide by milliseconds per day.
    //       // Round to nearest whole number to deal with DST.
    //       return Math.round((second-first)/(1000*60*60*24))+1;
    //   }
    //   return datediff(parseDate('2021-04-14'), parseDate('2021-04-18'))
    // },
  },
};
</script>

<style scoped>
</style>
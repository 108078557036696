 
// ******** Ardmo - muggli

export default{
  name:'ardmo',
  documentTitle:'AECOM - Ardmo',
  navbarTitle:'AECOM - Ardmo',//not used for now
  //TODO: correct this problem: does not work on https... 
  couchdbUrls: ['https://db.remfor.aecom.space/','https://db.remfor2.aecom.space/'],//,'http://192.168.50.2:5984/'], // we pass many as we want to support mobile field server
  databaseSystem:'forms_system_ardmo',
  pouhdb_replicate_batch_size:5, //default to 100, but crash in browser with photos.
  serial_position_active:false,
  inputGeopointDirectGpsClickAllowed:true,//this by pass the map.
  new_db_couchdb_security_users:"users_ardmo",
  default_language:'en'
}

<template>
  <!-- https://www.iconfinder.com/icons/1291767/aim_center_dedication_precision_target_icon -->
  
<svg
    :width="width"
    :height="height"
    viewBox="0 0 64 64"
    class="my1"
   >
  <g>
    <path
       style="fill:#000000;fill-opacity:1"
       d="M 33.75 5.6875 C 27.409434 5.6875 21.528344 7.7374279 16.78125 11.21875 L 19.6875 14.1875 C 23.659583 11.418491 28.503942 9.78125 33.75 9.78125 C 47.263962 9.78125 58.15625 20.565874 58.15625 33.90625 C 58.15625 47.246626 47.263962 58.03125 33.75 58.03125 C 20.236038 58.03125 9.34375 47.246626 9.34375 33.90625 C 9.34375 29.010205 10.81831 24.457004 13.34375 20.65625 L 10.4375 17.6875 C 7.1607093 22.278935 5.21875 27.865291 5.21875 33.90625 C 5.21875 49.485512 18.043077 62.125 33.75 62.125 C 49.456923 62.125 62.25 49.485512 62.25 33.90625 C 62.25 18.326988 49.456923 5.6875 33.75 5.6875 z "
       id="path2993" />
    <path
      style="fill:#000000;fill-opacity:1"
      
       d="M 33.65625 15.6875 C 30.067306 15.6875 26.694718 16.665876 23.8125 18.375 L 26.78125 21.40625 C 28.844828 20.365773 31.17179 19.78125 33.65625 19.78125 C 42.042089 19.78125 48.75 26.453629 48.75 34.71875 C 48.75 42.983871 42.042089 49.65625 33.65625 49.65625 C 25.27041 49.65625 18.53125 42.983871 18.53125 34.71875 C 18.53125 32.319707 19.113365 30.070136 20.125 28.0625 L 17.1875 24.90625 C 15.437038 27.77394 14.4375 31.130667 14.4375 34.71875 C 14.4375 45.222757 23.077449 53.75 33.65625 53.75 C 44.235051 53.75 52.84375 45.222757 52.84375 34.71875 C 52.84375 24.214743 44.235051 15.6875 33.65625 15.6875 z "
       id="path2993-1" />
  </g>
  <g>
    <path
       style="fill:#000000;fill-opacity:1"
       d="M 6.15625 0.40625 L 6.46875 6.53125 L 0.375 6.28125 L 5.0625 11.90625 L 9.09375 12.9375 L 30.65625 34.5 L 28.5625 36.9375 L 39.59375 39.875 L 37.75 27.34375 L 34.1875 30.375 L 34.09375 30.5 L 13 9.40625 L 13.0625 9.375 L 12.875 5.15625 L 6.15625 0.40625 z "
       id="rect3799" />
  </g>
</svg>

</template>

<script>
  export default {
    name:'svg-precision',
    props: {
      width: {
        type: String,
        default: '1em'
      },
      height: {
        type: String,
        default: '1em'
      },
    },
  }
</script>

<style scoped>
  .my1{
    /* Allow to fit better in a button - remove the text margins */
    margin-top: -0.4em;
    margin-bottom: -0.4em;
  }
</style>
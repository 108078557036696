<template>
  <input-row 
    :showElement="showElement"
    :label="label" 
    :elId="elId" 
    :status="status" 
    :config="config"
    :me="me"
  >
    <datetime v-model="myValue" auto 
      type="time"
      :input-id="elId"
      ></datetime>

  </input-row>
</template>

<script>
import base from './_baseInput.js'
import { Datetime } from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css';
import { DateTime as LuxonDateTime } from 'luxon'


export default {
  name:'ITime',
  mixins:[base],
  components:{
    datetime: Datetime
  },
  computed:{
  }
}
</script>

<style>

</style>

import Vue from "vue";

// https://gist.github.com/penguinboy/762197?permalink_comment_id=2773307#gistcomment-2773307
const flatten = (objectOrArray, prefix = '', formatter = (k) => (k)) => {
  const nestedFormatter = (k) => ('.' + k)
  const nestElement = (prev, value, key) => (
    (value && typeof value === 'object')
      ? { ...prev, ...flatten(value, `${prefix}${formatter(key)}`, nestedFormatter) }
      : { ...prev, ...{ [`${prefix}${formatter(key)}`]: value } });

  return Array.isArray(objectOrArray)
    ? objectOrArray.reduce(nestElement, {})
    : Object.keys(objectOrArray).reduce(
      (prev, element) => nestElement(prev, objectOrArray[element], element),
      {},
    );
};


// **** modifed to keep track of the object... not just the decomposed parts. Useful to get refrence to by path to objects or array. And not just theirs values.
// inspired from: https://gist.github.com/penguinboy/762197?permalink_comment_id=2773307#gistcomment-2773307
const flattenWithObject = (objectOrArray, prefix = '', formatter = (k) => (k)) => {
  const nestedFormatter = (k) => ('.' + k)
  const nestElement = (prev, value, key) => (
    (value && typeof value === 'object')
      ? { ...prev, ...{ [`${prefix}${formatter(key)}`]: value }, ...flattenWithObject(value, `${prefix}${formatter(key)}`, nestedFormatter) }
      : { ...prev, ...{ [`${prefix}${formatter(key)}`]: value } });

  return Array.isArray(objectOrArray)
    ? objectOrArray.reduce(nestElement, {})
    : Object.keys(objectOrArray).reduce(
      (prev, element) => nestElement(prev, objectOrArray[element], element),
      {},
    );
};


export default {
  isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  },
  // .forEach(function(value, index, array) {
  //     // The callback is executed for each element in the array.
  //     // `value` is the element itself (equivalent to `array[index]`)
  //     // `index` will be the index of the element in the array
  //     // `array` is a reference to the array itself (i.e. `data.items` in this case)
  // })

  //this version don't decompose the array if we provide array index
  //and return only the first elemnt of an array
  getPropTree(obj1, path1){
    let pVal=obj1
    for(let i=0;i<path1.length;i++){
      let value=path1[i]
      if (typeof value === 'string' || value instanceof String){
        if(pVal.hasOwnProperty(value)){
          if(Array.isArray(pVal[value])){
            const arr1=pVal[value]
            pVal=arr1[arr1.length-1]
          }else{
            pVal=pVal[value]
          }
        }else{
          return null
        }
      }else{
        //access Array - it's an integer...
        return null
      }
    }
    return pVal
  },
  // do a v2, because v1 already used and don't want to change...
  //this version decompose the array if we provide an array index
  //and return the array object if if the value asked is one
  getPropTree2(obj1, path1){
    let pVal=obj1 //return the object if no path specified (empty array [])
    for(let i=0;i<path1.length;i++){
      let value=path1[i]
      if (typeof value === 'string' || value instanceof String){
        if(pVal && pVal.hasOwnProperty(value)){          
          pVal=pVal[value]
        }else{
          return null
        }
      }else if(typeof value === 'number'){
        //access Array - it's an integer...
        if(Array.isArray(pVal) && value<pVal.length){
          pVal=pVal[value]
        }else{
          return null
        }
      }
    }
    return pVal
  },
  getLastArrayNamePath(path1){
    let path2 = path1
    for(let i=path1.length - 1;i>=0;i--){
      let value=path1[i]
      if (typeof value === 'string' || value instanceof String){
        //continue
      }else if(typeof value === 'number'){
        return path2
      }
      path2=path2.slice(0,-1)
    }
    return []
  },
  setPropTree2(obj1, path1, newvalue){
    let pVal=obj1 //return the object if no path specified (empty array [])
    let iLast = path1.length-1
    for(let i=0;i<path1.length;i++){
      let curPathName=path1[i]
      if(curPathName === 'repeat'){
        // we want to change the values in the repeat array
        if(Array.isArray(pVal)){
          pVal.map(x=>{
            this.setPropTree2(x, path1.slice(i+1), newvalue)
          })
        }else{
          return null
        }
      }else if (typeof curPathName === 'string' || curPathName instanceof String){
        if(i==iLast){
          // pVal[curPathName]=newvalue
          Vue.set(pVal,curPathName,newvalue)
        }else if(pVal && pVal.hasOwnProperty(curPathName)){          
          pVal=pVal[curPathName]
        }else{
          return null
        }
      }else if(typeof curPathName === 'number'){
        //access Array - it's an integer...
        if(Array.isArray(pVal) && curPathName<pVal.length){
          pVal=pVal[curPathName]
        }else{
          return null
        }
      }
    }
    return true
  },
  flattenObject(ob) {
    if(ob===null || ob===undefined){return {}}
    //source: https://gist.github.com/penguinboy/762197
    let that=this
    return Object.keys(ob).reduce(function(toReturn, k) {
      if (Object.prototype.toString.call(ob[k]) === '[object Date]') {
        toReturn[k] = ob[k].toString();
      }
      else if ((typeof ob[k]) === 'object' && ob[k]) {
        var flatObject = that.flattenObject(ob[k]);
        Object.keys(flatObject).forEach(function(k2) {
          toReturn[k + '.' + k2] = flatObject[k2];
        });
      }
      else {
        toReturn[k] = ob[k];
      }

      return toReturn;
    }, {});
  },
  // If key in not in ObjKey, will retrun a json.stringify if object
  flattenObjectNoTreeNoDeeperThanObKeys(ob, ObjKeys) {
    if(ob===null || ob===undefined){return {}}
    //source: https://gist.github.com/penguinboy/762197
    let that=this
    return Object.keys(ob).reduce(function(toReturn, k) {
      if (Object.prototype.toString.call(ob[k]) === '[object Date]') {
        toReturn[k] = ob[k].toString();
      }
      else if ((typeof ob[k]) === 'object' && ob[k]) {
        var flatObject = that.flattenObjectNoTreeNoDeeperThanObKeys(ob[k], ObjKeys);
        if (Object.keys(flatObject).length>0){
          // Verify only the first one...
          const k3 = Object.keys(flatObject)[0].split('.')[0]
          let decompose = that.isNumeric(k3)
          if (!decompose){
            const fis = Object.keys(flatObject)
            for (let i = 0; i < fis.length; i++) {
              const k4 = fis[i].split('.')[0];
              if(ObjKeys.hasOwnProperty(k4)){
                decompose=true
                break
              }
            }
          }
          if( decompose ){
            Object.keys(flatObject).forEach(function(k2) {
              toReturn[k + '.' + k2] = flatObject[k2];
            });
          }else{
            toReturn[k] = JSON.stringify(ob[k])
          }
        }
      }
      else {
        toReturn[k] = ob[k];
      }

      return toReturn;
    }, {});
  },
  //returns all values that are not array - so no repeat considered
  flattenObjectNoTree(ob) {
    if(ob===null || ob===undefined){return {}}
    let that=this
    return Object.keys(ob).reduce(function(toReturn, k) {
      if (Object.prototype.toString.call(ob[k]) === '[object Date]') {
        toReturn[k] = ob[k].toString();
      }
      else if ((typeof ob[k]) === 'object' && ob[k] && Array.isArray(ob[k])===false) {
        var flatObject = that.flattenObjectNoTree(ob[k]);
        Object.keys(flatObject).forEach(function(k2) {
          toReturn[k2] = flatObject[k2];
        });
      }//TODO: think about if we want to decompe the array... use case... as the array may have sub elements...
      else {
        toReturn[k] = ob[k];
      }

      return toReturn;
    }, {});
  },
  flattenObjectValueArray(ob) {
    //decompose the object and return an array for each value name.
    if(ob===null || ob===undefined){return {}}
    let that=this
    const toReturn={}
    Object.keys(ob).map(k=>{
      if (Object.prototype.toString.call(ob[k]) === '[object Date]') {
        that.flattenObjectValueArraySub(toReturn,k,ob[k].toString())
      }else if ((typeof ob[k]) === 'object' && ob[k] && Array.isArray(ob[k])===false) {
        const sub2=that.flattenObjectValueArray(ob[k])
        Object.keys(sub2).map(x2=>{
          //for each prop in the sub, add it to me.
          that.flattenObjectValueArraySub(toReturn,x2,sub2[x2])    
        })
      }else if(ob[k] && Array.isArray(ob[k])===true){
        //array decompose
        ob[k].map(x=>{
          const sub1=that.flattenObjectValueArray(x)
          Object.keys(sub1).map(x2=>{
            //for each prop in the sub, add it to me.
            that.flattenObjectValueArraySub(toReturn,x2,sub1[x2])    
          })
        })
      }else {
        that.flattenObjectValueArraySub(toReturn,k,ob[k])
      }
    })
    return toReturn
  },
  flattenObjectValueArraySub(toReturn,propName,valueToInsert){
    if(toReturn.hasOwnProperty(propName)){
      toReturn[propName]=toReturn[propName].concat(valueToInsert) // concat as it may already be an array
    }else{
      toReturn[propName]=[].concat(valueToInsert)// concat as it may already be an array
    }
  },
  flattenValuesObject(ob) {
    //inspired by: https://gist.github.com/penguinboy/762197
    const ob2=this.flattenObject(ob)
    return Object.keys(ob2).map(x=>ob2[x])
  },
  // https://gist.github.com/penguinboy/762197?permalink_comment_id=2773307#gistcomment-2773307
  // Flatten an object with the paths for keys - including the arrays
  flattenObjectArrayWithPath: flatten,
  flattenObjectArrayWithPathIncludeObjects: flattenWithObject,
  replaceAll (str,find, replace) {
    // https://stackoverflow.com/a/14822579/140384
    //var str = this;
    return str.replace(new RegExp(find.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), 'g'), replace);
  },
  // ignores any rejects except if all promises rejects
  //https://stackoverflow.com/a/54219925/140384
  PromiseFirstResolve (promises) {
    return new Promise(function (fulfil, reject) {
        var rejectCount = 0;
        promises.forEach(function (promise) {
            promise.then(fulfil, () => {
                rejectCount++;
                if(rejectCount == promises.length) {
                    reject('All promises were rejected');
                } 
            });
        });
    });
  },
  objectEqual(obj1,obj2){
    return JSON.stringify(obj1)==JSON.stringify(obj2)
  }
}

import axios from 'axios'
//import the store
import store from '../../store'

export default{
  getUserList(opts){
    return axios.get('_users/_all_docs?include_docs=true', opts).then(rep=>{
      // filter to avoid design docs.
      return Promise.resolve(rep.data.rows.filter(doc=>doc.id.indexOf('org')==0).map(doc=>doc.doc))
    }).catch(err=>{
      store.dispatch('app_message_error',err)
      return Promise.reject(err)
    })
  }
}
<template>
  <div>
    <dot :color="subLayer.ol_style.stroke.color" v-if="subLayer?.ol_style?.stroke?.color"></dot>
    <i v-else class="mdi mdi-circle-outline"></i>
    <i v-if="subLayer.visible" class="mdi mdi-eye ml-1"></i>
    <i v-else class="mdi mdi-eye-off ml-1"></i>
    <b-btn variant="secondary" class="mx-2" size="sm" @click="isEditing=!isEditing" v-if="canEdit"><span class="mdi mdi-pencil"></span></b-btn>
    <b-btn variant="danger" class="mx-2" size="sm" @click="deleteSubLayer(subLayer)" v-if="canEdit && isCouchdbForm"><span class="mdi mdi-trash-can"></span></b-btn>
    {{subLayer.label}}
    <div v-if="isEditing" class="card m-3 ml-5">
        <div class="card-header">
          Edit values and them save
        </div>
      <div class="card-body">
        <b-form-input v-model="subLayer.label"></b-form-input>
        <b-form-checkbox v-model="subLayer.visible" class="my-2">Visible</b-form-checkbox>
        <b-form-checkbox v-model="subLayer.allowCopyFeatureText" class="my-2">Allow Copy Feature Text (identify)</b-form-checkbox>
        <div>zIndex (Higher Z-index values are drawn on top of lower ones)</div>
        <b-form-input v-model.number="subLayer.zIndex" class="my-2"></b-form-input>
        <b-form-select v-model="subLayer.currentSurveyOption" :options="options" @click.stop="" v-if="isCouchdbForm"></b-form-select>
        <div v-if="canEditColor">
          <p-color v-model="colors" class="my-2"></p-color>
          <div>Radius</div>
          <b-form-input v-model.number="radius"></b-form-input>
          <div>Width</div>
          <b-form-input v-model.number="width"></b-form-input>
        </div>
        <div v-else-if="subLayer.mapbox_style">
          <!-- <div>mapbox_style <i class="mdi mdi-delete-circle" @click="delete subLayer.mapbox_style"></i></div> -->
          <div>mapbox_style</div>
          <b-form-textarea v-model="subLayer.mapbox_style" rows="10" disabled></b-form-textarea>
        </div>
        <div v-else>
          <div>ol_style</div>
          <b-form-textarea v-model="subLayer.ol_style" rows="10" disabled></b-form-textarea>
        </div>
        <b-btn variant="primary" @click="saveSubLayer(subLayer)" class="my-2 ml-4">Save</b-btn>
      </div>
    </div>
  </div>
</template>

<script>
  import { Swatches } from 'vue-color' // https://github.com/xiaokaike/vue-color
  import dot from '../utils/dots.vue'

  export default {
    name: "subLayer",
    components: {
      'p-color': Swatches,
      dot,
    },
    props: ["subLayer0",'project'],
    data() {
      return {
        isEditing: false,
        colors: this.subLayer0?.ol_style?.stroke?.color || '#000000',
        radius: this.subLayer0?.ol_style?.image?.radius || 6,
        width: this.subLayer0?.ol_style?.stroke?.width || 2,
        subLayer: JSON.parse(JSON.stringify(this.subLayer0)),
      }
    },
    computed: {
      dbProj(){
        return new this.$PouchDB(this.$store.state.couchdbUrl + this.project.id, this.$store.getters.pouchDbOptions)
      },
      optionsTranslation(){return this.$store.state.labels.map.surveyLayerVisibleOptions},
      options(){
        return [
          {value: 'onlyShowMySurvey', text: this.optionsTranslation.onlyShowMySurvey},
          {value: 'fadeOtherSurveys', text: this.optionsTranslation.fadeOtherSurveys},
          {value: 'allSurveys', text: this.optionsTranslation.allSurveys},
          {value: null, text: this.optionsTranslation.defaultVal},
        ]
      },
      canEdit(){
        if( this.$store.getters.isAdmin ){
          return true
        }
        return false
      },
      canEditColor(){
        if(
          this.$store.getters.isAdmin &&
          this.subLayer?.ol_style?.image?.fill &&
          this.subLayer?.ol_style?.stroke
        ){
          return true
        }
        return false
      },
      isCouchdbForm(){
        return !!this.subLayer.couchdb_form
      }
    },
    methods: {
      deleteSubLayer(subLayer){
        if(this.$store.getters.isAdmin){
          subLayer['_deleted']=true
          this.dbProj.bulkDocs([subLayer]).then(()=>{
            this.$emit('update')
          }).catch(err=>{
            this.$store.dispatch('app_message_error',err)
            this.$emit('errors',err)
          })
        }
        return false
      },
      saveSubLayer(subLayer){
        if(this.$store.getters.isAdmin){
          this.isEditing=false
          if(this.canEditColor && this.colors.hex){
            subLayer.ol_style.image.fill.color = this.colors.hex
            subLayer.ol_style.stroke.color = this.colors.hex
            subLayer.ol_style.image.radius = this.radius
            subLayer.ol_style.stroke.width = this.width
          }
          this.dbProj.bulkDocs([subLayer]).then(()=>{
            this.$emit('update')
          }).catch(err=>{
            this.$store.dispatch('app_message_error',err)
            this.$emit('errors',err)
          })
        }
        return false
      },
    },
  }
</script>

<style lang="scss" scoped>

</style>
<template>
  <div class="p-4">
    <b-btn @click="$emit('hide')" class="my-3" variant="primary" size="lg"><i class="fa fa-backward"></i></b-btn>
    <div class="row">
      <div class="col-12">
        <b-table striped hover :items="diff" :fields="fields" fixed></b-table>
      </div>
    </div>
    <b-btn @click="$emit('hide')" class="my-3" variant="primary" size="lg"><i class="fa fa-backward"></i></b-btn>
  </div>
</template>

<script>
  import utils from '../../utils/utils_gen.js'
  export default {
    props:['obj1','obj2', 'form_definition', 'revs'],
    computed:{
      obj1Flatten(){
        return utils.flattenObjectNoTreeNoDeeperThanObKeys(this.obj1['form_data'], this.formFields)
      },
      obj2Flatten(){
        return utils.flattenObjectNoTreeNoDeeperThanObKeys(this.obj2['form_data'], this.formFields)
      },
      diff(){
        const rep=[]
        Object.keys(this.obj1Flatten).forEach(key1=>{
          if(this.obj1Flatten[key1] != this.obj2Flatten[key1]){
            rep.push({key: key1 ,val1: this.obj1Flatten[key1], val2: this.obj2Flatten[key1]})
          }
        })
        Object.keys(this.obj2Flatten).forEach(key2=>{
          // first verify not already in obj1, if not, it's new.
          if(!this.obj1Flatten.hasOwnProperty(key2)){
            rep.push({key: key2 ,val1: undefined, val2: this.obj2Flatten[key2]})
          }
        })
        return rep.map(x=>{
          const x1 = x.key.split('.').filter(y=>!utils.isNumeric(y))
          const x2 = x.key.split('.').filter(y=>utils.isNumeric(y))
          x.name = [this.formFields[x1[x1.length-1]]].concat(x2).join(' / ')
          return x
        })
      },
      fields(){
        return[
          { key: 'name', label: 'Prop name',sortable: true },
          { key: 'val2',label: this.obj2['_rev']},
          { key: 'val1',label: this.obj1['_rev']},
        ]
      },
      formFields(){
        const f1=(children)=>{
          let rep={}
          children.forEach(y=>{
            rep[y.name] = y.label
            if(y.hasOwnProperty('children')){
              rep = Object.assign(rep,f1(y.children))
            }
          })
          return rep
        }
        return f1(this.form_definition.children)
      }
    },

  }
</script>

<style lang="scss" scoped>

</style>
<template>
  <div class="container">
    <div v-if="otherData && otherData.errors">
      <div show class="alert alert-danger" v-for="err in otherData.errors" :key="err">{{err}}</div>
    </div>
    <div v-if="isReady">
      <div class="h3">{{form_definition.title}}</div>
      <div class="mt-2 mb-4 h4">{{survey_id}}</div>
      <!-- We use group element to be sure we don't duplicate code.  -->
      <print-group
          :config="form_definition"
          :formDefinition="form_definition"
          :value="surveyData"
          :status="form_status"
          :showSystemValues="false"
          :surveyDoc="surveyDoc"
          :namePath="[]"
          :otherData="{}"
        ></print-group>
        
    </div>
  </div>
</template>

<script>

import base from './_base.js'
import printGroup from '../../components/print2/print2Group.vue'
import formUtils from '../../utils/form_utils.js'


export default {
  name:'survey_print',
  mixins:[base],
  // components:inputs,
  components:{
    printGroup,
  },
  props:['survey_id'],
  data(){
    return{
      surveyDoc:null,
      surveyData:null, // as we pass the property of doc object at the instantiation, the doc is changed righ away.
      form_definition:null,
      otherData:{},
      currentState:null,
    }
  },
  created(){

  },
  activated(){
    console.log('edit activated ' + this.survey_id);
    if(!this.surveyDoc && this.survey_id){
      this.fetchSurvey() // because if we go back to the same survey, survey_id is not changed, so no event.
    }
  },
  computed:{
    isReady(){
      if(this.surveyDoc && this.form_definition && this.currentState == null){
        return true
      }
      return false
    },
    form_status(){
      // the json form status.
      if(this.surveyDoc){
        return this.surveyDoc.form_status
      }
    },
  },
  watch:{
    form_id:'clear',
    survey_id:{
      handler:'fetchSurvey',
      immediate:true
    },
  },
  methods:{
    clear(){
      // remove pending formMap live components
      this.form_definition=null
      this.surveyDoc=null
      this.surveyData=null
      this.isDeleting=false
    },
    fetchSurvey(){
      console.log('fecth survey 1 ' + this.survey_id)
      if(!this.currentState){
        this.currentState='fetching'//avoid save - because fetch 2 times when refresh a form.
        this.fetchFormPromise.then(rep=>{
          this.fetchSurvey2()
        }).catch(err=>{
          this.$store.dispatch('app_message_error',err)
          this.$store.commit('error_message',err.message)
          this.$router.push('/error')
        })
      }
    },
    fetchSurvey2(){
      console.log('fecth survey 2 ' + this.survey_id)
      this.clear()
      //fetch the survey_id
      this.$store.dispatch('form/fetchSurvey',{survey_id: this.survey_id}).then(doc=>{
        this.surveyData=doc.form_data
        this.surveyDoc=doc
        return this.$store.dispatch('form/fetchDefinition',doc.form_definition)
      }).then(doc=>{
        this.form_definition=doc.form_definition
        //  when importing third party form, the status is not calculate, calculate it
        if (!this.surveyDoc.form_status){
          this.surveyDoc.form_status={}
          this.setStatus()
        }
        this.currentState=null
      }).catch(err=>{
          this.$store.dispatch('app_message_error',err)
          this.$store.commit('error_message',err.message)
          this.$router.push('/error')
        })
    },
    ///************ Status and relevant */
    setStatus(){
      // if value has change and status=2 put status=0
      this.otherData={}
      this.otherData.oldSurvey=this.surveyDoc.form_data
      this.otherData.oldSurveyStatus=this.surveyDoc.form_status
      //data has chnaged, so reset other data:
      // also at the same time, update calculated fields
      // TODO: avoid 2 times calculate status.
      // have to claculate status before, mostly when repeat or group status change because of a value change. and them calculated value can use them...
      let beforeUpdate=null;
      do {
        beforeUpdate = JSON.stringify(this.surveyDoc)
        formUtils.setStatusDataDefault(this.surveyDoc,this.form_definition,this.otherData)
        formUtils.getStatusData(this.surveyDoc,this.form_definition,this.otherData)
        formUtils.calculateValues(this.surveyDoc,this.form_definition,this.otherData)
        // reset the other data:
        formUtils.resetOtherDataValues(this.otherData)
      } while (beforeUpdate != JSON.stringify(this.surveyDoc) )
      // make it reactive in the computed prop...
      // https://vuejs.org/v2/guide/list.html#Object-Change-Detection-Caveats
      this.$set(this.surveyDoc, 'form_status_global', formUtils.getStatusOfTree(this.surveyDoc.form_status)) 
      this.surveyData=this.surveyDoc.form_data
    },
    ///************ Status and relevant */
    reloadPage(){
      window.location.reload();
    },
  },
}
</script>

<style>
.container{
  padding-right:3px;
  padding-left:3px;
}
.list-group-item{
  padding-right:3px;
  padding-left:3px;
}
</style>

<template>
  <b-btn
    :pressed="fieldObj[name]"
    size="sm"
    variant="outline-dark"
    class="ml-2"
    @click="toggle"
    ><i class="fas" :class="'fa-' + ico"></i
  ></b-btn>
</template>

<script>
export default {
  props:['fieldObj', 'ico', 'name'],
  methods: {
    toggle() {
      let val = true
      if(this.fieldObj.hasOwnProperty(this.name)){
        val = ! this.fieldObj[this.name]
      }
      this.$set(this.fieldObj, this.name, val)
    }
  },
};
</script>

<style scoped></style>

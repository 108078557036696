

export default{
  // return a Promise 
  resolveConflictWithLatest(db, docName){
    // https://pouchdb.com/guides/conflicts.html
    return db.get(docName,{conflicts:true}).then(doc=>{
      if(doc._conflicts){
        let arr=doc._conflicts.map(x=>{
          return db.remove(docName,x)
        })
        return Promise.all(arr)
      }else{
        return Promise.resolve(true)
      }
    }).catch(err=>{
      // the document does not exist - still ok, no conflicts
      return Promise.resolve(true)
    })
  }
}
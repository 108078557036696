// as position in index.js

// represent the gps position - either by
//    gps-websocket (COM-serial)
//    geo-browser geolocation

// TODO: Offer option to view the GPS status (NMEA)
//      easy with serial
//      or if cordova someday.

// TODO: Offer the option to select the active one.

//always watch the two, but the serial override the other
import moment from 'moment'
import map_utils from '../pages/form/map/map_utils.js'

//for serial GPS
const GPS = require('gps');
const serial_gps = new GPS();
const serial_url="ws://localhost:8080/ws" //default url for the serial web socket
// useful for Accuracy geometry
const sphere_ = new ol.Sphere(6378137)

//for geolocation
let geolocation=null

export default{
  namespaced: true,
  state:{
    //current position
    position:null, //[0]=x=longitude [1]=y=latitude in WGS84
    accuracy:null,
    altitude: null,
    sourceType:null,
    time:null,
    //last position
    lastPosition:null,
    lastTime:null,
    gpsAgeSeconds:-1,
    position_update_recenter:false,
  },
  getters:{
    getAccuracyGeometry(state){
      if(state.position && state.accuracy){
        //https://github.com/openlayers/openlayers/blob/v4.6.5/src/ol/geolocation.js
        return ol.geom.Polygon.circular(sphere_, state.position, state.accuracy)
      }
      return null
    },
    getGeoJSON(state){
      if( state.position){
        return {
          "type": "Feature",
          "properties": {
            gpsAge:state.gpsAgeSeconds,
            accuracy:state.accuracy,
            altitude:state.altitude,
            time:state.time.format(), // time is moment object.
            sourceType:state.sourceType
          },
          "geometry": {
            "type": "Point",
            "coordinates": state.position
          }
        }
      }
    },
    getGeojsonProj:(state) => (proj) =>{
      if( state.position){
        return {
          "type": "Feature",
          "properties": {
            gpsAge:state.gpsAgeSeconds,
            accuracy:state.accuracy,
            altitude:state.altitude,
            time:state.time.format(), // time is moment object.
            sourceType:state.sourceType
          },
          "geometry": {
            "type": "Point",
            "coordinates":  map_utils.project_geom(state.position,'EPSG:4326',proj) 
          }
        }
      }
    },
    inputGeopointDirectGpsClickAllowed(state,getters,rootState,rootGetters){
      //false by default... ??
      if(rootGetters['form/actualFormDefitionSetting']('inputGeopointDirectGpsClickAllowed',null,true)!==null){
        return rootGetters['form/actualFormDefitionSetting']('inputGeopointDirectGpsClickAllowed',null,true)
      }else if(rootState.settings.inputGeopointDirectGpsClickAllowed===true){
        return true
      }
      return false
    },
    showGpsAccuracy(state,getters,rootState,rootGetters){
      //false by default... ??
      const def1 = rootGetters['form/actualFormDefition']
      if(def1 && def1.showGpsAccuracy && def1.showGpsAccuracy.toLowerCase()=='true'){
        return true
      }
      return false
    },
    timeISO(state){
      return state.time.toISOString()
    },
  },
  mutations:{
    position(state,newPosition){
      // console.log('position store changed')
      //save the last position
      if(state.lastPosition){
        if(state.position[0] != state.lastPosition[0] || state.position[1] != state.lastPosition[1]){
          //postion has changed
          state.lastPosition=state.position
          state.lastTime=state.time
        }
      }else{
        state.lastPosition=state.position
        state.lastTime=state.time
      }


      //update the current one.
      state.accuracy=newPosition.accuracy
      state.sourceType=newPosition.type
      state.altitude = newPosition.altitude
      state.time=moment()
      state.position=newPosition.pos
    },
    position_update_recenter(state,position_update_recenter){
      state.position_update_recenter=position_update_recenter
    },
    position_update_recenter_toggle(state){
      state.position_update_recenter=!state.position_update_recenter
    }
  },
  actions:{
    start(context){
      //default action that define position for the whole app
      context.dispatch('def_geolocation')
      context.dispatch('def_serial')
    },
    def_geolocation(context){ //define geolocation
      geolocation=new ol.Geolocation({
        projection: 'EPSG:4326',
        tracking: true,
        trackingOptions: {
          enableHighAccuracy: true,
          maximumAge: 2000
        }
      })
      geolocation.on('error', error=>{
        console.error(error)
      })
      geolocation.on('change:position', pos=>{
        context.dispatch('geo_change')
      })
    },
    geo_change(context){
      // console.log(geolocation.getPosition());
      if(context.state.sourceType!='serial-GPS'){
        let a1=geolocation.getPosition()
        // console.log('geolocation - position changed ' + a1[0] + ' - ' + a1[1] );
        context.commit('position',{
          pos:geolocation.getPosition(),
          accuracy:geolocation.getAccuracy(),
          altitude: geolocation.getAltitude(),
          type:'geolocation'
        })
      }
    },
    def_serial(context){
      //check if activated in settings
      if(context.rootState.settings.serial_position_active===false){return}
      if(navigator.userAgent.indexOf("Mobile") > -1 || navigator.userAgent.indexOf("Tablet") > -1) {
        return // we don't use this on mobile, only desktop windows tablet.
      }
      //check if we have a web socket that could be use - used on windows tablet pc to broadcast the nmea gps messages.
      let socket = new WebSocket(serial_url);
      socket.onopen=mess=>{
        console.log('socket serial connected');
      }
      socket.onmessage=mess=>{
        // console.log('ws: '+mess.data);
        serial_gps.update(mess.data)
      }
      serial_gps.on('GGA',mess=>{
        context.dispatch('serial_change')
      })
    },
    serial_change(context){
      if(serial_gps.state.fix=='3D'){
        // console.log('serial position ' + serial_gps.state.lon + ' - ' + serial_gps.state.lat);
        context.commit('position',{
          pos:[serial_gps.state.lon,serial_gps.state.lat],
          accuracy:serial_gps.state.hdop,
          type:'serial-GPS'
        })
      }
    },
    positionAgeSeconds(context){
      if(context.state.time){
        var duration = moment.duration(moment().diff(context.state.time))
        context.state.gpsAgeSeconds=duration.asSeconds().toFixed(0)
      }
      return -1
    }
  }
}

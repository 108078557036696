<template>
  <div class="container">
    <div class="h2 text-center">
        {{labels.title}}
      </div>
      <div v-show="devices">
        <b-table striped hover :items="devices" :fields="fields" responsive>
          
        </b-table>
      </div>
  </div>
</template>

<script>
export default {
  computed: {
    labels(){return this.$store.state.labels.admin.deviceList},
    fields(){
      let obj=[
        {
          key: '_id',
          sortable: true
        },
        {
          key: 'deviceNumber',
          sortable: true
        },
        {
          key: 'deviceName',
          sortable: true
        }
      ]
      const fieldsNames=this.labels.fields
      return obj.map(x=>{
        x.label=fieldsNames[x.key]
        return x
      })
    }
  },
  asyncComputed:{
    devices(){
      return this.$store.state.dbDatabaseSystem.allDocs({
        include_docs:true,
        startkey:'device_',
        endkey:'device_\ufff0'
      }).then(docs=>{
        return docs.rows.map(doc=>{
          return doc.doc
        })
      })
    }
  }
}
</script>

<style>

</style>

<template>
  <div>
    <div id="geoshape003" class="mt-2">
      <div v-if="properties.readonly===false">
        <b-button @click="inputTypeClickSwitch('clickModify')" :variant="currentInputType=='clickModify'?'primary':'secondary'" :disabled="isIntervalActive" class="mr-2" v-if="geomValid">
          <span class="mdi mdi-pencil"></span>
        </b-button>
        <b-button @click="inputTypeClickSwitch('click')" :variant="currentInputType=='click'?'primary':'secondary'" :disabled="isIntervalActive">
            <span class="mdi mdi-cursor-pointer"></span>
        </b-button>
        <b-button @click="addPosition()" :disabled="currentInputType!='gps'" :variant="currentInputType=='gps'?'primary':'secondary'" class="ml-2">
          <!-- <span class="mdi mdi-vector-polyline-plus"></span> -->
          <div class="mdi-set">
            <span class="mdi mdi-crosshairs-gps"></span>
            <span class="mdi mdi-plus"></span>
          </div>
        </b-button>
        <b-button @click="deletePosition()" :disabled="!canDeleteCoord" variant="warning" class="ml-2"><span class="mdi mdi-vector-polyline-minus"></span></b-button>
      </div>
      <div class="mt-2" >
        <b-button @click="endPolygon()" variant="success" v-if="geomValid && !isIntervalActive && properties.readonly==false" class="mr-2">
          <span class="mdi mdi-check-bold"></span>
        </b-button>
        <b-button @click="backToForm" variant="info">
          <span class="glyphicon glyphicon-log-out"></span>
        </b-button>
      </div>
    </div>
    <div id="geoshape002" class="mt-2" v-if="1==0">
      <!-- <div>
        <b-btn @click="endPolygon" variant="primary">{{labels.map.endEdit}}</b-btn>
      </div> -->
      <!-- <b-btn v-if="properties.allowGpsButton" @click="getFromGPS">{{labels.map.addPointGPS}}</b-btn> -->
      <!-- <b-btn v-if="properties.allowGpsButton" @click="getFromGPS">{{labels.map.addPoint}}</b-btn> -->
      <div>
        <b-button-group class="mt-2">
          <b-button @click="currentInputType='click'" :variant="currentInputType=='click'?'primary':'secondary'" :disabled="isIntervalActive">
            <span class="mdi mdi-cursor-pointer"></span>
          </b-button>
          <div class="alert alert-secondary m-0 px-0"></div>
          <b-button @click="currentInputType='gps'" v-if="positionMap" :variant="currentInputType=='gps'?'primary':'secondary'" :disabled="isIntervalActive">
            <span class="mdi mdi-crosshairs-gps"></span>
          </b-button>
          <!-- <div class="alert alert-secondary m-0 px-0"></div> -->
          <!-- <b-button @click="currentInputType='interval'" :variant="currentInputType=='interval'?'primary':'secondary'">
            <div class="mdi-set">
              <span class="mdi mdi-clock-outline"></span>
              <span class="mdi mdi-crosshairs-gps"></span>
            </div>
          </b-button> -->
        </b-button-group>
      </div>
      <!-- GROUP CLICK -->
      <b-button-group v-if="currentInputType=='click'" class="mt-1">
        <b-button @click="deletePosition()" v-if="canDeleteCoord" variant="warning" class="ml-1"><span class="mdi mdi-vector-polyline-minus"></span></b-button>
      </b-button-group>
      <!-- GROUP GPS -->
      <b-button-group v-if="currentInputType=='gps'" class="mt-1">
        <b-button @click="addPosition()" variant="primary" class="ml-1"><span class="mdi mdi-vector-polyline-plus"></span></b-button>
        <b-button @click="deletePosition()" v-if="canDeleteCoord" variant="warning" class="ml-1"><span class="mdi mdi-vector-polyline-minus"></span></b-button>
      </b-button-group>
      <!-- GROUP INTERVAL -->
      <b-button-group v-if="currentInputType=='interval'">
        <b-button v-b-modal.modalGeoshapeGPSInterval variant="secondary" v-if="!gpsInterval">
          <span class="glyphicon glyphicon-time"></span>
        </b-button>
        <b-button @click="startLogging" variant="success"  v-if="!gpsInterval">
          <span class="glyphicon glyphicon-forward"></span>
        </b-button>
        <!-- <b-button @click="getFromMap" variant="secondary" class="ml-1">
          <span class="glyphicon glyphicon-pause"></span>
        </b-button> -->
        
        <b-button @click="endPolygon" variant="primary" >
          <span class="glyphicon glyphicon-stop"></span>
        </b-button>
        <b-button @click="backToFormContinueInterval" variant="info" v-if="gpsInterval">
          <span class="mid mdi-clipboard-arrow-right-outline"></span>
        </b-button>
      </b-button-group>
      <div class="mt-2" >
        <b-button @click="endPolygon()" variant="success" v-if="validPolygon && !isIntervalActive" class="mr-2">
          <span class="mdi mdi-check-bold"></span>
        </b-button>
        <b-button @click="backToForm" variant="info">
          <span class="glyphicon glyphicon-log-out"></span>
        </b-button>
      </div>
      <div>
        <div class="alert alert-secondary">
          <span class="mdi mdi-vector-polygon"></span>
          <span class="mdi mdi-alert" v-if="hasManyFeature"></span>
          {{(vectorGeom?.getCoordinates()?.length > 1) ? 'multipart!!':''}}
          {{vectorGeomCoordCount}}
        </div>
      </div>
      <interval v-model="gpsIntervalSeconds"></interval>
    </div>
  </div>
</template>

<script>
import base from './_baseComponent.js'
import map_utils from '../map_utils.js'
import interval from './geoshapeGPS_interval'

export default {
  name:'map-geoshape-gps2',
  mixins:[base],
  components:{
    interval
  },
  data(){
    return{
      currentInputType:'gps', // click, gps, interval
      source:null,
      ObjToRemove:null,
      gpsIntervalSeconds:1,
      gpsInterval:null,
      gpsCoords:[],
      clickCoordsCount:0,
      modifiedOperationCount:0,
    }
  },
  computed:{
    position(){
      return this.$store.state.position.position
    },
    positionMap(){
      // position in map coordinates
      return map_utils.project_geom(this.$store.state.position.position,'EPSG:4326',this.map.getView().getProjection())
    },
    isLive(){
      if (this.properties.liveId && this.$store.state.formMap.formComponentLive[this.properties.liveId]){
        return true
      }
      return false
    },
    isIntervalActive(){
      return !!this.gpsInterval
    },
    hasManyFeature(){
      // Should not happen - could remove this.
      try {
        return this.source.getFeatures().length > 1
      } catch (error) {
        return false
      }
    },
    vectorGeom(){
      try {
        return this.source.getFeatures()[0].getGeometry()
      } catch (error) {
        return null
      }
    },
    vectorGeomCoordCount(){
      try {
        let l1 = this.vectorGeom.getCoordinates()[0].length
        if(this.properties.isGeometryLine==false){
          // Polygon
          l1 = l1-1
        }
        return l1
      } catch (error) {
        return 0
      }
    },
    geomValid(){
      if(this.properties.isGeometryLine==false){
        // Polygon
        return this.vectorGeomCoordCount>2
      }else{
        // Line
        return this.vectorGeomCoordCount>1
      }
    },
    canDeleteCoord(){
      return this.vectorGeomCoordCount>1
    },
    inputType(){
      if(this.gpsCoords.length>0 && (this.clickCoordsCount>0 || this.modifiedOperationCount>0)){
        return 'GPS / Click'
      }else if(this.gpsCoords.length>0){
        return 'GPS'
      }else if(this.clickCoordsCount>0 || this.modifiedOperationCount>0){
        return 'Click'
      }else{
        return 'None'
      }
    }
  },
  watch:{
    isLive(){
      if(this.isLive==false){
        // has been destroyed, stop the interval.
        this.clearInterval()
      }
    },
    currentInputType(newVal, oldVal){
      if (oldVal == 'click') {
        this.stopClick()
      }
      if (oldVal == 'clickModify') {
        this.stopClickModify()
      }
      if(this.currentInputType=='click'){
        this.startClick()
      }
      if(this.currentInputType=='clickModify'){
        this.startClickModify()
      }
      if(this.currentInputType=='gps'){
        // nothing
      }
      if(this.currentInputType=='interval'){
        // nothing
      }
    }
  },
  methods:{
    start(){
      // it's the watch of the start.
      // when created or updated
      // console.log('start geoshape')
      var source = new ol.source.Vector();
      //add to source existing Geometry
      if(this.properties.currentValue){
        // console.log(this.properties.currentValue)
        try{
          var geojson  = new ol.format.GeoJSON();
          let feat=geojson.readFeature(this.properties.currentValue,{
            featureProjection: this.map.getView().getProjection(),
            dataProjection: 'EPSG:4326'})
          source.addFeature(feat)
          if(this.$store.state.position.position_update_recenter){
            this.$store.commit('position/position_update_recenter')//toggle off
          }
          // Get current attributes
          this.gpsCoords=feat.getProperties().gpsCoords ?? []
          this.clickCoordsCount=feat.getProperties().clickCoordsCount ?? 0
          this.modifiedOperationCount=feat.getProperties().modifiedOperationCount ?? 0
          //center map on feature.
          let extent1=feat.getGeometry().getExtent()
          this.map.getView().setCenter([(extent1[0]+extent1[2])/2,(extent1[1]+extent1[3])/2]);
        }catch(e){
          console.error(e)
        }
      }else{
        // add an empty polygons
        // have to do this later or crash if we add empty coords
      }

      //define layer
      var vector = new ol.layer.Vector({
        source: source,
        style: this.layerStyles()
      });
      this.ObjToRemove={}
      this.ObjToRemove.layer=vector
      this.map.addLayer(vector)
      this.source=source
      // console.log('start geoshape******')
      
    },
    layerStyles(){
      // We want to style the polygon and the last point of the geometry
      return [
        new ol.style.Style({
          fill: new ol.style.Fill({
            color: 'rgba(255, 255, 255, 0.2)'
          }),
          stroke: new ol.style.Stroke({
            color: '#ffcc33',
            width: 2
          }),
          image: new ol.style.Circle({
            radius: 6,
            fill: new ol.style.Fill({
              color: '#ffcc33'
            })
          })
        }),
        new ol.style.Style({
          image: new ol.style.Circle({
            radius: 6,
            fill: new ol.style.Fill({
              color: '#ffcc33'
            })
          }),
          geometry: function(feature) {
            try {
              // return the coordinates of the last point of the geometry
              var coordinates = feature.getGeometry().getCoordinates()[0];
              // if polygon
              if(feature.getGeometry().getType()=='Polygon'){
                coordinates = coordinates[coordinates.length-2];
              }else{
                coordinates = coordinates[coordinates.length-1];
              }
              return new ol.geom.MultiPoint([coordinates]);
            } catch (error) {
              
            }
          }
        })
      ]
    },
    // *** click specific function
    startClick(){
      // **** Add interractions
      let draw = new ol.interaction.Draw({
          // source: this.source,
          // type: this.properties.isGeometryLine?'LineString':'Polygon'
          type: 'Point'
        });
      draw.on('drawend',this.drawend)
      this.map.addInteraction(draw);
      this.ObjToRemove.draw=draw
    },
    startClickModify(){
      // **** Add interractions
      var modify = new ol.interaction.Modify({source: this.source});
      modify.on('modifyend',this.modifyend)
      this.map.addInteraction(modify);
      this.ObjToRemove.modify=modify
      let snap = new ol.interaction.Snap({source: this.source});
      this.map.addInteraction(snap);
      this.ObjToRemove.snap=snap
    },
    modifyend(evt){
      console.log('modifyend');
      console.log(evt);
      this.modifiedOperationCount++
    },
    drawend(evt){
      console.log('drawend');
      console.log(evt);
      this.clickCoordsCount++
      const pt = evt.feature.getGeometry().getCoordinates()
      // console.log(pt);
      this.addPosition(pt)
    },
    stopClick(){
      // try, because when clear() is called, the interaction might not be there if it was not click type.
      try {
        this.map.removeInteraction(this.ObjToRemove.draw);
        // this.map.removeInteraction(this.ObjToRemove.snap);
        this.ObjToRemove.draw=null
        // this.ObjToRemove.snap=null
      } catch (error) {
        
      }
    },
    stopClickModify(){
      // try, because when clear() is called, the interaction might not be there if it was not click type.
      try {
        this.map.removeInteraction(this.ObjToRemove.modify);
        // this.map.removeInteraction(this.ObjToRemove.snap);
        this.ObjToRemove.modify=null
        // this.ObjToRemove.snap=null
      } catch (error) {
        
      }
    },
    // ****** gps specific functions
    addPosition(newCoord0){
      let newCoord = this.positionMap
      if(newCoord0){
        newCoord = newCoord0
      }else{
        // it's the GPS coordinate - add it to the list
        this.gpsCoords.push([newCoord[0],newCoord[1], this.$store.state.position.altitude, this.$store.state.position.accuracy])
      }

      // the interval had arrived and add new position to my polygon.
      var coords=[]
      
      var feat=this.source.getFeatures()[0]
      if(feat){
        coords = feat.getGeometry().getCoordinates()
      }
      if(this.properties.isGeometryLine==false){
        /// ****** polygon
        if(coords.length==0){
          coords[0]=[]
        }
        if (coords[0].length==0){
          coords[0].push([newCoord[0],newCoord[1]])
          coords[0].push([newCoord[0],newCoord[1]])
        }else{
          coords[0].pop()
          coords[0].push([newCoord[0],newCoord[1]])
          coords[0].push(coords[0][0])
        }
        if(feat){
          feat.getGeometry().setCoordinates(coords)
        }else{
          this.source.addFeature(new ol.Feature({geometry: new ol.geom.Polygon(coords)}))
        }
      }else{
        // it's a line
        coords.push([newCoord[0],newCoord[1]])
        if(feat){
          feat.getGeometry().setCoordinates(coords)
        }else{
          this.source.addFeature(new ol.Feature({geometry: new ol.geom.LineString(coords)}))
        }
      }
    },
    deletePosition(newCoord0){
      // for now we don't support removing the start...
      if(!this.canDeleteCoord){
        return null
      }

      // the interval had arrived and add new position to my polygon.
      var feat=this.source.getFeatures()[0]
      let coords = feat.getGeometry().getCoordinates()
      let iCoord=this.properties.isGeometryLine==false ? coords[0].length-2 : coords[0].length-1
      if(newCoord0){
        newCoord=newCoord0
        // we have to find the closest point to newCoord
        let minDis
        let minDisIndex
        coords[0].map((x,i)=>{
          let dis = map_utils.getDistance(x,newCoord0)
          if(minDis==undefined || dis<minDis){
            minDis=dis
            minDisIndex=i
          }
        })
        iCoord=minDisIndex
        // return 'not supported at the moment'
      }
      if(this.properties.isGeometryLine==false){
        /// ****** polygon
        if (iCoord==0 || iCoord==(coords[0].length-1)){
          // remove the first and last point
          coords[0].splice(0,1)
          coords[0].splice(-1,1)
          // redo the loop
          coords[0].push(coords[0][0])
        }else{
          coords[0].splice(iCoord,1)
        }
      }else{
        // it's a line
        coords[0].splice(iCoord,1)
      }
      feat.getGeometry().setCoordinates(coords)
    },
    // ****** interval specific function
    startLogging(){
      this.gpsInterval = window.setInterval(this.intervalTrigger, this.gpsIntervalSeconds * 1000);
    },
    intervalTrigger(){
      this.addPosition()
      this.computeResponse()
      // Send the response live
      this.$store.commit('formMap/setFormComponentLiveResponse',{componentId:this.properties.liveId,response:this.response})
    },
    clearInterval(){
      if(this.gpsInterval){
        window.clearInterval(this.gpsInterval)
        this.gpsInterval=null
      }
      this.$store.commit('formMap/removeFormComponentLive',this.properties.liveId)
    },
    // ****** general
    endPolygon(){
      this.computeResponse()
      this.finished()
      this.clear()
    },
    clear_sub(){
      // clear each type
      this.stopClick()
      this.stopClickModify()
      this.clearInterval()
      // remove layer
      this.map.removeLayer(this.ObjToRemove.layer)
      this.ObjToRemove.layer=null
      this.source=null
    },
    backToFormContinueInterval(){
      this.$router.push(this.properties.returnRoute)
    },
    backToForm(){
      this.response=null // we reset the response in case it has changed. if this button, no change.
      this.finished()
      this.clear()
    },
    computeResponse(){
      // send response to listeners
      var geojson  = new ol.format.GeoJSON();
      var feat=this.source.getFeatures()[0]
      if(feat){
        if(this.properties.isGeometryLine){
          // feat.setProperties({length:feat.getGeometry().getLength(), type: 'click'})
          feat.setProperties({length:map_utils.getLength(feat.getGeometry(),this.map.getView().getProjection())})
        }else{
          // feat.setProperties({area:feat.getGeometry().getArea(), type: 'click'})
          feat.setProperties({area:map_utils.getArea(feat.getGeometry(),this.map.getView().getProjection())})
        }
        feat.setProperties({
          input_type: this.inputType,
          nbCoordinates: this.vectorGeomCoordCount,
          gpsCoords: this.gpsCoords,
          clickCoordsCount: this.clickCoordsCount,
          modifiedOperationCount: this.modifiedOperationCount
        })
        //send as geojson string...
        let as_geojson = geojson.writeFeatureObject(feat, {
          featureProjection: this.map.getView().getProjection(),
          dataProjection: 'EPSG:4326'
        });
        // set the response object
        this.response=as_geojson
      }
    },
    inputTypeClickSwitch(newInputType){
      if (this.currentInputType!=newInputType){
        this.currentInputType=newInputType
      }else{
        this.currentInputType='gps'
      }
    }
  }
}
</script>


<style scoped>
#geoshape{
  position: fixed;
  /* bottom: 0; */
  right: 10px;
  /* left:300px; */
  /* left:0; */
  top:70px;
}
</style>

import orderBy from 'lodash/orderBy'
import filter from 'lodash/filter'
import expr from '../../../utils/expressionEvaluation.js'
import optionUtils from '../../../utils/options.js'

// TODO: better handle the options, it's a mess at the moment.
export default {
  data(){
    return {
      options_opt:null,
      options0:null,
      optionsDefinedPromise:null,
      optionsRecomputeWhenSurveyValuesChange: false,
    }
  },
  computed:{
    orderSelectShow(){return this.$store.state.modals.orderSelectShow},
    options(){
      //dynamic as if the choice filter is active, it has to change on data change.
      if(this.config.hasOwnProperty('choice_filter') && this.options0){
        //filter(users, { 'age': 36, 'active': true });
        let opts= expr.filterChoices(this.surveyDoc,this.options0,this.config.choice_filter, this.namePath)
        // if we have choice_filter, a value that is not within those choice, must be invalid, so delete it.
        let filter1=opts.filter(x=>x.name==this.myValue)
        if(filter1.length==0){
          this.myValue=null
        }
        return opts
      }else{
        return this.options0
      }
    },
    optionsByName(){
      let rep = {}
      this.options.map(x=>{
        rep[x.name] = x
      })
      return rep
    },
    language(){
      return this.$store.state.language
    },
    surveyDocValues(){
      return this.surveyDoc.form_data
    },
    optionNotInList(){
      // TODO: This for the moment only return visual to the user. We should also have a different status.
      if(this.myValue){
        // Can happend if list has changed... if dynamis or form_definition changed.
        if(Array.isArray(this.myValue)){
          return this.myValue.map(y=>this.options?.map(x=>x.name).indexOf(y)==-1).some(x=>x)
        }else{
          return this.options?.map(x=>x.name).indexOf(this.myValue)==-1
        }
      }
      return false
    }
  },
  watch:{
    language(){
      // support for language change.
      this.m_options()
    },
    orderSelectShow(){
      //when hidden, recompute the options
      if(this.orderSelectShow==false && this.config.name==this.$store.state.modals.orderSelect.name){
        //save the config
        // TODO: make better handle errors or existing... really not urgent. Coucld also mode to store.
        const new_order=this.$store.state.modals.orderSelect.list.map(x=>{
          if(x.hasOwnProperty('device_order')){
            return {name:x.name,device_order:x.device_order}
          }
        }).filter(x=>{
          if(x && x.device_order && x.device_order!=""){
            return true
          }else{return false}
        })
        this.$store.state.localDB.db.get(this.$store.state.localDB.config.device_id).then(doc=>{
          doc.device_order[this.config.name]=new_order
          this.$store.state.localDB.db.put(doc).catch(err=>console.error(err)).then(rep=>{
            this.m_options()
          })
        }).catch(err=>{
          let obj1={_id:this.$store.state.localDB.config.device_id,device_order:{}}
          obj1.device_order[this.config.name]=new_order
          this.$store.state.localDB.db.put(obj1).catch(err=>this.$store.dispatch('app_message_error',err)).then(rep=>{
            this.m_options()
          })
        })
      }
    },
    myValue:{
      immediate:true,
      handler(){
        if(!this.options0){
          this.m_options().then(rep=>{
            this.setValue2()
          })
        }else{
          this.setValue2()
        }
      }
    },
    surveyDocValues:{
      handler(){
        if(this.optionsRecomputeWhenSurveyValuesChange){
          this.m_options()
        }
      },
      deep: true
    }
  },

  methods:{
    setValue2(){
      // do nothing by default
    },
    m_options_opt(){
      // get the options form the source and order them.
      let opt=[]
      if (this.config.hasOwnProperty('aecom_select')){
        this.optionsRecomputeWhenSurveyValuesChange=true
        //custom aecom select.
        let aecom_select=JSON.parse(this.config.aecom_select)
        if(!Array.isArray(aecom_select)){
          aecom_select=[aecom_select]
        }
        aecom_select.map(x=>{
          if(x.type=='surveyValues'){
            opt = opt.concat(optionUtils.surveyGetAllValues(this.surveyDoc, x, this.namePath))
          }else if(x.type='filterByPrevious'){
            const existingPrevious = optionUtils.surveyGetAllValues(this.surveyDoc, x, this.namePath).map(x=>x.name)
            opt = opt.concat(optionUtils.getDefinitionChoices(this.config, this.formDefinition).filter(x=> existingPrevious.indexOf( x.name)!=-1))
          }
        })
        // remove duplicates
        const seen={}
        opt = opt.filter(function(item) {
          if( ! seen.hasOwnProperty(item.name)){
            seen[item.name]=true
            return true
          }
          return false
        })
      } else {
        opt = optionUtils.getDefinitionChoices(this.config, this.formDefinition)
      }
      if(this.appearance_aecom.indexOf('device_order')>-1){
        return new Promise((resolve, reject) =>{
          this.$store.state.localDB.db.get(this.$store.state.localDB.config.device_id).then(doc=>{
            //add the device_order to my order.
            if(doc.device_order.hasOwnProperty(this.config.name)){
              opt.map(x=>x.device_order=undefined)
              doc.device_order[this.config.name].map(x=>{
                opt.filter(x2=>x2.name==x.name).map(x3=>{
                  x3.device_order=x.device_order
                })
              })
            }
            this.options_opt=orderBy (opt,['device_order','order','label'],'asc')
            resolve()
          }).catch(err=>{
            //no config configured.
            this.options_opt=orderBy (opt,['device_order','order','label'],'asc')
            resolve()
          })
        })
      }else{
        //normal case - not device orger
        opt.forEach(x=>{
          if(x.order){
            x.order=+x.order
          }
        }) // the json transform ndicate order as string...
        this.options_opt=orderBy (opt,['order','label'],'asc')
        return Promise.resolve()
      }
    },
    m_options(){
      // call m_options_opt and after change appearance if necessary
      const label_text = (x)=>{
        let label2 = null
        // Check for translation
        if(typeof x.label === 'object'){
          // TODO: optimize this to support key cache, better for performances.... to analyse.
          // select language
          const curLang = this.language
          Object.keys(x.label).map(y=>{
            if (y.indexOf('('+curLang+')')>-1){
              label2 = x.label[y]  
            }
          })
          if(!label2){
            label2 = x.label.default
          }
        }else{
          label2 = x.label
        }
        if(!label2){
          label2=x.name
        }
        return label2
      }
      return this.m_options_opt().then(rep=>{
        let opt=this.options_opt
        if(this.appearance_aecom.indexOf('with_name_label')>-1){
          opt=opt.map(x=>{
            let x2=Object.assign({},x)
            x2.label=label_text(x)
            if(x2.label){ // could be not defined....
              x2.label0=x2.label // useful to filter in select.vue
              x2.label=x2.name + ' - ' + x2.label
            }else{
              x2.label0=x2.name
              x2.label=x2.name
            }
            return x2
          })
        }
        // if no label provided, use the name.
        let sequence=0
        opt=opt.map(x=>{
          const newX = Object.assign({},x)
          newX.label=label_text(x)
          sequence++
          newX.sequence = sequence
          return newX 
        })
        // remove hidden ones
        opt = opt.filter(x=> x.hidden?false:true)
        //sort the list. the order is defined in the form in the column order. not all element has to have an order. Empty are at the end.
        // oder_device is configured in the interface, to get the optional button, appearance_aecom='device_order')
        this.options0=opt//orderBy (opt,['device_order','order','label'],'asc')
        return Promise.resolve(true)
      }).catch(err=>{
        this.$store.dispatch('app_message_error',err)
        return Promise.reject(false)
      })
    },
    configOrder(){
      //modal or page to configure order...
      const obj1={
        list:this.options_opt.slice(),
        name:this.config.name
      }
      this.$store.commit('modals/orderSelect',obj1)
    }
  }
}

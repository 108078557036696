<template>
  <div class="ml-3">
    <b-form-text v-if="me.status>=15 && me.constraint_message" text-variant="warning">
      <i class="mdi mdi-alert"></i>
      {{me.constraint_message}}
    </b-form-text>
    <b-form-text v-if="hint" class="">
      <i class="mdi mdi-chat mr-1"></i>
      <span>{{hint}}</span>
      <span v-if="hintAlt" class="info-text"> / {{hintAlt}}</span>
    </b-form-text>
    <div v-if="me.info && $store.state.form.formDisplayOptions.notePosition=='below-answer'" class="info-text">
      <i class="mdi mdi-information-outline"></i>
      {{me.info}}
    </div>
    <inputRowFooterFlag :me="me"></inputRowFooterFlag>
  </div>
</template>

<script>
  import inputRowFooterFlag from './_inputRowFooterFlag.vue'
  
  export default {
    name: 'input-row-footer',
    components:{
      inputRowFooterFlag,
    },
    props:['me'],
    computed: {
      config() {
        return this.me.config
      },
      hint(){
        if(typeof this.config.hint === 'object'){
          if(this.config.hint[this.$store.state.form.formLanguage] && this.$store.state.form.formDisplayOptions.hintLanguageMode == 'language'){
            return this.config.hint[this.$store.state.form.formLanguage]
          }else{
            return this.config.hint.default 
          }
        }
        return this.config.hint
      },
      hintAlt(){
        if(typeof this.config.hint === 'object'){
          if(this.config.hint[this.$store.state.form.formLanguage] && this.$store.state.form.formDisplayOptions.hintLanguageMode == 'default+language'){
            return this.config.hint[this.$store.state.form.formLanguage]
          }
        }
        return null
      },
    },
  }
</script>

<style scoped>
  .info-text{
    /* font-size: 0.8em; */
    color: #959575;
  }
</style>
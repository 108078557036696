<template>
  <div class="card card-body bg-light">
    <div class="card-title h5">
      <dots :variant="colorVariant" size="lg" ></dots><span class="ml-2">{{labels.title}}</span>
    </div>
    <div class="card-text">
      <div v-if="quota">
        <div class="">
          <b-progress :max="100" :variant="colorVariant">
            <b-progress-bar :value="quota.percent">
              {{labels.percent}}: <b>{{ quota.percent.toFixed(1)}} %</b>
            </b-progress-bar>
          </b-progress>
        </div>
        <div class="text-secondary">
          {{labels.usage}}: {{size(quota.usage)}}
        </div>
        <div class="text-secondary">
          {{labels.quota}}: {{size(quota.quota)}}
        </div>
        <!-- <div>
          {{labels.percent}}: <b>{{ quota.percent.toFixed(1)}} %</b>
        </div> -->
      </div>
      <div class="alert alert-danger" v-if="err">
        {{err}}
      </div>
    </div>
  </div>
</template>

<script>
var filesize = require('file-size');
import dots from './dots.vue'
import getQuota from '../../utils/quota'

export default {
  name:'quota',
  components:{dots},
  data(){
    return {
      err:null
    }
  },
  computed: {
    labels(){return this.$store.state.labels.quota},
    colorVariant(){
      if(this.quota && this.quota.percent>80){
        return 'danger'
      }else if(this.quota && this.quota.percent>60){
        return 'warning'
      }
      return 'success'
    }
  },
  asyncComputed:{
    quota(){
      // https://developer.mozilla.org/en-US/docs/Web/API/StorageManager/estimate
      return getQuota.getQuota().catch(err=>{
        this.err=err
        this.$store.dispatch('app_message_error',err)
      })
    }
  },
  methods:{
    size(val){
      //get a human readable size.
      return filesize(val,{fixed:1}).human('si')
    },
  }
}
</script>

<style>

</style>

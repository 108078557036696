<template>
  <li class="list-group-item px-0">
    <div class="mx-2" @click="showCollapse=!showCollapse">
      <span class="glyphicon"
        :class="{'glyphicon-chevron-right':!showCollapse,'glyphicon-chevron-down':showCollapse}">
      </span>
      <span class="ml-2">{{project.label}}</span>
      <span v-if="project.deleted" class="ml-2 m-deleted" >{{labels.deleted}}</span>
    </div>
    <b-collapse v-if="!isAddingForm" :id="project.id" v-model="showCollapse" class="my-bg-1 p-2">
      <div class="ml-5">
        <b-button @click="isAddingForm=true" size="sm" variant="primary" class="my-3" v-if="$store.getters['isAdmin']">
          <span class="glyphicon glyphicon-plus"></span>
            {{labels.addForm}}
        </b-button>
        <b-button
          :to="'/projectMapManagement/'+project.id"
          variant="primary"
          class="my-3 ml-2"
          size="sm"
          v-if="project.hasOwnProperty('configDb')"
          >
          <span class="glyphicon glyphicon-globe"></span>
            {{labels.manageProjectMap}}
        </b-button>
        <b-button v-else-if="$store.getters['isAdmin']" @click="createProjectConfig" size="sm" class="my-3 ml-2">
          {{labels.createProjectConfig}}
        </b-button>
        <b-button v-if="$store.state.settings.tableStatsViews[project.id]" class="my-3 ml-4" variant="primary" :to="'/project/'+project.id+'/tables'">
          <i class="glyphicon glyphicon-signal"></i>
        </b-button>

        
        <delete-button
              myclass="float-right mr-3" 
              btnclass="float-right"
              size="sm"
              v-if="$store.getters.isAdmin && !project.deleted"
              @delete="deleteProj" 
        ></delete-button>

      </div>
      <ul class="list-group">
        <li v-for="form in orderedForms" class="list-group-item" :key="'form_'+form.id">
          <div>
            {{form.label}}
            <span class="ml-3 small">
              {{form.form_comments}}
            </span>
            <span v-if="form.deleted" class="ml-2 m-deleted">{{labels.deleted}}</span>

            <b-btn  :to="'forms/'+form.id" variant="primary" class="float-right" size="sm" v-b-popover.hover.top="labels.projectForm.view">
              <span class="glyphicon glyphicon-eye-open float-right"></span>
            </b-btn>

            <b-btn @click="addFormLocal(form)" variant="success" class="float-right mr-3" size="sm" v-b-popover.hover.top="labels.projectForm.makeOffLine">
              <span class="glyphicon glyphicon-download-alt float-right"></span>
            </b-btn>

            <delete-button
              myclass="float-right mr-3" 
              btnclass="float-right"
              size="sm"
              v-if="$store.getters.isAdmin && !form.deleted"
              @delete="deleteForm(form)" 
            ></delete-button>

            <!-- update the current definition -->
            <b-btn @click="newDefinition(form)" variant="dark" class="float-right mr-3" size="sm" v-b-popover.hover.top="labels.projectForm.newDefinition" v-if="$store.getters['isAdmin']">
              <span class="glyphicon glyphicon-copy float-right"></span>
            </b-btn>
          </div>
          <b-alert v-if="messageId==form.id" :show="messageFormShow"
            dismissible
            :variant="messageFormVariant"
            class="mt-2"
            >
            {{messageForm}}
          </b-alert>
        </li>
      </ul>
    </b-collapse>
    <b-collapse v-if="isAddingForm" :id="project.id+'add'" v-model="showCollapse" class="my-bg-1 p-2">
      <div class="ml-5 my-2 h6" v-if="currentForm">{{ currentForm.label }}</div>
      <add-form 
        class="ml-5" 
        @end="isAddingForm=false;currentForm=null;" 
        :project="project"
        :form="currentForm"
        ></add-form>
    </b-collapse>
  </li>
</template>

<script>
import addForm from './addForm.vue'
import utils from '../utils/utils_gen.js'
import deleteButton from './utils/deleteButton.vue'

export default {
  name:'project',
  components:{
    addForm,
    deleteButton
  },
  props:['project','opened', 'showDeleted'],
  data () {
    return {
      showCollapse: this.opened,
      isAddingForm:false,
      addFormValues:null,
      messageId:null,
      messageForm:null,
      messageFormVariant:null,
      messageFormShow:0,
      currentForm:null
    }
  },
  created(){
    if(this.$store.state.project.project_id==this.project.id){
      this.showCollapse=true
    }
  },
  computed: {
    systemDoc(){return this.$store.state.systemDoc},
    labels(){return this.$store.state.labels},
    orderedForms(){
      return this.project.forms.filter(x=>{
        if(x.deleted && this.showDeleted===false){
          return false
        }
        return true
      }).sort((a,b)=>{
        if(utils.isNumeric(a.form_no) && utils.isNumeric(b.form_no)){
          if(Number(a.form_no)<Number(b.form_no)){
            return -1
          }
        }
        else if(a.form_no<b.form_no){
          return -1
        }
        return 1
      })
    },
  },
  methods:{
    addFormLocal(form){
      this.$store.dispatch('app_message','Begin add form local: ' + form.id)

      this.$store.commit('form/form_id',null) // may cause a refresh, so want to refresh the version number.
      let form2= Object.assign({},form)
      form2.project=this.project
      this.messageId=form.id
      this.messageForm=this.labels.project_addLocallyInprogress
      this.messageFormVariant='warning'
      this.messageFormShow=true
      this.$store.dispatch('localDB/addFormLocal',form2).then(rep=>{
          this.messageId=form.id
          this.messageForm=this.labels.project_addLocally
          this.messageFormVariant='success'
          this.messageFormShow=true
      }).catch(err=>{
          this.$store.dispatch('app_message_error',err)
          this.messageId=form.id
          this.messageForm=err
          this.messageFormVariant='danger'
          this.messageFormShow=true
      })
    },
    deleteForm(form){
      this.$store.dispatch('app_message','Begin delete form: ' + form.id)
      this.$store.commit('form/form_id',null)
      let db=this.$store.state.dbDatabaseSystem
      //read the definition content (uploaded file)
      db.get('config').then(doc=>{
        // witht the current doc delete the form
        let form2=doc.projects
        .filter(x=>x.id==form.project_id)[0].forms
        .filter(x2=>x2.id==form.id)[0]
        form2.deleted=true
        form2.deletedTime=this.$store.getters['utcCurrentTime']()
        return db.put(doc)
      }).then(res=>{
        // if all ok, then end and reread the config doc to update the UI
        this.$store.dispatch('getSystemDoc')
      }).catch(err=>this.$store.dispatch('app_message_error',err))
    },
    deleteProj(){
      this.$store.dispatch('app_message','Begin delete proj: ' + this.project.id)
      this.$store.commit('form/form_id',null)
      let db=this.$store.state.dbDatabaseSystem
      //read the definition content (uploaded file)
      db.get('config').then(doc=>{
        // witht the current doc delete the form
        let proj2=doc.projects.filter(x=>x.id==this.project.id)[0]
        proj2.deleted=true
        proj2.deletedTime=this.$store.getters['utcCurrentTime']()
        return db.put(doc)
      }).then(res=>{
        // if all ok, then end and reread the config doc to update the UI
        this.$store.dispatch('getSystemDoc')
      }).catch(err=>this.$store.dispatch('app_message_error',err))
    },
    createProjectConfig(){
      this.$store.dispatch('app_message','Begin create project config: ' + this.project.id)
      this.$store.dispatch('project/createNewProjectConfig',this.project.id).then(rep=>{
        //go there
        this.$router.push('/projectMapManagement/'+this.project.id)
      }).catch(err=>this.$store.dispatch('app_message_error',err))
    },
    newDefinition(form){
      this.$store.dispatch('app_message','Begin add form new definition: ' + form.id)
      this.$store.commit('form/form_id',null)
      this.currentForm=form
      this.isAddingForm=true
    }
  }
}
</script>

<style scoped>
  .my-bg-1{
    background-color: #c6c8ca;
  }
  .m-deleted{
    color: crimson;
    font-style: italic;
  }
</style>

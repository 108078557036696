<template>
  <div class="container-fluid" v-if="formConfig">
    <div class="h2  text-center">
      Sync / {{formConfig.label}}
    </div>
    <b-card no-body>
      <b-tabs card>
        <b-tab :title="labels_sync.syncPush">
          <sync-push :form_id="form_id"></sync-push>
        </b-tab>
        <b-tab :title="labels_sync.syncPull">
          <sync-pull :form_id="form_id"></sync-pull>
        </b-tab>
        <!-- TODO: tab to see sync_ docs... the remotes ones -->
        <b-tab :title="labels.manageProjectMapLabels.quota">
          <!-- Quota -->
          <div class="pt-5"><quota></quota></div>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
// import base from './_base.js'
import syncPush from '../../components/sync/syncPush.vue'
import syncPull from '../../components/sync/syncPull.vue'
import quota from '../../components/utils/quota.vue'
import base from './_base.js'

export default {
  name:'form_sync',
  mixins:[base],
  props:['form_id'],
  components:{
    syncPush,
    syncPull,
    quota,
  },
  computed: {
    labels_sync() {
      return this.$store.state.labels.form.sync
    },
    labels() {
      return this.$store.state.labels
    },
    formConfig(){return this.$store.state.form.actualFormConfig},
  },
}
</script>

<style>

</style>

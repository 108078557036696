<template>
  <div>
    <svg id="Layer_1" style="enable-background:new 0 0 612 792;" version="1.1" viewBox="0 0 2012 792" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><g><defs><rect height="512" id="SVGID_1_" width="512" x="50" y="140"/></defs><clipPath id="SVGID_2_"><use style="overflow:visible;" xlink:href="#SVGID_1_"/></clipPath><path class="st0" d="M306,629.5c128.8,0,233.5-104.7,233.5-233.5S434.8,162.5,306,162.5S72.5,267.2,72.5,396    S177.2,629.5,306,629.5L306,629.5z"/></g><polygon class="st1" points="421.4,271 241.9,450.5 174.9,383.5 122,436.4 241.9,556.2 257.3,540.8 257.4,540.8 474.3,323.9    421.4,271  "/></g></svg>
  </div>
</template>

<script>
// https://www.shareicon.net/check-yes-correct-success-circle-tick-mark-817722
export default{

}

</script>


<style type="text/css">
	.st0{clip-path:url(#SVGID_2_);fill:none;stroke:#41AD49;stroke-width:45;}
	.st1{fill:#41AD49;}
</style>

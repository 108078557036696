var render, staticRenderFns
import script from "./BFormInputMaskAutonumeric.vue?vue&type=script&lang=js"
export * from "./BFormInputMaskAutonumeric.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b006be0",
  null
  
)

export default component.exports
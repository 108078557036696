var wgs84Sphere = new ol.Sphere(6378137)

export default {
  project_geom(geom,sourceProj='EPSG:3857',toProj='EPSG:4326'){
    try {
      return ol.proj.transform(geom, sourceProj, toProj)
    } catch (error) {
      return null
    }
  },
  getLength(line, sourceProj){
    var coordinates = line.getCoordinates();
    var length = 0;
    // var sourceProj = map.getView().getProjection();
    for (var i = 0, ii = coordinates.length - 1; i < ii; ++i) {
        var c1 = ol.proj.transform(coordinates[i], sourceProj, 'EPSG:4326');
        var c2 = ol.proj.transform(coordinates[i + 1], sourceProj, 'EPSG:4326');
        length += wgs84Sphere.haversineDistance(c1, c2);
    }
    return length
    // var output;
    // if (length > 1000) {
    //     length=length/1000
    //     output = (Math.round(length * 100) / 100) + ' ' + 'km';
    // } else {
    //     output = (Math.round(length * 100) / 100) + ' ' + 'm';
    // }
    // return output;
  },
  getArea(polygon, sourceProj){
    var area = 0;//= polygon.getArea();
    // var sourceProj = map.getView().getProjection();
    var geom = /** @type {ol.geom.Polygon} */ (polygon.clone().transform(
        sourceProj, 'EPSG:4326'));
    var coordinates = geom.getLinearRing(0).getCoordinates();
    area = Math.abs(wgs84Sphere.geodesicArea(coordinates));
    return area
    // var output;
    // if (area > 1000000) {
    //     area=area/1000000
    //     output = (Math.round(area * 100) / 100) +' ' + 'km<sup>2</sup>';
    // } else {
    //     output = (Math.round(area * 10) / 10) + ' ' + 'm<sup>2</sup>';
    // }
    // return output;
  },
}

// as formMap in index.js
// represent the map object for a particular form.
import cuid from 'cuid'
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    geolocation_position:null, // hold the position of GPS. Could come from Position... Set by position.
    // ****** form interraction
    formComponent:null,
    formComponent_response:null,
    // Live are components that are not removed when the map is deactivated
    formComponentLive:{},
    formComponentLiveResponse:null,
  },
  mutations: {
    geolocation_position(state,newPosition){
      state.geolocation_position=newPosition
    },
    formComponent(state,new_formComponent){
      state.formComponent=new_formComponent
      if(new_formComponent){
        //not null - we want to set a response. In case back is pressed. so we get the reponse changed. see below.
        state.formComponent_response='pending'
      }else if (state.formComponent_response=='pending') {
        //if form component is null and response = pending - back has been pressed
        // because the map is deactivated, the form_components is set to null and the response has not changed
        // so we change the response to ensure that the calling component get a watch event on the response.
        state.formComponent_response=null
      }
    },
    formComponent_response(state,new_formComponent_response){
      state.formComponent_response=new_formComponent_response
    },
    addFormComponentLive(state,new_formComponent){
      Vue.set(state.formComponentLive,new_formComponent.properties.liveId,{response: null,component:new_formComponent}) 
    },
    removeFormComponentLive(state,componentId){
      Vue.delete(state.formComponentLive,componentId)
    },
    setFormComponentLiveResponse(state,response){
      Vue.set(state.formComponentLive[response.componentId],'response',response.response) 
    },
  },
  actions: {
    clearSurveyLiveComponents(context){
      // remove pending formMap live components
      // concat to avoid being fired or interupted during process... to see if necessary or ok....
      context.getters.formComponentLiveIds.concat([]).map(id=>{
        if(context.state.formComponentLive[id].component.type=='survey'){
          context.commit('removeFormComponentLive',id)
        }
      })
    },
  },
  getters: {
    survey_id(state){
      if(state.formComponent){
        return state.formComponent.properties.survey_id || null // null should not happen
      }
      return null
    },
    formComponentLiveIds(state){
      return Object.keys(state.formComponentLive)
    },
    surveyComponentLiveIds(state,getters){
      return getters.formComponentLiveIds.filter(id=>{
        if(state.formComponentLive[id].component.type=='survey'){
          return true
        }
        return false
      })
    }
  }
}

<template>
  <b-navbar toggleable="md" fixed="top" type="dark" variant="info">
    <!-- class="navbar navbar-expand-lg navbar-light bg-light fixed-top "> -->
    <b-navbar-brand to="/" class="mr-2 d-none d-md-block">

      <img
          height="30"
          src="static/medias/aecom-logo.svg" />
    </b-navbar-brand>

    <b-navbar-brand class="d-block d-lg-none d-xl-none d-md-none">
      <b-nav-form>
        <b-button-group class="">
          <b-button variant="info" @click="$router.go(-1)"><span class="glyphicon glyphicon-arrow-left"></span></b-button>
          <!-- <b-button variant="info" to="/" :exact="true"><span class="glyphicon glyphicon-home"></span></b-button> -->
          <!-- <b-button variant="info" to="/forms" :exact="true"><span class="glyphicon glyphicon-phone"></span></b-button> -->
          <b-button variant="info"
                    :to="'/forms/'+$store.state.form.form_id"
                    :disabled="$store.state.form.form_id?false:true"
                    :exact="true"
                    ><span class="glyphicon glyphicon-file"></span></b-button>
          <b-button variant="info"
                    :to="'/forms/'+$store.state.form.form_id+'/map'"
                    :disabled="$store.state.form.form_id?false:true"
                    :exact="true"
                    v-if="$store.getters['form/isMapActive']"
                    ><span class="glyphicon glyphicon-globe"></span></b-button>
        </b-button-group>
      </b-nav-form>
    </b-navbar-brand>


    <b-nav-form class="d-block d-lg-none d-xl-none d-md-none m-0">
      <nav-pages></nav-pages>
    </b-nav-form>

    <b-navbar-toggle target="navbarSupportedContent"></b-navbar-toggle>

    <b-collapse is-nav id="navbarSupportedContent">
      <b-navbar-nav>
        <!-- add exact unless, all parent route are active -->
        <b-nav-item to="/" :exact="true">{{labels.navbar.formsLink}}</b-nav-item>
        <b-nav-item to="/forms" :exact="true">{{labels.navbar.formsLinkOffile}}</b-nav-item>
        <b-nav-item
                  :to="'/forms/'+$store.state.form.form_id"
                  :disabled="$store.state.form.form_id?false:true"
                  :exact="true"
                  >{{labels.navbar.currentForm}}</b-nav-item>
        <b-nav-item :to="'/forms/'+$store.state.form.form_id+'/map'"
          :disabled="$store.state.form.form_id?false:true"
          v-if="$store.getters['form/isMapActive']"
          :exact="true"
          >{{labels.navbar.map}}</b-nav-item>
        <b-nav-form class="ml-5">
          <nav-pages></nav-pages>
        </b-nav-form>
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto">
        <b-nav-item-dropdown right >
          <template slot="button-content" >
            <span class="mr-2 glyphicon glyphicon-option-vertical"
              v-b-tooltip.hover title="Autres..."></span>
          </template>
          <li class="dropdown-header" v-if="$store.state.isLogged===true">
            <span class="glyphicon glyphicon-user actions" aria-hidden="true"></span>
            <span id="username">{{ labels.navbar.user }}: {{user}}</span>
          </li>
          <b-dropdown-item @click="login()" v-if="$store.state.isLogged!==true">
            <span class="glyphicon glyphicon-log-out actions" aria-hidden="true" >
            </span>
            {{labels.navbar.login}}
          </b-dropdown-item>
          <b-dropdown-item @click="logout()" v-if="$store.state.isLogged===true">
            <span class="glyphicon glyphicon-log-out actions" aria-hidden="true" >
            </span>
            {{labels.navbar.logout}}
          </b-dropdown-item>
          <b-dropdown-divider>div</b-dropdown-divider>
          <!-- Language -->
          <b-dropdown-item @click="$store.dispatch('switchLanguage','fr')" v-if="$store.state.language=='en'">
            <span class="mdi mdi-translate actions mr-1" aria-hidden="true" >
            </span>Français
          </b-dropdown-item>
          <b-dropdown-item @click="$store.dispatch('switchLanguage','en')" v-if="$store.state.language=='fr'">
            <span class="mdi mdi-translate actions mr-1" aria-hidden="true" >
            </span>English
          </b-dropdown-item>
          <b-dropdown-divider v-if="$store.getters['form/formLanguages'].length>0">div</b-dropdown-divider>
          <!-- Form language, the form language might not be implemented by the app -->
          <b-dropdown-item @click="$store.dispatch('form/setFormLanguage',formLanguage)" v-for="formLanguage in $store.getters['form/formLanguages']" :key="formLanguage">
            <span class="mdi mdi-check-bold" v-if="formLanguage == $store.state.form.formLanguage"></span>
            <span class="mdi mdi-form-select"></span>
            <span class="mdi mdi-translate actions mr-1">
            </span>{{formLanguage!='default' ? formLanguage: labels.navbar.defaultFormLanguage }}
          </b-dropdown-item>
          <!-- admin / config -->
          <b-dropdown-divider>div</b-dropdown-divider>
          <b-dropdown-item href="#/configuration" v-if="$store.state.isLogged===true">
            <span class="mdi mdi-cog mr-1"></span>
            {{labels.navbar.configuration}}
          </b-dropdown-item>
          <b-dropdown-item href="#/admin/sql" v-if="$store.getters.isAdmin">
            <span class="mdi mdi-database mr-1"></span>
            {{labels.navbar.adminSql}}
          </b-dropdown-item>
          <b-dropdown-item href="#/admin/devices" v-if="$store.getters.isAdmin">
            <span class="mdi mdi-devices mr-1"></span>
            {{labels.navbar.adminDevices}}
          </b-dropdown-item>
          <b-dropdown-item href="#/admin/projects" v-if="$store.getters.isAdmin">
            <span class="mdi mdi-list-box mr-1"></span>
            {{labels.navbar.adminProjects}}
          </b-dropdown-item>
          <b-dropdown-item href="#/admin/users" v-if="$store.getters.isAdmin">
            <span class="mdi mdi-account-multiple mr-1"></span>
            {{labels.navbar.adminUsers}}
          </b-dropdown-item>
          <b-dropdown-item href="#/help">
            <span class="mdi mdi-help mr-1"></span>
            {{labels.navbar.help}}
          </b-dropdown-item>
          <b-dropdown-item @click="refresh" class="mt-3">
            <span class="mdi mdi-refresh"></span>
            {{labels.navbar.refresh}}
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>



  </b-navbar>
</template>

<script>
import navPages from './navbar_form_pages.vue'
export default {
  name: 'navbar',
  components: {
    navPages:navPages
  },
data(){
  return {
    searchText:''
  }
},
  computed: {
    user() {
      return this.$store.getters['userName']
    },
    labels(){return this.$store.state.labels}
  },
  methods: {
    logout() {
      this.$store.dispatch('logout').then(()=>{
        this.$router.push("/login")  
      })
    },
    login() {
      this.$router.push("/login")
    },
    refresh(){
      location.reload()
    }
  }
}
</script>

<style scoped>
  .glyphicon.actions {
    top: 2px !important;
    right: 10px !important;
  }

  .btn-lg.button1 .glyphicon {
    right: 5px !important;
  }
  .my-pad-right{
    padding-right: 8px;
  }
  .my-pad-left{
    padding-left: 8px;
  }
</style>

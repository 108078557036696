// this file have utilities function for the options in the form
// those utilities are mostly aecom supplement.
import utils from './utils_gen.js'

export default{
    surveyGetAllValues(surveyDoc,aecom_select, namePath){
            
        // in the provided survey, get all the values of a particular field. mostly used with a repeat field
        const data= utils.flattenObject(surveyDoc.form_data)
        const rep=[]
        const repRemove=[]
        let showLabel=aecom_select.choices_show
        let paramsFromData = showLabel?showLabel.match(/\${(.*?)}/g):null
        const testFieldName = (name, testName, namePathCondition)=>{
            if(namePathCondition){
                return name.endsWith('.'+testName) && name.startsWith(namePathCondition)
            }
            return name.endsWith('.'+testName) || name==testName
        }
        const varNameSplit = aecom_select.choices_from_name.split('@')
        const varName = varNameSplit[0]
        let namePathCondition = null
        if(varNameSplit.length>1 && varNameSplit[1].startsWith('onlyCurLevel-')){
            // we have a condition to only get the current level
            const rootCondition = varNameSplit[1].split('-')[1]
            let namePathCondition0 = []
            for (let i = 0; i < namePath.length; ++i) {
                const namePathEl = namePath[i];
                if(namePathEl == rootCondition){
                    namePathCondition0.push(namePathEl)
                    if( namePath.length>(i+1) && typeof namePath[i+1] === 'number' ){
                        // the repeat number
                        namePathCondition0.push(namePath[i+1])
                    }
                    break
                }else{
                    namePathCondition0.push(namePathEl)
                }
            }
            namePathCondition = namePathCondition0.join('.')
        }
        Object.keys(data).map(x=>{
            if( testFieldName(x, varName, namePathCondition) && data[x]){ // marcch and ensure we have a value
                if(showLabel){
                    // aecom_select.choices_show is an expression in the form of ${chef_menage} (${chef_concession})
                    let elShowLabel=showLabel
                    const key1=x.split('.').slice(0,-1).join('.')
                    paramsFromData.map(x2=>{
                        let varName = x2.match(/[^${}]+/g)
                        // get the first part of the expression so we can find the others
                        if(data[key1+'.'+varName]){
                            //same level variable
                            elShowLabel = utils.replaceAll(elShowLabel, x2, data[key1+'.'+varName])
                        }else{
                            // TODO: support more levels... but in the XLSX we can still put a calculated field to do the job
                            //not supported - only support for now same level variable
                            elShowLabel = utils.replaceAll(elShowLabel, x2, '')
                        }
                    })
                    rep.push({name:data[x],label:elShowLabel})
                }else{
                    // no label
                    rep.push({name:data[x]})
                }
            }
            if(aecom_select.remove && testFieldName(x, aecom_select.remove) && data[x]){
                repRemove.push(data[x])
            }
        })
        // console.log(repRemove);
        
        // TODO: Test more and take into account that my value has to be there(id we remove previously done...)
        // if(regexRemove){
        //     return rep.filter(x=>{
        //         return repRemove.indexOf(x.name)===-1
        //     })
        // }
        return rep
    },
    getDefinitionChoices(config, formDefinition){
        let opt=[]
        if(config.hasOwnProperty('choices')){
            opt= config.choices
        }else if(config.hasOwnProperty('itemset')){
            opt= formDefinition.choices[config.itemset]
        }
        return opt
    }
}
<template>
  <div>
    <div>
      <b-table striped hover :items="props" :fields="fields"></b-table>
    </div>
    <div>

    </div>
  </div>
</template>

<script>
import base from './_base.js'

export default {
  name:'form_config',
  mixins:[base],
  data(){
    let labels=this.$store.state.labels
    return {
      sql:null,
      props:null,
      fields:{
        id:{
          sortable: true,
          label:labels.form.configIdCol
        },
        value:{
          label:labels.form.configValueCol
        }
      }
    }
  },
  watch:{
    formConfig:{
      immediate:true,
      handler(){
        if(this.formConfig){
          this.props=[]
          Object.keys(this.formConfig).map(x=>{
            this.props.push({id:x, value:this.formConfig[x]})
          })
        }
      }
    }
  },
  methods:{

  }

}
</script>

<style>

</style>

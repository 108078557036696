<template>
  <div class="">
    <div v-if="me.info && $store.state.form.formDisplayOptions.notePosition=='above-answer'" class="info-text">
      <i class="mdi mdi-information-outline mr-1"></i>
      <span v-html="me.info"></span>
    </div>
  </div>
</template>

<script>
  
  export default {
    name: 'input-row-header',
    components:{
    },
    props:['me'],
    computed: {
      config() {
        return this.me.config
      },
    },
  }
</script>

<style scoped>
  .info-text{
    font-size: 1.1em;
    color: #959575;
  }
</style>
<template>
  <div id="geopoint00" class="mt-1">
    <b-btn v-if="properties.allowGpsButton"
      @click="getFromGPS"
      :variant="GpsAge<15?'primary':'danger'"
      class="mr-2"
      >
      <span class="mdi mdi-crosshairs-gps"/>
    </b-btn>
    <b-button @click="finished()" variant="success" v-if="hasBeenClicked" class="mr-2">
        <span class="mdi mdi-check-bold"></span>
    </b-button>
    <b-button @click="backToForm" variant="info">
        <span class="glyphicon glyphicon-log-out"></span>
    </b-button>
  </div>
</template>

<script>
import base from './_baseComponent.js'
import map_utils from '../map_utils.js'
var unByKeyMouseClick=null

export default {
  name:'map-geopoint',
  mixins:[base],
  data(){
    return {
      vectorLayer:null,
      vectorSource:null,
      hasBeenClicked:false,
    }
  },
  computed:{
    GpsAge(){return this.$store.state.position.gpsAgeSeconds},
  },
  methods:{
    backToForm(){
      this.response=null // we reset the response in case it has changed. if this button, no change.
      this.finished()
    },
    start(){ // it's the watch of the start.
      //when created or updated
      if(this.properties.allowClickMap){
        unByKeyMouseClick=this.map.on('singleclick',this.getFromMap,this)
      }
      if(this.properties.currentValue){
        //we already have a value, display the geometry on th map.
        this.addLayer(this.properties.currentValue)
      }
    },
    getFromMap(evt){
      const pos=evt.coordinate
      const pos2= map_utils.project_geom(pos,this.map.getView().getProjection())
      this.response={
        component_name:'map-geopoint',
        type:'click',
        position:pos2,
      }
      this.finishedPoint()
    },
    getFromGPS(){
      this.response={
        component_name:'map-geopoint',
        type:'gps',
        position:this.$store.state.position.position,
        accuracy:this.$store.state.position.accuracy,
        altitude:this.$store.state.position.altitude,
        time:this.$store.getters['position/timeISO'],
        gps_age:this.$store.state.position.gpsAgeSeconds,
      }
      this.finishedPoint()
    },
    finishedPoint(){
      if(this.properties.inputGeopointClickDirectReturn){
        this.clear()
        this.finished()
      }else{
        this.hasBeenClicked=true
        this.map.removeLayer(this.vectorLayer)
        this.vectorLayer=null
        const obj1={
          type:'Feature',
          properties:{},
          geometry:{
            "type":"Point",
            "coordinates":[this.response.position[0],this.response.position[1]],
          }
        }
        this.addLayer(obj1)
      }
    },
    clear_sub(){
      console.log('geopoint  - clear sub')
      // this don't work in chrome on android...
      // this.map.un('singleclick',this.getFromMap)
      ol.Observable.unByKey(unByKeyMouseClick) //https://gis.stackexchange.com/a/241531/2043
      if(this.vectorLayer){
        this.map.removeLayer(this.vectorLayer)
        this.vectorLayer=null
      }
      this.vectorSource=null
    },
    addLayer(geoJSON){
      console.log('add geojson')
      this.vectorSource = new ol.source.Vector({});
      this.vectorSource.addFeature((new ol.format.GeoJSON()).readFeature(geoJSON,{
        //options
        dataProjection:'EPSG:4326',
        featureProjection:this.map.getView().getProjection()
      }))
      // const fill = new ol.style.Fill({color: 'red'});
      const stroke = new ol.style.Stroke({color: 'red', width: 2});
      this.vectorLayer = new ol.layer.Vector({
        source: this.vectorSource,
        style: new ol.style.Style({
          // image: new ol.style.Circle({
          //   radius: 6,
          //   fill: new ol.style.Fill({
          //     color: '#ff0000'
          //   })
          // })
          image: new ol.style.RegularShape({
            // fill: fill,
            stroke: stroke,
            points: 4,
            radius: 10,
            radius2: 0,
            angle: Math.PI / 4,
          })
        }),
      })
      this.map.addLayer(this.vectorLayer)
    }
  }
}
</script>


<style scoped>
#geopoint{
  position: fixed;
  /* bottom: 0; */
  right: 10px;
  /* left:300px; */
  /* left:0; */
  top:70px;
}
</style>

<template>
  <div :key="project_id">
    <!-- if we do v-if formConfig here or v-show, we have to ensure that the map define is done when the id map is accessible -->
    <!-- see subversion rev <1951 -->
    <div id="map">
    </div>
    <div id="mapcontrols" class="text-right" v-if="map">
      <project-add-survey></project-add-survey>
      <map-position></map-position>
      <b-btn to="./map/tableOfContent" class="mt-1">
        <!-- {{labels.map.tableOfContent}} -->
        <span class="glyphicon glyphicon-th-list"></span>
      </b-btn>
      <!-- <map-isonline></map-isonline> -->
      <map-accuracy></map-accuracy>
      <constraint-polygon></constraint-polygon>
      <!-- Form component controled by the form - edit.vue -->
      <component
        v-if="formComponent"
        :is="formComponent.component_name"
        :properties="formComponent.properties"
        :parent="$data"
      ></component>
      <component
        v-for="id in $store.getters['formMap/formComponentLiveIds']"
        :is="$store.state.formMap.formComponentLive[id].component.component_name"
        :properties="$store.state.formMap.formComponentLive[id].component.properties"
        :parent="$data"
        :key="id"
      ></component>
      <!-- NO identity when we edit features with a formComponent -->
      <map-identity :mapActivatedId="mapActivatedId" v-show="!formComponent"></map-identity>
      <!-- <map-hover></map-hover> -->
    </div>

  </div>
</template>

<script>
import base from '../_base.js'
import map_utils from './map_utils.js'
import map_form_components from './form_components'
import map_components from './components'

export default {
  name:'form_map',
  mixins:[base],
  components:Object.assign(map_components,map_form_components),
  data(){
    return{
      map:null,
      project_id:null,
      mapActivatedId:1,
    }
  },
  mounted(){

  },
  computed:{
    formComponent(){
      return this.$store.state.formMap.formComponent
    }
  },
  watch:{
    isLocal(){
      // database to use changed... reset myself.
      this.project_id = null
    },
  },
  methods:{
    checkMap(){
      console.log('check map')
      return this.fetchFormPromise.then(rep=>{ //wait for the form and project to be fetched
        console.log(this.project_id)
        console.log(this.$store.state.project.project_id)
        if(this.project_id!==this.$store.state.project.project_id){
          this.project_id=this.$store.state.project.project_id
          return this.defineMap()
        }else{
          return Promise.resolve(false)
        }
      }).catch(err=>this.$store.dispatch('app_message_error',err))
    },
    defineMap(){
      console.log('define map')
      return this.$store.dispatch('project/getMap').then(rep=>{
        //this.$store.state.formMap.map
        this.map=this.$store.state.map.map
        this.map.setTarget('map')
        // ** set the map to fit the div
        if(this.$store.state.map.map_config.bounds){
          this.map.getView().fit(this.$store.state.map.map_config.bounds,this.map.getSize())
        }else{
          // quebec bounds
          this.map.getView().fit([-9141947.44,5231225.21,-5688322.70,7415134.82],this.map.getSize())
        }
        return Promise.resolve(true)
      })
    },
    handleResize(event){
      //-Corrected the bug for the auto-hide address bar on mobile
      console.log('map resize ' + event.currentTarget.innerWidth + ' - ' + event.currentTarget.innerHeight)
      //console.log('map resize ' + event.currentTarget.innerWidth + ' - ' + event.currentTarget.innerHeight)
      //this.map.getView().fit(this.map.getView().calculateExtent(),this.map.getSize())
      this.map.updateSize()
    },
  },
  activated(){
    console.log('activated map');
    this.checkMap().then(hasCreatedMap=>{
      if(hasCreatedMap===false){
        console.log('activated map - update size');
        //this.map.getView().fit(this.map.getView().calculateExtent(),this.map.getSize())
        this.map.setTarget('map')//this.$store.state.formMap.div_id)
        // this.map.render()
        this.map.updateSize()
        // Send to the map store the event and them to the layers, some will have actions.
        this.mapActivatedId++
        this.$store.dispatch('map/mapActivated')
      }
    }).catch(err=>this.$store.dispatch('app_message_error',err))
  },
  mounted(){
    console.log('map mounted')
    window.addEventListener('resize',this.handleResize)
  },
  beforeDestroy(){
    window.removeEventListener('resize',this.handleResize)
  },
  deactivated(){
    console.log('deactivated map');
    console.log(this.$router);
    if (this.$router.currentRoute.name!='formMapTableOfContent'){
      this.$store.commit('formMap/formComponent',null)
    }
  }
}
</script>

<style>
#map{
  position: fixed;
  bottom: 0;
  right: 0;
  /* left:300px; */
  left:0;
  top:60px;
}
/* http://dev.openlayers.org/examples/bootstrap.html */
img.olTileImage {
  max-width: none;
}
/* setting the ol-mous-position with the scoped option does not work. */
.ol-mouse-position {
    top: unset !important;
    bottom: 8px !important;
    /* Already defined */
    /* right: 8px;
    position: absolute; */
}

#mapcontrols{
  position: fixed;
  /* bottom: 0; */
  right: 10px;
  /* left:300px; */
  /* left:0; */
  top:70px;
}
</style>

<template>
  <div>
      <h3>
        Error
      </h3>
      <div v-if="$store.state.error_message" class="alert alert-warning">
        {{$store.state.error_message}}
      </div>
      <h3 class="mt-5">
        Messages
      </h3>
      <div v-for="(mess,i) in $store.state.app_messages" class="alert alert-secondary mt-2" :key="i">
        {{mess}}
      </div>
      <!-- <b-btn class="my-4" @click="retry">Retry</b-btn> -->
  </div>
</template>

<script>
export default {
  name:'help',
  methods:{
    retry(){
      this.$store.commit('isLoggedToNull')
      this.$router.push('/')
    }
  }
}
</script>

<style>

</style>

<template>
  <div>
    <table class="table table-striped table-sm">
      <thead>
        <tr>
          <th>name</th>
          <th>label</th>
          <th>order</th>
          <th>device_order</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="el in orderSelectList" :key="'orderList'+el.name">
          <td>{{el.name}}</td>
          <td>{{el.label}}</td>
          <td>{{el.order}}</td>
          <td>
            <!-- <b-form-input size="sm" type="number" v-model="el.device_order"></b-form-input> -->
            <input type="number" class="form-control form-control-sm" v-model="el.device_order" />
          </td>
        </tr>
      </tbody>
    </table>
    <!-- <b-table striped hover
      :items="orderSelect.list"
      :fields="fields"
      >
      <span slot="device_order" slot-scope="data" >
         <b-form-input type="number" v-model="orderSelect[data.index].device_order"></b-form-input>

      </span>
      <span slot="order" slot-scope="data" >
         <b-form-input type="number" v-model="orderSelect[data.index].order"></b-form-input>
      </span>
    </b-table>-->
    <!-- {{orderSelect.list}} -->
  </div>

</template>

<script>

export default{
  data(){
    return {
      fields: [
        {
          key: 'name',
          sortable: true
        },
        {
          key: 'label',
          sortable: true
        },
        {
          key: 'order',
          sortable: true,
        },
        {
          key: 'device_order',
          sortable: true,
        }
      ],
    }
  },
  computed:{
    orderSelect(){
      return this.$store.state.modals.orderSelect
    },
    orderSelectList(){
      return this.$store.state.modals.orderSelect.list
    }
  }
}

</script>

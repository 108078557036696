<template>
  <div class="container-fluid" v-if="project">
    <div class="h2  text-center">
      {{labels.manageProjectMap}} - {{project.project_label}}
    </div>
    <b-card no-body>
      <b-tabs card>
        <b-tab :title="labels.manageProjectMapLabels.manageConfig" active>
          <!-- Manage config tab -->
          <map-layers :project="project"></map-layers>
        </b-tab>
        <b-tab :title="labels.manageProjectMapLabels.quota">
          <!-- Quota -->
          <div class="pt-5"><quota></quota></div>
        </b-tab>
        <b-tab 
          :title="labels.manageProjectMapLabels.manageImagery + ' ' + imageryDoc.label" 
          v-for="imageryDoc in couchImagery"
          :key="'imagery_'+imageryDoc._id"
        >
          <!-- manage imagery tab -->
          <div class="row justify-content-md-center">
            <div class="col-lg-7 mt-4">
              <couch-imagery-db-management :imageryDoc="imageryDoc"></couch-imagery-db-management>
            </div>
          </div>
        </b-tab>
        <b-tab :title="labels.manageProjectMapLabels.seedImagery" v-if="$store.getters.isAdmin">
          <!-- Seed imagery -->
          <seed-imagery :project="project"></seed-imagery>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import couchImageryDbManagement from "../components/ProjectManagement/couchImageryDbManagement.vue"
import mapLayers from "../components/ProjectManagement/mapLayers.vue"
import seedImagery from '../components/ProjectManagement/seedImagery.vue'
import quota from '../components/utils/quota.vue'

export default {
  components: {
    couchImageryDbManagement,
    mapLayers,
    seedImagery,
    quota
  },
  props: ["project_id"],
  data() {
    return {
      couchImagery: null
    }
  },
  computed: {
    systemDoc() {
      return this.$store.state.systemDoc
    },
    labels() {
      return this.$store.state.labels
    },
    project(){
      return this.$store.getters['project/project']
    },
  },
  watch: {
    project_id: {
      immediate: true,
      handler: "fetchProject"
    }
  },
  methods: {
    fetchProject() {
      this.$store.dispatch("project/setProjectId", this.project_id).then(proj => {
        return this.$store.dispatch("project/getImageryDocs")
      }).then(couchImagery => {
        if (couchImagery.length > 0) {
          this.couchImagery = couchImagery
        }
      }).catch(err=>this.$store.dispatch('app_message_error',err))
    }
  }
}
</script>

<style>
</style>

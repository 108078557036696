
var Parser = require('expr-eval').Parser;
//https://github.com/silentmatt/expr-eval
//  var expr = Parser.parse('state==$state2 and county==county3');
//  expr.evaluate({state:'f',$state2:'f',county:'bvbv',county3:'bvbv'})

//also check these:
//only dcompose the expression
//  http://jsep.from.so/
//mathjs can be used, used in EBI, but more heavy 495kb vs 19kb
//  http://mathjs.org/docs/expressions/parsing.html
//  ****** mathjs support more complex evaluation as:
//    var math = require('mathjs');
//    var code1 = math.compile('1+x');
//    code1.eval({x:[5,6]}) //[ 6, 7 ]
//    var code1 = math.compile('sum(x)');
//    code1.eval({x:[5,6]}) //11
//  but not string evaluation as:
//    var code1 = math.compile('state==$state2 and county==county3');
//    code1.eval({state:'f',$state2:'f',county:'bvbv',county3:'bvbv'})
//      Cannot convert "f" to a number
//don't do what we want... but still looked - complex...
//  https://tomazy.github.io/xcell/


//***to extract variables could use:
//https://www.npmjs.com/package/string-tokenizer
//  tokenizer().input('state=${state} and county=${county}').token('test',/\$\{[^\}]*\}/g).resolve()
//but more simple to do:
//  'state=${state} and county=${county}'.match(/\${(.*?)}/g)
//    and
//  'state=${state} and county=${county}'.match(/\${(.*?)}/g)[0].match(/[^${}]+/g)

//*********** check for better regex:
//  http://xregexp.com/
import xregexp from 'xregexp'
// try to solve problem of look behind firefox problem


// import isString from 'lodash/isString'

import utils_gen from './utils_gen.js'
import functionsForParser from './expressionEvaluationFunctions.js'
import cuid from 'cuid'
import optionUtils from './options.js'
import store from '../store'
// hold formatted expression as we are doing it in pure string, so slow, so avoid doing it twice.
// const formatedExpressionsOri=[]
// const formatedExpressions=[]

export default {
  // ************* public functions - what others call
  evaluateExprString(myData,exprString0,otherData,namepath){
    try {
      let {expr,objEvaluation}=buildExprObj(myData,exprString0,otherData,namepath)
      myLog(expr);
      myLog(objEvaluation);
      const rep = expr.evaluate(objEvaluation)
      if(rep===undefined){
        return null
      }
      return rep
    } catch (error) {
      console.error(error)    
      logError(otherData,`ERROR ${namepath.join('.')}: ${exprString0}`)
    }
  },
  filterChoices(surveyDoc, choices, filterString,namepath) {
    try {
      myLog('filter choices')
      // We pass survey doc as sometimes the global status and flag status for the survey is needed.
      let {expr,objEvaluation}=buildExprObj(surveyDoc.form_data, filterString, {surveyDoc:surveyDoc}, namepath)
      return choices.filter(x=>{
        // myLog(x)
        // myLog(expr.evaluate(x))
        return expr.evaluate(Object.assign(objEvaluation,x)) // for the moment, don't use the objEvaluation
      })
    } catch (error) {
      console.error(error)
      logError(otherData,`ERROR ${namepath.join('.')}: ${filterString}`)
    }
  },
  checkRelevant(myData,relevantString,otherData,namepath){
    // check relevant and also the required prop if it's a formula
    try {
      return this.evaluateExprString(myData,relevantString,otherData,namepath)
    } catch (error) {
      console.error(error)      
      logError(otherData,`ERROR ${namepath.join('.')}: ${relevantString}`)
    }
  },
  //check constraint
  checkConstraint(val,constraint,myData,otherData,namepath){
    try {
      myLog('constraint / ' + val + ' / ' + constraint)
      myLog(val)
      //  Repalce currrent value with a slug so it can be evaluated
      const id='a'+cuid.slug()
      // TODO: Could be better done, as some times the . could be within '' in regex expression
      let expr0=xregexp.replace(constraint, xregexp(/([^\d\\a-zA-Z\}\]])(\.)|^\./gm) , '$1 '+id ,'all')
      expr0=replaceAll(expr0,id,'${'+namepath[namepath.length-1]+'}')
      expr0=xregexp.replace(expr0, xregexp(/\bFALSE\b(?!')/gm) , 'false' ,'all')
      return this.evaluateExprString(myData,expr0,otherData,namepath)
    } catch (error) {
      console.error(error)
      logError(otherData,`ERROR ${namepath.join('.')}: ${constraint}`)
    }
  },
  // ************* end - public functions - what others call
}

// ************************************** 
//   static functions
// ************************************** 
const getValue=function(varName,myData,otherData,namepath){
  //support for modifier, which allow to get values with different namepath (usually, but could support more options)
  if(varName.indexOf('@')!==-1){
    // we have a modifier - what is it
    const varSplit=varName.split('@')
    if(varSplit[1]=='all'){
      //all values with this name, se sent an empty path.
      myLog('all')
      return getValueNormal(varSplit[0],myData,otherData,[])  
    }else if(varSplit[1]=='onlyCurRepeat'){
      return getValueNormal(varSplit[0],myData,otherData,namepath, 'onlyCurRepeat')
    }else if(varSplit[1].startsWith('onlyCurLevel-')){
      return getValueOnlyLevel(varSplit[0],myData,otherData,namepath, varSplit[1].split('-')[1])
    }else if(varSplit[1]=='parent'){ 
      // Return the parent of the current element
      return utils_gen.getPropTree2(myData,namepath.slice(0,-1))
    }else if(varSplit[1]=='n'){
      // it's the current repeat n value
      for (let i = namepath.length-1; i >0 ; i--) {
        if(typeof namepath[i] == 'number'){
          return namepath[i]
        }
      }
    }
  }else{
    return getValueNormal(varName,myData,otherData,namepath)
  }
}

//normal get value, we search for a given value with no modifier
const getValueNormal=function(varName,myData,otherData,namepath, param){
  myLog(varName)
  // not in a repeat // all values that are not array - so no repeat considered
  let newVal=otherData.myDataFlattenObjectNoTree[varName]// myDataFlattenObjectNoTree calculated previously
  // as a single prop in the depth of my childs - in the name path use full if in a repeat
  let repeatNamePath = utils_gen.getLastArrayNamePath(namepath)
  if(newVal===undefined && repeatNamePath.length != 0){
    // our name path is in a repeat, so first check if our value is within this repeat...
    let [tree,treeName]=getTreeName(repeatNamePath,0)
    if(otherData.hasOwnProperty('type0.'+treeName)===false){
      otherData['type0.'+treeName]=utils_gen.flattenObjectNoTree(utils_gen.getPropTree2(myData,repeatNamePath))
    }
    newVal=otherData['type0.'+treeName][varName]
    if(param == 'onlyCurRepeat'){ // we don't dig deeper to find a value even if emtpy
      return newVal
    }
  }
  // as a single prop in my tree path
  if(newVal===undefined){
    //check first for the direct value of parents.
    for(let i=0;i<=namepath.length;i++){ // from 1, as 0 would represent the current element.
      //for as we want to exit the loop if we found what we need
      let [tree,treeName]=getTreeName(namepath,i)
      myLog(tree)
      let obj1=utils_gen.getPropTree2(myData,tree)
      if(obj1 && obj1.hasOwnProperty(varName)){
        newVal=obj1[varName]
        break
      }
    }
  }
  if(newVal===undefined){
    //now check if it's a value within our object path
    for(let i=0;i<=namepath.length;i++){
      //for as we want to exit the loop if we found what we need
      const [tree,treeName]=getTreeName(namepath,i)
      //check if already calculated
      if(otherData.hasOwnProperty('type1.'+treeName)===false){
        otherData['type1.'+treeName]=utils_gen.flattenObjectNoTree(utils_gen.getPropTree2(myData,tree))
        myLog('type1.'+treeName);
        myLog(otherData['type1.'+treeName]);
      }
      let obj1=otherData['type1.'+treeName]
      if(obj1.hasOwnProperty(varName)){
        newVal=obj1[varName]
        break
      }
    }
  }
  //TODO: Replace the previous one, if not an array, should have less processing - see implications
  if(newVal===undefined){
    //now check if it's a value array within our object path
      //we want to deep check all the possible values
      //condition on the repeat master object
      //as a repeat don't have duplicates
    for(let i=0;i<=namepath.length;i++){
      //for as we want to exit the loop if we found what we need
      const [tree,treeName]=getTreeName(namepath,i)
      //check if already calculated
      if(otherData.hasOwnProperty('type2.'+treeName)===false){
        otherData['type2.'+treeName]=utils_gen.flattenObjectValueArray(utils_gen.getPropTree2(myData,tree))
        
        myLog('type2.'+treeName);
        myLog(myData)
        myLog(utils_gen.getPropTree2(myData,tree))

        myLog(otherData['type2.'+treeName]);
      }
      let obj1=otherData['type2.'+treeName]
      if(obj1.hasOwnProperty(varName)){
        newVal=obj1[varName]
        break
      }
    }
  }
  return newVal
}

const getValueOnlyLevel=function(varName,myData,otherData,namePath, rootCondition){
  let namePathCondition0 = []
  for (let i = 0; i < namePath.length; ++i) {
      const namePathEl = namePath[i];
      if(namePathEl == rootCondition){
          namePathCondition0.push(namePathEl)
          if( namePath.length>(i+1) && typeof namePath[i+1] === 'number' ){
              // the repeat number
              namePathCondition0.push(namePath[i+1])
          }
          break
      }else{
          namePathCondition0.push(namePathEl)
      }
  }
  const namePathCondition = namePathCondition0.join('.')
  const testFieldName = (name, testName, namePathCondition)=>{
    return name.endsWith('.'+testName) && name.startsWith(namePathCondition)
  }
  // Should we cache that in otherData?
  const data= utils_gen.flattenObject(myData)
  const rep=[]
  Object.keys(data).map(x=>{
    if( testFieldName(x, varName, namePathCondition)){ // marcch and ensure we have a value        
      rep.push(data[x])
    }
  })
  return rep
}

const getTreeName=function(namepath,i){
  if(i===0){
    return [namepath, namepath.join('.')]
  }
  const a=namepath.slice(0,-1*i)
  return [a,a.join('.')]
}

const getChoices=function(name,formDefinition){
  const xField = store.getters['form/actualFormDefitionFieldsFlatten'].filter(x=>x.field.name==name)[0]
  if(xField){
    return optionUtils.getDefinitionChoices(xField.field, formDefinition)
  }
  return []
}

const replaceAll = utils_gen.replaceAll
const formatValue=function(val){
  // if(Array.isArray(val)){
  //   return val
  // }else if(isNaN(val)===false){ //after array, as isNaN([null])=false
  //   //transform string number to numbers...
  //   return +val
  // }
  if(val===undefined){
    return null
  }
  return val
}

const replaceFormDefinitionChoices=function(exprString,otherData){
  //useful to get selected choice other attribute than name.
  //this function only return the choices with all attributes.
  // use with choicesAttributes function usually
  let paramsFromData = exprString.match(/@{(.*?)}/g)
  const obj={}
  if(paramsFromData){
    paramsFromData.map((x) => {
      myLog('choices: ' + x)
      let varName = x.match(/[^@{}]+/g)[0] // the match function return array, so get the first one.
      obj['choices_'+varName]=getChoices(varName,otherData.formDefinition)
      exprString = replaceAll(exprString, x, 'choices_'+varName)
    })
  }
  return {exprString3:exprString,objEvaluation3:obj}
}
const formatExprString_constants=function(exprString){
  // ****** prepare the expression
  exprString=replaceAll(exprString, 'count-selected', 'countselected')// function with - inside will not work.
  //***** need to replace = by ==
    //exprString=this.replaceAll(exprString, '=', '==')
    //exprString=exprString.replace(/(?<![!<>=])[=](?![<>=])/g,'==')
    //console.log(exprString)
    //crash in firefox
    //exprString=xregexp.replace(exprString,xregexp(`(?<![!<>=])[=](?![<>=])`),'==','all')

    // This work in firefox :) - don'T know if the xregexp necessary, but use it.
    //https://stackoverflow.com/a/17067209/140384
  exprString=xregexp.replace(exprString,xregexp(`([^=:<>!])=(?!=)`),'$1==','all')
  // testing in node - the ?: don't work, the caracter before the = disapear.
  /*
  var a = require('xregexp')
  a.replace('656!=656=344 dsaf = fdsfa ==345545',/(?:[^!<>=])([=])(?:[^<>=])/,'==')
  a.replace('656!=656>=344 dsaf = fdsfa ==345545(fdf<=)',/(?:[^!<>=])([=])(?:[^<>=])/,'==','all')
  a.replace('656!=656=344 dsaf = fdsfa ==345545(fdf<=)',/(?<![!<>=])[=](?![<>=])/,'==','all')
  */ 
  // for regex, escape must be double
  exprString=xregexp.replace(exprString,xregexp(`([^\\\\])\\\\(?!\\\\)`),'$1\\\\','all')
  return exprString
}

const formatExprString=function(myData,exprString,namepath,otherData){
  //mydata is the whole survey data.
  //namepath refer to the path of the current element 
  //ortherData holds current myData representation: ** changed here, but as it's a reference, should be kept
  //  -flattenObjectNoTree
  //  -flattenObjectValueArray
  // ******** change parameters for value identifier
  let values={}
  let paramsFromData = exprString.match(/\${(.*?)}/g)
  if(paramsFromData){
    if(otherData.hasOwnProperty('myDataFlattenObjectNoTree')===false){
      //calculate at the begining, as it's always check first
      otherData.myDataFlattenObjectNoTree=utils_gen.flattenObjectNoTree(myData)
      // Add here some global values that are not in the survey data
      otherData.myDataFlattenObjectNoTree.form_status_global=otherData?.surveyDoc?.form_status_global
      otherData.myDataFlattenObjectNoTree.survey_valid=otherData?.surveyDoc?.survey_valid
      otherData.myDataFlattenObjectNoTree.form_flags_status_global=otherData?.surveyDoc?.form_flags_status_global
    }
    paramsFromData.map((x, index) => {
      myLog(x)
      let varName = x.match(/[^${}]+/g)[0] // the match function return array, so get the first one.
      let newVal=getValue(varName,myData,otherData,namepath)
      myLog('newVal')
      myLog(newVal)
      let valueId='b'+cuid.slug()
      exprString = replaceAll(exprString, x, valueId)
      values[valueId]=formatValue(newVal)
    })
  }
  //replace constants
  exprString=formatExprString_constants(exprString)
  return {exprString:exprString,values:values}
}

const buildExprObj=function(myData,exprString0,otherData,namepath){
  let {exprString,values}=formatExprString(myData,exprString0,namepath,otherData)
  // myLog(exprString + ' -after replace values')
  let {exprString2,objEvaluation}=replaceArrays(exprString) // we replace the array we have as given string
  // myLog(exprString2 + ' -afterArrays')
  let {exprString3,objEvaluation3}=replaceFormDefinitionChoices(exprString2,otherData)
  var parser = new Parser()
  let ObjEval=Object.assign(objEvaluation,objEvaluation3,functionsForParser,values)
  myLog('object passed to evaluation')
  myLog(exprString3)
  var expr = parser.parse(exprString3)
  myLog(expr)
  myLog(ObjEval)
  return {expr:expr,objEvaluation:ObjEval}
}

const replaceArrays=function(exprString){
  // https://stackoverflow.com/a/11503678/140384
  // we have to avoid matching brackets in regex string: regex( ${a3q_num_tel}, '^[0-9]{4}[0-9]{4}[0-9]{2}$')
  // But this does not look like it's working: regex( [34,54] ${a3q_num_tel}, '^[0-9]{4}[0-9]{4}[0-9]{2}$', [56,78,4])
  // const paramsFromData = exprString.match(/\[(.*?)\](?=(?:[^\']*\'[^\']*\')*[^\']*\Z)/g)
  // https://stackoverflow.com/a/23641809/140384
  // Capture all, but has to remove the ones begining with '
  const paramsFromData = exprString.match(/(\'[^\']*\')|\[(.*?)\]/g)

  const obj={}
  if(paramsFromData){
    paramsFromData.map((x, index) => {
      //  has to remove the onew between quotes:
      if (x.indexOf("'")!=0){
        myLog(x)
        exprString=replaceAll(exprString, x, 'a'+index)
        //repalce ' by "" for parse - unless errors
        myLog(x + 'array replaced to json parse')
        const x2=replaceAll(x, "'", '"') // xlsx file could have one or the other.
        obj['a'+index]=JSON.parse(x2);
      }
    })
  }
  return {exprString2:exprString,objEvaluation:obj}
}

const logError=(otherData, error)=>{
  if(!otherData.errors){
    otherData.errors = []
  }
  otherData.errors.push(error)
}

const myLog=function(message){
  // for testing, it really slow donw the app.
  // console.log(message)
}
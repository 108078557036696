// as map in index.js

//represent the current map
//one map - but many layers
//it has to be directly below root in the namespace as layer use context.rootState for example.

import layer from './layer.js'
import layer_vector from './layer_vector.js'
import layer_vector_couchdb from './layer_vector_couchdb.js'
import layer_imagery from './layer_imagery.js'
import layer_imagery_couchdb from './layer_imagery_couchdb.js'
import labels from './map_labels_fr.js'

import cuid from 'cuid'
import store from '../index.js'

export default{
  namespaced: true,
  state(){
    return{
      labels:labels,
      projectProperties:null,

      //the project also hold the map
      map_config:null,
      map:null,
      layers_all:null,
      layers_basemap:null,
    }
  },
  getters:{
    projection(state){
      if(state.map){
        return state.map.getView().getProjection()
      }
    },
    layers(state){
      if(state.layers_all){
        let bases=state.layers_basemap
        return state.layers_all.filter(x=>{
          return bases.indexOf(x)>-1?false:true
        }).reverse()
      }
    },
    layers_basemap(state){
      return state.layers_basemap
    },
    resolution(state){
      if(state.map){
        return state.map.getView().getResolution()
      }
    }
  },
  mutations:{

  },
  actions:{
    getMap(context,config){
      context.state.map_config=config
      context.state.layers_all=[]
      context.state.layers_basemap=[]
      let map = new ol.Map({
          controls: ol.control.defaults(config.controls_defaults),
          pixelRatio: 1,
          // layers:[null],//insert a null layer as basemap
          view: new ol.View({
              projection: new ol.proj.Projection(config.projection_params),
              enableRotation: false,
          })
      })
      context.state.map=map
      return context.dispatch('getControls',config).then(rep=>{
        rep.map(x=>context.state.map.addControl(x))
        return Promise.resolve(context.state.map)
      })
    },
    getControls(context,config){
      //TODO: should come from config, at least add the ones from config.
      let controls = [
        // ******** mouse position
        new ol.control.MousePosition({
          projection: 'EPSG:4326',
          coordinateFormat: function(coordinate) {
            return ol.coordinate.format(coordinate, '{y} {x}', 5);
          }
        }),
        // ******** scale line
        new ol.control.ScaleLine()
      ]
      return Promise.resolve(controls)
    },
    addLayer(context,layer_config){
      let layer1_id=cuid()
      layer_config.layer_id=layer1_id
      let layer1=null
      switch (layer_config.type_store) {
        //We use Inheritance to simplify coding.
        case 'layer_vector':
          layer1=mergeImplementation(layer,layer_vector)
          break;
        case 'layer_imagery':
          layer1=mergeImplementation(layer,layer_imagery)
          break;
        case 'layer_imagery_couchdb':
          layer1=mergeImplementation(layer,mergeImplementation(layer_imagery,layer_imagery_couchdb, false))
          break;
        case 'layer_vector_couchdb':
          layer1=mergeImplementation(layer,mergeImplementation(layer_vector,layer_vector_couchdb, false))
          break;
        default:
          console.error('type_store not suported - '+layer_config.type_store)
          // return Promise.reject('type_store not suported - '+layer_config.type_store)
          return Promise.resolve(null)
      }
      //dynamic modules references:
      //https://www.vuemastery.com/conferences/vueconf-2018/dynamic-store-modules-with-vuex-adam-bradford/
      //https://vuex.vuejs.org/guide/modules.html#dynamic-module-registration
      //https://vuex.vuejs.org/guide/modules.html#module-reuse
      store.registerModule(['map',layer1_id],layer1)
      context.state.layers_all.push(layer1_id) //hold the list of layers
      if(layer_config.is_basemap){
        context.state.layers_basemap.push(layer1_id)
      }
      return context.dispatch(layer1_id+'/define_from_config',layer_config).then(rep=>{
        if(rep){
          context.dispatch(layer1_id+'/addToMap',context.state.map)
          return Promise.resolve(rep)
        }
      })
    },
    getFeatures(context,opts){
      //  TODO remove image layer
      let coordinate=opts.coordinate
      let allLayers=!opts.onlyVisible
      return Promise.all(context.state.layers_all.map(lay=>{
        if(allLayers || context.state[lay].visible){
          return context.dispatch(lay+'/getFeatures',coordinate)
        }else{
          return Promise.resolve([])
        }
      })).then(arrayOfResults=>{
        let result={}
        context.state.layers_all.map((lay,i)=>{
          result[lay]=arrayOfResults[i]
        })
        return Promise.resolve(result)
      })
    },
    getFeaturesAtPixel(context,opts){
      //  TODO remove image layer
      let pixel=opts.pixel
      let allLayers=!opts.onlyVisible
      return Promise.all(context.state.layers_all.map(lay=>{
        if((allLayers || context.state[lay].visible) && ( opts.hover !== true || context.state[lay].config.hover)){
          return context.dispatch(lay+'/getFeaturesAtPixel',pixel)
        }else{
          return Promise.resolve([])
        }
      })).then(arrayOfResults=>{
        let result={}
        context.state.layers_all.map((lay,i)=>{
          result[lay]=arrayOfResults[i]
        })
        return Promise.resolve(result)
      })
    },
    // switchBaseLayer(map0,layerdef,isFirst) {
    //     let layColl = map0.getLayers();
    //     if(isFirst===false){
    //         layColl.removeAt(0)
    //     }
    //     layColl.insertAt(0, layerdef.layer);
    // }
    // ************** sync action
    setBaseLayer(context,id_new_basel_layer){
      context.state.layers_basemap.map(x=>{
        if(x==id_new_basel_layer){
          context.commit(x+'/setVisible',true)
        }else{
          context.commit(x+'/setVisible',false)
        }
      })
    },
    mapActivated(context){
      // the map has been activated, we want to trigger certain event.
      //  for now only the visible layers
      return Promise.all(context.state.layers_all.map(lay=>{
        if(context.state[lay].visible){
          return context.dispatch(lay+'/mapActivated')
        }else{
          return Promise.resolve(true)
        }
      }))
    },
    setForceCurrentSurveyOptionAllLayers(context,force){
      // set the forceCurrentSurveyOption on all layers
      return Promise.all(context.state.layers_all.map(lay=>{
        context.dispatch(lay+'/setForceCurrentSurveyOption',force)
      }))
    },
  }
}


// **** helper function
var mergeImplementation=function(main,specific, stateAsFunction=true){
  // in case we merge many, only the last merge must convert the stateAsFunction
  let newObj={namespaced:true}
  let type=['state','mutations','actions','getters']
  type.map(x=>{
    if (x=='state' && stateAsFunction){
      newObj[x]=function(){return Object.assign({},main[x],specific[x])}
    }else{
      newObj[x]=Object.assign({},main[x],specific[x])
    }

  })
  return newObj
}

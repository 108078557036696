// as modals in index.js

// reprensent features to show on modals...
//as modals has to be on the element root to be correctly displayed.

export default {
  namespaced: true,
  state: {
    photo:null,
    photoShow:false,
    orderSelect:null,
    orderSelectShow:false,
  },
  mutations: {
    photo(state, photo){
      state.photo=photo
      state.photoShow=true
    },
    orderSelect(state,orderSelect){
      state.orderSelect=orderSelect
      state.orderSelectShow=true
    },
    orderSelectHide(state){
      state.orderSelectShow=false
    }
  },
  actions: {

  },
  getters: {

  }
}

 
// ******** Rivière Chaudière - Damien

export default{
  name:'chadiere',
  documentTitle:'AECOM - Rivière Chaudière',
  navbarTitle:'AECOM - Rivière Chaudière',//not used for now
  //TODO: correct this problem: does not work on https... 
  couchdbUrls: ['https://db.remfor.aecom.space/','https://db.remfor2.aecom.space/'],//,'http://192.168.50.2:5984/'], // we pass many as we want to support mobile field server
  databaseSystem:'forms_system_chaudiere',
  pouhdb_replicate_batch_size:5, //default to 100, but crash in browser with photos.
  serial_position_active:false,
  inputGeopointDirectGpsClickAllowed:true,//this by pass the map.
  new_db_couchdb_security_users:"users_chaudiere",
  default_language:'fr'
}

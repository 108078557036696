<template>
  <div v-if="layers_id && layers_id.length > 0" class="panel panel-default">
    <ul class="list-group">
      <li class="list-group-item list-group-item-dark d-flex justify-content-between">
        <div class="h3">
          {{ labels.tableOfContent.layers }}
        </div>
        <b-dropdown variant="primary" class="mx-2">
          <template #button-content>
            <i class="mdi mdi-check-all"></i>
            <i class="mdi mdi-eye"></i>
          </template>
          <b-dropdown-item 
            v-for="opt in options" 
            :key="'cde'+opt.value" 
            @click.stop="$store.dispatch('map/setForceCurrentSurveyOptionAllLayers', opt.value)"
          >
            {{ opt.value ? opt.text : opt.text }}
          </b-dropdown-item>
        </b-dropdown>
      </li>
      <layer-item
        :key="'layer_' + lay"
        v-for="lay in layers_id"
        :layer_id="lay"
      ></layer-item>
    </ul>
  </div>
</template>

<script>
import layerItem from "./layer_item.vue";

export default {
  components: { layerItem },
  computed: {
    labels() {
      return this.$store.state.map.labels;
    },
    layers_id() {
      return this.$store.getters["map/layers"];
    },
    optionsTranslation(){return this.$store.state.labels.map.surveyLayerVisibleOptions},
    options(){
      return [
        {value: 'onlyShowMySurvey', text: this.optionsTranslation.onlyShowMySurvey},
        {value: 'fadeOtherSurveys', text: this.optionsTranslation.fadeOtherSurveys},
        {value: 'allSurveys', text: this.optionsTranslation.allSurveys},
        {value: null, text: this.optionsTranslation.defaultVal},
      ]
    },
  },
  methods: {},
};
</script>

<style scoped>
.checkbox {
  padding-left: 20px;
}

.checkbox label {
  display: inline-block;
  position: relative;
  padding-left: 5px;
}

.checkbox label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 17px;
  height: 17px;
  left: 0;
  margin-left: -20px;
  border: 1px solid #cccccc;
  border-radius: 3px;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}

.checkbox label::after {
  display: inline-block;
  position: absolute;
  width: 16px;
  height: 16px;
  left: 0;
  top: 0;
  margin-left: -20px;
  padding-left: 3px;
  padding-top: 1px;
  font-size: 11px;
  color: #555555;
}

.checkbox input[type="checkbox"] {
  opacity: 0;
}

.checkbox input[type="checkbox"]:focus + label::before {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.checkbox input[type="checkbox"]:checked + label::after {
  font-family: "FontAwesome";
  content: "\f00c";
}

.checkbox input[type="checkbox"]:disabled + label {
  opacity: 0.65;
}

.checkbox input[type="checkbox"]:disabled + label::before {
  background-color: #eeeeee;
  cursor: not-allowed;
}

.checkbox.checkbox-circle label::before {
  border-radius: 50%;
}

.checkbox.checkbox-inline {
  margin-top: 0;
}
</style>

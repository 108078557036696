import upperFirst from 'lodash/upperFirst'

export default {
  list:{
    'start':'text',
    'end':'text',
    'deviceid':'text',

  },
  transcode(el){
    let type=el.type
    if(type=='select all that apply'){
      type='selectmultiple'
    }
    let t2= type.replace(' ','')// select one => selectone
    if(this.list.hasOwnProperty(t2)){
      t2=this.list[t2]
    }
    if(t2=='integer'){
      t2='decimal'
    }
    if(t2=='geotrace'){
      t2='geoshape' // condition for line string in the component.
    }
    if(t2=='photo' && el.control && el.control.appearance=='signature'){
      t2='signature' // signature is not handle as a photo...
    }
    if(type=='repeat' && el.control && el.control.appearance=='compact'){
      t2='repeatCompact'
    }
    return 'I'+upperFirst(t2)
  }
}

 
// ******** Trois-Rivières

export default{
  name:'mh',
  documentTitle:'AECOM Mileux humides',
  navbarTitle:'Formulaires - Mileux humides',//not used for now
  //TODO: correct this problem: does not work on https... 
  couchdbUrls: ['https://db.troisrivieres.aecom.space/'],
  databaseSystem:'forms_system_mh',
  pouhdb_replicate_batch_size:5, //default to 100, but crash in browser with photos.
  serial_position_active:false,
  inputGeopointDirectGpsClickAllowed:true,//this by pass the map.
  new_db_couchdb_security_users:"mh_users",
  sql_params:{
    api_url: 'https://api.troisrivieres.aecom.space/graphql',
    login_suffix: '@couchdb.sys',
    auto_sync_changes: true, // if we automatically create the record so nodesync will begin sync
    auto_sql_creation: true, // 
    auto_sql_creation_options:{ // same options possible as the sql.vue
      namebyform: true,
      selectManyTable: true,
      attachements: true,
      attachmentsView: true,
    }
  },
  role_can_edit_all_survey: 'mh_edit_all', 
}

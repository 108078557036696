<template>
<div class="container">
    <div class="page-header">
        <h1>
            <!-- <img src="static/medias/aecom_logo.png?v=3" class="img-rounded" alt="Logo"  /> -->
            {{$store.state.settings.documentTitle}}</h1>
    </div>
    <div class="jumbotron">
        <div class="wrapper">
            <div class="form-signin">
                <h2 class="form-signin-heading">{{labels.login.connect}}</h2>
                <input v-model="user" type="text" class="form-control" name="username" :placeholder="labels.login.email" required="" autofocus="" />
                <input v-model="password" type="password" class="form-control my-2" name="password" :placeholder="labels.login.password" required="" @keyup.enter="login" />
                <button :disabled="!canLogin" class="btn btn-lg btn-primary btn-block mt-4"  @click="login">{{labels.login.button}}</button>
                <h3 class="mt-3"><span class="label label-warning text-center">{{msg}}</span></h3>
                <div class="mt-3 text-secondary">{{msg2}}</div>
            </div>
        </div>
        <div class="mx-4" v-if="hasOfflineForms">
            <b-btn to="/forms">{{ labels.login.hasLocalAccess}}</b-btn>
        </div>
    </div>
    <div class="text-secondary my-0">
        {{ this.$store.state.couchdbUrl }}
    </div>
</div>
</template>

<script>

export default {
    name: 'login',
    data() {
        return {
            msg: null,
            msg2: null,
            user: null,
            password: null
        }
    },
    computed:{
        canLogin(){
            if(this.user && this.password){
                return true
            }
            return false
        },
        labels(){
            return this.$store.state.labels
        },
        hasOfflineForms(){
            return this.$store.state.localDB.config.forms.length>0
        }
    },
    methods: {
        login() {
            this.msg = this.labels.login.processing_message // so the user know that the action is ongoing.
            this.$store.dispatch('sqlApi/login',{user: this.user, pass: this.password}).then(user=>{
                // console.log(user)
                return this.$store.dispatch('verifySession')
            }).then(()=>{
                if(this.$route.query.redirect != null){
                    this.$router.push(this.$route.query.redirect)
                }else{
                    this.$router.push('/')
                }
                this.msg=null
            }).catch(err=>{
                this.$store.dispatch('app_message_error',err)
                this.msg=this.labels.login.error_message
                this.msg2=err.reason
            })
            
        }
    }
}
</script>




<style>
/*
body {
	background: #eee !important;
}*/

.wrapper {
    margin-top: 80px;
    margin-bottom: 80px;
}
.mycenter{
    align: center;
}

.form-signin {
    max-width: 380px;
    padding: 15px 35px 45px;
    margin: 0 auto;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    .form-signin-heading,
    .checkbox {
        margin-bottom: 30px;
    }
    .checkbox {
        font-weight: normal;
    }
    .form-control {
        position: relative;
        font-size: 16px;
        height: auto;
        padding: 10px;
        @include box-sizing(border-box);
        &:focus {
            z-index: 2;
        }
    }
    input[type="text"] {
        margin-bottom: -1px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
    input[type="password"] {
        margin-bottom: 20px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
}
</style>

<template>
  <div>
    <div class="my-4 pt-4 h3">{{labelTitle}}</div>
    <b-btn class="mt-2" @click="makeOnline">
      {{labelOptViewOnline}}
    </b-btn>
  </div>
</template>

<script>
  export default {
    name:'editNotLocalButRemote',
    props:['survey_id', 'options'],
    computed:{
      labels2(){
        return this.$store.state.labels.form.editNotLocalButRemote
      },
      labelTitle(){
        if(this.options && this.options.title){
          return this.options.title
        }
        return this.labels2.title
      },
      labelOptViewOnline(){
        if(this.options && this.options.optViewOnline){
          return this.options.optViewOnline
        }
        return this.labels2.optViewOnline
      },
    },
    methods:{
      makeOnline(){
        return this.$store.dispatch('localDB/switchDatabasesAccesMode',1).then(()=>{
          this.$emit('goOnline',true)
        }).catch(err=>this.$store.dispatch('app_message_error',err))
      },
    },
  }
</script>

<style lang="scss" scoped>

</style>
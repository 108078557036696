<template>
  <div>
    <div class="h2  text-center">
      {{project.project_label}}
    </div>
    <div class="row justify-content-md-center">
      <div class="col-lg-6">
        <div class="my-4">
          <div v-if="syncingState==0">
            <b-button @click="replicateTo" variant="primary">{{labels.sendData}}</b-button>
            <div>
              <b-form-checkbox
                class="ml-3 mt-2"
                v-model="syncIncludePhotos"
              >
                {{ syncLabels.syncIncludePhotos }}
              </b-form-checkbox>
            </div>
            <sync-options v-model="selectedOption"></sync-options>
          </div>
          <div v-if="syncingState==2">
            <div class="my-4 ml-3">
              <b-btn to="." variant="secondary">{{labels.return}}</b-btn> 
            </div>
            <b-card v-if="syncIncludePhotos!==false">
              <b-btn @click="deleteLocals" variant="warning">{{labels.deletelocals}}</b-btn>
              <div class="small alert alert-warning mt-2">{{labels.deletelocalsMess}}</div>
            </b-card>
          </div>
          <div v-if="syncingState==99">
            <div v-if="syncingState==99" class="alert alert-danger">
              <h4>{{labels.globalError}}</h4>
            </div>
          </div>
        </div>
        <div class="list-group">
          <div v-for="form in forms" :key="form.id" class="list-group-item">
            <span class="h5 mx-2">{{form.label}}</span>
            <div class="ml-5 mt-3">
              <sync-state :sync-state="form.syncState" :change-counter="ChangeCounter" :error-message="form.errorMessage"></sync-state>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import syncOptions from "../components/sync/syncOptions.vue";
import syncState from "../components/sync/syncState.vue";

export default {
  props: ["project_id"],
  components: {
    syncOptions,
    syncState
  },
  data() {
    return {
      syncingState: 0,
      forms: this.formsList(),
      selectedOption:null,
      syncIncludePhotos:true,
    };
  },
  computed: {
    syncLabels(){return this.$store.state.labels.form.sync},
    labels() {
      return this.$store.state.labels.project.sync;
    },
    project(){
      return this.$store.getters['project/project']
    },
    ChangeCounter() {
      return this.$store.state.form.ChangeCounter;
    },
  },
  watch: {
    project_id: {
      immediate: true,
      handler: "fetchProject"
    },
    syncIncludePhotos:{
      handler(){
        this.$store.state.form.syncIncludePhotos = this.syncIncludePhotos
      },
      immediate: true
    } 
  },
  methods: {
    formsList() {
      if (this.project_id) {
        const rep = this.$store.state.localDB.config.forms
          .filter(x => x.project.id == this.project_id)
          .slice();
        rep.map(x => {
          x.syncState = 0;
        });
        return rep;
      }
    },
    fetchProject() {
      this.$store
        .dispatch("project/setProjectId", this.project_id)
        .catch(err => console.error(err));
    },
    replicateTo() {
      this.syncingState = 1
      // https://stackoverflow.com/a/24586168/140384
      // execute all form one after the other
      var p = Promise.resolve()
      this.forms.forEach(form =>{
          p = p.then(() => this.replicateForm(form))
      });
      p.then(()=>{
        //all has been synced
        this.syncingState=2
      }).catch(err=>{
        this.$store.dispatch('app_message_error',err)
        this.syncingState = 99
      })
    },
    replicateForm(form){
      return this.$store
        .dispatch('form/setActualForm',form.id)
        .then(()=>this.$store.dispatch("form/replicateToMaster", this.selectedOption))
        .then(rep => {
          form.syncState = 2;
          return Promise.resolve()
        })
        .catch(err => {
          form.errorMessage=err
          form.syncState = 99;
          this.$store.dispatch('app_message_error',err)
          return Promise.reject(err)
        });
    },
    deleteLocals(){
      this.syncingState=3
      // https://stackoverflow.com/a/24586168/140384
      // execute all form one after the other
      var p = Promise.resolve()
      this.forms.forEach(form =>{
          p = p.then(() => this.deleteLocalData(form))
      });
      p.then(()=>{
        //all has been synced
        this.syncingState=4
      }).catch(err=>{
        this.$store.dispatch('app_message_error',err)
        this.syncingState = 99
      })
    },
    deleteLocalData(form){
      form.syncState=3
      const formId=form.id
      const projectId=this.project_id
      const formSytemConfig=this.$store.getters.forms.filter(x=>x.id==formId)[0]
      let form2= Object.assign({},formSytemConfig)
      form2.project=this.$store.getters.projects.filter(x=>x.id==projectId)[0]
      form2= JSON.parse( JSON.stringify(form2)) // or keep a reference to the project object and we have a problem that the current project object is corrupted
      delete form2.project.forms
      //erase local data (the database) and after sync back the definition.
      return this.$store.dispatch('localDB/deleteFormLocal',{id:formId})
      .then(()=>{
        // add local
        return this.$store.dispatch('localDB/addFormLocal',form2)
      }).then(()=>{
        //all done, message the user
        form.syncState=4
        return Promise.resolve()
      }).catch(err=>{
        form.syncState=99
        form.errorMessage=err
        this.$store.dispatch('app_message_error',err)
        return Promise.reject(err)
      })
    }
  }
};
</script>

<style scoped>
</style>

// ******** Amaria

export default{
  name:'amaria',
  documentTitle:'AECOM Formulaires - Amaria',
  navbarTitle:'Formulaires - Amaria',//not used for now
  // we pass many as we want to support mobile field server or have a backup server(couchdb cluster/replication needed).
  couchdbUrls: ['https://db3.amaria.aecom.space/','https://db.amaria2.aecom.space/'],//,'http://192.168.50.2:5984/'],
  databaseSystem:'forms_system',
  pouhdb_replicate_batch_size:5, //default to 100, but crash in browser with photos.
  serial_position_active:false,
  inputGeopointDirectGpsClickAllowed:true, //this by pass the map.
  new_db_couchdb_security_users:"amaria_users",
}

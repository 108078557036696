<template>
  <svg
   xmlns:dc="http://purl.org/dc/elements/1.1/"
   xmlns:cc="http://creativecommons.org/ns#"
   xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
   xmlns:svg="http://www.w3.org/2000/svg"
   xmlns="http://www.w3.org/2000/svg"
   xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
   xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
   :width="width"
   :height="height"
   viewBox="0 0 64 64"
   id="svg2985"
   version="1.1"
   inkscape:version="0.48.4 r9939"
   sodipodi:docname="New document 2"
   class="my1"
   >
  
  <g
     id="layer1"
     inkscape:label="Layer 1"
     inkscape:groupmode="layer">
    <path
       style="fill:none;stroke:#000000;stroke-width:2.5;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;stroke-miterlimit:4;stroke-dasharray:none"
       d="M 7.8181805,8.0002707 56.181819,22.727462 52.727275,45.818244 19.454544,56.727273 z"
       id="path2993"
       inkscape:connector-curvature="0"
       sodipodi:nodetypes="ccccc" />
    <path
       sodipodi:type="arc"
       style="fill:#000000;fill-opacity:1;stroke-width:0;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0"
       id="path2997"
       sodipodi:cx="13.363636"
       sodipodi:cy="12.909091"
       sodipodi:rx="5.3636365"
       sodipodi:ry="5.090909"
       d="m 18.727273,12.909091 a 5.3636365,5.090909 0 1 1 -10.7272735,0 5.3636365,5.090909 0 1 1 10.7272735,0 z"
       transform="matrix(0.88135594,0,0,0.88135594,-2.6872112,-3.0724191)" />
    <path
       sodipodi:type="arc"
       style="fill:#000000;fill-opacity:1;stroke-width:0;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0"
       id="path2997-1"
       sodipodi:cx="13.363636"
       sodipodi:cy="12.909091"
       sodipodi:rx="5.3636365"
       sodipodi:ry="5.090909"
       d="m 18.727273,12.909091 a 5.3636365,5.090909 0 1 1 -10.7272735,0 5.3636365,5.090909 0 1 1 10.7272735,0 z"
       transform="matrix(0.88135594,0,0,0.88135594,43.494607,11.713405)" />
    <path
       sodipodi:type="arc"
       style="fill:#000000;fill-opacity:1;stroke-width:0;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0"
       id="path2997-7"
       sodipodi:cx="13.363636"
       sodipodi:cy="12.909091"
       sodipodi:rx="5.3636365"
       sodipodi:ry="5.090909"
       d="m 18.727273,12.909091 a 5.3636365,5.090909 0 1 1 -10.7272735,0 5.3636365,5.090909 0 1 1 10.7272735,0 z"
       transform="matrix(0.88135594,0,0,0.88135594,39.858244,33.531586)" />
    <path
       sodipodi:type="arc"
       style="fill:#000000;fill-opacity:1;stroke-width:0;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0"
       id="path2997-4"
       sodipodi:cx="13.363636"
       sodipodi:cy="12.909091"
       sodipodi:rx="5.3636365"
       sodipodi:ry="5.090909"
       d="m 18.727273,12.909091 a 5.3636365,5.090909 0 1 1 -10.7272735,0 5.3636365,5.090909 0 1 1 10.7272735,0 z"
       transform="matrix(0.88135594,0,0,0.88135594,8.2218804,44.986132)" />
  </g>
</svg>

</template>

<script>
  export default {
    name:'svg-polygon',
    props: {
      width: {
        type: String,
        default: '1em'
      },
      height: {
        type: String,
        default: '1em'
      },
    },
  }
</script>

<style scoped>
  .my1{
    margin-top: -0.4em;
    margin-bottom: -0.4em;
  }
</style>
<template>
  <div class="my-4 mx-4">
    <div v-if="form_not_offline">
      <edit-not-local-but-remote :options="options" @goOnline="goOnline"></edit-not-local-but-remote>
    </div>
    <div v-else>
      <h5>{{form_error_message}}</h5>
    </div>
  </div>
</template>

<script>
import editNotLocalButRemote from '../../components/form/editNotLocalButRemote.vue'

export default{
  components:{
    editNotLocalButRemote,
  },
  computed: {
    form_error_message(){return this.$store.state.form.form_error_message},
    labels(){return this.$store.state.labels},
    form_not_offline(){
      if(this.form_error_message == 'form_not_offline'){
        return true
      }
      return false
    },
    options(){
      if(this.form_not_offline){
        return {
          title: this.labels.form.formNotOffline,
          optViewOnline: this.labels.form.formNotOffline2,
        }
      }
    },
  },
  methods: {
    goOnline() {
      this.$router.push('/forms/' + this.$store.state.form.form_id)
    }
  },
}

</script>

// Implements layer.js...

export default {
  namespaced: true,
  state: {
    className:'layer_vector_couchdb',
    db:null,
    update_seq:null,
    db_changes:null,
    source:null,
    define_from_config_sub_tasks: ['define_style'],
    forceCurrentSurveyOption: null,
  },
  getters: {
    currentSurveyOption(state){
      // we want null, not undefined
      return state.forceCurrentSurveyOption || state.config.currentSurveyOption || 'allSurveys'
    },
    defaultSurveyOption(state){
      // we want null, not undefined
      return state.config.currentSurveyOption || 'allSurveys'
    },
  },
  mutations: {
    
  },
  actions: {
    define_source(context,config){ // we override the layer_vector function
      if(context.state.db_changes){
        context.state.db_changes.cancel()
      }
      let source1=null
      if (config.type=='geojson_couchdb') {
        source1=new ol.source.Vector({
          features:[]
        })
      }else{
        console.error('Not a layer type defined')
      }
      context.state.source=source1
      return context.dispatch('localDB/getDatabase',{dbName:config.couchdb_form},{root:true}).then(db=>{
        context.state.db=db.db
        return getGeoJsonFeatures(db.db,config.couchdb_view_name,{update_seq:true})
      }).then(geojson=>{
        let fes=new ol.format.GeoJSON().readFeatures(geojson,
          {
            dataProjection:config.projection,
            featureProjection:context.rootGetters['map/projection']
          })
        source1.addFeatures(fes)
        return context.dispatch('update_seq',geojson.update_seq)
        // return Promise.resolve()
      }).then(rep=>{
        return Promise.resolve(source1)
      })
    },
    setForceCurrentSurveyOption(context, value){
      context.state.forceCurrentSurveyOption = value
      // Force a layer refresh
      context.dispatch('define_style')
    },
    define_style(context,config){
      context.dispatch('setBaseStyle') // we have to srest it
      if (!context.state.map_layer){return Promise.resolve(false)} // we don't have a layer yet
      const baseStyle = context.state.map_layer.getStyleFunction()
      const getFeauteId = (feature) => {
        return feature.getProperties().id_survey
        // const fId = feature.getId()
        // if (!fId){
        // }
        // return fId
      }
      if( context.getters['currentSurveyOption'] == 'onlyShowMySurvey'){
        context.state.map_layer.setStyle(function(feature, resolution) {
          if(getFeauteId(feature) == context.rootGetters['formMap/survey_id'] || !context.rootGetters['formMap/survey_id']){
            return baseStyle(feature, resolution)
          }
        })
      } else if (context.getters['currentSurveyOption'] == 'fadeOtherSurveys'){
        const fade_style = new ol.style.Style({
          stroke: new ol.style.Stroke({
            color: 'rgba(50,50,50,0.75)',
            width: 3
          }),
          image: new ol.style.Circle({
            radius: 6,
            fill: new ol.style.Fill({
              color: 'rgba(50,50,50,0.75)'
            }),
          })
        })
        context.state.map_layer.setStyle(function(feature, resolution) {
          if(getFeauteId(feature) == context.rootGetters['formMap/survey_id'] || !context.rootGetters['formMap/survey_id']){
            return baseStyle(feature, resolution)
          }else{
            return fade_style
          }
        })
      }
      return Promise.resolve(true)
    },
    update_seq(context,update_seq){
      if(!context.state.config.couchdb_live){return Promise.resolve(true)}
      context.state.update_seq=update_seq
      //then initialise a new changes
      context.state.db_changes=context.state.db.changes({
        live: true,
        since:update_seq,
        filter:'_view',
        view: context.state.config.couchdb_view_name
      }).on('change', function(change) {
        // handle change
        context.dispatch('newFeatures', change)
      }).on('complete', function(info) {
        // changes() was canceled
        console.log('change layer couchdb cancelled')
      }).on('error', function (err) {
        console.error(err);
      })
      return Promise.resolve(true)
    },
    newFeatures(context,change){
      // console.log('change:');
      // console.log(change);
      context.state.update_seq=change.seq
      //remove from our source each id
      context.dispatch('removeSurveyId',change.id)
      //TODO: check if the change._deleted==true and don't do the rest. easy, but need to be tested...
      getGeoJsonFeatures(context.state.db,context.state.config.couchdb_view_name,{key:change.id,update_seq:true}).then(newJson=>{
        // console.log(newJson);
        let fes=new ol.format.GeoJSON().readFeatures(newJson,
          {
            dataProjection:context.state.config.projection,
            featureProjection:context.rootGetters['map/projection']
          })
        context.state.source.addFeatures(fes)
      })
    },
    removeSurveyId(context, id_survey){
      const features = context.state.source.getFeatures();
      const featuresRemoved=[]//there can be many features with the same id_survey.
      if (features != null && features.length > 0) {
        for (let x in features) {
          const properties = features[x].getProperties();
          const id = properties.id_survey;
          if (id == id_survey) {
            context.state.source.removeFeature(features[x]);
            featuresRemoved.push(features[x])
          }
        }
      }
      return featuresRemoved
    },
    downloadGeoJson(context){
      return getGeoJsonFeatures(context.state.db,context.state.config.couchdb_view_name,{include_docs:true})
    },
    mapActivated(context){
      if(context.getters['currentSurveyOption'] != 'allSurveys'){
        // Trigger the changed event to refresh the layer - as the survey id for style fonction might have changed.
        context.state.map_layer?.getSource()?.changed();
      }
      return Promise.resolve(true)
    },
  },

}

//helper function
let getGeoJsonFeatures=function(db,viewName,opts){
  return db.query(viewName,opts).then(geometries=>{
    let features= geometries.rows.map(x=>{
      //put the id in the properties
      let geom=x.value
      if(!geom.properties){
        geom.properties={}
      }
      geom.properties.id_survey=x.id
      if(opts.include_docs){
        Object.assign(geom.properties,x.doc.form_data)
      }
      return geom
    })
    let geojson={
      type:'FeatureCollection',
      // crs: {
      //   "type": "name",
      //   "properties": {
      //       "name": "EPSG:4326"
      //   }
      // },
      update_seq:geometries.update_seq,
      features:features
    }
    return Promise.resolve(geojson)
  })
}

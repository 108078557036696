<template>
  <div>
    <b-button-group v-if="Boolean(nbPages)">
      <b-button variant="dark"
          @click="$store.commit('form/inputGroupCurrentPage',currentPage-1)"
          :disabled="!showPreviousPage"
      ><span class="glyphicon glyphicon-arrow-left"></span></b-button>
      <b-button variant="light">{{currentPage+1}}</b-button>
      <b-button variant="dark"
          @click="$store.commit('form/inputGroupCurrentPage',currentPage+1)"
          :disabled="!showNextPage"
          ><span class="glyphicon glyphicon-arrow-right"></span></b-button>
    </b-button-group>
    <span class="ml-3" v-if="hasViews">
      <b-button 
        variant="dark"
        @click="$store.commit('form/setViewMode',$store.state.form.viewMode+1)"
        >
        <i :class="viewModeIcon"></i>
      </b-button>
    </span>
    <span class="ml-2" v-if="hasSurvey && $store.getters['form/userCanCreateFlag']">
      <b-btn v-if="$store.state.form.formDisplayOptions.showFlagCreate" @click="$store.commit('form/setFormDisplayOptions',{key:'showFlagCreate',val:false})" class="btn-flag">
        <i class="mdi mdi-eye-off"></i>
        <i class="mdi mdi-flag"></i>
      </b-btn>
      <b-btn v-if="$store.state.form.formDisplayOptions.showFlagCreate===false" @click="$store.commit('form/setFormDisplayOptions',{key:'showFlagCreate',val:true})" class="btn-flag">
        <i class="mdi mdi-eye"></i>
        <i class="mdi mdi-flag"></i>
      </b-btn>
    </span>
    <span class="ml-2 align-items-end">
      <i v-if="$store.getters['localDB/isOnlineMode']===true" class="mdi mdi-signal"></i>
      <i v-if="$store.getters['localDB/isOnlineMode']===false" class="mdi mdi-signal-off"></i>
    </span>
  </div>
</template>
<!--
<b-btn @click="$store.commit('form/inputGroupCurrentPage',currentPage-1)" v-if="showPreviousPage" variant="dark">{{labels.form.previousPage}}</b-btn>
<div class="text-italic mx-3"
>{{currentPage+1}} / {{formElements.length}}</div>
<b-btn @click="$store.commit('form/inputGroupCurrentPage',currentPage+1)" v-if="showNextPage" variant="dark">{{labels.form.nextPage}}</b-btn>
</div> -->

<script>

export default{
  computed:{
    currentPage(){
      return this.$store.state.form.inputGroupCurrentPage
    },
    nbPages(){
      if(this.$store.state.form.inputGroupPages){
        return this.$store.state.form.inputGroupPages.length
      }
      return 0
    },
    hasSurvey(){
      // we use the inputGroupPages to know if we have a survey. if 0 or >0, we have a survey.
      if(this.$store.state.form.inputGroupPages || this.$store.state.form.inputGroupPages==0){
        return true
      }
    },
    //those 2 are the same as in inputGroup.vue ()
    //TODO: put them in the store.
    showPreviousPage(){
      return Boolean(this.nbPages) && this.currentPage!=0
    },
    showNextPage(){
      return Boolean(this.nbPages) && this.currentPage!=(this.nbPages-1)
    },
    hasViews(){
      if(this.viewValue && this.$store.getters['form/hasViews']){
        return true
      }
      return false
    },
    viewValue(){
      return this.$store.state.form.viewValue
    },
    viewMode(){
      return this.$store.state.form.viewMode
    },
    viewModeIcon(){
      if (this.viewMode==1) return "glyphicon glyphicon-eye-open"
      if (this.viewMode==2) return "glyphicon glyphicon-eye-close"
      if (this.viewMode==3) return "glyphicon glyphicon-adjust"
    }
  },
}
</script>

<style scoped>
  .btn-flag{
    background-color: rgb(255, 207, 159);
    border: 0;
    color: rgb(64, 64, 64);
  }
  .btn-flag:hover{
    background-color: rgb(252, 178, 103);
    color: rgb(64, 64, 64);
  }
  .btn-flag:focus{
    background-color: rgb(252, 178, 103);
    color: rgb(64, 64, 64);
  }
</style>
<template>
  <div class="mt-2">
    <!-- TODO: useful??? but not really user friendly, so put in option if we really want. -->
    <!-- <div>
      <b-button @click="addPosition" variant="info" class="ml-1" v-if="!gpsInterval">
        <span class="glyphicon glyphicon-plus"></span>
      </b-button>
    </div> -->
    <b-button-group class="mt-2">
      <b-button v-b-modal.modalGeoshapeGPSInterval variant="secondary" v-if="!gpsInterval && !averageInterval">
        <span class="glyphicon glyphicon-time"></span>
      </b-button>
      <b-button @click="startLogging" variant="success"  v-if="!gpsInterval">
        <span class="glyphicon glyphicon-forward"></span>
      </b-button>
      <!-- <b-button @click="getFromMap" variant="secondary" class="ml-1">
        <span class="glyphicon glyphicon-pause"></span>
      </b-button> -->
      <b-button @click="backToForm" variant="info" v-if="gpsInterval">
        <span class="glyphicon glyphicon-log-out"></span>
      </b-button>
      <b-button disabled :variant="nbPositions > properties.nbMinPositions?'success':'warning'" v-if="nbPositions">
        <i class="fas fa-flag"></i> {{nbPositions}}
      </b-button>
      <b-button @click="endPolygon" variant="primary" >
        <span class="glyphicon glyphicon-stop"></span>
      </b-button>
    </b-button-group>
    <interval v-model="gpsIntervalSeconds"></interval>
  </div>
</template>

<script>
import base from './_baseComponent.js'
import map_utils from '../map_utils.js'
import interval from './geoshapeGPS_interval'

export default {
  name:'map-geopoint-average',
  mixins:[base],
  components:{
    interval
  },
  data(){
    return{
      source:null,
      ObjToRemove:null,
      gpsIntervalSeconds: this.properties.averageInterval || 1,
      gpsInterval:null,
    }
  },
  computed:{
    position(){
      return this.$store.state.position.position
    },
    positionMap(){
      // position in map coordinates
      return map_utils.project_geom(this.$store.state.position.position,'EPSG:4326',this.map.getView().getProjection())
    },
    isLive(){
      if (this.properties.liveId && this.$store.state.formMap.formComponentLive[this.properties.liveId]){
        return true
      }
      return false
    },
    averageInterval(){
      return this.properties.averageInterval
    },
    nbPositions(){
      if(this.response){
        return this.response.properties.positions_nb
      }
      if(this.properties.currentValue){
        return this.properties.currentValue.properties.positions_nb
      }
      return null
    }
  },
  watch:{
    isLive(){
      if(this.isLive==false){
        // has been destroyed, stop the interval.
        this.clear()
      }
    }
  },
  methods:{
    start(){
      // it's the watch of the start.
      // when created or updated
      console.log('start geopoint interval')
      var source = new ol.source.Vector();
      //add to source existing Geometry
      if(this.properties.currentValue){
        console.log(this.properties.currentValue)
        try{
          var geojson  = new ol.format.GeoJSON();
          let feat=geojson.readFeature(this.properties.currentValue,{
            featureProjection: this.map.getView().getProjection(),
            dataProjection: 'EPSG:4326'})
          source.addFeature(feat)
          if(this.$store.state.position.position_update_recenter){
            this.$store.commit('position/position_update_recenter')//toggle off
          }
          //center map on feature.
          let extent1=feat.getGeometry().getExtent()
          this.map.getView().setCenter([(extent1[0]+extent1[2])/2,(extent1[1]+extent1[3])/2]);
        }catch(e){
          console.error(e)
        }
      }else{
        // add an empty polygons
        // later or crash
      }

      //define layer
      var vector = new ol.layer.Vector({
        source: this.source,
        style: new ol.style.Style({
          image: new ol.style.Circle({
            radius: 6,
            fill: new ol.style.Fill({
              color: '#ff0000'
            })
          })
        }),
      })
      this.ObjToRemove={}
      this.ObjToRemove.layer=vector
      this.map.addLayer(vector)
      this.source=source
      console.log('start geopoint average******')
    },
    startLogging(){
      this.gpsInterval = window.setInterval(this.addPosition, this.gpsIntervalSeconds * 1000);
    },
    endPolygon(){
      if(this.gpsInterval){
        window.clearInterval(this.gpsInterval)
        this.gpsInterval=null
      }
      console.log('end point average');
      this.sendResponse()
      this.finished_me()
    },
    finished_me(){
      // TODO: **********************************************************************
      console.log('gps component finished')
      // console.log(this.response)
      this.$store.commit('formMap/removeFormComponentLive',this.properties.liveId)
      this.clear()
      this.$router.push(this.properties.returnRoute)
    },
    clear_sub(){
      if(this.gpsInterval){
        window.clearInterval(this.gpsInterval)
        this.gpsInterval=null
      }
      console.log('clear sub polygon')
      console.log(this.map.removeLayer(this.ObjToRemove.layer))
      // this.map.removeInteraction(this.ObjToRemove.draw);
      // this.map.removeInteraction(this.ObjToRemove.snap);
      // this.map.removeInteraction(this.ObjToRemove.modify);
    },
    backToForm(){
      this.$router.push(this.properties.returnRoute)
    },
    sendResponse(){
      // send response to listeners
      var geojson  = new ol.format.GeoJSON();
      var feat=this.source.getFeatures()[0]
      if(feat){
        //send as geojson string...
        let as_geojson = geojson.writeFeatureObject(feat, {
          featureProjection: this.map.getView().getProjection(),
          dataProjection: 'EPSG:4326'
        });
        //send the object
        // console.log(as_geojson);
        this.response=as_geojson
        this.$store.commit('formMap/setFormComponentLiveResponse',{componentId:this.properties.liveId,response:as_geojson})
      }
    },
    addPosition(){
      console.log('add');
      console.log(this.position);
      // the interval had arrived and add new position to my polygon.
      var coords=[]

      let pos = Object.assign({},{
        position:this.$store.state.position.position,
        accuracy:this.$store.state.position.accuracy,
        altitude:this.$store.state.position.altitude,
        time:this.$store.getters['position/timeISO'],
        gps_age:this.$store.state.position.gpsAgeSeconds,
      })
      
      var feat=this.source.getFeatures()[0]
      if(feat){
        coords = feat.getProperties().positions
      }else{
        coords = []
      }
      coords.push(pos)
      // calculate avg postions
      // https://stackoverflow.com/a/10624256/140384
      const sumX = coords.map(x=>x.position[0]).reduce((a, b) => a + b, 0);
      const avgX = (sumX / coords.length) || 0;
      const sumY = coords.map(x=>x.position[1]).reduce((a, b) => a + b, 0);
      const avgY = (sumY / coords.length) || 0;

      const posGeom = [avgX,avgY]
      this.source.clear();
      this.source.addFeature(new ol.Feature({
        geometry: new ol.geom.Point( map_utils.project_geom(posGeom,'EPSG:4326',this.map.getView().getProjection())), 
        positions: coords, 
        positions_nb: coords.length,
        postions_std: (getStandardDeviation(coords.map(x=>x.position[0])) + getStandardDeviation(coords.map(x=>x.position[1])))/2,
        input_type: 'GPS-average',
      }))
      console.log('aa2');

      this.sendResponse()
    }
  }
}

// https://stackoverflow.com/a/53577159/140384
const getStandardDeviation = (array) => {
  const n = array.length
  const mean = array.reduce((a, b) => a + b) / n
  return Math.sqrt(array.map(x => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n)
}
</script>


<style scoped>
#geoshape{
  position: fixed;
  /* bottom: 0; */
  right: 10px;
  /* left:300px; */
  /* left:0; */
  top:70px;
}
</style>

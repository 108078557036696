<template>
  <div class="col-6">
    <b-list-group>
      <b-list-group-item active class="py-0">
        <div class="h5">{{name}}</div>
        <div class="ml-2">{{labels.total}}: <b>{{size(sizeAll)}}</b></div>
        <div>{{labels.selected}}: <b>{{size(selectedSize)}}</b>
          <div class="float-right" v-if="showDelete">
            <delete-button @delete="$emit('delete')"></delete-button>
          </div>
        </div>
        <b-btn @click="selectAll()" size="sm" class="ml-3 my-2" v-if="showSelection=='true'">{{labels.selectAll}}</b-btn>
        <div v-else class="mt-5"></div>
      </b-list-group-item>
      <b-form-checkbox-group v-model="selected">
        <b-list-group-item v-for="(level,i) in stats" :key="'level-'+name+i" class="py-1">
          <!-- {{level.level}} ({{level.nbBlank}}/{{level.nbTiles}}): {{size(level.size)}} {{size(level.sizeNotBlank)}} -->
          <b-check :value="level.level" v-if="showSelection=='true'">
            <b>{{level.level}}</b>: {{size(level.size)}} <span class="small float right ml-2"><i>({{level.nbTiles}})</i></span>
          </b-check>
          <div v-else>
            <b>{{level.level}}</b>: {{size(level.size)}} <span class="small float right ml-2"><i>({{level.nbTiles}})</i></span>
          </div>
        </b-list-group-item>
      </b-form-checkbox-group>
    </b-list-group>
  </div>
</template>

<script>
var filesize = require('file-size');
import deleteButton from '../utils/deleteButton'

export default{
  components:{deleteButton},
  props:['name','stats','levels','showSelection','showDelete'],
  data(){
    return{
      selected:[]
    }
  },
  computed:{
    labels() {
      return this.$store.state.labels.manageProjectMapLabels.imagery
    },
    sizeAll(){
      let sizeAll=0
      Object.keys(this.stats).map(x=>sizeAll+=this.stats[x].size)
      return sizeAll
    },
    selectedSize(){
      let sizeAll=0
      this.selected.map(x=>sizeAll+=this.stats[x].size)
      return sizeAll
    }
  },
  watch:{
    selected(){
      this.$emit('selected',this.selected)
    }
  },
  methods:{
    size(val){
      //get a human readable size.
      if (val > -1){ // if local, for now don't show the size - performances reasons
        return filesize(val,{fixed:1}).human('si')
      }else{
        return ''
      }
    },
    selectAll(){
      this.selected=Object.keys(this.stats)
    }
  },
}
</script>

<template>
  <div>
    <div class="" v-if="$store.getters['form/userCanCreateFlag'] && !hasFlag && me.readonly===false">
      <b-btn size="sm" class="btn-flag btn-base-flag" @click="createFlag"><i class="mdi mdi-flag"></i></b-btn>
    </div>
  </div>
</template>

<script>
  // *** just to override the html template
  import _inputRowFooterFlagVue from './_inputRowFooterFlag.vue'

  export default {
    name: 'input-row-footer-flag2',
    mixins: [_inputRowFooterFlagVue],
  }
</script>
<template>
  <div>
    <!-- <b-btn class="mb-1" >test</b-btn> -->
    <div class="alert" :class="{'alert-success':isOnlineDb,'alert-warning':!isOnlineDb}" @click="switchOnlineState">{{isOnlineDb}}</div>
  </div>
</template>

<script>
import map_utils from '../map_utils.js'
import base from './_base.js'

export default{
  name:'map-isonline',
  mixins:[base],
  data(){
    return{
      
    }
  },
  created(){

  },
  computed:{
     isOnlineDb(){
      return this.$store.getters['project/isOnlineDb']
    }
  },
  asyncComputed:{
   
  },
  methods:{
    init(){
      
    },
    switchOnlineState(){
      let newAccessMode = 0
      if (this.isOnlineDb){
        newAccessMode = 2
      }else{
        newAccessMode = 1
      }
      return this.$store.dispatch('localDB/switchDatabasesAccesMode',{accessMode: newAccessMode}).then(()=>{
        return this.$store.dispatch('project/setProjectId',this.$store.getters['form/get_project_id'])

      })
    }
  }
}
</script>

<template>
  <b-modal id="modalGeoshapeGPSInterval" :title="labels.addProject" @ok="ok" centered>
    <b-row class="my-1">
      <b-col sm="3">
        <label>
          <!-- {{ type.label }}: -->
          <!-- TODO: translation -->
          Interval: 
        </label>
      </b-col>
      <b-col sm="9">
        <b-form-input 
          type="number" v-model="myval">
        </b-form-input>
      </b-col>
    </b-row>
    <!-- <b-btn @click="ok" variant="primary">Ok</b-btn> -->
  </b-modal>
</template>

<script>
import cuid from 'cuid'

export default {
  name:'interval',
  props:['value'],
  data(){
    return{
      myval:this.value
    }
  },
  computed: {
    systemDoc(){return this.$store.state.systemDoc},
    labels(){return this.$store.state.labels}
  },
  methods:{
    ok(){
      this.$emit('input',this.myval)
    }
  }
}
</script>

<style>

</style>

// as form/survey in index.js

// TODO: big refactor so that every component use this store  and not send event on edition...
// Each component would commit to the data given the path. not only through edit.vue

import utils_gen from "@/utils/utils_gen"

export default {
  namespaced: true,
  state: {
    surveyDoc: null,
  },
  getters: {
    doc_id(state){
      return state.surveyDoc?._id
    },
    // TODO: think if we really need 2... the second include the first...
    surveyDataFlatten(state){
      return utils_gen.flattenObjectArrayWithPath(state.surveyDoc.form_data) ?? {}
    },
    surveyStatusFlatten(state){
      return utils_gen.flattenObjectArrayWithPath(state.surveyDoc.form_status) ?? {}
    },
    surveyDataFlattenWithObjects(state){
      return utils_gen.flattenObjectArrayWithPath(state.surveyDoc.form_data) ?? {}
    },
    flagsStatusByPath: (state, getters) => (path) => {
      // Get child flags for this path
      const pathString = path.join(".")
      const data = getters.surveyDataFlatten
      const dataStatus = getters.surveyStatusFlatten
      return Object.keys(data).filter(x=> (x.startsWith(pathString) && x.endsWith("__flag.status"))).filter(x=>{
        // First of all, if value not more relevant, don't take into account the flag.
        const x2 = x.replace('__flag.status','')
        if(dataStatus[x2] == 4){
          // So it's not relevant... delete the flag or don't take it into account....
          return false
        }
        return true
      }).map(x=>data[x])
    },
    flagsByPath: (state, getters) => (path) => {
      // Get child flags for this path - all of them.
      const pathString = path.join(".")
      const data = getters.surveyDataFlattenWithObjects
      return Object.keys(data).filter(x=> (x.startsWith(pathString) && x.endsWith("__flag"))).map(x=>data[x])
    },
    getPhotoIds: (state, getters) => {
      // Get all photos ids for this survey
      const data = getters.surveyDataFlattenWithObjects
      const regex = /^photo_c[a-z0-9]{24}$/gm
      return Object.keys(data).filter(x=> regex.test(data[x]) ).map(x=>data[x])
    },
  },
  mutations: {
    setSurveyDoc(state, surveyDoc) {
      state.surveyDoc = surveyDoc
    },
  },
  actions: {}
}

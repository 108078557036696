<template>
  <input-row 
    :showElement="showElement"
    :label="label" 
    :elId="elId" 
    :status="status" 
    :config="config"
    :me="me"
  >
    <!-- Default appearance -->
    <b-form-input :id="elId"
                  type="text"
                  :value="myValue"
                  readonly
                  >
    </b-form-input>

  </input-row>
</template>

<script>
import base from './_baseInput.js'


export default {
  name:'ICalculate',
  mixins:[base],
  watch:{
    
  }
}
</script>

<style>

</style>

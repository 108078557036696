<template>
  <div>
    <i class="fas mr-2 my-color" :class="getIcon(fieldObj.type)"></i>
    {{fieldObj.label}}
  </div>
</template>

<script>
  export default {
    props:['fieldObj'],
    methods: {
      getIcon(type){
        if(type=="select one"){
          return "fa-check"
        }else if (type=='select all that apply'){
          return "fa-tasks"
        }else if (type=='photo'){
          return "fa-camera"
        }else if (type=='geopoint'){
          return "fa-map-marked-alt"
        }else if (type=='integer' || type=='decimal'){
          return "fa-sort-numeric-down"
        }else{
          return "fa-quote-right"
        }
      },
    },
  }
</script>

<style scoped>
.my-color{
  color: #188583;
}
</style>

export default {
  props:['form_id'],
  data(){
    return {
      fetchFormPromise:null, //used to ensure the code is only run once. use as in edit.vue that hase to fetch also a survey.
      ready:false // when promise complete - second way to have the state
    }
  },
  computed: {
    config(){return this.$store.state.localDB.config},
    labels(){return this.$store.state.labels},
    formConfig(){return this.$store.state.form.actualFormConfig},
    formDefinition(){return this.$store.getters['form/actualFormDefition']},
    form_error_message(){return this.$store.state.form.form_error_message},
    isLocal(){return this.$store.getters['localDB/isOnlineMode']===false},
    hasLocal(){return this.$store.getters['form/hasLocal']},
    online(){return window.navigator.onLine},
    isLogged(){return this.$store.state.isLogged},
  },
  watch:{
    form_id:{
      handler:'fetchForm',
      immediate:true
    },
    isLocal(){
      this.fetchForm()
    },
    form_error_message(){
      if(this.form_error_message){
        this.$router.push("form_error")
      }
    }
  },
  methods:{
    fetchForm(){
      this.ready=false
      // console.log('fetch form...')
      // return promise as in the components, they can wait for this to complete. see edit.vue
      this.fetchFormPromise=this.$store.dispatch('form/setActualForm',this.form_id).then(rep=>{
        return this.$store.dispatch('form/getActualFormDefition')
      }).then(rep=>{
        //fetch project
        // console.log('fetch project')
        return this.$store.dispatch('project/setProjectId',this.$store.getters['form/get_project_id'])
      }).then(()=>{
        this.ready=true
      })
      return this.fetchFormPromise
    },
  }
}

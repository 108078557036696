<template>
    <div v-if="relevant">
      <div class="list-group-item"
        :style="{'padding-left': padding + 'px'}"
        :class="groupStatusClass"
        @click="showCollapse=!showCollapse"
      >
        <a class="glyphicon" :class="{'glyphicon-chevron-right':!showCollapse,'glyphicon-chevron-down':showCollapse}"></a>
        <span v-html="label"></span>
      </div>
      <b-collapse :id="'repeat'+config.name+i" v-model="showCollapse" class="list-group" v-for="(data1,i) in value" :key="'repeat'+config.name+i">
        <print2-group
            :config="config"
            :formDefinition="formDefinition"
            :value="data1"
            :status="status[i]"
            :showSystemValues=showSystemValues
            :surveyDoc="surveyDoc"
            :namePath="newNamePath(i)"
            :pagesObj="pagesObj"
            :isRootRepeat="true"
            >
        </print2-group>
      </b-collapse>
    </div>
</template>

<script>
import base from '../input/_baseInput.js'
import formUtils from '../../utils/form_utils.js'

export default {
  name:'prin2-repeat',
  mixins:[base],
  props:['pagesObj'],
  data(){
    return {
      showCollapse:true
    }
  },
  beforeCreate () {
    //if we use normal registration, it doesn't work.
    //https://vuejs.org/v2/guide/components-edge-cases.html#Circular-References-Between-Components
    this.$options.components.Print2Group = require( './print2Group.vue').default
  },
  computed:{
    padding(){
      return (this.namePath.length) * 40
    },
    groupStatus(){
      if(Array.isArray(this.status)){
        return formUtils.getStatusOfTree(this.status)
      }
      if(this.status){
        return this.status
      }
    },
    groupStatusClass(){
      if(this.groupStatus){
        return this.status_list[this.groupStatus].list_group_class
      }
    },
  },
  methods:{
    newNamePath(index){
      let new1=this.namePath.slice()
      if(!this.isRoot){
        new1.push(this.config.name)
        new1.push(index)
      }
      return new1
    },
    
  }
}
</script>

<style>

</style>

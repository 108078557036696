<template>
    <div v-if="relevant">
      <div class="list-group-item"
        :style="{'padding-left': padding + 'px'}"
        :class="groupStatusClass"
        @click="showCollapse=!showCollapse"
      >
        <div class="d-flex justify-content-between">
          <div>
            <i class="glyphicon mx-2" :class="{'glyphicon-chevron-right':!showCollapse,'glyphicon-chevron-down':showCollapse}"></i>
            <span v-html="label"></span>
          </div>
          <div>
            <span v-for="(flagCount, flag) in flagsClass" class="mr-3" :class="flag" :key="flag">{{flagCount}} <i class="mdi mdi-flag"></i></span>
          </div>
        </div>
      </div>
      <b-collapse :id="'repeat'+config.name+i" v-model="showCollapse" class="list-group" v-for="(data1,i) in value" :key="'repeat'+config.name+i">
        <summary-group
            :config="config"
            :formDefinition="formDefinition"
            :value="data1"
            :status="status[i]"
            :showSystemValues=showSystemValues
            :surveyDoc="surveyDoc"
            :namePath="newNamePath(i)"
            :pagesObj="pagesObj"
            :isRootRepeat="true"
            >
        </summary-group>
      </b-collapse>
    </div>
</template>

<script>
import base from './input/_baseInput.js'
import formUtils from '../utils/form_utils.js'

export default {
  name:'summary-repeat',
  mixins:[base],
  props:['pagesObj'],
  data(){
    return {
      showCollapse:false
    }
  },
  beforeCreate () {
    //if we use normal registration, it doesn't work.
    //https://vuejs.org/v2/guide/components-edge-cases.html#Circular-References-Between-Components
    this.$options.components.SummaryGroup = require( './summaryGroup.vue').default
  },
  computed:{
    padding(){
      return (this.namePath.length) * 40
    },
    groupStatus(){
      if(Array.isArray(this.status)){
        return formUtils.getStatusOfTree(this.status)
      }
      if(this.status){
        return this.status
      }
    },
    groupStatusClass(){
      if(this.groupStatus){
        return this.status_list[this.groupStatus].list_group_class
      }
    },
    flagsClass(){
      let flags =  this.$store.getters['form/survey/flagsStatusByPath'](this.currentNamePath)
      const rep = {}
      flags.forEach((f1)=>{
        if(!rep[f1]){
          rep[f1]=0
        }
        rep[f1]++
      })
      return rep
    },
  },
  methods:{
    newNamePath(index){
      let new1=this.namePath.slice()
      if(!this.isRoot){
        new1.push(this.config.name)
        new1.push(index)
      }
      return new1
    },
    
  }
}
</script>

<style>

</style>

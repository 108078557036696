<template>
  <div class="container-fluid" v-if="systemDoc">
    <div class="h2  text-center">
      SQL exports
    </div>
    <div class="row justify-content-md-center">
      <div class="col-lg-8">
        <b-form-checkbox-group v-model="selected_forms_id">
          <ul class="list-group" v-if="since_checkpoints">
            <li class="list-group-item bg-dark text-white text-center my-pad2">
              {{labels.projectsListTitle2}}
            </li>
            <!-- Project list -->
            <template v-for="project in systemDoc.projects.filter(x=>!x.deleted)"  >
              <li class="list-group-item px-0 list-group-item-secondary" :key="project.id" >
                <div class="mx-2">
                    {{project.label}}
                </div>
              </li>
              <!-- Forms list -->
              <li v-for="form in project.forms.filter(x=>!x.deleted)" class="list-group-item" :key="project.id + form.id">
                <b-row class="my-1">
                  <b-col >
                    <b-form-checkbox :value="form.id">{{form.label}}</b-form-checkbox> 
                  </b-col>
                  <!-- ViewName -->
                  <b-col >
                    <b-form-input :value="form.sql_name_prefix" placeholder="Enter sql name" @input="form.sql_name_prefix=$event;sqlNames[form.id]=$event"></b-form-input>
                  </b-col>
                  <b-col sm="1">
                    <b-btn class="float-right" v-if="$store.getters['sqlApi/isAuthenticated'] && !since_checkpoints[form.id]" size="sm" @click="sqlTrack(form.id)">Track in SQL</b-btn>
                  </b-col>
                </b-row>
              </li>
            </template>
          </ul>
        </b-form-checkbox-group>
      </div>
    </div>
    <div class="row justify-content-md-center mt-3">
      <div class="col-lg-6">
        <!-- options -->
        <b-form-checkbox-group v-model="selected_options">
          <ul class="list-group">
            <li class="list-group-item bg-dark text-white text-center my-pad2">
              Options
            </li>
            <li class="list-group-item" v-for="opt in options" :key="opt.name">
              <b-form-checkbox :value="opt.name">{{opt.label}}</b-form-checkbox>
              <b-form-input v-if="opt.hasInput && selected_options.indexOf(opt.name)!=-1" v-model="selected_options_vals[opt.name]" placeholder="Valeur?"></b-form-input>
            </li>
          </ul>
        </b-form-checkbox-group>
        <!-- Buttons actions  -->
        <div>
          <b-btn variant="primary" class="my-3" @click="generateSql" :disabled="!hasFormsSelected">
            Get SQL
          </b-btn>
          <b-btn variant="outline-primary" class="my-3 ml-3" @click="getPolygons" :disabled="!hasFormsSelected">
            Get Polygons
          </b-btn>
          <b-btn variant="outline-primary" class="my-3 ml-3" @click="generateDesignDocs" :disabled="!hasFormsSelected">
            Generate design docs (geom also)
          </b-btn>
          <b-btn variant="outline-warning" class="my-3 ml-3" @click="verifyAllSurveys" :disabled="!hasFormsSelected">
            Verify all surveys
          </b-btn>
          <b-btn variant="primary" class="my-3 ml-3" @click="validateFormDef" :disabled="!hasFormsSelected">
            Validate form definition
          </b-btn>
        </div>
      </div>
    </div>
    <div v-if="message" class="alert alert-secondary">
      {{message}}
    </div>
    <div v-if="messageHtml" class="alert alert-secondary" v-html="messageHtml">
    </div>
    <div v-if="errors" class="alert alert-danger">
      {{errors}}
    </div>
    <div class="row mx-3" v-if="sql">
      <div class="my-3" v-if="$store.getters['sqlApi/isAuthenticated']">
        <b-btn variant="info" @click="sqlCreate">Create SQL</b-btn>
      </div>
      <div class="card" >
        {{sql}}
      </div>
    </div>
    <!-- <div class="card my-5">
      {{selected_forms}}
    </div> -->
  </div>
</template>

<script>
import admin_utils from '../../utils/admin_utils.js'
import formUtils from '../../utils/form_utils.js'
import edit from '../form/edit.vue'

export default {
  name:'sql-admin',
  components:{
    edit,
  },
  data(){
    return {
      sql:null,
      selected_forms_id:[],
      selected_options: this.$store.state.systemDoc.sqlSelectedOptions ?? ['namebyform','numbersErrors','selectManyTable', 'varchar','postgis_schema'],
      selected_options_vals: this.$store.state.systemDoc.sqlSelectedOptionsVals ?? {},
      message:null,
      messageHtml:null,
      errors:null,
      options:[
        {name:'namebyform',label:'Name the view with the settings sql_name_prefix value, unless use the form name'},
        {name:'showLabelValue',label:'Show the label value for choices'},
        {name:'exportChoices',label:'Export the choices'},
        {name:'nobase',label:'Don\'t export the base SQL (want other option)'},
        {name:'varchar',label:'Text (not multiline) as vachar(256)'},
        {name:'splitNbColumns',label:'Split columns - If more columns than specified number create different tables (complete base view also create)', hasInput: true},
        {name:'numbersErrors',label:'Use function with error handling for nubmers(convert_to_int and convert_to_float)'},
        {name:'postgis',label:'Export PostGIS geometries'},
        {name:'postgis_schema',label:'Include PostGIS schema in search_path (todo when problems with geometries, depends on database configuration)'},
        {name:'gpsaccuray',label:'Include GPS accuracy'},
        {name:'attachements',label:'Attachements list generation (sql_attachment_list)'},
        {name:'attachements-union',label:'Union attachments list (create v_attachments_list)'},
        {name:'selectManyNoStringConcatenation',label:'Select many, no string concatenation'},
        {name:'selectManyTable',label:'Select many put in a separate table(view)'},
        {name:'repeatNumber',label:'Export a repeat Number (no)'},
        {name:'attachmentsView',label:'Attachements view generation (generic for all attachmnets/images)( one view for all in same form)'}, 
        {name:'metrializedView',label:'Create materialized view (at the end in comment refresh code) (if view(s) already exists, delete them manually first)'}, 
        {name:'schemas',label:'If want data in another schem than public, specify the Schemas order (separated by comma(,)) (includ the form data schema, usually public) (has to exists and role creators granted create right', hasInput: true}, 
        {name:'grantUsers',label:'Grant user(s) select (separated by comma(,)', hasInput: true}, 
        {name:'verifyAllUpdateDef',label:'When verify all, update the definition to the latest definition'}, 
        {name:'includeDeletedSurveys',label:'Include deleted surveys (if not selected, the deleted surveys are not in the SQL export)'}, 
      ],
      sqlNames:{}, //change sql names
    }
  },
  computed: {
    systemDoc(){
      return this.$store.state.systemDoc
    },
    labels(){return this.$store.state.labels},
    forms(){
      const rep = []
      this.systemDoc.projects.map(pro=>{
        pro.forms.map(form=>{
          rep.push(form)
        })
      })
      return rep
    },
    selected_forms(){
      return this.forms.filter(x=>this.selected_forms_id.indexOf(x.id)!=-1)
    },
    hasFormsSelected(){
      return this.selected_forms_id.length>0
    },
  },
  asyncComputed:{
    since_checkpoints(){
      if(this.$store.getters['sqlApi/isAuthenticated']){
        return this.$store.state.sqlApi.axiosInstance.post('',{query: `query{allSinceCheckpoints{nodes{pgtable}}}`}).then(req=>{
          const rep={}
          req.data.data.allSinceCheckpoints.nodes.map(x=>{
            rep[x['pgtable']]=true
          })
          return rep
        })
      }else{
        return Promise.resolve({})
      }
    }
  },
  methods:{
    saveOptions(){
      //  save option and option val to couchdb
      return this.$store.state.dbDatabaseSystem.get('config').then(doc=>{
        doc.sqlSelectedOptions=this.selected_options
        doc.sqlSelectedOptionsVals=this.selected_options_vals
        return this.$store.state.dbDatabaseSystem.put(doc)
      }).then(()=>{
        return this.$store.dispatch('getSystemDoc')
      }).catch(err=>{
        this.$store.dispatch('app_message_error',err)
        return Promise.reject()
      })
    },
    generateSql(){
      this.sqlNamesCommit().then(()=>{
        return this.saveOptions()
      })
      this.message=null
      this.errors=null
      this.sql=null
      const opts={couchDbUrl:this.$store.state.couchdbUrl}
      this.selected_options.map(x=>{
        opts[x]=true
      })
      Object.keys(this.selected_options_vals).map(x=>{
        opts[x + '_val'] = this.selected_options_vals[x]
      })
      //TODO: always use online form version...
      //https://decembersoft.com/posts/promises-in-serial-with-array-reduce/
      // also explain it https://blog.hellojs.org/use-reduce-and-promises-to-execute-multiple-async-calls-sequentially-4caf03a34b9a
      this.selected_forms.reduce((promiseChain,CurrentTask)=>{
        return promiseChain.then(chainResults =>
          // First set the current form
          this.$store.dispatch('form/setActualForm',CurrentTask.id).then(rep=>{
            // ensure we are in databse mode 1 == online
            this.$store.dispatch('localDB/switchDatabasesAccesMode',{accessMode: 1})
          }).then(rep=>{
            console.log(CurrentTask.id)
            console.log(rep)
            console.log(this.$store.getters['form/actualFormDefitionId'])
            return this.$store.dispatch('form/fetchDefinition',this.$store.getters['form/actualFormDefitionId'])
          }).then(doc=>{
            let sql1=''
            let opts2=Object.assign({},opts)
            //define a v_name for the naming of the created views
            opts2.v_name='v_'+CurrentTask.id
            // name with the settings parameter
            if(doc.form_definition.sql_name_prefix){
              opts2.v_name=doc.form_definition.sql_name_prefix
            }
            // Name with the sql_prefix, can be edited in this component. Useful if one form xlsx is resuse for many froms
            if(this.selected_options.indexOf('namebyform')!=-1 && CurrentTask.sql_name_prefix){
              opts2.v_name=CurrentTask.sql_name_prefix
            }
            //base SQL
            if(this.selected_options.indexOf('nobase')==-1){
              sql1+=admin_utils.createViewSql(CurrentTask.id,doc.form_definition,opts2)
            }
            //attachments
            if(this.selected_options.indexOf('attachements')!=-1 && doc.form_definition.sql_attachment_list){
              sql1+=';'+admin_utils.generateAttachmentList(CurrentTask.id,JSON.parse(doc.form_definition.sql_attachment_list),opts2)
            }
            if(this.selected_options.indexOf('attachmentsView')!=-1){
              sql1+=admin_utils.createViewSqlAttachments(CurrentTask.id,doc.form_definition,opts2)
            }
            return sql1
          }).then(rep_sql=>
            [ ...chainResults, rep_sql ]
          )
        )
      },Promise.resolve([])).then(arrayOfResults => {
        // Do something with all results
        this.sql=arrayOfResults.join(';')
        if(this.selected_options.indexOf('attachements-union')!=-1){
          this.sql+=';'
          this.sql+=admin_utils.unionAttachmentList(this.sql,{})
        }
      }).catch(err=>this.$store.dispatch('app_message_error',err))
      /*
      Sample for one!
      this.$store.dispatch('form/fetchDefinition',this.$store.getters['form/actualFormDefitionId']).then(doc=>{
        console.log(doc)
        this.sql=admin_utils.createViewSql(this.formConfig.id,doc.form_definition,this.$store.state.couchdbUrl)
      }).catch(err=>this.$store.dispatch('app_message_error',err))
      */
    },
    //used for more simple function that we can call. more easily.
    executeAll(functionToExecute){
      return this.selected_forms.reduce((promiseChain,CurrentTask)=>{
        return promiseChain.then(chainResults =>
          this.$store.dispatch('form/setActualForm',CurrentTask.id).then(rep=>{
            return this.$store.dispatch('localDB/switchDatabasesAccesMode',{accessMode: 1})
          }).then(rep=>{
            return this.$store.dispatch('form/fetchDefinition',this.$store.getters['form/actualFormDefitionId'])
          }).then(doc=>{
            return functionToExecute(doc)
          }).then(rep_current=>
            [ ...chainResults, rep_current ]
          )
        )
      },Promise.resolve([]))//.then(arrayOfResults => {
        // return Promise.resolve(arrayOfResults)
      // })
    },
    getPolygons(){
      this.executeAll(this.getPolygons_sub).then(arrayOfResults => {
        let merged = [].concat.apply([], arrayOfResults);
        let geojson={
          type:'FeatureCollection',
          features:merged
        }
        this.sql=JSON.stringify(geojson)
      }).catch(err=>this.$store.dispatch('app_message_error',err))
    },
    getPolygons_sub(doc){
      //TODO: create the view if not exists...
      //view alreay present:
      // {
      //   "_id": "_design/polygons",
      //   "views": {
      //     "poly": {
      //       "map": "function(doc){if(doc.form_data && doc.form_data.commentaires.poly_groupe){emit(doc._id,doc.form_data.commentaires.poly_groupe);}}"
      //     }
      //   }
      // }
      //for now we don't use the definition.. view is hard coded
      return this.$store.state.form.db.query('polygons/poly').then(polygons=>{
        return polygons.rows.map(x=>{
          //put the id in the properties
          let poly=x.value
          poly.properties={
            id_survey:x.id
          }
          return poly
        })
      })
    },
    generateDesignDocs(){
      this.executeAll(this.generateDesignDocs_sub).then(arrayOfResults => {
        this.sql='design docs generated'
      }).catch(err=>this.$store.dispatch('app_message_error',err))
    },
    generateDesignDocs_sub(){
      return this.$store.dispatch('form/createDesignDocs')
    },
    verifyAllSurveys(){
      this.executeAll(this.verifyAllSurveys_sub).then(arrayOfResults => {
        this.message='All documents verified'
        arrayOfResults.forEach(x=>{
          if(x.length>0){
            this.message += ' / Survey with error validating, see console for more details: ' + x.join(',')
          }
        })
      }).catch(err=>this.$store.dispatch('app_message_error',err))
    },
    async verifyAllSurveys_sub(){
      // get list of seurveys
      // loop
      this.verifyAll_form_id = this.$store.state.form.form_id
      const list1 = (await this.$store.state.form.db.allDocs({
        include_docs: false,
        attachments: false,
        descending: true,
        //Whenever descending is set to true, we need to switch the startkey and endkey to get the results we want:
        //https://pouchdb.com/2014/04/14/pagination-strategies-with-pouchdb.html
        endkey: "survey_",
        startkey: "survey_\ufff0"
      }))['rows'];

      const errorList = []
      for (var i = 0; i < list1.length; i++) {
        const x = list1[i]

        const doc = await this.$store.dispatch('form/fetchSurvey',{survey_id: x.id})
        const def = await this.$store.dispatch('form/fetchDefinition',doc.form_definition)
        
        let def2 = def.form_definition
        if (this.selected_options.indexOf('verifyAllUpdateDef')!=-1){
          def2 = this.$store.getters['form/actualFormDefition']
          doc.form_definition = this.$store.getters['form/actualFormDefitionId']
        }
        try {
          formUtils.setSurveyDocStatus(doc, def2, null, false)
          doc.auto_validation_time = this.$store.getters['utcCurrentTime']()
          await this.$store.dispatch('form/saveSurveyNoEditor',doc)
        } catch (error) {
          console.error(x.id);
          console.error(error);
          errorList.push(x.id)
        }
        this.message=`verified: ${i+1}` 
      };
      return errorList
    },
    validateFormDef(){
      this.executeAll(this.validateFormDef_sub).then(arrayOfResults => {
        this.messageHtml= 'All definition verified </br>' + arrayOfResults.join('</br>')
      }).catch(err=>this.$store.dispatch('app_message_error',err))
    },
    async validateFormDef_sub(){
      // get definition
      const names = this.$store.getters['form/actualFormDefitionFieldsFlatten']
      const names2 = names.map(x=>x['path'][x['path'].length - 1])
      // verify that all calculation, relevant and constraint referenced values are in list of names.
      const getRefFields = (exprString)=>exprString.match(/\${(.*?)}/g).map(x=>x.match(/[^${}]+/g)[0].split('@')[0])
      const names3 = names.reduce((fis,fi) => {
        ['calculate','constraint','relevant'].forEach(y=>{
          if(fi.field.bind && fi.field.bind[y]){ // return the calculation column of the excel file.
            try {
              fis = fis.concat(getRefFields(fi.field.bind[y]))
            } catch (error) {}
          }
        })
        return fis
      },[]);
      let mess=[]
      // fields that are no in the definition
      mess.push('*** Duplicated Fields - Very important to correct')
      mess=mess.concat(names2.filter((e, i, a) => a.indexOf(e) !== i))
      mess.push('*** Fields referenced but not present in definition')
      mess=mess.concat(names3.filter(x=>x!='@n').filter(x=>names2.indexOf(x)==-1))
      return mess.join('</br>')
    },
    sqlTrack(id){
      return this.$store.state.sqlApi.axiosInstance.post('',{query:`mutation {createSinceCheckpoint(input: {sinceCheckpoint: {pgtable: "${id}", enabled: ${true}}}){sinceCheckpoint{pgtable}}}`}).then(rep=>{
        this.message='Tracking done, but still need to wait some minutes till it\'s ready for use and sql creation. Please refresh your browser and ensure "Track in SQL" disapered.'
        this.$asyncComputed.since_checkpoints.update()
      }).catch(err=>{
        this.errors='Tracking failed: \n' + JSON.stringify(err.response.data)
      })
    },
    sqlCreate(){
      this.message=null
      this.errors=null
      return this.$store.state.sqlApi.axiosInstance.post('',{
        query:`mutation MyMutation($input: CreateSqlCreateViewInput!){createSqlCreateView(input: $input){sqlCreateView{id}}}`,
        variables:{input: {sqlCreateView: {sqlText: this.sql}}}
      }).then(rep=>{
        console.log(rep);
        if(rep.data.errors){
          this.errors='SQL creation failed: \n' + JSON.stringify(rep.data.errors)
        }else{
          this.message='SQL creation done'
        }
      }).catch(err=>{
        this.errors='SQL creation failed: \n' + JSON.stringify(err.response.data)
      })
    },
    sqlNamesCommit(){
      if(Object.keys(this.sqlNames).length>0){
        // this.$store.state.systemDoc
        return this.$store.state.dbDatabaseSystem.get('config').then(doc=>{
          doc.projects.map(x=>{
            x.forms.map(form=>{
              if(form.id in this.sqlNames){
                form.sql_name_prefix=this.sqlNames[form.id]
              }
            })
          })
          return this.$store.state.dbDatabaseSystem.put(doc)
        }).then(()=>{
          return this.$store.dispatch('getSystemDoc')
        }).catch(err=>{
          this.$store.dispatch('app_message_error',err)
          return Promise.reject()
        })
      }else{
        return Promise.resolve()
      }

    }
  }
}

</script>

<style>

</style>

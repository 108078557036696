<script>
  // ***** we extend the vue bootstrap input component to add the mask and money functionality
  import { BFormInput } from 'bootstrap-vue'
  import {Money} from 'v-money'
  import {TheMask} from 'vue-the-mask'
  import BFormInputMaskAutonumeric from './BFormInputMaskAutonumeric.vue'
  
  export default {
    name:'BFormInputMask',
    mixins:[BFormInput],
    components:{
      Money,
      TheMask,
      BFormInputMaskAutonumeric
    },
    props:['mask','money','autonumeric', 'masked'],
    computed: {
      computedListeners() {
        return {
          ...this.bvListeners,
          // input: this.onInput,
          // change: this.onChange,
          blur: this.onBlur
        }
      },
    },
    methods: {
      onInput2: function onInput(event) {
        this.localValue = event
        this.updateValue(event)
        this.$emit('input', event);
      },
    },
    render(h) {
      let comp1 = 'the-mask'
      if(this.money){
        comp1 = 'money'
      }
      if(this.autonumeric){
        comp1 = 'b-form-input-mask-autonumeric'
      }
      return h(comp1, {
      // return h('imput', {
        class: this.computedClass,
        attrs: Object.assign(this.computedAttrs,{mask:this.mask, options:this.autonumeric, value:this.localValue ?? 0, masked: this.masked},this.money),
        // domProps: { value: this.localValue },
        on: {
          ...this.bvListeners,
          input: this.onInput2,
          change: this.onChange,
          blur: this.onBlur
        },
        // on: this.computedListeners,
        ref: 'BFormInputMaskSub'
      })
    }
  }
</script>

<style lang="scss" scoped>

</style>
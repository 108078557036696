<template>
  <input-row 
    :showElement="showElement"
    :label="label" 
    :elId="elId" 
    :status="status" 
    :config="config"
    :me="me"
  >
    <b-card bg-variant="light" class="mx-2">
      <b-row class="my-1">
        <b-col sm="3" >
          <my-label :label="labels.inputs.longitude" :elId="elId+'a'" :status="status"></my-label>
        </b-col>
        <b-col sm="9">
          <b-form-input :id="elId+'a'" type="number" :value="x" :readonly="true" v-if="type!='manual'"
          ></b-form-input>
          <b-form-input :id="elId+'a'" type="number" v-model="x_manual" v-if="type=='manual'" :readonly="readonly"
          ></b-form-input>
        </b-col>
      </b-row>

      <b-row class="my-1">
        <b-col sm="3" >
          <my-label :label="labels.inputs.latitude" :elId="elId+'b'" :status="status"></my-label>
        </b-col>
        <b-col sm="9">
          <b-form-input :id="elId+'b'" type="number" :value="y" :readonly="true" v-if="type!='manual'"
          ></b-form-input>
          <b-form-input :id="elId+'b'" type="number" v-model="y_manual" v-if="type=='manual'" :readonly="readonly"
          ></b-form-input>
        </b-col>
      </b-row>

      <b-row class="my-1" v-if="altitude">
        <b-col sm="3" >
          <label :for="elId+'c'">
          {{labels.inputs.altitude}}
          </label>
        </b-col>
        <b-col sm="9">
          <b-form-input :id="elId+'c'" type="number" v-model="altitude" :readonly="type!='manual'" >
          </b-form-input>
        </b-col>
      </b-row>

      <b-row class="my-1" v-if="accuracy">
        <b-col sm="3" >
          <label :for="elId+'c'">
            {{labels.inputs.accuracy}}
          </label>
        </b-col>
        <b-col sm="9">
          <b-form-input :id="elId+'c'" type="number" v-model="accuracy" :readonly="type!='manual'" >
          </b-form-input>
        </b-col>
      </b-row>

      <!-- Type -->
      <b-row class="my-1" v-if="type">
        <b-col sm="3" >
          {{labels.inputs.typePoint}}:
        </b-col>
        <b-col sm="9">
          {{type}}
          <b-button class="ml-2" disabled :variant="nbPositions > averageNbMinPositions?'success':'warning'" v-if="nbPositions" size="sm">
            <i class="fas fa-flag"></i> {{nbPositions}}
          </b-button>
        </b-col>
      </b-row>
      <b-row class="my-1">
        <b-col sm="3" ></b-col>
        <b-col sm="9">
          <div v-if="!readonly">
            <!-- Buttons -->
            <b-btn v-if="showGpsEdit" v-show="type!='manual'" @click="clickManual()" size="sm" class="ml-2 myManual">
              <span class="glyphicon glyphicon-pencil"></span>
            </b-btn>
            <b-button @click="getFromMap(1)" variant="primary" class="ml-2" v-if="$store.getters['form/isMapActive'] && !isLive">
              <span class="glyphicon glyphicon-globe"></span>
            </b-button>
            <b-button @click="getFromMap(2)" :variant="GpsAge<15?'primary':'danger'" class="ml-2" v-if="isAveragePositions && $store.getters['form/isMapActive']">
              <span class="glyphicon glyphicon-screenshot" v-if="!isLive"></span>
              <span class="glyphicon glyphicon-refresh" v-if="isLive"></span>
            </b-button>
            <b-button 
                @click="getFromGPS"
                v-if="!isAveragePositions && $store.getters['position/inputGeopointDirectGpsClickAllowed']"
                :variant="GpsAge<15?'primary':'danger'"
                class="ml-2"
                :disabled ="($store.state.position.position)?false:true">
              <!-- {{labels.inputs.add}} -->
              <span class="glyphicon glyphicon-screenshot"></span>
            </b-button>
            <accuracy uiVersion="1"></accuracy>
            <span class="small ml-1">
              {{labels.inputs.GpsLastUpdate}}: {{minutes}}{{labels.inputs.GpsAgeMinutes}} {{seconds}}{{labels.inputs.GpsAgeSeconds}}
            </span>
            <delete-button myclass="float-right" @delete="myValue=null" size="sm" v-if="myValue"></delete-button>
          </div>
          <div v-else>
            <!-- Readonly -->
            <b-button @click="getFromMap(3)" variant="primary" class="ml-2" v-if="$store.getters['form/isMapActive']">
              <span class="glyphicon glyphicon-globe"></span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </input-row>
</template>

<script>
// it show X Y, but it save the result as geojson. easier to show and store some attributes (accuracy).
import base from './_baseInput.js'
import Geolocation from 'ol/geolocation'
import accuracy from '../../pages/form/map/components/accuracy.vue'
import cuid from 'cuid'
import deleteButton from '../utils/deleteButton.vue'


export default {
  name:'i-geopoint',
  mixins:[base],
  components:{
    accuracy,
    deleteButton,
  },
  data(){
    return{
      x_manual:null,
      y_manual:null,
      isManual:false,
      waitForResponse:null,
      liveId: null,
      mapMode: 1,  // 1=normal, 2= average, 3=view only
    }
  },
  computed:{
    GpsAge(){return this.$store.state.position.gpsAgeSeconds},
    seconds(){return this.GpsAge % 60 },
    minutes(){return Math.trunc(this.GpsAge/ 60) },
    x(){
      return this.value ? this.value.geometry.coordinates[0]:null
    },
    y(){
      return this.value ? this.value.geometry.coordinates[1]:null
    },
    accuracy(){
      return (this.value && this.value.properties.accuracy) ? this.value.properties.accuracy:null
    },
    altitude(){
      return (this.value && this.value.properties.altitude) ? this.value.properties.altitude:null
    },
    type(){
      if(this.isManual){
        return 'manual'
      }
      return this.value?.properties?.input_type
    },
    uid(){
      return this.value? this.value.properties.uid:null
    },
    showGpsEdit(){
      // its a definition or parameter setting
      return this.$store.getters['form/actualFormDefitionSetting']('showGpsEdit',true,true) == true && this.parameters.showGpsEdit!==false
    },
    // **** Params for average position
    isAveragePositions(){
      return this.parameters.point_average == '1'
    },
    averageNbMinPositions(){
      let rep = Number(this.parameters.min_nb_positions)
      if (! rep > 0){
        rep = 10 // default to 10 positions
      }
      return rep
    },
    averageInterval(){
      let rep = Number(this.parameters.position_interval)
      if (! rep > 0){
        rep = null // default to null so can be setup in the map component
      }
      return rep
    },
    isLive(){
      if (this.liveId && this.$store.state.formMap.formComponentLive[this.liveId]){
        return true
      }
      return false
    },
    liveResponse(){
      if (this.isLive){
        return this.$store.state.formMap.formComponentLive[this.liveId].response
      }
    },
    nbPositions(){
      if(this.isAveragePositions && this.myValue){
        return this.myValue.properties.positions_nb
      }
    },
    value_manual_changing_complete(){
      return this.x_manual && this.y_manual
    }
  },
  watch:{
    x_manual:'setManual',
    y_manual:'setManual',
    value:{
        immediate:true,
        handler(){
          // console.log('geopoint value changed')
          if(this.value && this.value.properties.input_type=='manual'){
            // console.log('geopoint value changed - manual')
            if(this.x_manual!=this.x){
              this.x_manual=this.x
            }
            if(this.y_manual!=this.y){
              this.y_manual=this.y
            }
          }
        }
    }
  },
  methods:{
    clickManual(){
      this.x_manual=null
      this.y_manual=null
      this.isManual=true
      this.setManual()
    },
    setManual(){
      //set the type to mamual, with the corrent coordinates
      // console.log('set manual')
      if(this.value_manual_changing_complete) {
        // console.log('set manual 2')
        let pos = {
          position:[+this.x_manual,+this.y_manual],
          type:'manual'
        }
        this.getFromMap_response(pos)
      }
    },
    getFromGPS(){
      // Normal case - one position
      let pos = Object.assign({},{
        position:this.$store.state.position.position,
        accuracy:this.$store.state.position.accuracy,
        altitude:this.$store.state.position.altitude,
        time:this.$store.getters['position/timeISO'],
        gps_age:this.GpsAge,
        type:'gps'
      })
      this.getFromMap_response(pos)
    },
    getFromMap(mode){
      if(this.isAveragePositions && mode == 2){
        this.getFromMapAverage()
      }else{
        this.getFromMapNormal(mode)
      }
    },
    getFromMapNormal(mode){
      this.mapMode=mode
      // ********* OPEN the map and let the user click or select to use the GPS
      let formInput={
        component_name:'map-geopoint',
        properties:{
          survey_id: this.surveyDoc._id,
          returnRoute:this.$route.path,
          allowGpsButton: mode==3?false:true,
          allowClickMap: mode==3?false:true,
          inputGeopointClickDirectReturn: this.$store.getters['form/actualFormDefitionSetting']('inputGeopointClickDirectReturn',null,true) ?? true,
          currentValue: this.myValue // if a value, we want to draw it on the map.
        }
      }
      this.$store.commit('formMap/formComponent',formInput)
      // console.log('create watch')
      this.waitForResponse=this.$watch(()=>{
        return this.$store.state.formMap.formComponent_response
      },(newVal,oldVal)=>{
        if (mode!=3){
          this.getFromMap_response(newVal)
        }
      })
      // console.log('wait for response created')
      this.$router.push('../map')
    },
    getFromMapAverage(){
      if (!this.isLive){
        this.mapMode=2
        let formInput={
          component_name:'map-geopoint-average',
          isLive:true,
          type:'survey', // type survey says that when we quit the survey, the components close
          currentNamePath:this.currentNamePath,
          properties:{
            liveId:cuid(),
            returnRoute:this.$route.path,
            allowGpsButton:true,
            allowClickMap:false,
            currentValue: (this.myValue && this.myValue.input_type=='GPS-average')?this.myValue:null, // if a value, we want to draw it on the map.
            averageInterval: this.averageInterval,
            nbMinPositions: this.averageNbMinPositions,
          }
        }
        this.$store.commit('formMap/addFormComponentLive',formInput)
        this.liveId=formInput.properties.liveId
      }
      this.$router.push('../map')
    },
    getFromMap_response(newVal){ // used for manual and from GPS as well.
      if(newVal && this.mapMode == 1){
        // console.log('update coord - input - geopoint.vue')
        //should create a geojson feature. But has to support existing form or convert date in them.
        let obj1={
          type:'Feature',
          properties:{},
          geometry:{
            "type":"Point",
            "coordinates":[newVal.position[0],newVal.position[1]],
          }
        }
        obj1.properties.input_type=newVal.type;
        // TODO: see of logical to copy the current accuracy????
        if(this.accuracy){
          obj1.properties.accuracy=this.accuracy
        }else{
          delete obj1.properties.accuracy
        }
        ['accuracy', 'altitude'].map(prop=>{
          // console.log(prop);
          // console.log(newVal);
          if(newVal.hasOwnProperty(prop) && newVal[prop]){
            // console.log('ici1');
            obj1.properties[prop]=newVal[prop]
          }else{
            // console.log('ici2');
            delete obj1.properties[prop]
          }
        })
        // console.log('ici4');
        if(this.$store.state.position.position){
          //also save the GPS position for reference.
          obj1.properties.gps_geojson=this.$store.getters['position/getGeoJSON']
        }
        // console.log('ici5');
        // console.log(obj1);
        this.myValue=obj1
      }
      if(this.waitForResponse!==null){ //verify as we use the same function for newval from direct GPS button (getFromGPS)
        // console.log('wait for response geopoint removed')
        this.waitForResponse()
        this.waitForResponse=null
      }
    }
  }
}
</script>

<style scoped>
  .myManual{
    background-color: #ffc107;
    border-color: #ffc107;
  }
</style>

<template>
    <div v-if="relevant && printVisible">
      <div v-if="!isRoot && label_group"
        :style="{'padding-left': padding + 'px'}"
        :class="groupClasses"
      >
        {{prefixDash}}
        <span :class="{'ml-1': printStyle!='raw'}" v-html="label_group"></span>
      </div>
      <div v-if="isRoot" class="my-3">
        <b-btn variant="primary" @click="$emit('hide')"><span class="glyphicon glyphicon-edit"></span> {{labels.form.summary.backToEdit}}</b-btn>
        <b-btn  @click="showPrint" variant="dark" class="ml-5"><i class="glyphicon glyphicon-print"></i></b-btn>
        
      </div>
      <div :id="'group'+config.name" v-if="true" :class="{'list-group': printStyle!='raw'}">
        <div v-if="isRoot && print_title">
          <div>{{print_title}}</div>
          <br/>
        </div>
        <component v-for="(el) in formElements"
            :is="getComponentName(el)"
            :config="el"
            :key="el.name"
            :formDefinition="formDefinition"
            :value="myValue[el.name]"
            :status="status[el.name]"
            :surveyDoc="surveyDoc"
            :showSystemValues="showSystemValues"
            :namePath="newNamePath()"
            :pagesObj="pagesObj"
            >
        </component>
        <div v-if="isRoot && print_footer">
          <br/>
          <div>{{print_footer}}</div>
        </div>
      </div>
      <div v-if="isRoot" class="my-3">
        <b-btn variant="primary" @click="$emit('hide')"><span class="glyphicon glyphicon-edit"></span> {{labels.form.summary.backToEdit}}</b-btn>
        <b-btn  @click="showPrint" variant="dark" class="ml-5"><i class="glyphicon glyphicon-print"></i></b-btn>
        
      </div>
      
    </div>
</template>

<script>
import base from '../input/_baseInput.js'
import printItem from './printItem.vue'
import printRepeat from './printRepeat.vue'
import formUtils from '../../utils/form_utils.js'

export default {
  name:'print-group',
  mixins:[base],
  components:{'print-item':printItem,'print-repeat':printRepeat},
  props:{
    pagesObj:{},
    isRootRepeat:{
      type:Boolean,
      default:false
    }
  },
  data(){
    return {
    }
  },
  created(){
    if(this.isRoot){
      
    }
  },
  computed:{
    padding(){
      if(this.printStyle == 'raw'){
        return 0
      }
      if(this.isRootRepeat){
        return (this.namePath.length * 40) - 30
      }
      return (this.namePath.length * 40)
    },
    prefixDash(){
      if(this.printStyle == 'raw'){
        if(this.namePath.length > 0 && this.label_group){
          if(this.isRootRepeat){
            return '-'.repeat(this.namePath.length)
          }
          return '-'.repeat(this.namePath.length+1)
        }
        return '-'
      }
    },
    groupStatus(){
      if (this.status){
        return formUtils.getStatusOfTree(this.status)
      }
    },
    groupClasses(){
      if(this.printStyle != 'raw'){
        return ['list-group-item']
      }
      return ''
    },
    label_group(){
      if(this.isRootRepeat){
        if (this.config[this.printName + '_label_repeat'] == 'none'){
          return null
        }else if(this.config[this.printName + '_label_repeat'] ){
          return this.config[this.printName + '_label_repeat'].replace('${n}', (parseInt(this.namePath.slice(-1))+1) )
        }
        return this.label + ' (' + (parseInt(this.namePath.slice(-1))+1) + ')'
      }
      if (! this.printLabel){
        return null
      }
      return this.printLabel
    },
    print_title(){
      return this.formDefinition['print_title_' + this.printName] || null
    },
    print_footer(){
      return this.formDefinition['print_footer_' + this.printName] || null
    },
  },
  watch:{
    
  },
  methods:{
    getComponentName(el){
      if(el.type=='group'){
        return 'print-group'
      }else if(el.type=='repeat'){
        return 'print-repeat'
      }
      return 'print-item'
    },
    newNamePath(){
      let new1=this.namePath.slice()
      if(!this.isRoot && this.isRootRepeat===false){
        new1.push(this.config.name)
      }
      return new1
    },
    showPrint(){
      // open a new window to only print relevant info.
      let w1 = window.open()
      w1.document.body.innerHTML = document.getElementById('group'+this.config.name).innerHTML
    },
  }
}
</script>

<style>

</style>

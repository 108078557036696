<template>
  <div class="d-flex justify-content-between">
    <div>
      <div>
        {{lay.label}}
      </div>
      <div class="text-muted ml-4 mt-2" v-for="subLayer in lay.layers" :key="subLayer._id">
        <sub-layer :subLayer0="subLayer" :project="project" @update="$emit('update')" @errors="$emit('errors',$event)"></sub-layer>
      </div>
      <p-color v-model="colors" v-if="isAdding"></p-color>
    </div>
    <div>
      <b-btn variant="primary" v-if="!lay.hasExisting && $store.getters.isAdmin && isAdding===false" @click="isAdding=true"><span class="glyphicon glyphicon-plus"></span></b-btn>
      <b-btn variant="primary" v-if="isAdding" @click="addGeom(lay)"><span class="mdi mdi-content-save"></span></b-btn>
      <b-btn variant="danger" v-if="lay.hasExisting && $store.getters.isAdmin" @click="removeGeom(lay)"><span class="glyphicon glyphicon-trash"></span></b-btn>
    </div>
  </div>
</template>

<script>
  import { Swatches } from 'vue-color' // https://github.com/xiaokaike/vue-color
  import subLayer from './mapLayersLayerSub.vue'

  export default {
    name: "mapLayersLayer",
    components: {
      'p-color': Swatches,
      subLayer,
    },
    props: ['project', "lay"],
    data() {
      return {
        isAdding:false,
        colors: '#ff0000',
      }
    },
    computed: {
      dbProj(){
        return new this.$PouchDB(this.$store.state.couchdbUrl + this.project.id, this.$store.getters.pouchDbOptions)
      },
    },
    methods: {
      addGeom(layer){
        const db= new this.$PouchDB(this.$store.state.couchdbUrl + layer.id, this.$store.getters.pouchDbOptions)
        db.get('_design/geometries').then(doc=>{
          const docsCreate=[]
          Object.keys(doc.views).map(view=>{
            docsCreate.push(JSON.parse(template.replace(/@id@/g,layer.id).replace(/@view@/g,view)
            .replace(/@color@/g,this.colors.hex).replace(/@label@/g,layer.form_label)))
          })
          return this.dbProj.bulkDocs(docsCreate)
        }).then(()=>{
          this.$emit('update')
          this.isAdding=false
        }).catch(err=>{
          this.$store.dispatch('app_message_error',err)
          this.$emit('errors',err)
        })
      },
      removeGeom(layer){
        // easy, just remove the doc in project
        const docs=layer.layers.map(x=>{
          return {_deleted: true,_id:x._id,_rev:x._rev}
        })
        this.dbProj.bulkDocs(docs).then(()=>{
          this.$emit('update')
        }).catch(err=>{
          this.$emit('errors',err)
        })
      },
    },
  }

const template=`{
  "_id": "map_layer_@id@_@view@",
  "type_store": "layer_vector_couchdb",
  "visible": true,
  "type": "geojson_couchdb",
  "label": "@label@ / @view@",
  "couchdb_form": "@id@",
  "couchdb_view_name": "geometries/@view@",
  "couchdb_live": true,
  "renderMode": "image",
  "projection": "EPSG:4326",
  "ol_style": {
    "image": {
      "fill": {
        "color": "@color@"
      },
      "radius": 6,
      "zIndex": 3
    },
    "stroke":{
      "color": "@color@",
      "width": 2,
      "zIndex": 2
    }
  }
}`
</script>

<style lang="scss" scoped>

</style>
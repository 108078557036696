import Vue from 'vue'
import Router from 'vue-router'
import listProjects from '../pages/listProjects.vue'
import forms from '../pages/forms.vue'
import form_menu from '../pages/form/form_menu.vue'
import help from '../pages/main/help.vue'
import error from '../pages/main/error.vue'
import formConfig from '../pages/form/config.vue'
import formEdit from '../pages/form/edit.vue'
import emptyForm from '../pages/form/emptyForm.vue'
import surveySummary from '../pages/form/surveySummary.vue'
import formNewFromWfs from '../pages/form/newFromWfs.vue'
import formList from '../pages/form/list.vue'
import formSync from '../pages/form/sync.vue'
import formSum from '../pages/form/summary.vue'
import formMap from '../pages/form/map/map.vue'
import formMapTableOfContent from '../pages/form/map/mapPages/tableOfContent.vue'
import form_error from '../pages/form/form_error.vue'
import success from '../components/success.vue'
import projectMapManagement from '../pages/projectMapManagement.vue'
import sql from '../pages/admin/sql.vue'
import adminprojects from '../pages/admin/projects.vue'
import login from '../pages/main/login.vue'
import configuration from '../pages/main/config.vue'
import adminDeviceList from '../pages/admin/deviceList.vue'
import adminUsers from '../pages/admin/users.vue'
import projectSync from '../pages/projectSync.vue'
import projectAddSurvey from '../pages/projectAddSurvey'
import downloadPhotos from '../pages/form/downloadPhotos'
import tables from '../forms/pages/tables.vue'
import table from '../forms/pages/table.vue'
import surveyPrint2 from '../pages/form/surveyPrint.vue'

//import the store
import store from '../store'

Vue.use(Router)

let myrouter= new Router({
  routes: [
    {
      path: '/',
      name: 'listProjects',
      component: listProjects,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/help',
      name: 'help',
      component: help,
      meta: {
        bypassVerifications: true
      }
    },
    {
      path: '/error',
      name: 'error',
      component: error,
      meta: {
        bypassVerifications: true
      }
    },
    {
      path: '/login',
      name: 'login',
      component: login
    },
    // ************ project
    {
      path: '/projectAddSurvey/:project_id',
      name: 'projectAddSurvey',
      component: projectAddSurvey,
      props:true,
      meta: {
        requiresAuth: true,
        isFromLocalOk:true
      }
    },
    {
      path: '/projectMapManagement/:project_id',
      name: 'projectMapManagement',
      component: projectMapManagement,
      props:true,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/projectSync/:project_id',
      name: 'projectSync',
      component: projectSync,
      props:true,
      meta: {
        requiresAuth: true
      }
    },
    // ************ form
    {
      path: '/forms',
      name: 'forms',
      component: forms
    },
    {
      path: '/forms/form_error',
      name: 'form_error',
      component: form_error
    },
    {
      path: '/forms/:form_id',
      name: 'form',
      component: form_menu,
      props:true,
      meta: {
        requiresAuth: true,
        isFromLocalOk:true,
        editOnEndReturnTo: 'form_menu',
      }
    },
    {
      path: '/forms/:form_id/edit/:survey_id?', // ? makes the parameter optional, our case, for new survey
      name: 'formEdit',
      component: formEdit,
      props:true,
      meta:{
        keepAlive:true,
        requiresAuth: true,
        isFromLocalOk:true
      }
    },
    {
      path: '/forms/:form_id/print2/:survey_id', // ? makes the parameter optional, our case, for new survey
      name: 'surveyPrint2',
      component: surveyPrint2,
      props:true,
      meta:{
        keepAlive:false,
        requiresAuth: true,
        isFromLocalOk:true
      }
    },
    {
      path: '/forms/:form_id/emptyForm',
      name: 'emptyForm',
      component: emptyForm,
      props:true,
      meta:{
        requiresAuth: true,
        isFromLocalOk:true
      }
    },
    {
      path: '/forms/:form_id/edit/:survey_id/page/:page_id',
      name: 'formEditPage',
      component: formEdit,
      props:true,
      meta:{
        requiresAuth: true,
        isFromLocalOk:true
      }
    },
    {
      path: '/forms/:form_id/summary',
      name: 'formSum',
      component: formSum,
      props:true,
      meta:{
        requiresAuth: true,
        isFromLocalOk:true
      }
    },
    {
      path: '/forms/:form_id/downloadPhotos',
      name: 'downloadPhotos',
      component: downloadPhotos,
      props:true,
      meta:{
        requiresAuth: true,
        isFromLocalOk:true
      }
    },
    {
      path: '/forms/:form_id/summary/:survey_id',
      name: 'surveySummary',
      component: surveySummary,
      props:true,
      meta:{
        requiresAuth: true,
        isFromLocalOk:true
      }
    },
    {
      path: '/forms/:form_id/sync',
      name: 'formSync',
      component: formSync,
      props:true,
      meta:{
        requiresAuth: true,
      }
    },
    {
      path: '/forms/:form_id/list/:curDate?',
      name: 'formList',
      component: formList,
      props:true,
      meta:{
        keepAlive:true, // so the currently select date is preserved.
        requiresAuth: true,
        isFromLocalOk:true,
        editOnEndReturnTo: 'form_list',
      }
    },
    {
      path: '/forms/:form_id/config',
      name: 'formConfig',
      component: formConfig,
      props:true,
      meta:{
        requiresAuth: true,
        isFromLocalOk:true
      }
    },
    {
      path: '/forms/:form_id/views',
      name: 'formViews',
      component: () => import ('../pages/form/views.vue') ,
      props:true,
      meta:{
        requiresAuth: true,
        isFromLocalOk:true
      }
    },
    {
      path: '/forms/:form_id/map',
      name: 'formMap',
      component: formMap,
      props:true,
      meta:{
        keepAlive:true,
        requiresAuth: true,
        isFromLocalOk:true,
        editOnEndReturnTo: 'map',
      },
    },
    {
      path: '/forms/:form_id/map/tableOfContent',
      name: 'formMapTableOfContent',
      component: formMapTableOfContent,
      props:true,
      meta:{
        hideDefaultNavBar:true,
        requiresAuth: true,
        isFromLocalOk:true
      },
    },
    {
      path: '/forms/:form_id/success',
      name: 'success',
      component: success,
      meta:{
        requiresAuth: true,
        isFromLocalOk:true
      },
    },
    {
      //****** route to accept the creation of a from from an existing wfs feature.
      path: '/forms/:form_id/new_from_wfs/:wfs_id',
      name: 'formNewFromWfs',
      component: formNewFromWfs,
      props:true,
      meta:{
        requiresAuth: true,
        isFromLocalOk:true
      },
    },
    {
      path: '/configuration',
      name: 'configuration',
      component: configuration,
      meta: {
        requiresAuth: true
      }
    },
    // **** admin routes
    {
      path: '/admin/sql',
      name: 'sql',
      component: sql,
      meta: {
        requiresAuth: true,
        requiresAdmin: true,
      }
    },
    {
      path: '/admin/projects',
      name: 'admin-project',
      component: adminprojects,
      meta: {
        requiresAuth: true,
        requiresAdmin: true,
      }
    },
    {
      path: '/admin/devices',
      name: 'admin-devices',
      component: adminDeviceList,
      meta: {
        requiresAuth: true,
        // requiresAdmin: true,
      }
    },
    {
      path: '/admin/users',
      name: 'admin-users',
      component: adminUsers,
      meta: {
        requiresAuth: true,
        requiresAdmin: true,
      }
    },
    // Table stats view
    {
      path: "/project/:project_id/tables",
      name: "tables",
      component: tables,
      props: true
    },
    {
        path: "/project/:project_id/table/:tableName",
        name: "table",
        component: table,
        props: true
    },
    // *** route don't match
    {
      path: '*',
      redirect: '/forms'
    }
  ]
})

myrouter.beforeEach((to, from, next)=> {
  // **** setup some variable that we reset at each page change.
  store.commit('cuidIsDeleting', null)

  // **** check if we received a token in the query, if yes, do as if it login
  if (to.query && to.query['user-token']){
    localStorage.setItem('user-token', to.query['user-token'])
    // verifyArr.push(store.dispatch('verifySession'))
    const query = Object.assign({}, to.query);
    delete query['user-token'];
    next({ path: to.path, query })
    return 
  }

  // ******* checks fo authentication and local storage
  // if error, no more verifications
  if(to.matched.some(record => record.meta && record.meta.bypassVerifications)){
    next()
    return
  }
  // has to verify sothings, that the local store is open...
  let verifyArr=[]
  if(!store.state.isLogged){
    verifyArr.push(store.dispatch('verifySession',{noError:true}))
  }
  if(store.state.localDB.config===null){
    verifyArr.push(store.dispatch('localDB/openLocalStore'))
  }

  Promise.all(verifyArr).then(rep=>{
    if (to.matched[0]?.meta?.editOnEndReturnTo) {
      // define when edit end() clikecked, the route sent to
      store.commit('form/setEditOnEndReturnTo', to.matched[0].meta.editOnEndReturnTo)
    }
    if (to.matched.some(record => record.meta && record.meta.requiresAuth)) {
      //require auth
      if (store.state.isLogged===true) {
        // we are logged
        if (to.matched.some(record => record.meta && record.meta.requiresAdmin)) {
          if(store.getters['isAdmin']){
            // auth and isadmin = ok
            next()
          }else{
            // should show an error
            store.commit('error_message',$store.labels.error_message_needAdmin)
            next('/error')
          }
        }else{
          //is looged and don't need admin
          next()
        }
      } else {
        //not logged
        // is it ok if the current form is offline:
        if(to.meta && 
          to.meta.isFromLocalOk && 
          ((to.params.form_id && (store.getters['localDB/isOnlineMode']===false || store.getters['localDB/hasLocalForm'](to.params.form_id)))
          ||
          (to.params.project_id && (store.getters['project/isOnlineDb']===false || store.getters['localDB/hasLocalProject'](to.params.project_id))))
        ){
          // the form is local so don't need to authenticate
          next()
        }else{
          next({
            name: 'login',
            query: { redirect: to.fullPath } //https://stackoverflow.com/a/48271996/140384
            // params: {
            //   nextUrl: to.fullPath
            // }
          })
        }
      }
    }else{
      next()
    }
  }).catch(err=>{
    // so no local DB as the verify session return resolve even if don't work.
    // goto err page.
    store.commit('error_message',err)
    store.dispatch('app_message_error',err)
    next('/error')
  })
})


export default myrouter

<template>
  <li @click.prevent="clickOn" class="list-group-item d-flex justify-content-between">
      <!-- <div class="checkbox">
          <input type="checkbox" :id="lay.name" :checked="lay.active">
          <label :for="lay.name">{{lay.label}}</label>
      </div> -->
      <div class="">

        <b-form-checkbox :id="'layer_'+layer_id"
          :checked="visible"
        >
          {{label}}
          <i class="mdi mdi-warning text-warning" v-if="hasMapLayer"></i>
        </b-form-checkbox>
      </div>
      <div>
        <div class="d-flex">
          <b-dropdown :variant="layer.forceCurrentSurveyOption?'primary':'secondary'" class="mx-2" v-if="layer.className=='layer_vector_couchdb'">
            <template #button-content>
              <i class="mdi mdi-eye"></i>
            </template>
            <b-dropdown-item 
              v-for="opt in options" 
              :key="'cde'+opt.value" 
              @click.stop="$store.dispatch('map/' + layer_id+ '/setForceCurrentSurveyOption', opt.value)"
            >
              <i class="mdi" :class="layer.forceCurrentSurveyOption == opt.value?'mdi-check-bold':''"></i> {{ opt.value ? opt.text : opt.text + ' (' + optionsTranslation[defaultSurveyOption] + ')'}}
            </b-dropdown-item>
          </b-dropdown>
          <b-btn class="" size="md" variant="outline-primary" @click.stop="download" v-if="layer.className=='layer_vector_couchdb'" v-b-tooltip.hover title="Export GeoJSON">
            <i class="mdi mdi-download"></i>
          </b-btn>
        </div>
      </div>
      <!-- <div v-if="lay.type=='WMS'" class="ml-4">
          <img :src="lay.layer.getSource().getUrl() +
              '?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER='+lay.layer.getSource().getParams().LAYERS" />
      </div> -->
  </li>
</template>

<script>

export default{
  props:['layer_id'],
  computed:{
    layer(){return this.$store.state.map[this.layer_id]},
    label(){return this.$store.getters['map/'+this.layer_id+'/label']},
    visible(){return this.$store.getters['map/'+this.layer_id+'/visible']},
    hasMapLayer(){return this.$store.getters['map/'+this.layer_id+'/hasMapLayer']},
    defaultSurveyOption(){return this.$store.getters['map/'+this.layer_id+'/defaultSurveyOption']},
    optionsTranslation(){return this.$store.state.labels.map.surveyLayerVisibleOptions},
    options(){
      return [
        {value: 'onlyShowMySurvey', text: this.optionsTranslation.onlyShowMySurvey},
        {value: 'fadeOtherSurveys', text: this.optionsTranslation.fadeOtherSurveys},
        {value: 'allSurveys', text: this.optionsTranslation.allSurveys},
        {value: null, text: this.optionsTranslation.defaultVal},
      ]
    },
  },
  methods:{
    clickOn(){
      //set this base layer as visible
      this.$store.commit('map/'+this.layer_id +'/toggleVisible')
    },
    download(){
      
      this.$store.dispatch('map/'+this.layer_id+'/downloadGeoJson').then(rep=>{
        download(this.label + '.geojson',JSON.stringify(rep))
        // *** First solution, but found better!
        // http://jennifermadden.com/javascript/window3.html
        // var frog = window.open('',"_blank")
        // frog.name=this.label + '.geojson'
        // frog.document.title=this.label + '.geojson'
        // frog.document.open()
        // frog.document.write('<html><head><title>' + this.label + '.geojson' + '</title></head>' + JSON.stringify(rep) +'</body></html>')
        // frog.document.close()
        //
        // useful, but not the same: https://stackoverflow.com/a/19567232/140384
      })
    }
  }
}


// https://stackoverflow.com/a/18197341/140384
const download=(filename, text)=>{
  var element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

</script>

<template>
  <div>
    <div v-if="!remoteStats" class="alert alert-warning">
      Getting Data.... should take less than a minute
    </div>
    <div v-if="remoteStats">
      <div class="mb-3">{{labels.info}}</div>
      <div class="alert alert-secondary">
        <div>
          {{labels.database}}: <strong>{{couchDbName}}</strong>
        </div>
        <div>
          {{labels.imageSet}}: <strong>{{imageryDoc.name}}</strong>
        </div>
        <div class="d-flex justify-content-end">
          <b-btn @click="fetchLocalStats()" size="sm" class="mb-2 mr-3" v-b-tooltip.hover title="Load local data from files"><i class="mdi mdi-refresh"></i></b-btn>
          <b-btn @click="pullMode='files'" v-if="pullMode=='couchdbServer'" size="sm" class="mb-2" v-b-tooltip.hover title="Load local data from files"><i class="mdi mdi-file-arrow-left-right-outline"></i></b-btn>
          <b-btn @click="pullMode='couchdbServer'" v-if="pullMode=='files'" size="sm" class="mb-2" variant="primary" v-b-tooltip.hover title="Load local data from files"><i class="mdi mdi-file-alert-outline"></i></b-btn>
        </div>
      </div>
      <div v-if="pullMode=='couchdbServer'">
        <div class="alert alert-info" v-if="filters">
          <div class="h3">{{labels.filters}}</div>
          <div v-for="f1 in filtersByType" :key="f1.type" class="ml-2 my-1">
            <div>
              <b-btn class="mr-3" size="sm" @click="$set(showFilterType, f1.type, !showFilterType[f1.type])"><i class="fas fa-eye"></i></b-btn>
              <span class="h5">{{f1.type}}</span>
              <span class="ml-2">({{selectedFilter.filter(x=>x.indexOf(f1.type)==0).length}})</span>
            </div>
            <b-form-checkbox-group class="mt-2" v-model="selectedFilter" v-if="showFilterType[f1.type]">
              <b-list-group-item v-for="f2 in f1.filters" :key="f2.label" class="py-1">
                <b-check :value="f1.type + f2.label">
                  <b>{{f2.label}}</b> <i>({{f2.tiles_list.length}})</i>
                </b-check>
              </b-list-group-item>
            </b-form-checkbox-group>
          </div>
        </div>

        <div class="row">
          <c-item :name="labels.remote" :stats="remoteStats2" :levels="allLevels" @selected="remoteSelected=$event" showSelection="true"></c-item>
          <c-item :name="labels.local" :stats="localStats" :levels="allLevels" :showDelete="true" @delete="deleteLocalData"></c-item>
        </div>
        <div>
          <div v-if="remoteSelected.length>0 && syncInProgress===false">
            <b-btn size='sm' @click="syncLocally" 
              class="mt-2"
              variant="primary">{{labels.syncLocally}}</b-btn>
            <sync-options v-model="selectedOption" class="ml-5"></sync-options>
          </div>
          <div v-if="messages" v-html="messages" class="mt-3 mx-2 alert alert-secondary">
          </div>
        </div>
      </div>
      <div v-else-if="pullMode=='files'">
        <h2 class="mt-5">{{ labelsFile.jsonTitle }}</h2>
        <h5 class="ml-3 mb-3">{{ labelsFile.title2 }}</h5>
        <h5 class="ml-3 mb-3 text-warning">{{ labelsFile.jsonTitle2 }}</h5>   
        
        <b-form-file
          v-model="files"
          :state="files && files.length>0"
          accept=".json"
          :placeholder="labelsFile.jsonFile"
          class="my-3"
          multiple
        >
        </b-form-file>
        <!-- <div v-if="file && !nbSurveys">
          {{ this.labelsFile.jsonFileError }}
        </div> -->
        <div v-if="files && files.length>0">
          <div>
            {{ labelsFile.jsonCount}}: {{ files.length }}
          </div>
          <div class="my-3" v-if="!fileMess">
            <b-btn @click="uploadFiles" variant="primary"><i class="glyphicon glyphicon-download mr-2"></i>{{ labelsFile.buttonText }}</b-btn>
          </div>
        </div>
        <div>
          <b-alert show variant="secondary" v-if="fileMess">{{fileMess}}</b-alert>
        </div>
        <div v-if="fileResults">
          <b-list-group>
            <b-list-group-item v-for="item in fileResults" :key="item.name">{{ item.name}} ({{item.sizeh}}): {{item.nb}}</b-list-group-item>
          </b-list-group>
        </div>
      </div>
      <div v-else>Error, not implemented</div>
    </div>
  </div>
</template>

<script>
import cItem from './couchImageryDbManagement_item.vue'
import syncOptions from "../sync/syncOptions.vue";
import { Promise } from 'q';
import server from '../../services/couchdb/server';
import filesize from 'filesize'


export default{
  name:'couchImageryDbManagement',
  props:['imageryDoc'],
  components:{cItem,syncOptions},
  data(){
    return{
      remoteStats:null,
      localStats:null,
      remoteSelected:[],
      remoteSelectedDone:[],
      syncInProgress:false,
      mess:[],
      selectedOption:null,
      showFilterType:{},
      selectedFilter:[],
      pullMode:'couchdbServer', // couchdbServer or files
      files: null,
      fileContent: null,
      fileMess: null,
      fileResults: null,
    }
  },
  created(){
    // we can use created as it's not a keep-alive component.
    this.init()
  },
  computed:{
    labels() {
      return this.$store.state.labels.manageProjectMapLabels.imagery
    },
    labelsFile() {
      return this.$store.state.labels.form.sync.pull;
    },
    allLevels(){
      return Object.keys(this.remoteStats)
    },
    couchDbName(){
      return this.imageryDoc.couchdb_name
    },
    urlPrefix(){
      return this.imageryDoc.url_online.split('/')[1].split('-')[0]
    },
    messages(){
      return this.mess.join('</br>')
    },
    filtersByType(){
      return this.filters.FiltersByType.map(x=>{
        x.filters = x.filters.sort((a,b)=>a.label.localeCompare(b.label))
        return x
      }).sort((a,b)=>a.type.localeCompare(b.type))
    },
    selectedFilterTiles(){
      if(this.selectedFilter.length == 0 ){
        return null
      }
      let rep = []
      this.filtersByType.map(fi1=>{
        fi1.filters.filter(x=>this.selectedFilter.indexOf(fi1.type + x.label)!=-1).forEach(fi2=>{
          rep = rep.concat(fi2.tiles_list)
        })
      })
      // remove duplicates before returning
      return [...new Set(rep)]
    },
    selectedFilterTilesByZoom(){
      if( !this.selectedFilterTiles ){
        return null
      }
      let rep = {}
      this.allLevels.forEach(x=>{
        rep[x] = 0
      })
      this.selectedFilterTiles.map(x=>{
        rep[Number(x.split('-')[1])] += 1
      })
      return rep
    },
    remoteStats2(){
      if(!this.selectedFilterTiles){
        return this.remoteStats
      }else{
        let rep={}
        this.allLevels.forEach(x=>{
          rep[x] = Object.assign({},this.remoteStats[x])
          const ratio = this.selectedFilterTilesByZoom[x] / rep[x]['nbTiles']
          rep[x]['nbTiles'] = this.selectedFilterTilesByZoom[x]
          rep[x]['size'] = rep[x]['size'] * ratio
          rep[x]['sizeNotBlank'] = rep[x]['sizeNotBlank'] * ratio
        })
        return rep 
      }
    },
  },
  asyncComputed:{
    filters(){
      return this.getRemoteDb().then(rep=>{
        return rep.db.get('filters')})
      .then(doc=>{
        return Promise.resolve(doc)
      })
    },
  },
  methods:{
    init(){
      Promise.all([
        this.fetchRemote(),
        this.fetchLocal()
      ]).then(end=>{
        this.remoteStats=end[0]
        this.localStats=end[1]
      })
    },
    getLocalDb(){
      return this.$store.dispatch('localDB/getDatabase',
          {dbName:this.couchDbName,alwaysGetLocalDb:true},
          { root: true })
    },
    getRemoteDb(){
      return this.$store.dispatch('localDB/getDatabase',
          {dbName:this.couchDbName,alwaysUseRemoteDb:true},
          { root: true })
    },
    verifySession(){
      return this.$store.dispatch('verifySession')
    },
    fetchLocalStats(){
      return this.fetchLocal().then(rep=>{
        this.localStats=rep
      })
    },
    fetchLocal(){
      return this.getLocalDb().then(rep=>{
        return this.calculateStatsLocal(rep.db)
      })
    },
    fetchRemote(){
      return this.getRemoteDb().then(rep=>{
        return this.calculateStats(rep.db)
      })
    },
    syncLocally(){
      this.mess=[this.labels.messBegin]
      this.remoteSelectedDone=[]
      this.syncInProgress=true
      if(this.remoteSelected && this.remoteSelected.length>0){

        return this.syncLocallyRetry(this.syncLocallyFn)
        .catch(err=>{
          this.syncInProgress=false
          // more than the retry...
          this.mess.push('!!! ' + this.labels.syncError + ' !!!')
          
          this.$store.dispatch('app_message_error',err)
        })
      }
    },
    syncLocallyFn(rep){
      // TODO: refactor with more funciton to be more clear
      // let ids=[]
      // this.remoteSelected.map(x=>{
      //   ids=ids.concat(this.remoteStats[x].tiles)
      // })
      //https://decembersoft.com/posts/promises-in-serial-with-array-reduce/
      // also explain it https://blog.hellojs.org/use-reduce-and-promises-to-execute-multiple-async-calls-sequentially-4caf03a34b9a
      var t1 = performance.now();
      var t2 = performance.now();
      var writtenDocs = 0
      return this.remoteSelected.filter(x=>this.remoteSelectedDone.indexOf(x)===-1).reduce((promiseChain,CurrentTask)=>{
        this.mess.push('') // change pop the message....
        return promiseChain.then(chainResults =>{
          return Promise.all([
            rep[0].db.allDocs({
              startkey : this.imageryDoc.name + '-' + CurrentTask + '-',
              endkey: this.imageryDoc.name + '-' + CurrentTask + '-\ufff0'
            }),
            rep[1].db.allDocs({
              startkey : this.imageryDoc.name + '-' + CurrentTask + '-',
              endkey: this.imageryDoc.name + '-' + CurrentTask + '-\ufff0'
            }),
          ]).then(values=>{
            const serverDocs = values[0].rows.map(x=>x.id).filter(x=>{
              //  filter if we have a selected a filter(s)
              if(this.selectedFilterTiles){
                if(this.selectedFilterTiles.indexOf(x)!=-1){
                  return true
                }else{
                  return false
                }
              }
              return true
            })
            const localDocs = values[1].rows.map(x=>x.id)
            // return only the one we don't have locally
            return Promise.resolve( serverDocs.filter(x=> localDocs.indexOf(x)==-1) )
          }).then(docsIds=>{
            t1 = performance.now();
            t2 = performance.now();
            this.mess.pop()
            this.mess.push('level item to do: ' + docsIds.length)
            this.mess.push('') 
            // return rep[0].db.replicate.to(rep[1].db,
            //   //options
            //   Object.assign(
            //     // TODO: clarify if we should do this
            //     // test with retries: https://stackoverflow.com/a/51332115/140384
            //     {checkpoint:false}, // too chatty with check points and crash...
            //     this.selectedOption,
            //     {
            //       // doc_ids:ids
            //       //TODO test if we could make faster. see below comments on filters for references
            //       // probably small number of ids(500) could be faster to get the list and them pass them.
            //       // could also join all the levels in only one request...
            //       // selector:{
            //       //   // "_id":{"$regex": "^"+this.imageryDoc.name+'-'+CurrentTask+'-'},
            //       //   "$gt": this.imageryDoc.name+'-'+CurrentTask+'-',
            //       //   "$lt": this.imageryDoc.name+'-'+CurrentTask+'-'              
            //       //   // "_attachments":{tile:{length:{'$gt':853}}} // we don't want empty tiles.
            //       // }
            //       // filter:'_view',
            //       // view:'zooms_' + this.imageryDoc.name + '/z' + CurrentTask
            //       // *** many tests with views and filters, and all ended up to take many times to just sync one document. Example, sync level 4 of OMVG bing sat iamges
            //       // *** this is the fastest for now we found... but don't know in the end if too many document will work...
            //       doc_ids : docsIds
            //     }
            //   )
            // ).on('change',info=>{
            //   this.mess.pop()
            //   //  calc items per second 
            //   var t3 = performance.now()
            //   writtenDocs = info.docs_written
            //   var itemS = (writtenDocs / ((t3 - t1)/1000)).toFixed(1)
            //   this.mess.push(this.labels.inProgress + ': ' + CurrentTask + '  (' + writtenDocs +', ' + itemS + ' item/s, batch => ' + ((t3 - t2)/1000).toFixed(0.2) + ' s )')
            //   t2 =  t3
            // })


            // chunk the doc ids
            // https://stackoverflow.com/a/10456644/140384
            let n = this.selectedOption.batch_size
            let range = function(n) {
              // Array.range(5) --> [0,1,2,3,4]
              return Array.apply(null,Array(n)).map((x,i) => i)
            };
            let chunks = range(Math.ceil(docsIds.length/n)).map((x,i) => docsIds.slice(i*n,i*n+n));
            return chunks.reduce((promiseChain,chunk)=>{
              return promiseChain.then(chainResults2 =>{
                // get documents
                return rep[0].db.allDocs({
                  include_docs: true,
                  attachments: true,
                  keys: chunk,
                }).then(docs=>{
                  const newDocs = docs.rows.map(x=>x.doc)
                  // write documents
                  return rep[1].db.bulkDocs(newDocs,{new_edits:false})
                }).then(currentResult =>{
                  var t3 = performance.now()
                  writtenDocs += chunk.length
                  var itemS = (writtenDocs / ((t3 - t1)/1000)).toFixed(1)
                  this.mess.pop()
                  this.mess.push(this.labels.inProgress + ': ' + CurrentTask + '  (' + writtenDocs +', ' + itemS + ' item/s, batch => ' + ((t3 - t2)/1000).toFixed(0.2) + ' s )')
                  t2=t3
                  return [ ...chainResults2, currentResult ]
                })
                // .catch(err=>{
                //   return [ ...chainResults2, Promise.reject(err)]
                // })
              })
            }, Promise.resolve([]))
            
          }).then(()=>{
              this.mess.pop()
              var t3 = performance.now()
              var itemS = (writtenDocs / ((t3 - t1)/1000)).toFixed(1)
              this.mess.push(this.labels.levelFinished + ': ' + CurrentTask + ' (' + writtenDocs + ', ' + itemS + ' item/s)')
              this.mess.push('') // change pop the message....
              this.remoteSelectedDone.push(CurrentTask) // so we don't do it twice if we retry...
              return [ ...chainResults, CurrentTask ] //return
          })
        })
      },Promise.resolve([])).then(arrayOfResults => {
        // Do something with all results
        this.mess.pop()
        this.mess.push('*** ' + this.labels.syncFinished + ' ***')
        this.remoteSelected=[]
        this.remoteSelectedDone=[]
        this.syncInProgress=false
        this.fetchLocalStats() // update the display
        this.$store.dispatch('localDB/addOtherDatabaseLocal',this.couchDbName) // add in the local databases names
        return Promise.resolve()
      })//.catch(err=> Promise.reject(err))
    },
    syncLocallyRetry(fn, retries=300, err=null){
      if (!retries) { // when it's 0
        this.popMessIfEmpty()
        this.mess.push('!!!!!!!! ' + this.labels.syncError)
        return Promise.reject(err);
      }
      return this.verifySession().then(()=>{
        return Promise.all([
          this.getRemoteDb(),
          this.getLocalDb()
        ])
      }).then(rep=>{
          return fn(rep)
      }).catch(err => {
        this.$store.dispatch('app_message_error',err)
        this.popMessIfEmpty()
        this.mess.push('!!! ' + this.labels.syncErrorAutoRetry + ' - ' + err.message)
        return delay(3000).then(() => this.syncLocallyRetry(fn, (retries - 1), err));
      });
    },
    popMessIfEmpty(){
      if (this.mess[this.mess.length -1] == ''){
        this.mess.pop()
        this.popMessIfEmpty()
      }
    },
    calculateStats(db){
      return verifyDesignZoom(db,this.imageryDoc).then(()=>{
        return verifyDesignZooms(db,this.imageryDoc)
      }).then(()=>{
        console.log('we have a zoom document ' + db.name)
        // we have a design zoom doc
        //get all the possible zoom:
        return db.query('zoom/'+this.imageryDoc.name,{reduce:true,group:true})
      // }).then(zoom_levels=>{
      //   // we want to check that for each zoom level, we have a filter
      //   return verifyDesignFilters(db,zoom_levels).then(()=>{
      //     return Promise.resolve(zoom_levels)
      //   })
      }).then(zoom_levels=>{
        console.log(zoom_levels);
        const stats={}
        zoom_levels.rows.map(zoom_level=>{
          stats[zoom_level.key]={
            level:zoom_level.key,
            nbTiles:zoom_level.value.count,
            size:zoom_level.value.sum,
            sizeNotBlank:zoom_level.value.sum,
            minX:-1,
            maxX:-1,
            minY:-1,
            maxY:-1,
            nbBlank:0,
            // tiles:[]
          }
        })
        return Promise.resolve(stats)
      })
    },
    calculateStatsLocal(db){
      // same results as calculateStats, but faster... we hope
      var stats={}
      return [...Array(25).keys()].reduce((promiseChain, currentTask) => {
        return promiseChain.then(chainResults =>{
          return db.allDocs({
            startkey : this.imageryDoc.name + '-' + currentTask + '-',
            endkey: this.imageryDoc.name + '-' + currentTask + '-\ufff0'
          }).then(docs =>{
            stats[currentTask]={
              level:currentTask,
              nbTiles:docs.rows.length,
              size:-1,
              sizeNotBlank:-1,
              minX:-1,
              maxX:-1,
              minY:-1,
              maxY:-1,
              nbBlank:0,
            }
            return Promise.resolve(true)
          }).then(currentResult => [ ...chainResults, currentResult ])
        });
      }, 
      Promise.resolve([])).then(arrayOfResults => {    
        delete stats[0]
        return Promise.resolve(stats)
      });
    },
    deleteLocalData(){
      this.mess=[]
      this.mess.push('Deleteting DB - start')
      this.getLocalDb().then(rep=>{
        return rep.db.destroy()
      }).then(rep=>{
        this.fetchLocalStats() // update the display
        this.mess.push('Database deleted - end')
      }).catch(err=>{
        this.$store.dispatch('app_message_error',err)
        this.message.push('*** Error ****')
        this.message.push(err.toString())
      })
    },
    uploadFiles(){
      // TODO: implements as component and use it in syncPull.vue also.
      this.fileMess = this.labelsFile.jsonInProgress
      console.log(this.getLocalDb());
      // add in the local databases names
      return this.$store.dispatch('localDB/addOtherDatabaseLocal',this.couchDbName).then(()=>{
        return this.getLocalDb()
      }).then(localForm0=>{
        const localForm = localForm0.db
        // https://decembersoft.com/posts/promises-in-serial-with-array-reduce/
        return this.files.reduce((promiseChain, currentTask) => {
            var nbDone = 0
            return promiseChain.then(chainResults => {
              return this.readFile(currentTask).then(fileContent=>{
                nbDone = fileContent.length
                this.$store.dispatch('app_message','uploadFiles / doing file ' + nbDone)
                return localForm.bulkDocs(fileContent,{new_edits:false})
                //  when new_edits is false, it does return nothing... so get the number before.
              }).then(currentResult => [ ...chainResults, nbDone ]).catch(err=>{
                this.$store.dispatch('app_message_error',err)
                return [ ...chainResults, err ]
              })
            })
          }, Promise.resolve([])
        )             
      }).then(arrayOfResults=>{
        this.fileResults = this.files.map((file, i)=>{
          const rep =  {
            name: file.name,
            size: file.size,
            sizeh: filesize(file.size,{round:1}),
          }
          if(Array.isArray(arrayOfResults[i])){
            rep['nb'] = arrayOfResults[i].length
          }else{
            rep['nb'] = arrayOfResults[i]
          }
          return rep
        })
        return Promise.resolve()
      }).then(rep=>{
        this.fileMess = this.labelsFile.jsonEnd
      }).catch(err=>{
        this.$store.dispatch('app_message_error',err)
        this.$store.dispatch('app_message',err)
        this.fileMess = this.labelsFile.jsonError + ' - ' + err.toString()
      })
    },
    readFile(file) {
      return new Promise((resolve, reject) => {
        const fr = new FileReader();
        //fr.onload =  resolve;  // CHANGE to whatever function you want which would eventually call resolve
        fr.onload = (e) => {
          let fileContent = JSON.parse(fr.result);
          if (Array.isArray(fileContent) && fileContent[0].rows) {
            fileContent = fileContent[0].rows.map(x=>x.doc);
          }else if (!Array.isArray(fileContent)) {
            fileContent = []
          }
          resolve(fileContent);
        };
        fr.readAsText(file);
      });
    },
  },
}

const verifyDesignZoom=(db,imageryDoc)=>{
  //TODO: Support for many zoom type as we have many imagery prefix
  return db.get('_design/zoom').then(doc=>{
    if(doc.views && doc.views.hasOwnProperty(imageryDoc.name)){
      return Promise.resolve()
    }
    return createDesignZoom(db,imageryDoc,doc)
  }).catch(err=>{
    //doc does not exists - create
    console.log('create design doc zoom');
    return createDesignZoom(db,imageryDoc,null)
  })
}

const createDesignZoom=(db,imageryDoc,existingDoc)=>{
  var ddoc = {
    _id: '_design/zoom',
    "views": {
    },
    "language": "javascript"
  }
  if(existingDoc){
    ddoc=existingDoc
  }
  ddoc.views[imageryDoc.name]={
    "map": "function (doc) {\n  var tileZXY_0 = doc._id.match(/^"+imageryDoc.name+"-(\\d*)-(\\d*)-(\\d*)$/)\n  if(tileZXY_0){\n    var tileZXY=tileZXY_0.slice(1).map(function(x){return Number(x)});//slice remove the global match\n    emit(tileZXY[0],doc._attachments.tile.length);\n  }\n}",
    "reduce": "_stats"
  }
  return db.put(ddoc,{
  ajax: {
    timeout: 1000*60*5,
  }})
}


// Zooms are used in the filter replication, very more efficient than the old selector way

const verifyDesignZooms=(db,imageryDoc)=>{
  return db.get('_design/zooms_' + imageryDoc.name).then(doc=>{
    return Promise.resolve()
  }).catch(err=>{
    //doc does not exists - create
    console.log('create design doc zooms');
    return createDesignZooms(db,imageryDoc,null)
  })
}

const createDesignZooms=(db,imageryDoc,existingDoc)=>{
  var ddoc = {
    _id: '_design/zooms_' + imageryDoc.name,
    "views": {
    },
    "language": "javascript"
  }
  if(existingDoc){
    ddoc=existingDoc
  }
  // We have 25 so we ensure we won't have more... as this can change over time, but we don't want to change the view.
  [...Array(25).keys()].map(zoom=>{
    ddoc.views['z'+ zoom]={
      "map": "function (doc) {if (doc._id.indexOf('" + imageryDoc.name + "-" + zoom +  "-')==0){emit(doc._id)}}"
    }
  })
  return db.put(ddoc,{
  ajax: {
    timeout: 1000*60*5,
  }})
}

// https://stackoverflow.com/a/39538518/140384
function delay(t, v) {
  return new Promise(function(resolve) { 
    setTimeout(resolve.bind(null, v), t)
  });
}

// ******** not impllemented as:
// https://stackoverflow.com/questions/52491025/views-vs-filters-in-couchdb-pouchdb
// http://docs.couchdb.org/en/stable/api/database/changes.html#view
// https://stackoverflow.com/questions/50994899/filtered-sync-between-couchdb-and-pouchdb/50995858#50995858

// const verifyDesignFilters=(db,zoom_levels)=>{
//   return db.get('_design/zoom_filters').then(doc=>{
//     let allOk=true
//     zoom_levels.rows.map(x=>{
//       if(! doc.filters['z' + x.key]){
//         allOk=false
//       }
//     })
//     if(allOk===false){
//       // we have to create or recreate the filters
//       return createDesignFilters(db,zoom_levels,doc._rev)
//     }
//     return Promise.resolve(doc)
//   }).catch(err=>{
//     return createDesignFilters(db,zoom_levels)
//   })
// }


// const createDesignFilters=(db,zoom_levels,rev)=>{
//   const  ddoc = {
//     _id: '_design/zoom_filters',
//     "filters": {
//       // we add after
//     }
//   }
//   if(rev){
//     //if we have rev, we have a current doc, so update it
//     ddoc._rev=rev
//   }
//   zoom_levels.rows.map(x=>{
//     ddoc.filters['z' + x.key]=`function (doc) {
//       var tileZXY_0 = doc._id.match(/-(\d*)-(\d*)-(\d*)$/)
//       if(tileZXY_0){
//         var tileZXY=tileZXY_0.slice(1).map(function(x){return Number(x)});//slice remove the global match
//         if(tileZXY[0]==` + x.key + `){
//           return true
//         }
//       }
//       return false
//     }`
//   })
//   return db.put(ddoc)
// }

</script>

<template>
  <div>
    <div v-if="error" class="alert alert-danger">
      {{error}}
    </div>
    <div v-else class="alert alert-secondary">
      Création en cours
    </div>
  </div>
</template>

<script>
import base from './_base.js'
import axios from 'axios'
import form_utils from '../../utils/form_utils.js'

export default{
  name:'form_new_from_wfs',
  mixins:[base],
  props:['wfs_id'],
  data(){
    return {
      error:null
    }
  },
  watch:{
    // wfs_id:{
    //   handler:'createSurvey',
    //   immediate:true
    // },
    formConfig:{
      handler(){
        if(this.formConfig){
          this.createSurvey()
        }
      },
      immediate:true
    },
  },
  methods:{
    createSurvey(){
      //fetch the wfs element and them create a new form and them returnRoute
      //fetch element
      let urlWFS='https://www.sgesss-benin.org/geoserver/sgesss/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=sgesss:geo_s_parcelles&maxFeatures=50&outputFormat=application%2Fjson&cql_filter=gid=@id'
      let url1=urlWFS.replace('@id',this.wfs_id)
      let verifyArr=[]
      verifyArr.push(axios.get(url1))
      verifyArr.push(this.$store.dispatch('form/newSurvey'))
      var wfs_feature=null
      var survey=null
      Promise.all(verifyArr).then(rep=>{
        wfs_feature=rep[0].data.features[0]
        return this.$store.dispatch('form/fetchSurvey',{survey_id: rep[1].id})
      }).then(survey0=>{
        survey=survey0
        survey.wfs_feature=wfs_feature //save for futher info the original object
        //get the attributes from wfs_feature
        let props=wfs_feature.properties
        survey.form_data.situation.enqueteur=props.enqueteur
        //survey.form_data. =wfs_feature.dateenquet
        survey.form_data.identification.group_de7cd42.Nom_de_l_occupant_exploitant=props.nompap
        survey.form_data.identification.details.Num_ro_de_t_l_phone_occupant_exploitant =props.telpap
        survey.form_data.situation.statut =props.statut
        survey.form_data.situation.code_par =props.code_par
        let obj2=Object.assign({},wfs_feature)
        obj2.properties=null
        survey.form_data.situation.polygon=obj2
        //update the status
        return this.$store.dispatch('form/fetchDefinition',this.$store.getters['form/actualFormDefitionId'])
      }).then(doc=>{

        form_utils.getStatusData(survey,doc.form_definition)
        return this.$store.dispatch('form/saveSurvey',survey)
      }).then(rep=>{
        this.$router.replace('../edit/' + rep.id)
      }).catch(err=>{
        this.$store.dispatch('app_message_error',err)
        this.error=err
      })
    }
  }

}
</script>

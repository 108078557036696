// as localDB in index.js

import cuid from 'cuid'
import PouchDB from 'pouchdb'

export default {
  namespaced: true,
  state: {
    dbName:'aecom_forms_local_system',
    // const db=new PouchDB('local_system')
    // db.destroy()
    db:null,
    config:null,
    databasesAccesMode: localStorage.getItem('databasesAccesMode')?localStorage.getItem('databasesAccesMode'):1
      // 1= use only online
      // 2= use only local
      // TODO the folowing are not implemented, see if necessary... think asbout user experience.
      // Also could be by each databse but really more complex
      // 3= Use local if available, but remote if not, 
      // 4 = use remote, unless not connected, use local
  },
  mutations: {
    config(state,doc){
      state.config=doc
    },
    db(state,db){
      state.db=db
    },
    setDatabasesAccesMode(state, newVal){
      if (newVal != state.databasesAccesMode){
        state.databasesAccesMode=newVal
        localStorage.setItem('databasesAccesMode', newVal)
      }
    },
  },
  actions: {
    switchDatabasesAccesMode(context, {accessMode}){
      let accessModeChanged = false
      if (accessMode && (accessMode != context.state.databasesAccesMode)){
        context.commit('setDatabasesAccesMode', accessMode)
        accessModeChanged = true
      }else if(!accessMode && (context.state.databasesAccesMode < 3)){
        if(context.state.databasesAccesMode == 1){
          context.commit('setDatabasesAccesMode',2)
        }else{
          context.commit('setDatabasesAccesMode',1)
        }
        accessModeChanged = true
      }
      if(accessModeChanged){
        // TODO some triggers
        // return context.dispatch('form/setActualForm',context.rootGetters['form/form_id'],{root:true}).then(()=>{
        //   return context.dispatch('project/setProjectId',context.rootGetters['form/get_project_id'],{root: true})
        // })
      }
      return Promise.resolve(context.state.databasesAccesMode)
    },
    getPouchDbOptions(context){
      let options ={}
      if(navigator.userAgent.indexOf("Firefox") !== -1 && navigator.userAgent.indexOf("Mobile") === -1) {
        context.dispatch('app_message','PouchDB persistent storage',{root:true})
        options=Object.assign(options,{storage:'persistent'})
      }
      return options
    },
    openLocalStore(context){
      const db=new PouchDB(context.state.dbName,context.dispatch('getPouchDbOptions'))
      context.commit('db',db)
      return db.get('config').then(doc=>{
        context.commit('config',doc)
        return Promise.resolve(true)
      }).catch(err=>{
        //no doc present, so create one:
        return db.put({
          _id:'config',
          device_id:'device_'+cuid(),
          creation_time: context.rootGetters['utcCurrentTime'](),
          forms:[], //
          projects:[]
        }).then(res=>{
          return context.dispatch('openLocalStore')
        }).catch(err=>{
          context.dispatch('app_message_error',err,{root:true})
          return Promise.reject(err)
        })
      })
    },
    addFormLocal(context,form){
      // console.log(form);
      
      //add a form to the local store
      const localForm=new PouchDB(form.id,context.dispatch('getPouchDbOptions'))
      var db=context.state.db
      //replicate remote to a local db
      const remoteDb = new PouchDB(context.rootState.couchdbUrl+form.id, context.rootGetters.pouchDbOptions)
      let projectId=null
      return remoteDb.get('form_config').then(doc=>{
        projectId=doc.project_id // used below to replicate project also
        // filter replication - don't send survey
        let ids_replicate=['form_config'].concat(doc.form_definitions)
        if(doc.always_sync_docs){
          ids_replicate=ids_replicate.concat(doc.always_sync_docs)
        }
        // TODO: have the list dynamically
        ids_replicate=ids_replicate.concat('_design/geometries')
        ids_replicate=ids_replicate.concat('_design/editList')
        ids_replicate=ids_replicate.concat('_design/summary')
        return localForm.replicate.from(context.rootState.couchdbUrl+form.id,
          Object.assign(
            {doc_ids:ids_replicate,batch_size:context.rootState.settings.pouhdb_replicate_batch_size},
            context.rootGetters.pouchDbOptions
          )
          )
      }).then(()=>{
        return context.dispatch( 'addProjectLocal', projectId) // replicate also the 
      }).then(()=>{
        //Now add this to my current local config
        return db.get('config')
      }).then(doc=>{
        let forms=doc.forms.filter(x=>x.id!=form.id) // ensure no duplicates if was already there.
        forms.push(form)
        doc.forms=forms
        return db.put(doc)//save
      }).then(response=>{
        //reload my config
        context.dispatch('openLocalStore')
        //add a meta doc in the local system db
        //verify if exists
        //and get all doc in form so we can futher know what we added locally
        return Promise.all([
          db.get('meta_'+form.id).then(doc=> { //https://stackoverflow.com/a/42319119/140384
              return Promise.resolve(doc);
          }).catch( () => {
              return Promise.resolve(false);
          }),
          localForm.allDocs()
        ])
      }).then( values =>{
        const hasMeta=values[0]
        const allDocs=values[1]
        const newDoc = {
          _id:'meta_'+form.id,
          creation_time: context.rootGetters['utcCurrentTime'](),
          last_sync_time: context.rootGetters['utcCurrentTime'](),
          last_replicate_all_docs:allDocs
        }
        if(hasMeta!==false){
          newDoc._rev=hasMeta._rev
        }
        return db.put(newDoc)
      }).then(rep=>{
        // notice the user it is added sucessfully
        return Promise.resolve(true);
      }).catch(err => {
        context.dispatch('app_message_error',err,{root:true})
        return Promise.reject(err);
      })
    },
    deleteFormLocal(context,form){
      //delete local DB
      const localForm=new PouchDB(form.id)
      const db=context.state.db
      return localForm.destroy().then(rep=>{
        return db.get('config')
      }).then(doc=>{
        //remove record in config
        let forms=doc.forms.filter(x=>x.id!=form.id) // ensure no duplicates if was already there.
        doc.forms=forms
        return db.put(doc)//save
      }).then(rep=>{
        return context.dispatch('openLocalStore')
      }).catch(err=>context.dispatch('app_message_error',err,{root:true}))
    },
    addProjectLocal(context,project_id){
      const localProjectDb=new PouchDB(project_id,context.dispatch('getPouchDbOptions'))
      var db=context.state.db
      //replicate remote to a local db
      const remoteDb = new PouchDB(context.rootState.couchdbUrl+project_id, context.rootGetters.pouchDbOptions)
      return localProjectDb.replicate.from(remoteDb,
        {batch_size:context.rootState.settings.pouhdb_replicate_batch_size}
      ).then(result=>{
        //Now add this to my current local config
        return db.get('config')
      }).then(doc=>{
        if(!doc.projects){doc.projects=[]}
        let projects=doc.projects.filter(x=>x.id!=project_id)
        projects.push({
          id:project_id,
          creation_time: context.rootGetters['utcCurrentTime'](),
          last_sync_time: context.rootGetters['utcCurrentTime'](),
        })
        doc.projects=projects
        return db.put(doc)//save
      }).then(response=>{
        //reload my config
        context.dispatch('openLocalStore') // get the rev
        // notice the user it is added sucessfully
        return Promise.resolve(true);
      }).catch(err => {
        context.dispatch('app_message_error',err,{root:true})
        return Promise.reject(err);
      })
    },
    deleteProjectLocal(context,project_id){
      //delete local DB
      const localForm=new PouchDB(project_id)
      const db=context.state.db
      return localForm.destroy().then(rep=>{
        return db.get('config')
      }).then(doc=>{
        //remove record in config
        let projects=doc.projects.filter(x=>x.id!=project_id)
        doc.projects=projects
        return db.put(doc)//save
      }).then(rep=>{
        return context.dispatch('openLocalStore')
      }).catch(err=>context.dispatch('app_message_error',err,{root:true}))
    },
    addOtherDatabaseLocal(context,dbName){
      return context.dispatch('openLocalStore').then(()=>{ 
        return context.dispatch('getDatabase',{dbName:dbName,alwaysGetLocalDb:true})
      }).then(()=>{  
        return context.state.db.get('config')
      }).then(doc=>{
        if(!doc.otherDatabaseLocal){doc.otherDatabaseLocal=[]}
        let dbs=doc.otherDatabaseLocal.filter(x=>x.id!=dbName)
        dbs.push({
          id:dbName,
          creation_time: context.rootGetters['utcCurrentTime'](),
          last_sync_time: context.rootGetters['utcCurrentTime'](),
        })
        doc.otherDatabaseLocal=dbs
        return context.state.db.put(doc)//save
      }).then(response=>{
        //reload my config
        context.dispatch('openLocalStore') // get the rev
        // notice the user it is added sucessfully
        return Promise.resolve(true);
      }).catch(err => {
        context.dispatch('app_message_error',err,{root:true})
        return Promise.reject(err);
      })
    },
    deleteOtherDatabaseLocal(context,dbName){
      //delete local DB
      const localForm=new PouchDB(dbName)
      const db=context.state.db
      return localForm.destroy().then(rep=>{
        return db.get('config')
      }).then(doc=>{
        //remove record in config
        let otherDatabaseLocal=doc.otherDatabaseLocal.filter(x=>x.id!=dbName)
        doc.otherDatabaseLocal=otherDatabaseLocal
        return db.put(doc)//save
      }).then(rep=>{
        return context.dispatch('openLocalStore')
      }).catch(err=>context.dispatch('app_message_error',err,{root:true}))
    },
    getDatabase(context, options){
      //use to get all database, take into account online or offline
      //options are dbName and db (hold a pouchdb object if already defined, this will check if need to replace.)
      //TODO: move to this fucntion the old ones as in form.js
      // console.log('getDatabase')
      const dbName=options.dbName
      if(dbName===undefined){
        console.error('error in getDatabase provide a dbName')
      }
      //set the name we want
      let opts = {}
      let response={ //pass some information that are be useful... to some
        connection:null,
        online:null,
        createdLocal:false,
      }
      if((context.state.databasesAccesMode == 1 || options.alwaysUseRemoteDb) && options.alwaysGetLocalDb!==true){
        opts = context.rootGetters.pouchDbOptions
        response.connection=context.rootState.couchdbUrl+dbName
        response.online=true
      }else if (context.getters.hasLocalDatabase(dbName) && (context.state.databasesAccesMode == 2 || options.alwaysGetLocalDb) && options.alwaysUseRemoteDb!==true) {
        response.connection=dbName
        response.online=false
      }else if(options.alwaysGetLocalDb===true){
        // usually this is for creation
        response.connection=dbName
        response.online=false
        response.createdLocal = true
      }else{
        // console.error('localDB/getDatabase => This case of getDatabase should not happen');
        throw 'localDB/getDatabase => This case of getDatabase should not happen'
      }
      //*** now check if we update db
      if(options.db){
        //check if it's the same
        if(options.db.name==response.connection){
          return Promise.resolve(Object.assign({db:options.db,sameAsProvided:true},response))
        }else{
          return Promise.resolve(Object.assign({db:new PouchDB(response.connection, opts),sameAsProvided:false},response))
        }
      }else{
        //don't have any already, create a new one.
        return Promise.resolve(Object.assign({db:new PouchDB(response.connection, opts),sameAsProvided:false},response))
      }
    },
    setLocalUserName(context,userCtx){
      const db = context.state.db
      return db.get('config').then(doc=>{
        doc.userCtx = userCtx
        doc.userName = userCtx.name
        return db.put(doc)//save
      }).then(response=>{
        return context.dispatch('openLocalStore') //reload my config
      })
    }
  },
  getters: {
    isOnlineMode(state){
      if(state.databasesAccesMode==1){
        return true
      }else if(state.databasesAccesMode==2){
        return false
      }
      return undefined
    },
    hasLocalForm: (state) => (form_id) => {
      if(state.config){
        return state.config.forms.find(x => x.id == form_id)!== undefined
      }
    },
    hasLocalProject: (state) => (project_id) => {
      if(state.config && state.config.projects){
        return state.config.projects.find(x => x.id == project_id)!== undefined
      }
    },
    hasLocalOtherDatabase: (state) => (dbName) => {
      if(state.config && state.config.otherDatabaseLocal){
        return state.config.otherDatabaseLocal.find(x => x.id == dbName)!== undefined
      }
    },
    hasLocalDatabase:(state,getters)=>(dbName)=>{
      if(getters.hasLocalForm(dbName)){return true}
      if(getters.hasLocalProject(dbName)){return true}
      if(getters.hasLocalOtherDatabase(dbName)){return true}
      return false
    },
    listLocalForms(state){
      return state.config.forms
    },
    listLocalProjects(state){
      return state.config.projects
    },
    listLocalOtherDatabase(state){
      return state.config.otherDatabaseLocal
    },
    device_id(state){
      if(state.config && state.config.device_id){
        return state.config.device_id
      }
    },
    form_object_for_local_add:(state,getters,rootState, rootGetters)=>(formId)=>{
      let projectId=null
      const formSytemConfig=rootGetters['forms'].filter(x=>x.id==formId)[0]
      if(formSytemConfig.project_id){
        projectId=formSytemConfig.project_id
      }
      if(projectId===null){
        console.error('No project id found')
        return null
      }
      let form2= Object.assign({},formSytemConfig)
      form2.project=rootGetters['projects'].filter(x=>x.id==projectId)[0]
      form2= JSON.parse( JSON.stringify(form2)) // or keep a reference to the project object and we have a problem that the current project object is corrupted
      delete form2.project.forms
      return form2
    }

  }
}

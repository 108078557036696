<template>
    <div v-if="relevant && printVisibleRepeat">
      <div 
        :style="{'padding-left': padding + 'px'}"
        :class="groupClasses"
        v-if="printLabel"
      >
        {{prefixDash}}<span v-html="printLabel"></span>
      </div>
      <div :id="'repeat'+config.name+i" :class="{'list-group': printStyle!='raw'}" v-for="(data1,i) in value" :key="'repeat'+config.name+i">
        <print-group
            :config="config"
            :formDefinition="formDefinition"
            :value="data1"
            :status="status[i]"
            :showSystemValues=showSystemValues
            :surveyDoc="surveyDoc"
            :namePath="newNamePath(i)"
            :pagesObj="pagesObj"
            :isRootRepeat="true"
            >
        </print-group>
      </div>
    </div>
</template>

<script>
import base from '../input/_baseInput.js'
import formUtils from '../../utils/form_utils.js'

export default {
  name:'print-repeat',
  mixins:[base],
  props:['pagesObj'],
  data(){
    return {
    }
  },
  beforeCreate () {
    //if we use normal registration, it doesn't work.
    //https://vuejs.org/v2/guide/components-edge-cases.html#Circular-References-Between-Components
    this.$options.components.PrintGroup = require( './printGroup.vue').default
  },
  computed:{
    padding(){
      if(this.printStyle == 'raw'){
        return 0
      }
      return (this.namePath.length) * 40
    },
    prefixDash(){
      if(this.printStyle == 'raw'){
        if(this.printLabel){
          return '-'.repeat(this.namePath.length+1)
        }
        return ''
      }
    },
    groupStatus(){
      if(Array.isArray(this.status)){
        return formUtils.getStatusOfTree(this.status)
      }
      if(this.status){
        return this.status
      }
    },
    groupClasses(){
      if(this.printStyle != 'raw'){
        return ['list-group-item']
      }
      return ''
    },
  },
  methods:{
    newNamePath(index){
      let new1=this.namePath.slice()
      if(!this.isRoot){
        new1.push(this.config.name)
        new1.push(index)
      }
      return new1
    },
    
  }
}
</script>

<style>

</style>

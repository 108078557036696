// as localConfig in index.js

import Vue from 'vue'

// Load existing local config
let existingLocalConfig = {}
const existingLocalConfigText = localStorage.getItem('AecomFormLocalConfig')
if(existingLocalConfigText){
  // parse json
  existingLocalConfig = JSON.parse(existingLocalConfigText)
}

export default {
  namespaced: true,
  state: {
    jsonConfig: existingLocalConfig
  },
  actions: {
    setConfig(context, data){
      Vue.set(context.state.jsonConfig, data.key, data.val)
      context.dispatch('saveLocalConfig')
    },
    saveLocalConfig(context){
      localStorage.setItem('AecomFormLocalConfig', JSON.stringify(context.state.jsonConfig))
    }
  }
}

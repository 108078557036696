<template>
  <span v-if="showGpsAccuracy">
    <div v-if="uiVersion==0">
      <!-- :variant="accuracy<1?'success':'warning'" -->
      <b-btn class="mt-1" 
        :disabled="true"
        v-if="accuracy"
        >
        <!-- <span class="small">{{labels.map.accuracy}}: ></span -->
        <svg-precision width="1.5em" height="1.5em"/> {{accuracy_round}}</b-btn>
      <b-btn class="mt-1" :disabled="true" v-if="!accuracy" variant="danger"><svg-precision width="1.5em" height="1.5em"/></b-btn>
    </div>
    <span v-if="uiVersion==1">
      <b-btn 
        size="sm"
        class="ml-2"
        :disabled="true"
        >
        <!-- <span class="small">{{labels.map.accuracy}}: ></span -->
        <svg-precision width="1.5em" height="1.5em"/> {{accuracy_round}}</b-btn>
    </span> 
  </span>
</template>

<script>
import map_utils from '../map_utils.js'
import base from './_base.js'
import svgPrecision from '../../../../components/svg/precision.vue'

export default{
  name:'map-accuracy',
  mixins:[base],
  components:{svgPrecision},
  props:{
    uiVersion:{
      default:0
    }
  },
  data(){
    return{
      
    }
  },
  created(){

  },
  computed:{
    accuracy(){
      return this.$store.state.position.accuracy
    },
    accuracy_round(){
      return Math.round(+this.accuracy*100)/100
    },
    showGpsAccuracy(){
      return this.$store.getters['position/showGpsAccuracy']
    }
  },
  asyncComputed:{
   
  },
  methods:{
    init(){
      
    },
  }
}
</script>

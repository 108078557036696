<template>
<div class="">
    <template v-if="infoByCol && info">
      <div class="container p-0">
        <div class="row">
          <div class="col-6">
            <div v-html="label" class="my-wrap"></div>
          </div>
          <div class="col-6">
            <div class="info-text my-wrap" v-html="info"></div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div v-html="label" class="my-wrap"></div>
      <div v-if="oneline && info"> // </div>
      <div class="info-text ml-2 my-wrap" v-if="info" v-html="info"></div>
    </template>
  </div>
</template>

<script>
  export default {
    name: 'select-option-text',
    props:['opt', 'oneline', 'appearance_aecom'],
    computed: {
      selectInfoMode(){
        return this.$store.state.form.formDisplayOptions.selectInfoMode 
      },
      infoRaw() {
        // Additional info to show, take into account the form language.
        if(typeof this.opt?.info == 'object'){
          return this.opt?.info?.[this.$store.state.form.formLanguage] ?? this.opt?.info?.default ?? null
        }
        return this.opt?.info ?? null
      },
      info() {
        if(this.selectInfoMode == 'label+info'){
          return this.infoRaw
        }
        return null
      },
      label(){
        let rep = null
        if(typeof this.opt.label == 'object'){
          rep = this.opt?.label?.[this.$store.state.form.formLanguage] ?? this.opt?.label?.default ?? null
        }else{
          rep = this.opt?.label ?? this.opt?.name ?? null
        }
        if(this.selectInfoMode == 'info'){
          return this.infoRaw ?? rep
        }
        // this.selectInfoMode == 'label'
        return rep
      },
      infoByCol(){
        return (this.appearance_aecom?.indexOf('infoByCol') ?? -1) != -1
      },
    },
  }
</script>

<style scoped>
  .info-text{
    /* font-size: 0.8em; */
    color: #959575;
  }
  .my-wrap{
    /* indent the second line and avoid nowrap in the v-select choices if the text is too long. */
    white-space: normal;
    padding-left:0.8em;
    text-indent:-0.8em;
  }
</style>
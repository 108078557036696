<template>
  <input-row 
    :showElement="showElement"
    :label="label" 
    :elId="elId" 
    :status="status" 
    :config="config"
    :me="me"
  >
    <div class="row">
      <datetime 
        v-model="myValue" 
        auto 
        :min-datetime="minDate" 
        :max-datetime="maxDate"
        :flow="flow"
        format="yyyy-LL-dd"
        value-zone="local"
        zone="local"
        :input-id="elId"
        :disabled="readonly"
      ></datetime>  
      <b-btn class="glyphicon glyphicon-remove ml-3" variant="outline-danger" size="sm" @click="myValue=null" v-if="!readonly"></b-btn>  
    </div>
  </input-row>
</template>

<script>
import base from './_baseInput.js'
// https://www.npmjs.com/package/vue-datetime
import { Datetime } from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css';
import { DateTime as LuxonDateTime } from 'luxon'

export default {
  name:'IDate',
  mixins:[base],
  components:{
    datetime: Datetime
  },
  data() {
    return {

    }
  },
  computed:{
    // TODO: add config for min and max 
    minDate(){
      if(this.parameters['date-min'] ){
        return this.parameters['date-min']
      }
      return LuxonDateTime.local().minus({ year: 130 }).toISO()
    },
    maxDate(){
      if(this.parameters['date-max'] ){
        return this.parameters['date-max']
      }
      //today
      return LuxonDateTime.local().toISO()
    },
    flow(){
      if(this.appearance_aecom.indexOf('short')!=-1){
        return ['date']
      }
      // this is the default as for compatibility support for old surveys
      return ['year', 'month', 'date']
    }
  },
  watch:{

  },
  methods: {
    formatEmitValue(){
      // Mostly for components that want to format value before emit
      if (this.myValue && this.myValue.indexOf('T')!=-1){
        return this.myValue.split('T')[0]
      }
      return this.myValue
    },
    formatInputValue(){
      if (this.value && this.value.indexOf('T')!=-1){
        return this.value.split('T')[0]
      }
      // Mostly for components that want to format value before emit
      return this.value
    },
  },
}
</script>

<style>

</style>
